import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from '../../components';
import moment from 'moment';

const Confirmation = (props) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Layout headerTitle="Schedule" onClickBack={() => history.goBack()} back>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Confirmation</h5>
                <p className="card-text">Your appointment has been scheduled.</p>
                <p className="card-text">
                  <b>Appointment Time:</b>{' '}
                  {moment(location.state.appointmentTime).format('MMMM Do YYYY, h:mm a')}
                </p>
                <p className="card-text">
                  <b>Appointment Location</b> {location.state.location}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Confirmation;
