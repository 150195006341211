import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Form } from 'react-bootstrap';
import Alert from '@material-ui/lab/Alert';

import './Login.css';
import { gsignIn } from '../../api/auth';
import { storeData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getUserData } from '../../api/models/user';
import { setUser, setUserInsurance } from '../../store/action/user';
import { authentication } from '../../store/action/auth';
import { GoogleLogin } from 'react-google-login';

function Login(props) {
  const { authenticationDispatch, setUserDispatch, setUserInsuranceDispatch } = props;
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({ invalid: false, message: '' });
  const { invalid, message: errorMessage } = validation;

  const history = useHistory();

  async function glogin(gdata) {
    try {
      setLoading(true);
      const response = await gsignIn(gdata);
      setLoading(false);

      if (response.jwt) {
        storeData(USER_CACHE_NAME, { ...response, status: 'login' });
        const profile = await getUserData(response.user_id, response.jwt);
        setUserDispatch(profile.data);
        setUserInsuranceDispatch(profile.included || []);
        authenticationDispatch({
          ...response,
          status: 'login',
          loading: false,
        });
        history.push('/profile');
      } else {
        const { message } = response;

        if (message) {
          setValidation({ invalid: true, message });
        } else {
          setValidation({ invalid: true, message: 'error' });
        }
      }
    } catch (error) {
      setLoading(false);
      setValidation({ invalid: true, message: 'something went wrong' });
    }
  }

  async function handleLoginFailure(data) {
    let msg = (data && data.details) || 'An error occurred';
    alert(msg);
  }
  const { brand } = useParams();

  let s = `../${brand}.png`;

  return (
    <>
      <Form className="px-4 py-5 my-5 login-card form-login">
        <div className="mt-2 text-center welcome-text ">Welcome To</div>
        <div className="mt-2 text-center welcome-text ">Aura</div>

        <img alt="" src={s} className="my-5 logo" />
        {invalid && (
          <Alert severity="error" className="mb-2">
            {errorMessage}
          </Alert>
        )}
        <div className="gbutton mb-3">
          <GoogleLogin
            clientId="814079886124-0seg2j8e74gs86s0866g3dln5nh6en7m.apps.googleusercontent.com"
            onSuccess={glogin}
            onFailure={handleLoginFailure}
            cookiePolicy="single_host_origin"
            responseType="code,token"
          >
            {loading ? <Spinner animation="border" variant="warning" /> : 'Login'}
          </GoogleLogin>
        </div>

        <Form.Group
          controlId="formBasicCheckbox"
          className="d-flex justify-content-center align-items-center pb-3"
        >
          <a
            className="medium-sub-font-blue pr-3"
            href="https://auratracker.org/privacy_policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          |
          <a
            className="medium-sub-font-blue pl-3"
            href="https://auratracker.zendesk.com/hc/en-us/requests/new"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
        </Form.Group>
      </Form>
    </>
  );
}

const mapStateTopProps = (state) => ({
  authentication: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(Login);
