import React, { useState, useEffect } from 'react';
import './FamilyInsuranceCheck.module.css';
import Check from '../../assets/image/check2.png';
import { useHistory, useParams } from 'react-router';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getUserData, getUserFamilyMembers } from '../../api/models/user';
import InsuranceShield from '../../assets/image/insuranceshield.png';

export default function FamilyInsuranceCheck() {
  const [selectedValue, setSelectedValue] = useState('');
  const { familyMemberId } = useParams();
  const history = useHistory();
  const [familyMembers, setFamilyMembers] = useState([]);
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const userDataCache = await getStoredData(USER_CACHE_NAME);
    setUser(userDataCache);
    let familyMemberList =
      (await getUserFamilyMembers(userDataCache.user_id, userDataCache.jwt)).included || [];
    setFamilyMembers(familyMemberList);
    let userData = await getUserData(userDataCache.user_id, userDataCache.jwt);
    const name =
      familyMemberList.find((member) => member?.id == familyMemberId)?.attributes?.['first-name'] ||
      userData?.data?.attributes?.['first-name'];
    console.log(name);
    setName(name);
    console.log(userData);
  };

  const handleSubmit = () => {
    if (selectedValue == 'yes') {
      const url = `/activation-insurance/${familyMemberId}`;
      console.log(url);
      history.push(url, {
        selectedMember: familyMemberId,
      });
    }
    if (selectedValue == 'no') {
      history.push('/ineligible-hrsa');
    }
  };

  return (
    <>
      <div className="text-center noCost-header mt-5 pt-2">Insurance information</div>
      <div className="border m-0 my-3" />

      <div className="pt-5 mt-5">
        <div className="d-flex justify-content-center align-items-center">
          <img src={InsuranceShield} alt="insurance" width={100} />
        </div>
        <div className="text-center noCost-header p-0 m-0 mt-5 pt-1">
          Does {name} have Health Insurance?
        </div>
      </div>

      <div className="row m-0 p-0 mt-2 mb-5 pb-5">
        <div className="col-10 offset-1 col-sm-6 offset-sm-3 ">
          <div
            className={`mt-4 d-flex justify-content-center align-items-center w-100 ${
              selectedValue === 'yes' ? 'selected-button' : 'unSelected-button'
            }`}
            onClick={() => setSelectedValue('yes')}
          >
            {selectedValue === 'yes' && <img src={Check} alt="Check" className="mr-2" />} Yes, I
            have Health Insurance
          </div>

          <div
            className={`mt-3 mb-5 d-flex justify-content-center align-items-center w-100 ${
              selectedValue === 'no' ? 'selected-button' : 'unSelected-button'
            }`}
            onClick={() => setSelectedValue('no')}
          >
            {selectedValue === 'no' && <img src={Check} alt="Check" className="mr-2" />} No, I do
            not have Health Insurance
          </div>

          <div
            className="mt-5 d-flex justify-content-center align-items-center continue-button"
            style={selectedValue ? {} : { opacity: '0.4' }}
            onClick={() => {
              if (selectedValue && handleSubmit) handleSubmit(selectedValue);
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </>
  );
}
