import React from 'react';
import { useTranslation } from 'react-i18next';

const Symptoms = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row ml-4 mr-4">
        <div className="col-sm pt-5">
          <h5>{t('screenerInstructions3')}</h5>
        </div>
      </div>

      <div className="row pt-4 mx-2">
        <div className="col-md-6 pb-4">
          <label className="h-100 w-100">
            <input
              type="radio"
              name="pwn_health_order[symptom_severity]"
              className="card-input-element d-none radio"
              id="mild"
              value="mild"
            />
            <div className="card card-input h-100 w-100">
              <div className="card-header">
                <div className="row">
                  <div className="col text-left">Mild</div>
                  <div id="mild-visual" className="col text-right">
                    <img src="/circle.svg" alt="" width="15" height="15" />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="disc pl-4">
                  <li>{t('screenerMildSymptom1')}</li>
                  <li>{t('screenerMildSymptom2')}</li>
                  <li>{t('screenerMildSymptom3')}</li>
                  <li>{t('screenerMildSymptom4')}</li>
                  <li>{t('screenerMildSymptom5')}</li>
                  <li>{t('screenerMildSymptom6')}</li>
                  <li>{t('screenerMildSymptom7')}</li>
                  <li>{t('screenerMildSymptom8')}</li>
                </ul>
              </div>
            </div>
          </label>
        </div>

        <div className="col-md-6 pb-4">
          <label className="h-100 w-100">
            <input
              type="radio"
              name="pwn_health_order[symptom_severity]"
              className="card-input-element d-none radio"
              id="none"
              value="none"
            />

            <div className="card card-input h-100 w-100">
              <div className="card-header">
                <div className="row">
                  <div className="col text-left">None</div>
                  <div id="none-visual" className="col text-right">
                    <img src="/circle.svg" alt="" width="15" height="15" />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="disc pl-4">
                  <li>{t('screenerNoneSymptom')}</li>
                </ul>
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default Symptoms;
