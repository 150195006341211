import React from 'react';
import classNames from 'classnames';
import { Layout } from '../../components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

import okIcon from '../../assets/svg/feelok.svg';
import greatIcon from '../../assets/svg/feelgreat.svg';
import sickIcon from '../../assets/svg/feelsick.svg';

const Eligibility = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { history } = props;

  return (
    <Layout
      tab={activeTab}
      headerTitle={t('checkInEligibility')}
      onClickBack={() => history.goBack()}
      back
    >
      <div className="vaccine">
        <div className={css.subContainer}>
          <p>{t('howAreYou')}</p>
          <div className={css.listItem} onClick={() => history.push('/vaccinedocs')}>
            <div className={css.left2}>
              <img src={greatIcon} alt="Logo" className={classNames(css.scanIcon, css.well)} />
            </div>
            <div className={css.middle}>
              <div className={css.stationHeader}>{t('iFeelGreat')}</div>
              <p className="sub-font">{t('iAmIn')}</p>
            </div>
          </div>

          <div className={classNames(css.listItem)} onClick={() => history.push('/vaccinedocs')}>
            <div className={css.left2}>
              <img src={okIcon} alt="Logo" className={classNames(css.scanIcon, css.ok)} />
            </div>
            <div className={css.middle}>
              <div className={css.stationHeader}>{t('iFeelOkay')}</div>
              <p className="sub-font">{t('notMyGreatest')}</p>
            </div>
          </div>

          <div className={classNames(css.listItem)} onClick={() => history.push('/vaccine')}>
            <div className={css.left2}>
              <img src={sickIcon} alt="Logo" className={classNames(css.scanIcon, css.sick)} />
            </div>
            <div className={css.middle}>
              <div className={css.stationHeader}>{t('iDoNot')}</div>
              <p className="sub-font">{t('poorHealth')}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Eligibility);
