import * as React from 'react';

function Ep_locationInformation(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_278_38102)" fill="#8B8B8B">
        <path d="M17.614 8.613a6.605 6.605 0 10-13.21 0c0 2.71 2.168 6.241 6.605 10.471 4.437-4.23 6.605-7.761 6.605-10.47zM11.009 21.09c-5.382-4.892-8.072-9.051-8.072-12.476a8.072 8.072 0 0116.144 0c0 3.425-2.69 7.584-8.072 12.476z" />
        <path d="M11.009 10.815a2.202 2.202 0 100-4.403 2.202 2.202 0 000 4.403zm0 1.468a3.67 3.67 0 110-7.339 3.67 3.67 0 010 7.339z" />
        <rect x={2.937} y={20.355} width={16.145} height={2.202} rx={1.101} />
      </g>
      <defs>
        <clipPath id="prefix__clip0_278_38102">
          <path fill="#fff" transform="translate(0 .541)" d="M0 0h22.016v22.016H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoEp_locationInformation = React.memo(Ep_locationInformation);
export default MemoEp_locationInformation;
