import * as React from 'react';

function History(props) {
  return (
    <svg width="45px" height="45px" viewBox="0 0 57 57" fill="none" {...props}>
      <rect x={0.859} y={1.068} width={55.48} height={54.599} rx={6.605} fill="#fff" />
      <rect
        x={0.859}
        y={1.068}
        width={55.48}
        height={54.599}
        rx={6.605}
        fill="#F90"
        fillOpacity={0.8}
      />
      <path
        d="M39.608 21.763v-9.54a1.908 1.908 0 00-1.909-1.908H18.62a1.908 1.908 0 00-1.908 1.908v19.08a1.908 1.908 0 001.908 1.908h9.54"
        stroke="#000"
        strokeWidth={2.642}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.003 30.57a3.963 3.963 0 100-7.926 3.963 3.963 0 000 7.925z"
        stroke="#000"
        strokeWidth={2.642}
      />
      <path
        d="M36.085 28.808l3.523 2.642M21.995 16.48h12.329M21.995 21.763h4.403"
        stroke="#000"
        strokeWidth={2.642}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.784 48.495h.966v-3.53h4.254v3.53h.967v-7.75h-.967v3.35H11.75v-3.35h-.966v7.75zm8.606-6.908c.354 0 .644-.29.644-.644a.647.647 0 00-.644-.645.646.646 0 00-.645.645c0 .354.29.644.645.644zm-.473 6.908h.935v-5.79h-.935v5.79zm4.75.102c1.316 0 2.32-.715 2.32-1.746v-.01c0-.828-.526-1.3-1.617-1.564l-.891-.215c-.682-.166-.973-.413-.973-.805v-.01c0-.511.505-.866 1.193-.866.698 0 1.15.317 1.273.774h.918c-.129-.946-.972-1.553-2.186-1.553-1.23 0-2.154.726-2.154 1.687v.005c0 .833.49 1.306 1.574 1.563l.897.215c.714.172 1.01.446 1.01.838v.01c0 .527-.553.898-1.332.898-.741 0-1.203-.317-1.359-.8h-.956c.107.956.994 1.579 2.283 1.579zm5.84-.06c.182 0 .36-.02.542-.053v-.795a3.905 3.905 0 01-.43.021c-.601 0-.837-.273-.837-.956v-3.276h1.267v-.773h-1.267v-1.499h-.967v1.499h-.913v.773h.913v3.513c0 1.106.5 1.547 1.692 1.547zm4.298.06c1.649 0 2.67-1.139 2.67-2.992v-.01c0-1.86-1.021-2.992-2.67-2.992s-2.67 1.133-2.67 2.991v.011c0 1.853 1.021 2.992 2.67 2.992zm0-.828c-1.096 0-1.713-.8-1.713-2.164v-.01c0-1.37.617-2.165 1.713-2.165s1.713.795 1.713 2.164v.011c0 1.364-.617 2.165-1.713 2.165zm4.186.726h.934v-3.588c0-.849.634-1.413 1.526-1.413.204 0 .38.022.574.054v-.908a3.139 3.139 0 00-.467-.038c-.784 0-1.327.355-1.547.962h-.086v-.86h-.934v5.79zm4.803 2.03c1.026 0 1.504-.376 1.982-1.676l2.261-6.144h-.983l-1.584 4.764h-.086l-1.59-4.764h-1l2.144 5.795-.107.344c-.21.666-.532.907-1.064.907-.129 0-.274-.005-.387-.026v.768c.13.021.29.032.414.032z"
        fill="#000"
      />
      <rect
        x={0.859}
        y={1.068}
        width={55.48}
        height={54.599}
        rx={6.605}
        stroke="#F90"
        strokeWidth={0.881}
      />
    </svg>
  );
}

const MemoHistory = React.memo(History);
export default MemoHistory;
