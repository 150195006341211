import * as Yup from 'yup';
import moment from 'moment';

export const signupSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string()
    .required('Date of birth is required')
    .test('isValid', 'Invalid Date', (value) => {
      const date = moment(value).format('MM/DD/YYYY');

      if (moment(date, 'MM/DD/YYYY', true).isValid() && moment().diff(date) > 0) return true;
      return false;
    })
    .test('is!8', 'Less than 18 years.', (birthday) => {
      let optimizedBirthday = birthday?.replace(/-/g, '/');

      let myBirthday = new Date(optimizedBirthday);

      let currentDate = `${new Date().toJSON().slice(0, 10)} 01:00:00`;

      let myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

      if (myAge < 18) {
        return false;
      }
      return true;
    }),
  addressLine1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string()
    .required('Zip code is required')
    .nullable()
    .test('is-present', 'Invalid Zip Code', async (value) =>
      /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    ),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test(
      'len',
      'Phone number must be exactly 13 characters: (###) ###-####',
      (val) => val?.replace(/ /g, '')?.length === 13
    ),
  gender: Yup.string().required('Gender is required'),
  race: Yup.string().required('Race is required'),
  ethnicity: Yup.string().required('Ethnicity is required'),
  email: Yup.string()
    .required('Email is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Must be a valid email address'),
  emailConfirmation: Yup.string()
    .required('Email confirmation is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Must be a valid email address')
    .oneOf([Yup.ref('email'), null], 'Emails must match'),
  password: Yup.string()
    .max(70, 'Password must be less than 70 characters')
    .min(8, 'Password must be a minimum of 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character'
    )
    .required('Password is required'),
});
