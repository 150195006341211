import React, { useState } from 'react';
import { useHistory } from 'react-router';
import MaskedInput from 'react-text-mask';
import MenuItem from '@material-ui/core/MenuItem';
import { Form, Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Layout, ListForm } from '../../components';
import css from './AddContacts.module.css';
import { validateUserContacts } from '../../api/validation';
import { processAPI } from '../../api/models/process';
import { generateId } from '../../api';
import { addNewContact } from '../../store/action/user';

const categoryList = [
  { value: 'associates', label: 'Associates' },
  { value: 'familyMembers', label: 'Family Members' },
];

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '1', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const AddContact = (props) => {
  const history = useHistory();
  const { addNewContactDispatch } = props;

  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [category, setCategory] = useState('familyMembers');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;

  async function onSubmit(e) {
    e.preventDefault();
    const { authentication } = props;
    const { invalid, message } = validateUserContacts({
      familyName,
      givenName,
      email,
      phoneNumber,
    });
    setValidation({ invalid, message });

    if (!invalid) {
      try {
        const myData = {
          data: {
            type: 'user-contacts',
            attributes: {
              'user-id': authentication.user_id,
              'contact-id': generateId(),
              'contact-details': {
                familyName,
                givenName,
                email,
                phoneNumber,
                category,
              },
            },
          },
        };

        setLoading(true);
        const data = await processAPI({
          key: authentication.jwt,
          methodType: 'POST',
          data: myData,
          endpoint: 'user-contacts',
        });
        setLoading(false);

        addNewContactDispatch([myData.data]);
        history.goBack();
      } catch (error) {
        console.log('error adding contacts', error);
      }
    }
  }

  return (
    <Layout headerTitle="Add Contact" onClickBack={() => history.goBack()} back>
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.subContainer}>
            <Form noValidate onSubmit={onSubmit}>
              <ListForm
                title="Given Name"
                value={givenName}
                placeholder="input your given name"
                onChange={(e) => setGivenName(e.target.value)}
                required
                error={invalid === 'givenName'}
                errorMessage={errorMessage}
              />
              <ListForm
                title="Family Name"
                value={familyName}
                placeholder="input your family name"
                onChange={(e) => setFamilyName(e.target.value)}
                required
                error={invalid === 'familyName'}
                errorMessage={errorMessage}
              />
              <ListForm
                title="Email"
                type="email"
                value={email}
                placeholder="input email address"
                onChange={(e) => setEmail(e.target.value)}
                required
                error={invalid === 'email'}
                errorMessage={errorMessage}
              />
              <ListForm
                id="phone-number"
                title="Phone Number"
                value={phoneNumber}
                placeholder="phonenumber"
                onChange={(e) => setPhoneNumber(e.target.value)}
                inputcomponent={TextMaskCustom}
                required
                autofocus
                setCursor
                error={invalid === 'phoneNumber'}
                errorMessage={errorMessage}
              />
              <ListForm
                title="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                select
                required
              >
                {categoryList.map((item) => (
                  <MenuItem className="normal-font" value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </ListForm>
              <Button type="submit" className="mt-4 btn btn-login">
                {loading ? <Spinner animation="border" variant="warning" /> : 'Add'}
              </Button>
            </Form>
          </div>
        </div>
        <div className={css.flex1} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({
  addNewContactDispatch(payload) {
    dispatch(addNewContact(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
