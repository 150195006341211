import React from 'react';
import { useTranslation } from 'react-i18next';

const Risk = () => {
  const { t } = useTranslation();

  return (
    <div className="row ml-4 mr-4">
      <div className="col-sm pt-5">
        <h5 id="comorbidity-instructions">
          Please choose all options that describe you. (Select all that apply)
        </h5>
        <p className="text-muted">
          Select "No conditions" if none of the other options apply to you.
        </p>

        <div className="row pt-4">
          <div className="col-md-6 pb-4">
            <label className="h-100 w-100">
              <input
                type="radio"
                name="pwn_health_order[comorbidity_risk]"
                className="card-input-element d-none radio"
                id="high_risk"
                value="high"
              />
              <div className="card card-input h-100 w-100">
                <div className="card-header">
                  <div className="row">
                    <div className="col text-left">High Risk</div>
                    <div id="high-risk-visual" className="col text-right" />
                  </div>
                </div>
                <div className="card-body">
                  <li>{t('HighRisk1')}</li>
                  <li>{t('HighRisk2')}</li>
                  <li>{t('HighRisk3')}</li>
                  <li>{t('HighRisk4')}</li>
                  <li>{t('HighRisk5')}</li>
                  <li>{t('HighRisk6')}</li>
                </div>
              </div>
            </label>
          </div>

          <div className="col-md-6 pb-4">
            <label className="h-100 w-100">
              <input
                type="radio"
                name="pwn_health_order[comorbidity_risk]"
                className="card-input-element d-none radio"
                id="low_risk"
                value="low"
              />
              <div className="card card-input h-100 w-100">
                <div className="card-header">
                  <div className="row">
                    <div className="col text-left">Low Risk</div>
                    <div id="low-risk-visual" className="col text-right" />
                  </div>
                </div>
                <div className="card-body">
                  <label>I do not have any of these conditions</label>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Risk;
