import React from 'react';

import CaretDownIcon from '../../assets/svg/caret_down_wt.svg';
import { Table, Row, Accordion, Card } from 'react-bootstrap';
import css from './TestKits.module.css';
import step1 from '../../assets/image/test_kit_instructions/step_1.png';
import step2 from '../../assets/image/test_kit_instructions/step_2.png';
import step3 from '../../assets/image/test_kit_instructions/step_3.png';
import step4 from '../../assets/image/test_kit_instructions/step_4.png';
import step5 from '../../assets/image/test_kit_instructions/step_5.png';
import step6 from '../../assets/image/test_kit_instructions/step_6.png';
import step7 from '../../assets/image/test_kit_instructions/step_7.png';
import step8 from '../../assets/image/test_kit_instructions/step_8.png';
import step9 from '../../assets/image/test_kit_instructions/step_9.png';
import step10 from '../../assets/image/test_kit_instructions/step_10.png';
import step11 from '../../assets/image/test_kit_instructions/step_11.png';
import step12 from '../../assets/image/test_kit_instructions/step_12.png';
import step13 from '../../assets/image/test_kit_instructions/step_13.png';
import step14 from '../../assets/image/test_kit_instructions/step_14.png';
import step15 from '../../assets/image/test_kit_instructions/step_15.png';
import step16 from '../../assets/image/test_kit_instructions/step_16.png';
import step17 from '../../assets/image/test_kit_instructions/step_17.png';
import step18 from '../../assets/image/test_kit_instructions/step_18.png';
import { useTranslation } from 'react-i18next';

function InstructionList() {
  const { t, i18n } = useTranslation();
  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="1"
          style={{ backgroundColor: '#1B3D6E', padding: '12px' }}
        >
          <div className="d-flex flex-row">
            <img alt="steps" src={CaretDownIcon} width={25} />
            <div className="ml-2">
              <h4 className={css.subTitle}>{t('collectionInstructions')}</h4>
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <>
            <h5 className="px-3 py-3" style={{ backgroundColor: '#F2F2F2' }}>
              {t('readAllInstructions')}
            </h5>

            <div className={css.gridTable}>
              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>1</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step1} alt="Logo" />
                  <p>{t('washAndDry')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>2</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step2} alt="Logo" />
                  <p>{t('usingANon')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>3</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step3} alt="Logo" />
                  <p>{t('nextUsingThe')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>4</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step4} alt="Logo" />
                  <p>{t('screwOffThe')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>5</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step5} alt="Logo" />
                  <p>{t('takeTheSwab')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>6</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step6} alt="Logo" />
                  <p>{t('holdSwabIn')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>7</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step7} alt="Logo" />
                  <p>{t('insertTheTip')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>8</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step8} alt="Logo" />
                  <p>{t('rotateTheSwab')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>9</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step9} alt="Logo" />
                  <p>{t('usingTheSame')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>10</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step10} alt="Logo" />
                  <p>{t('placeTheSwab')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>11</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step11} alt="Logo" />
                  <p>{t('breakTheSwab')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>12</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step12} alt="Logo" />
                  <p>{t('screwTheTop')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>13</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step13} alt="Logo" />
                  <p>{t('discardTheBroken')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>14</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step14} alt="Logo" />
                  <p>{t('washAndDry')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>15</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step15} alt="Logo" />
                  <p>{t('placeTheCollection')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>16</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step16} alt="Logo" />
                  <p>{t('sealTheBiohazard')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>17</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step17} alt="Logo" />
                  <p>{t('foldTheBiohazard')}</p>
                </div>
              </div>

              <div className={css.gridRow}>
                <div className={css.index}>
                  <strong>18</strong>
                </div>
                <div className={css.gridContainer}>
                  <img src={step18} alt="Logo" />
                  <p>{t('placeTheShipping')}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: 20,
                backgroundColor: '#E5F4F3',
                color: '#1B3D6E',
                border: '1px solid #8BD0C9',
              }}
            >
              <h5>
                <b>{t('returnTheTest')}</b>
              </h5>
              <p>
                <b>{t('yourTestKit')}</b>
              </p>
              <p>{t('pleaseDropYour')}</p>
              <p>{t('thePreAddressed')}</p>
              <p>
                {t('visit')}{' '}
                <a href="https://bit.ly/fedexdropbox" target="_blank" rel="noreferrer">
                  bit.ly/fedexdropbox
                </a>{' '}
                {t('onlineToView')}
              </p>
            </div>
          </>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default InstructionList;
