import * as React from 'react';

function Arrow(props) {
  return (
    <svg width="100%" height="47px" viewBox="0 0 24 47" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.127 35.491L5.34 24.35a1.227 1.227 0 010-1.698L16.127 11.51a1.647 1.647 0 012.38 0c.656.678.656 1.78 0 2.458l-9.226 9.534 9.226 9.53c.656.68.656 1.782 0 2.46a1.647 1.647 0 01-2.38 0z"
        fill="#F90"
      />
    </svg>
  );
}

const MemoArrow = React.memo(Arrow);
export default MemoArrow;
