import React from 'react';

import css from './TestKits.module.css';
import { useParams, useHistory, Link } from 'react-router-dom';
import { InstructionList, InstructionVideo } from '..';
import { Layout } from '../../components';
import { useTranslation } from 'react-i18next';

const KitInstructions = (props) => {
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <Layout
      tab={activeTab}
      headerTitle="GENETWORx COVID-19 Nasal Swab Test Kit Instructions"
      back
      onClickBack={() => history.goBack()}
    >
      <>
        <div>
          <div className={css.instructionsContainer}>
            <div className="mx-2">
              <h4 className="medium-font mb-4 pt-4 text-danger">
                {t('activateKitReminder')}
                <Link to="/testkits">{t('clickHere')}</Link> {t('toActivate')}
              </h4>
              <h5 className="pb-2">{t('yourTestKit')}</h5>

              <InstructionList />
              <InstructionVideo />
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default KitInstructions;
