import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Spinner, Form } from 'react-bootstrap';
// import Alert from "@mui/material/Alert";
import MuiAlert from '@material-ui/lab/Alert';

import './index.css';
import { processAPI } from '../../api/models/process';
import { TextField, CommonLogo } from '../../components';
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // discoverearly.auratracker.org is included here as 'aura'
    const source = window.location.hostname === 'genetworx.auratracker.org' ? 'gx' : 'aura';

    const data = await processAPI({
      methodType: 'POST',
      data: { email, source },
      endpoint: 'passwords',
    });

    if (data?.message) {
      setShowAlert(true);

      setTimeout(() => {
        setLoading(false);
        history.goBack();
      }, 2000);
    }
  };

  return (
    <div className="">
      <div
        className={`d-flex justify-content-center justify-content-sm-end mx-2 mx-sm-4 mt-4 align-items-center ${
          showAlert ? '' : 'invisible'
        }`}
      >
        <MuiAlert
          variant="outlined"
          severity="success"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionComponent="TransitionUp"
          className="d-flex justify-content-center text-center align-items-center alertText"
          style={{ height: '60px', background: '#F6FFF5' }}
        >
          <div className="alertText">If you have an account, we’ll email you a reset link.</div>
        </MuiAlert>
      </div>

      <div className="mt-sm-2 mb-3 d-flex justify-content-center">
        <CommonLogo />
      </div>

      <div className="mt-0 text-center forget_form_header d-flex justify-content-center">
        {t('didYouForget')}
      </div>

      <div className="mt-3 pt-1 text-center forget_form_header_des d-flex justify-content-center">
        {t('didYouForgethelp')}
      </div>

      <div className="d-flex justify-content-center">
        <Form onSubmit={onSubmit} className="px-sm-5 py-sm-5 my-5 forget_form">
          <TextField
            type="email"
            label={t('emailAddress')}
            placeholder="e.g. youremail@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            data-cy="email"
            autoCapitalize="none"
            required
            dat
            a-testid="FORGOTEMAIL"
          />

          <div className="mt-3">
            <button
              disabled={loading}
              type="submit"
              className="btn_reset_"
              data-testid="REQLINK"
              style={email ? {} : { opacity: '0.4' }}
            >
              {loading ? <Spinner animation="border" variant="warning" /> : t('resetmyPassword')}
            </button>
          </div>

          <div
            className="goBack my-3"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
