import { API } from '../const';

export function symptoms(key) {
  return new Promise(async (res, rej) => {
    try {
      const apiMedtests = `${API}symptoms`;

      const body = await fetch(apiMedtests, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });

      const json = await body.json();

      if (!json.errors) {
        res(json.data);
      }
      res([]);
    } catch (error) {
      res([]);
      console.log('error update 3', error);
    }
  });
}
