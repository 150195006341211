import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, StrokeButton, ListSimple, Divider } from '../../components';
import FamHistoryResults from './FamHistory_Results';
import css from './FamilyHistory.module.css';
import Close from '../../assets/svg/close.svg';
import GreenPositiveIcon from '../../assets/svg/green_positive.svg';
import GreenNegativeIcon from '../../assets/svg/green_negative.svg';
import RedNegativeIcon from '../../assets/svg/red_negative.svg';
import RedPositiveIcon from '../../assets/svg/red_positive.svg';
import OrangePendingIcon from '../../assets/svg/orange_pending.svg';
import OpenTriangle from '../../assets/image/OpenTriangleF.png';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Modal2 from 'react-bootstrap/Modal';
import closeButton from '../../assets/svg/close_button.svg';
import { getUserData } from '../../api/models/user';
import horizontalKebab from '../../assets/svg/horizontalkebab.svg';
import historyicon from '../../assets/svg/result-history.svg';
import symptomcheckericon from '../../assets/svg/symptom-checker.svg';
import insuranceicon from '../../assets/svg/insurance-icon.svg';
import documentsicon from '../../assets/svg/documents-icon.svg';
import vaxicon from '../../assets/svg/vax-icon.svg';
import qricon from '../../assets/svg/qr-icon.svg';

const FamilyHistory = (props) => {
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const iconSrc = {
    greenNegative: GreenNegativeIcon,
    greenPositive: GreenPositiveIcon,
    redPositive: RedPositiveIcon,
    redNegative: RedNegativeIcon,
    pending: OrangePendingIcon,
  };
  const history = useHistory();
  const [selectedData, setSelectedData] = useState();
  const { id } = useParams();

  const [navResults, setNavResults] = useState(false);
  const [navHealthReport, setNavHealthReport] = useState(false);
  const [navVaccines, setNavVaccines] = useState(false);
  const [navInsurance, setNavInsurance] = useState(false);
  const [navDocuments, setNavDocuments] = useState(false);
  const [navQRCode, setNavQRCode] = useState(false);

  async function loadUser() {
    const user = await getUserData(props?.authentication?.user_id, props?.authentication?.jwt);
    setNavHealthReport(user?.data?.attributes['health-report']);
    setNavResults(user?.data?.attributes['nav-results']);
    setNavVaccines(user?.data?.attributes['nav-vaccines']);
    setNavInsurance(user?.data?.attributes['requires-insurance']);
    setNavDocuments(user?.data?.attributes['nav-documents']);
    setNavQRCode(user?.data?.attributes['nav-scan']);

    return user.data;
  }

  const [showFamilyModal, setShowFamilyModal] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const goToResultsHistory = () => {
    history.push(`/results-history/${id}`);
  };

  const goToSymptomChecker = () => {
    history.push(`/daily-symptom-checker/${id}`);
  };

  const goToInsurance = () => {
    history.push(`/insurance-information/${id}`);
  };

  const goToVaccination = () => {
    history.push(`/vaccine-information/${id}`);
  };

  const goToDocuments = () => {
    history.push(`/documents/${id}`);
  };

  const hideEllipse = useMemo(() => {
    if (
      !navDocuments &&
      !navHealthReport &&
      !navInsurance &&
      !navQRCode &&
      !navResults &&
      !navVaccines
    )
      return true;
    return false;
  }, [navDocuments, navHealthReport, navInsurance, navQRCode, navResults, navVaccines]);

  return (
    <div className={css.page}>
      {!selectedData && !hideEllipse && (
        <div
          style={{
            top: '2%',
            right: '2%',
            position: 'fixed',
            cursor: 'pointer',
            zIndex: '10000',
          }}
          onClick={(e) => {
            setShowFamilyModal(!showFamilyModal);
          }}
        >
          <img src={horizontalKebab} alt="horizontalKebab" />
        </div>
      )}

      <Modal2
        show={showFamilyModal}
        onHide={() => setShowFamilyModal(false)}
        backdrop="static"
        keyboard={false}
        style={{
          borderRadius: '30px',
          border: 'none',
        }}
        dialogClassName={css.bb}
      >
        <div className={css.bb2}>
          <div className={css.modalHeader}>
            <p className={css.modalHeaderTitle}>
              {user?.attributes?.['first-name']} {user?.attributes?.['first-name']}
            </p>

            <img
              src={closeButton}
              alt="close"
              className={css.modalCloseButton}
              onClick={() => setShowFamilyModal(false)}
            />
          </div>
          <div className={css.modalBody}>
            {[
              navResults && {
                text: 'Results History',
                icon: <img src={historyicon} alt="result-history" />,
                action: goToResultsHistory,
              },
              navHealthReport && {
                text: 'Symptom Checker',
                icon: <img src={symptomcheckericon} alt="symptoms" />,
                action: goToSymptomChecker,
              },
              navInsurance && {
                text: 'Insurance',
                icon: <img src={insuranceicon} alt="insurance" />,
                action: goToInsurance,
              },
              navDocuments && {
                text: 'Documents',
                icon: <img src={documentsicon} alt="documents" />,
                action: goToDocuments,
              },
              navVaccines && {
                text: 'Vaccination Info',
                icon: <img src={vaxicon} alt="Vaccination-Info" />,
                action: goToVaccination,
              },
              navQRCode && {
                text: 'QR Code',
                icon: <img src={qricon} alt="QR Code" />,
              },
            ]
              .filter((val) => val)
              .map((item, index) => (
                <div className={css.modalItem} key={index} onClick={item.action}>
                  {item.icon}
                  <p className={css.modalItemText}>{item.text}</p>
                </div>
              ))}
          </div>
        </div>
      </Modal2>

      <Layout onClickBack={() => history?.goBack()} back headerTitle="Results History">
        <div className={css.container}>
          <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
            <span className={`${css.tabText} ml-2 mr-2 mr-sm-3`}>My Aura</span>

            <img src={OpenTriangle} alt="OpenTriangle" />
            <span className={`${css.tabText} mx-3 `}>My Family</span>

            {user && (
              <>
                {' '}
                <img src={OpenTriangle} alt="OpenTriangle" />
                <span className={`${css.tabText} mx-3 `}>
                  {user?.attributes?.['first-name']}
                </span>{' '}
              </>
            )}

            <img src={OpenTriangle} alt="OpenTriangle" />
            <span className={`${css.tabText} mx-2 mx-sm-3`} style={{ color: '#FF9900' }}>
              Results History
            </span>
          </div>

          <div className={css.flex1}>
            <FamHistoryResults
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              UserData={(data) => setUser(data)}
            />
          </div>
        </div>
      </Layout>

      {selectedData && (
        <div className={css.pageRight}>
          <div className={css.headerSection}>
            <img
              src={Close}
              className={css.closeButton}
              alt="close"
              onClick={() => {
                setSelectedData(null);
              }}
            />
            <div className={css.titleContainer}>
              <h2 className="large-font">{selectedData['medtest-type']}</h2>
            </div>
          </div>
          <Divider />
          <div className={css.sideContentContainer}>
            <div className={css.sideContentData}>
              <div>
                <h3 className="normal-font">
                  Results: {selectedData['test-result']}
                  <img alt="icon" className={css.icon} src={iconSrc[selectedData.iconColor]} />
                </h3>
                <p className="small-sub-font">
                  Last Updated {moment(selectedData['updated-at']).calendar()}
                </p>
              </div>

              <ListSimple
                title="Date of Test"
                description={moment(selectedData['test-date']).format('dddd, MMM Do, YYYY')}
                divider
              />
              <ListSimple
                title="Time since Test"
                description={moment(selectedData['test-date']).startOf('day').fromNow()}
                divider
              />
              <ListSimple title="Location of Testing" description={selectedData['test-location']} />
            </div>

            {selectedData['test-result-url'] != null && (
              <>
                <StrokeButton
                  className={css.strokeButtonPDF}
                  title={t('viewPdfResult')}
                  handlePress={() => {
                    window.open(selectedData['test-result-url']);
                  }}
                />
              </>
            )}

            <div className={css.linkContainer}>
              <span className={css.scheduleLink}>Schedule Testing</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  user: state.user.profile,
  userProfile: state.user.userProfile[0],
  authentication: state.authentication,
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyHistory);
