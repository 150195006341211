import React, { useState, useMemo, useEffect } from 'react';
import '../TestKits/ActivationInsurance.css';
import Select from 'react-select';
import Check from '../../assets/image/check2.png';
import aura_icon_camera from '../../assets/image/aura-icon-camera.png';
import { setUser, setUserInsurance } from '../../store/action/user';
import { processAPI } from '../../api/models/process';
import { uploadFile } from '../../api/models/forms';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import {
  getUserData,
  getUserFamilyMembersFullData,
  getAccountUserFullData,
} from '../../api/models/user';
import { connect } from 'react-redux';
import Replace from '../../assets/svg/Replace.svg';
import { stateList } from '../../utils/utils';
import { Layout, Checkbox, Alert, Divider } from '../../components';
import { Spinner } from 'react-bootstrap';

const RegEx = {
  name: /^[a-zA-Z ]*$/,
};
function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

const AddInsurance = (props) => {
  const { setUserInsuranceDispatch } = props;

  let { id } = useParams();
  if (!id) {
    id = props.user.id;
  }

  const history = useHistory();
  const [insuranceData, setInsuranceData] = useState({});
  const [insuranceNotListed, setInsuranceNotListed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [planList, setPlanList] = useState([]);
  const insuranceId = props.userInsurance?.id || '';
  const [hasData, setHasData] = useState(true);
  const [files, setFiles] = useState([]);
  const [notPrimaryAddress, setNotPrimaryAddress] = useState(false);
  const [formValues, setFormValues] = useState({
    Insurance_Name: '',
    Insurance_Freeform_Name: '',
    relationship: [
      {
        id: '',
        value: '',
      },
    ],
    Insured_First_Name: '',
    Insured_Last_Name: '',
    gender: '',
    Insured_Phone: '',
    Insured_DOB: '',
    Insured_Group_Number: '',
    Insured_ID_Number: '',
    Address_1: '',
    Address_2: '',
    City: '',
    Zip: '',
    State: '',
    not_primary_address: true,
  });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const [familyMemberDetails, setFamilyMemberDetails] = useState([]);
  const [accountUserDetails, setAccountUserDetails] = useState(null);

  function hideAlert() {
    setShowAlert({ show: false });
  }

  useEffect(() => {
    if (Object.keys(insuranceData).length) {
      setInsuranceNotListed(insuranceData['free-form-name']);

      setFormValues({
        Insurance_Name:
          planList[insuranceData['insurance-plan-id']]?.label ||
          insuranceData['free-form-name'] ||
          '',
        Insurance_Freeform_Name: '',
        relationship: '',
        Insured_First_Name: '',
        Insured_Last_Name: '',
        gender: '',
        Insured_Phone: '',
        Insured_DOB: '',
        Insured_Group_Number: '',
        Insured_ID_Number: '',
        Address_1: '',
        Address_2: '',
        City: '',
        Zip: '',
        State: '',
        not_primary_address: true,
      });

      if (insuranceData['insurance-company-name']) {
        setSelectedOption({
          value: insuranceData['insurance-plan-id'],
          label: insuranceData['insurance-company-name'],
        });
      }
    }
  }, [insuranceData, planList]);

  useEffect(() => {
    (async () => {
      await searchPlan('');
      await loadData();
      await getFamily();
      await getAccountUserData();
    })();
  }, []);

  const loadData = async () => {
    const response = await getUserData(props.user.id, props.userAuthentication.jwt);
    const insuranceDataObject = response?.included?.[0]?.attributes;
    setInsuranceData(insuranceDataObject || {});
    if (insuranceDataObject) {
      setHasData(true);
    }
  };

  async function getFamily() {
    const familyMemberData = (
      await getUserFamilyMembersFullData(props.user.id, props.userAuthentication.jwt)
    ).included;

    if (id == props?.user?.id) {
      setAccountUserDetails(
        familyMemberData?.find((item) => item?.type == 'user-profiles')?.attributes
      );
    } else {
      setFamilyMemberDetails(familyMemberData?.find((item) => item.id == id)?.attributes);
    }
  }

  console.log(familyMemberDetails, 'family member', accountUserDetails, 'user');

  async function getAccountUserData() {
    const userAccountData = (
      await getAccountUserFullData(props.user.id, props.userAuthentication.jwt)
    ).included;
    setAccountUserDetails(userAccountData[0].attributes);
  }

  const notReadyToSubmit = useMemo(() => {
    let res = false;
    res =
      !formValues.Insured_ID_Number ||
      (insuranceNotListed ? !formValues.Insurance_Freeform_Name : !formValues.Insurance_Name) ||
      files.length < 1 ||
      // (formValues.relationship !== 'SEL' &&
      !formValues.Insured_First_Name ||
      !formValues.Insured_Last_Name ||
      // !formValues.gender ||
      !formValues.Insured_DOB ||
      !formValues.Insured_Phone ||
      // )
      (formValues.not_primary_address &&
        (!formValues.Address_1 ||
          !formValues.City ||
          !formValues.Zip ||
          !formValues.State ||
          formValues.Zip.length !== 5));
    return res;
  }, [formValues, files]);

  async function loadUser() {
    const user = await getUserData(props.userAuthentication.user_id, props.userAuthentication.jwt);
    props.setUserDispatch(user.data);
    setUserInsuranceDispatch(user.included);
  }

  const onChange = (e) => {
    const { name, value } = e?.target;

    if (name === 'Insured_First_Name' || name === 'Insured_Last_Name') {
      if (!RegEx.name.test(value)) return;
    }

    if (name === 'Insured_Phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      return setFormValues({ ...formValues, [name]: formattedPhoneNumber });
    }

    if (name === 'Zip') {
      if (isNaN(value) || value.length >= 6) return;
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const onSubmitForm = () => {
    const formData = { ...formValues };
    delete formData.not_primary_address;
    const { attributes } = props.user;

    // if (formValues.relationship === 'SEL') {
    if (props.user.id == id) {
      formData.Insured_First_Name = attributes['first-name'];
      formData.Insured_Last_Name = attributes['last-name'];
      formData.gender = accountUserDetails.gender;
      formData.Insured_Phone = accountUserDetails['phone-number'];
      formData.Insured_DOB = accountUserDetails['date-of-birth'];
    } else {
      formData.Insured_First_Name = familyMemberDetails['first-name'];
      formData.Insured_Last_Name = familyMemberDetails['last-name'];
      formData.Insured_DOB = familyMemberDetails['date-of-birth'];
      formData.gender = familyMemberDetails.gender;
      formData.Insured_Phone = familyMemberDetails['phone-number'];
    }
    // }

    if (!formValues.not_primary_address) {
      if (props.user.id == id) {
        formData.Address_1 = accountUserDetails['address-line-1'];
        formData.Address_2 = accountUserDetails['address-line-2'];
        formData.City = accountUserDetails.city;
        formData.State = accountUserDetails.state;
        formData.Zip = accountUserDetails.zip;
      } else {
        formData.Address_1 = familyMemberDetails['address-line-1'];
        formData.Address_2 = familyMemberDetails['address-line-2'];
        formData.City = familyMemberDetails.city;
        formData.State = familyMemberDetails.state;
        formData.Zip = familyMemberDetails.zip;
      }
    }

    console.log(formData, 'family formdata');
    let res = false;
    Object.keys(formData).forEach((val) => {
      if (!formData[val]) return (res = true);
    });

    handleSubmit({ ...formData });
  };

  async function getUrl() {
    const { jwt } = props.userAuthentication;

    const preSigned = {
      filename: files[0]?.name,
      image_type: files[0]?.type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'pre-signed-post',
    });
    return data;
  }

  async function saveFileName(fileName, insurancePlanId) {
    const { jwt } = props.userAuthentication;

    const data = await processAPI({
      key: jwt,
      methodType: 'PATCH',
      data: {
        filename: fileName,
        imageable_id: insurancePlanId,
        imageable_type: 'InsurancePolicy',
      },
      endpoint: 'image',
    });

    return data;
  }

  async function searchPlan(planLabel) {
    const endpoint = `insurance-plans?filter[plan-name]=${planLabel}`;
    const { jwt: key } = props.userAuthentication;

    const data = await processAPI({ endpoint, key, methodType: 'GET' });
    const plans = data.map((item) => {
      const { attributes } = item;
      const label =
        attributes['company-name'] === attributes['plan-name']
          ? attributes['plan-name']
          : `${attributes['plan-name']}-${attributes['company-name']}`;
      return { value: item.id, label };
    });

    setPlanList(plans);
    return plans;
  }

  async function handleSubmit(values) {
    const { jwt } = props.userAuthentication;
    setLoading(true);

    let meta;
    try {
      if (values?.relationship.length > 1) {
        values.relationship?.map(async (item, idx) => {
          const myFamilyData = {
            data: {
              type: 'insurance-policy-members',
              attributes: {
                'user-id': parseInt(values.relationship[0].id),
                'insurance-policy-id': parseInt(insuranceId),
                'relationship-to-policy-holder': 'Other',
              },
            },
          };
          await processAPI({
            key: jwt,
            methodType: 'POST',
            data: myFamilyData,
            endpoint: `insurance-policy-members`,
          });
        });
      }

      if (files?.length > 0) {
        meta = await getUrl();
        await uploadFile(meta.url, files?.[0], meta.fields);
      }
      const myData = {
        data: {
          type: 'insurance-policies',
          attributes: {
            'policy-number': values?.Insured_ID_Number,
            'group-number': values?.Insured_Group_Number || '',
            'first-name-of-insured': values?.Insured_First_Name,
            'last-name-of-insured': values?.Insured_Last_Name,
            'insureds-gender': values?.gender,
            'insureds-phone-number': values?.Insured_Phone,
            'insureds-date-of-birth': values?.Insured_DOB,
            'insureds-address': `${values?.Address_1} ${values?.Address_2 || ''} ${values?.City} ${
              values?.State
            } ${values?.Zip}`,
            'insureds-address-1': values?.Address_1,
            'insureds-address-2': values?.Address_2 && values?.Address_2,
            'insureds-city': values?.City,
            'insureds-state': values?.State,
            'insureds-zip-code': values?.Zip,
            'insureds-relationship-to-patient': values?.relationship,

            'insurance-plan-id':
              (selectedOption && !insuranceNotListed && parseInt(selectedOption?.value)) || null,
            'free-form-name': insuranceNotListed ? values?.Insurance_Freeform_Name : null,
          },
          relationships: {
            user: {
              data: { type: 'users', id: parseInt(id) },
            },
          },
        },
      };

      !hasData && (myData.data.id = insuranceId);
      const data = await processAPI({
        key: jwt,
        methodType: hasData ? 'POST' : 'PATCH',
        data: myData,
        endpoint: hasData ? 'insurance-policies' : `insurance-policies/${insuranceId}`,
      });

      if (data.id) {
        await saveFileName(meta?.fields?.key, data.id);
        await loadUser();
        history.goBack();
      }
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
      console.log(error);
    }
    setLoading(false);
  }

  const checkIfRelationExists = (id, value) => {
    return (
      formValues?.relationship?.length > 0 &&
      formValues?.relationship?.find((item) =>
        value !== undefined ? item?.id === id && item?.value === value : item?.id === id
      )
    );
  };

  const updateValue = (id, value) => {
    const data = formValues?.relationship?.filter((item) => {
      return item?.id !== id;
    });
    data.push({
      id,
      value,
    });

    return data;
  };

  return (
    <Layout
      onClickBack={() => history.goBack()}
      headerTitle="Insurance Information"
      tab="insurance"
      back
    >
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      {/* Breadcrumbs */}
      <h4 className="breadCrumbs">
        <Link to="/">My Aura {'    '} </Link>&#x25B8;
        <Link to="/insurance-information">
          {'    '} Insurance {'    '}
        </Link>
        &#x25B8;
        <h4 className="default">{'    '} Add a New Insurance</h4>
      </h4>
      <div className="row m-0 p-0 mt-3 pt-1 ml-sm-5">
        <div className="col-12 col-lg-9 col-xl-7">
          <div className="insurance-header">Insurance Card Details</div>
          <div className=" my-4">
            <div className="insurance-label">Insurance Name*</div>

            {insuranceNotListed && (
              <div className="mb-3 pb-1">
                <input
                  className="insurance-input"
                  name="Insurance_Name"
                  placeholder="Please type in the insurance name"
                  value={formValues?.Insurance_Freeform_Name}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Insurance_Freeform_Name: e?.target.value,
                    });
                  }}
                />
              </div>
            )}

            {!insuranceNotListed && (
              <div className="position-relative  mb-3 pb-1">
                <Select
                  classNamePrefix="form-select"
                  name="Insurance_Name"
                  placeholder="Please type in the insurance name"
                  value={selectedOption}
                  options={planList}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Insurance_Name: e?.value,
                    });
                    setSelectedOption(e);
                  }}
                />
              </div>
            )}

            <div className="my-2 d-flex align-items-center">
              <Checkbox
                selected={insuranceNotListed}
                handleChange={() => {
                  setInsuranceNotListed(!insuranceNotListed);
                  insuranceNotListed
                    ? setFormValues({
                        ...formValues,
                        Insurance_Name: formValues.Insurance_Name,
                      })
                    : setFormValues({
                        ...formValues,
                        Insurance_Freeform_Name: '',
                      });
                }}
                label={insuranceNotListed ? '' : 'Insurance not listed?'}
              />
            </div>
          </div>
          <div className=" my-4">
            <div className="insurance-label">Policy Holder First Name*</div>
            <div className="position-relative  mb-3 pb-1">
              <input
                className="insurance-input"
                name="Insured_First_Name"
                placeholder="Enter Last Name"
                value={formValues?.Insured_First_Name}
                onChange={onChange}
              />
            </div>
          </div>
          <div className=" my-4">
            <div className="insurance-label">Policy Holder Last Name*</div>
            <div className="position-relative  mb-3 pb-1">
              <input
                className="insurance-input"
                name="Insured_Last_Name"
                placeholder="Enter Last Name"
                value={formValues?.Insured_Last_Name}
                onChange={onChange}
              />
            </div>
          </div>
          <div className=" my-4">
            <div className="insurance-label">Policy Holder Mobile Number*</div>

            <div className="position-relative  mb-3 pb-1">
              <input
                className="insurance-input"
                name="Insured_Phone"
                placeholder="000-000-0000"
                value={formValues?.Insured_Phone}
                onChange={onChange}
              />
            </div>
          </div>
          <div className=" my-4">
            <div className="insurance-label">Policy Holder Date of Birth*</div>

            <div className="position-relative  mb-3 pb-1">
              <input
                type="date"
                className="insurance-input"
                name="Insured_DOB"
                placeholder="MM-DD-YYYY"
                value={formValues?.Insured_DOB}
                onChange={onChange}
              />
            </div>
          </div>
          {/* {formValues?.relationship && formValues?.relationship !== 'SEL' && (
            <>
              <div className=" my-4">
                <div className="insurance-label">Insured First Name*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="Insured_First_Name"
                    placeholder="Enter First Name"
                    value={formValues?.Insured_First_Name}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">Insured Last Name*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="Insured_Last_Name"
                    placeholder="Enter Last Name"
                    value={formValues?.Insured_Last_Name}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="my-4">
                <div className="insurance-label">Insured Gender*</div>

                <div className="d-flex flex-wrap flex-sm-nowrap  align-items-center">
                  <div
                    className={`mt-3 ml-3 ml-sm-0 d-flex justify-content-center align-items-center ${
                      formValues?.gender === 'Male' ? 'selected-button' : 'unSelected-button'
                    }`}
                    onClick={() => setFormValues({ ...formValues, gender: 'Male' })}
                  >
                    {formValues?.gender === 'Male' && (
                      <img src={Check} alt="Check" className="mr-2" />
                    )}
                    Male
                  </div>

                  <div
                    className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                      formValues?.gender === 'Female' ? 'selected-button' : 'unSelected-button'
                    }`}
                    onClick={() => setFormValues({ ...formValues, gender: 'Female' })}
                  >
                    {formValues?.gender === 'Female' && (
                      <img src={Check} alt="Check" className="mr-2" />
                    )}
                    Female
                  </div>

                  <div
                    className={`mt-3 ml-3  d-flex justify-content-center align-items-center ${
                      formValues?.gender === 'Other' ? 'selected-button' : 'unSelected-button'
                    }`}
                    onClick={() => setFormValues({ ...formValues, gender: 'Other' })}
                  >
                    {formValues?.gender === 'Other' && (
                      <img src={Check} alt="Check" className="mr-2" />
                    )}
                    Other
                  </div>
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">Insured Phone*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="Insured_Phone"
                    placeholder="000-000-0000"
                    value={formValues?.Insured_Phone}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">Insured Date of Birth*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    type="date"
                    className="insurance-input"
                    name="Insured_DOB"
                    placeholder="MM-DD-YYYY"
                    value={formValues?.Insured_DOB}
                    onChange={onChange}
                  />
                </div>
              </div>
            </>
          )} */}
          <div className=" my-4">
            <div className="insurance-label">Insured Group Number</div>

            <div className="position-relative  mb-3 pb-1">
              <input
                className="insurance-input"
                name="Insured_Group_Number"
                placeholder="Enter Group Number"
                value={formValues?.Insured_Group_Number}
                onChange={onChange}
              />
            </div>
          </div>
          <div className=" my-4">
            <div className="insurance-label">Insured ID Number*</div>

            <div className="position-relative  mb-3 pb-1">
              <input
                className="insurance-input"
                name="Insured_ID_Number"
                placeholder="Enter ID Number"
                value={formValues?.Insured_ID_Number}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="my-4 d-flex align-items-center">
            <Checkbox
              selected={!notPrimaryAddress}
              handleChange={() => {
                setNotPrimaryAddress(!notPrimaryAddress);
                setFormValues({
                  ...formValues,
                  not_primary_address: notPrimaryAddress,
                });
              }}
              label="Insured address is different from primary address"
            />
          </div>
          {!notPrimaryAddress && (
            <>
              <div className=" my-4">
                <div className="insurance-label">Address*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="Address_1"
                    placeholder="Enter Address"
                    value={formValues?.Address_1}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">Address 2: Apartment, suite, etc. </div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="Address_2"
                    placeholder="Enter Address 2"
                    value={formValues?.Address_2}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">City*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="City"
                    placeholder="Enter City"
                    value={formValues?.City}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">State*</div>

                <div className="position-relative mb-3 pb-1">
                  <Select
                    placeholder="Enter State"
                    value={formValues?.State?.value}
                    defaultInputValue={formValues?.State}
                    onChange={(e) => {
                      setFormValues({ ...formValues, State: e?.value });
                    }}
                    options={stateList.map((s) => ({
                      value: s.value,
                      label: s.label,
                    }))}
                  />
                </div>
              </div>

              <div className=" my-4">
                <div className="insurance-label">Zip*</div>

                <div className="position-relative  mb-3 pb-1">
                  <input
                    className="insurance-input"
                    name="Zip"
                    placeholder="Enter Zip"
                    value={formValues?.Zip}
                    onChange={onChange}
                  />
                </div>
              </div>
            </>
          )}
          <div className=" my-4">
            <div className="insurance-label">Insurance Card Image is Required*</div>
            <div
              style={
                files.length > 0
                  ? {
                      borderRadius: '16px',
                      background: '#F0F0F0',
                      padding: '0 20px 20px 20px',
                      marginTop: '20px',
                    }
                  : { borderRadius: '16px' }
              }
            >
              <div
                className={`${
                  files.length == 0 && 'input-image'
                } my-3 position-relative d-flex align-items-center justify-content-center mb-3 pb-1`}
                style={{ height: 150 }}
              >
                {files.length == 0 && (
                  <>
                    <img src={aura_icon_camera} alt="aura_icon_camera" />
                    <div className="ml-2">Upload Insurance Card</div>
                  </>
                )}
                <input
                  type="file"
                  className="position-absolute z-50 insurance-fileImage"
                  accept="image/png, image/jpeg"
                  style={{ opacity: '0', cursor: 'pointer' }}
                  onChange={(e) => {
                    setFiles(e.target.files);
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </div>
              {files && files.length > 0 && (
                <div style={{ display: 'flex', flexDirectio: 'row' }}>
                  <img
                    src={URL.createObjectURL(files?.[0])}
                    alt="insurance-card"
                    style={
                      window.innerWidth > '480'
                        ? { width: '320px', height: 'auto' }
                        : { width: '100px', height: 'auto' }
                    }
                  />
                  <img
                    src={Replace}
                    alt="replace"
                    className="replacebutton"
                    onClick={() => setFiles([])}
                  />{' '}
                </div>
              )}
            </div>
          </div>
          <Divider />
          {props?.familyMembers?.length > 0 && (
            <div className="my-4">
              {/* <div className="insurance-label">Insurance Card Details</div> */}
              <div className="insurance-header">Assign Family Member to this Insurance</div>
              {props?.familyMembers?.map((family) => (
                <div className="mt-5">
                  <div className="d-flex justify-content-between align-items-center mt-5 mb-2">
                    <div className="insurance-header">{family?.attributes['first-name']}</div>
                    <div className="d-flex align-items-center">
                      {!checkIfRelationExists(family?.id) && (
                        <div className="warn-label d-md-block d-none">
                          Patient Relationship to Policy Holder Required
                        </div>
                      )}
                      <div className="mt-2 ml-2">
                        <Checkbox
                          selected={checkIfRelationExists(family?.id)}
                          // handleChange={() => {}}
                          // label={insuranceNotListed ? '' : 'Insurance not listed?'}
                        />
                      </div>
                    </div>
                  </div>
                  {!checkIfRelationExists(family?.id) && (
                    <div
                      className="warn-label mb-3 d-block d-md-none text-right ml-auto"
                      style={{ width: 'fit-content' }}
                    >
                      Patient Relationship to Policy Holder Required
                    </div>
                  )}
                  <div
                    className="insurance-label"
                    style={{ fontSize: 12 }}
                  >{`${family?.attributes['first-name']}'s Relationship to the Policy Holder*`}</div>
                  <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                    <div
                      className={`mt-3 ml-3 ml-sm-0 d-flex justify-content-center align-items-center ${
                        checkIfRelationExists(family?.id, 'SEL')
                          ? 'selected-button'
                          : 'unSelected-button'
                      }`}
                      onClick={() => {
                        checkIfRelationExists(family?.id, 'SEL')
                          ? console.log(
                              'Already exists',
                              formValues?.relationship?.find((item) => item?.id === family?.id)
                            )
                          : checkIfRelationExists(family?.id)
                          ? setFormValues({
                              ...formValues,
                              relationship: updateValue(family?.id, 'SEL'),
                            })
                          : setFormValues({
                              ...formValues,
                              relationship: [
                                ...formValues?.relationship,
                                { id: family?.id, value: 'SEL' },
                              ],
                            });
                      }}
                    >
                      {checkIfRelationExists(family?.id, 'SEL') && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Self
                    </div>

                    <div
                      className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                        checkIfRelationExists(family?.id, 'SPO')
                          ? 'selected-button'
                          : 'unSelected-button'
                      }`}
                      onClick={() =>
                        checkIfRelationExists(family?.id, 'SPO')
                          ? console.log(
                              'Already exists',
                              formValues?.relationship?.find((item) => item?.id === family?.id)
                            )
                          : checkIfRelationExists(family?.id)
                          ? setFormValues({
                              ...formValues,
                              relationship: updateValue(family?.id, 'SPO'),
                            })
                          : setFormValues({
                              ...formValues,
                              relationship: [
                                ...formValues?.relationship,
                                { id: family?.id, value: 'SPO' },
                              ],
                            })
                      }
                    >
                      {checkIfRelationExists(family?.id, 'SPO') && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Spouse
                    </div>

                    <div
                      className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                        checkIfRelationExists(family?.id, 'CHD')
                          ? 'selected-button'
                          : 'unSelected-button'
                      }`}
                      onClick={() =>
                        checkIfRelationExists(family?.id, 'CHD')
                          ? console.log(
                              'Already exists',
                              formValues?.relationship?.find((item) => item?.id === family?.id)
                            )
                          : checkIfRelationExists(family?.id)
                          ? setFormValues({
                              ...formValues,
                              relationship: updateValue(family?.id, 'CHD'),
                            })
                          : setFormValues({
                              ...formValues,
                              relationship: [
                                ...formValues?.relationship,
                                { id: family?.id, value: 'CHD' },
                              ],
                            })
                      }
                    >
                      {checkIfRelationExists(family?.id, 'CHD') && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Child
                    </div>

                    <div
                      className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                        checkIfRelationExists(family?.id, 'OTH')
                          ? 'selected-button'
                          : 'unSelected-button'
                      }`}
                      onClick={() =>
                        checkIfRelationExists(family?.id, 'OTH')
                          ? console.log(
                              'Already exists',
                              formValues?.relationship?.find((item) => item?.id === family?.id)
                            )
                          : checkIfRelationExists(family?.id)
                          ? setFormValues({
                              ...formValues,
                              relationship: updateValue(family?.id, 'OTH'),
                            })
                          : setFormValues({
                              ...formValues,
                              relationship: [
                                ...formValues?.relationship,
                                { id: family?.id, value: 'OTH' },
                              ],
                            })
                      }
                    >
                      {checkIfRelationExists(family?.id, 'OTH') && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Other
                    </div>

                    <div
                      className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                        checkIfRelationExists(family?.id, 'UNK')
                          ? 'selected-button'
                          : 'unSelected-button'
                      }`}
                      onClick={() =>
                        checkIfRelationExists(family?.id, 'UNK')
                          ? console.log(
                              'Already exists',
                              formValues?.relationship?.find((item) => item?.id === family?.id)
                            )
                          : checkIfRelationExists(family?.id)
                          ? setFormValues({
                              ...formValues,
                              relationship: updateValue(family?.id, 'UNK'),
                            })
                          : setFormValues({
                              ...formValues,
                              relationship: [
                                ...formValues?.relationship,
                                { id: family?.id, value: 'UNK' },
                              ],
                            })
                      }
                    >
                      {checkIfRelationExists(family?.id, 'UNK') && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Unknown
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div
            className="InsuranceForm__continue d-flex align-items-center justify-content-center my-5"
            style={{
              opacity: notReadyToSubmit || loading ? '0.4' : '1',
            }}
            onClick={() => {
              if (!notReadyToSubmit) {
                onSubmitForm();
              }
            }}
          >
            {loading ? <Spinner animation="border" variant="secondary" /> : 'Save and Continue'}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state?.user?.userProfile?.[0],
  userInsurance: state?.user?.insurance?.[0],
  userAuthentication: state.authentication,
  familyMembers: state?.family?.familyMembers,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(AddInsurance);
