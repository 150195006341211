import React from 'react';
import { SmallLogo } from '..';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const ProgressBar = (props) => {
  const { list, step } = props;
  console.log('list', list);
  console.log('step', step);
  return (
    <div className="col-md-4 col-sm-3 col-xl-2 h-screen">
      {/* <div className="d-none d-lg-block mt-4 text-center">
        <SmallLogo />
      </div> */}

      <div className="ml-0 ml-lg-4 mt-4">
        {list?.map((val, i) => (
          <>
            <div key={i} className="d-flex align-items-center">
              {list[i] && (
                <div
                  className={`border rounded-circle round border-5 text-center ${
                    step >= i ? 'border-secondary' : ''
                  }`}
                  style={{ minWidth: '1.25rem', minHeight: '1.25rem' }}
                >
                  {step > i && <span>&#10003;</span>}
                </div>
              )}
              <div className="ml-2 side_text" style={step === i ? {} : { color: '#777777' }}>
                {val}
              </div>
            </div>

            {i !== list?.length - 1 && list[i + 1] && <div className="ml-2 border h-line" />}
          </>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
