import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';

import { processAPI } from '../../api/models/process';
import { symptoms } from '../../api/models/symptoms';
import {
  getUserFamilyMembers,
  userSymptoms,
  getUserData,
  updateUserData,
  getUserTestKits,
} from '../../api/models/user';
import { resultsMedTest } from '../../api/models/medTest';
import HeartIcon from '../../assets/image/dsc_icon.png';
import {
  Layout,
  List,
  BigButton,
  Modal,
  Checkbox,
  Divider,
  Alert,
  DataList,
  ListSimple,
} from '../../components';
import { clearCache, getStoredData, storeData } from '../../api/cache';
import { USER_CACHE_NAME, USER_CONTACT_KEY } from '../../api/const';
import { authentication, logout } from '../../store/action/auth';
import {
  setUser,
  setUserInsurance,
  clearUser,
  setUserAppointments,
  deleteAppointments,
  setUserContacts,
  setUserTestKits,
} from '../../store/action/user';
import { setResults } from '../../store/action/results';
import css from './Profile.module.css';
import SyringeIcon from '../../assets/image/vaccine_icon.png';
import OrganizationIcon from '../../assets/image/myorganization_icon.png';
import FamilyIcon from '../../assets/image/aura_family_icon1.png';
import LockIcon from '../../assets/image/lock_icon.png';
import InsuranceIcon from '../../assets/image/insuranceBg.png';
import TestKitIcon from '../../assets/image/testkiticon.png';
import { capitalize, differenceByHours, processAddress, linkify } from '../../api';
import { clearIntervalByKey, saveCurrentLocation } from '../../api/geolocation';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import SelectInput from '../../components/SelectInput/SelectInput';
import { StrokeButton, MultipleButton } from '../../components/Button/Button';
import {
  getAvailableDays,
  getAvailableTimes,
  getTestLocationAvailability,
} from '../../api/models/testLocation';
import Close from '../../assets/svg/close.svg';

import History from '../../components/Card/History';
import Calendar from '../../components/Card/Calendar';
import Location from '../../components/Card/Location1';
import DocumentIcon from '../../assets/image/documents_icon.png';
import SettingIcon from '../../assets/image/tech_support_icon.png';
import MedicalIcon from '../../assets/image/medical_icon.png';
import MyTestsIcon from '../../assets/image/my_tests_icon@1.5x.png';

let timeoutFloatingCard;

const ProfileContainer = (props) => {
  const {
    user,
    setUserDispatch,
    setUserInsuranceDispatch,
    clearUserDispatch,
    logoutDispatch,
    userInsurance,
    setResultsDispatch,
    results,
    setContactsDispatch,
    userContacts,
    status,
    setAuthenticationDispatch,
    alertCard,
    setAppointmentDispatch,
    appointments,
    deleteAppointmentsDispatch,
  } = props;

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingList, setLoadingList] = useState(true);
  const [showModal, setShowModal] = useState('');
  const [userSymptomsData, setUserSymptomsdata] = useState([]);
  const [list, setList] = useState([]);
  const [userAuth, setUserAuth] = useState({});
  const [updatedList, setUpdatedList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [showFloatingCard, setShowFloatingCard] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({});

  const [showAlert, setShowAlert] = useState({ show: false });

  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const lastUpdated = userSymptomsData && userSymptomsData.length > 0 && moment().format('L');

  useEffect(() => {
    if (doesntHaveRequiredInfo && props?.user?.['can-edit-profile']) {
      setShowModal('requiredInformation');
    }
  }, [user]);

  const convertFrom24To12Format = () => {
    let dt = new Date();
    let hours = dt.getHours(); // gives the value in 24 hours format
    let AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    let minutes = dt.getMinutes();
    let finalTime = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${AmOrPm}`;
    return finalTime;
  };

  const headerTitle =
    user &&
    user.attributes &&
    user.attributes['first-name'] &&
    `${user.attributes['first-name'].split(' ')[0]}'s Aura`;

  const navDocuments = user && user.attributes && user.attributes['nav-documents'];
  const requireInsurance = user && user.attributes && user.attributes['requires-insurance'];
  const doesntHaveRequiredInfo =
    user &&
    user.attributes &&
    (user.attributes['has-required-information'] === null ||
      user.attributes['has-required-information'] === false);
  const requireHealthReport = user && user.attributes && user.attributes['health-report'];
  const daysNextTest = user && user.attributes && user.attributes['days-until-next-test'];
  const navTesting = user && user.attributes && user.attributes['nav-testing'];
  const navTestDue = user && user.attributes && user.attributes['nav-next-test-due'];
  const pendingResults = (user && user.attributes && user.attributes['pending-results']) || 0;
  const navTestKit = true; // user && user.attributes && user.attributes['nav-test-kit'];
  const navResults = user && user.attributes && user.attributes['nav-results'];
  const navFamily = user && user.attributes && user.attributes['nav-family-groups'];
  const navVaccine = user && user.attributes && user.attributes['nav-vaccines'];
  const healthWallet = user && user.attributes && user.attributes['health-wallet'];
  const manualVaccineUpload = user && user.attributes && user.attributes['manual-vaccine-upload'];

  const isUnder13 = user && user.attributes && user.attributes['under-13'] === true;
  const showModalUnder13 =
    user && user.attributes && user.attributes['under-13-signed'] !== true && isUnder13;
  const under13Text = (user && user.attributes && user.attributes['under-13-text']) || '';
  const unsignedFamilyConsentForms =
    user && user?.attributes && user?.attributes['unsigned-family-consent-forms'];
  const [showUnsignedFamilyConsentModal, setShowUnsignedFamilyConsentModal] = useState(
    unsignedFamilyConsentForms && navFamily
  );
  const [showAddFamilyModal, setShowFamilyModal] = useState(true);
  const [familyMembers, setFamilyMembers] = useState([]);

  const [loadingInterval, setLoadingInterval] = useState(false);
  const [defaultIntervalTime, setDefaultIntervalTime] = useState(
    (user && user.attributes && user.attributes['testing-center-location-interval']) || 15
  );
  const [intervalTime, setIntervalTime] = useState(defaultIntervalTime);
  const [selectedKit, setSelectedKit] = useState();

  const [showInsuranceModal, setShowInsuranceModal] = useState(
    user &&
      user.attributes &&
      user.attributes['requires-insurance'] &&
      !user.attributes['any-insurance-policies'] &&
      localStorage.getItem('user_loggedIn')
  );
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonOther, setCancelReasonOther] = useState('');

  const [appointmentsData, setAppointmentsData] = useState(null);

  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedAppointmentData, setSelectedAppointmentData] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  const cancelReasonOptions = ['1', '2', '3'];
  const reasonDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: 200,
      borderBottom: '1px dotted pink',
      // color: state.selectProps.menuColor,
      padding: 20,
    }),

    // control: (_, { selectProps: { width }}) => ({
    //   width: width
    // }),

    // singleValue: (provided, state) => {
    //   const transition = 'opacity 300ms';

    //   return { ...provided, transition };
    // }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const { status } = alertCard;
    if (status === 'appointment') {
      loadAppointmentsWarning();
    }
  }, [alertCard]);

  // useEffect(() => {
  //   if (doesntHaveRequiredInfo) {
  //     openModal("requiredInformation");
  //   }
  // }, [user]);

  const nextAppointment = appointments?.map((item) => {
    const { attributes, id } = item;
    console.log('nextAppointment', item);
    return {
      id,
      appointmentTime: attributes['appointment-time'],
      date: moment(attributes['appointment-time']).format('MM/DD/YYYY'),
      time: moment.utc(attributes['appointment-time']).format('LT'),
      place: attributes.address,
      patientName: attributes['patient-name'],
      allowCancel: attributes['allow-cancel'],
      allowReschedule: attributes?.['allow-reschedule'],
      locationId: attributes?.['medtest-location-id'],
      productIds: attributes?.['location-product-ids'],
      productCounts: attributes?.['location-product-counts'],
      productNames: attributes?.['product-names'] && attributes?.['product-names'].join(', '),
    };
  });
  const cancelableAppointment = selectedAppointment.allowCancel;
  const rescheduleableAppointment = selectedAppointment.allowReschedule;
  const history = useHistory();
  const lastTest = results.length > 0 ? results[results.length - 1].attributes : {};

  const gotoScreener =
    user &&
    user.attributes &&
    user.attributes['hipaa-release-details'].unsigned.length > 0 &&
    !user.attributes['hipaa-release-details'].unsigned.every((item) => item.force_sign === false);

  async function loadData() {
    if (gotoScreener) {
      history.push(`/sign-doc/${user.id}`);
    }
    const userData = await getStoredData(USER_CACHE_NAME);
    if (userData && userData.user_id) {
      const profile = await getUserData(userData.user_id, userData.jwt);
      const family = await getUserFamilyMembers(userData.user_id, userData.jwt);
      setFamilyMembers(family.included);
      const symptomsResults = await symptoms(userData.jwt);
      const userSymptomsResults = await userSymptoms({
        methodType: 'GET',
        key: userData.jwt,
      });
      setLoadingList(false);
      let result = (await resultsMedTest(userData.jwt)) || [];
      if (result && result.length > 0) {
        result = result.sort(compare);
        // result = filterDataPerDay(30, result);
        let now = new Date().toISOString();
        let future = result.filter((x) => x.attributes['test-date'] > now);
        setResultsDispatch(result);
      }

      const resultContact = await getStoredData(USER_CONTACT_KEY);
      if (resultContact) {
        setContactsDispatch(resultContact);
      }

      const resultTestKits = (await getUserTestKits(userData.user_id, userData.jwt)) || [];
      if (resultTestKits && resultTestKits.included && resultTestKits.included[0]) {
        resultTestKits.included.filter((x) => x.attributes['specimen-code'] === null);
        props.setUserTestKitsDispatch(resultTestKits.included);
        setSelectedKit(resultTestKits.included[0]);
      }

      const dataList = filterSelectedSymptoms(symptomsResults, userSymptomsResults);

      setUserSymptomsdata(userSymptomsResults);
      setUserAuth(userData);
      setList(dataList);
      setUserDispatch(profile.data);
      setUserInsuranceDispatch(profile.included || []);
      setLoading(false);
      loadAppointmentsWarning();

      const interval =
        (user && user.attributes && user.attributes['testing-center-location-interval']) || 15;
      saveCurrentLocation(userData.user_id, userData.jwt, interval);
    } else {
      setLoading(false);
      setShowDialog(true);
    }
  }

  async function loadAppointmentsData(date, index, id) {
    console.log('locationid', id);
    setLoading(true);
    const formatDate = moment(date).format('YYYY-MM-DD');
    const result = await getAvailableDays(
      formatDate,
      id,
      selectedAppointment.productIds,
      selectedAppointment.productCounts
    );
    setAvailableDays(result);
    console.log('result', result);
    const { jwt } = props.userAuthentication;

    const { data, included } = await processAPI({
      endpoint: 'medtest-location-appointments?include=medtest-location',
      methodType: 'GET',
      key: jwt,
      dataIncluded: true,
    });
    const dataAppointment = processAddress(data, included);
    // const serializeData = result.map((item) => ({
    //   time: item,
    //   timeFormatted: formatTime(item),
    //   selected: isSelected(item, dataAppointment, id, formatDate),
    // }));
    // const newData = [...data];
    // newData[index] = serializeData;
    setAppointmentsData(result);
    setLoading(false);
    setAppointmentDispatch(dataAppointment);
  }

  async function loadAppointmentsWarning() {
    const { jwt } = props.userAuthentication;
    const { data, included } = await processAPI({
      endpoint:
        'medtest-location-appointments?filter[include-family]=true&include=medtest-location',
      methodType: 'GET',
      key: jwt,
      dataIncluded: true,
    });
    const dataAppointment = processAddress(data, included);

    setAppointmentDispatch(dataAppointment);
  }

  function compare(a, b) {
    const testA = a.attributes['test-date'];
    const testB = b.attributes['test-date'];

    let comparison = 0;
    if (testA > testB) {
      comparison = 1;
    } else if (testA < testB) {
      comparison = -1;
    }

    return comparison;
  }

  function filterSelectedSymptoms(symptoms, userSymptoms) {
    if (!userSymptoms) return [];
    return symptoms.map((item) => {
      const index = userSymptoms.findIndex((item1) => item1.attributes['symptom-id'] == item.id);

      if (index > -1) {
        return { ...item, selected: true, symptomId: userSymptoms[index].id };
      }

      return item;
    });
  }

  function filterDataPerDay(days, data) {
    return data.filter((item) => {
      const currentDate = moment().format('L');
      const testDate = moment(item.attributes['test-date']).add(days, 'days').format('L');

      return testDate >= currentDate;
    });
  }

  async function postSymptoms(key, userId, symptomId) {
    const data = {
      data: {
        type: 'user-symptoms',
        attributes: {
          'user-id': userId,
          'symptom-id': symptomId,
        },
      },
    };

    await userSymptoms({ data, key, methodType: 'POST' });
  }

  async function deleteSymptoms(key, symptomId) {
    const data = {
      data: {
        type: 'user-symptoms',
        id: symptomId,
      },
    };

    await userSymptoms({
      data,
      key,
      methodType: 'DELETE',
      endpoint: symptomId,
    });
  }

  function updateAppointmentList(item, selected) {
    setSelectedAppointmentData(selected ? '' : item);
  }

  function updateList(id, selected, symptomId) {
    const newList = [...list];
    const index = newList.findIndex((item) => item.id === id);

    newList[index] = { ...newList[index], selected };
    setList(newList);

    let data = updatedList;
    const indexUpdatedList = data.findIndex((item) => item.id == id);

    // postSymptoms(userAuth.jwt, userAuth['user_id'], id);
    // !selected &&  symptomId && symptomId > -1 && deleteSymptoms(userAuth.jwt, symptomId);

    if (indexUpdatedList > -1) {
      data.splice(indexUpdatedList, 1);
    } else {
      data.push({ id, symptomId, selected });
    }

    setUpdatedList(data);
  }

  function showTimedFloatingCard() {
    setShowFloatingCard(true);
    if (timeoutFloatingCard) clearTimeout(timeoutFloatingCard);
    timeoutFloatingCard = setTimeout(() => {
      setShowFloatingCard(false);
    }, 5000);
  }

  async function multiplePost() {
    try {
      const postData = updatedList.filter((item) => item.selected);
      const deleteData = updatedList.filter((item) => !item.selected);
      setLoadingUpdate(true);
      await Promise.all(
        postData.map(async (item) => {
          await postSymptoms(userAuth.jwt, userAuth.user_id, item.id);
        })
      );

      await Promise.all(
        deleteData.map(async (item) => {
          await deleteSymptoms(userAuth.jwt, item.symptomId);
        })
      );
      setLoadingUpdate(false);
      setUpdatedList([]);
      showTimedFloatingCard();
      hideModal();
      setShowAlert({
        show: true,
        message: 'Save successfully',
        type: 'success',
      });

      setUserSymptomsdata(postData); // data doesnt matter just for rendering
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }
  }

  async function logOut() {
    setLoading(true);
    await clearCache(USER_CACHE_NAME);
    await clearCache(USER_CONTACT_KEY);
    clearUserDispatch();
    clearIntervalByKey('locationTimeout'); // to clear setInterval save user location
    logoutDispatch();
  }

  function hideModal() {
    setShowModal('');
  }

  function openModal(modalName) {
    setShowModal(modalName);
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  function handleNextTest(data) {
    setSelectedAppointment(data);
    openModal('appointment');
  }

  function timeDifference(item) {
    let currentTime = moment();
    let timeStore = moment(item);

    return Math.abs(currentTime.diff(timeStore, 'h'));
  }

  async function deleteAppointments() {
    const { jwt: key } = props.userAuthentication;
    const { id } = selectedAppointment;
    const type = 'medtest-location-appointments';
    const endpoint = `${type}/${id}`;
    const data = {
      data: {
        type,
        id,
      },
    };

    setLoadingUpdate(true);

    try {
      await processAPI({ data, key, endpoint, methodType: 'DELETE' });
    } catch (error) {}

    const index = appointments.findIndex((item) => item.id === id);
    deleteAppointmentsDispatch(index);
    setLoadingUpdate(false);
    hideModal();
    setShowModal('cancelAppointmentReason');
  }

  function handleButtonPress(index) {
    setSelectedButton(index);
    // setSelectedAppointmentData(null);
  }

  async function submitCancelReason() {
    const { jwt: key } = props.userAuthentication;
    const { id } = selectedAppointment;
    console.log(id);
    const type = 'medtest-location-appointments';
    const endpoint = `${type}/${id}/cancel`;
    console.log(endpoint);
    const data = {
      cancellation_reason: cancelReason == 'Other' ? cancelReasonOther : cancelReason,
    };
    try {
      await processAPI({ data, key, endpoint, methodType: 'PUT' });
    } catch (error) {
      console.log(error);
    }
  }

  async function submitReschedule() {
    const { jwt: key } = props.userAuthentication;
    const { id } = selectedAppointment;
    console.log(id);
    const type = 'medtest-location-appointments';
    const endpoint = `${type}/${id}/reschedule`;
    console.log(endpoint);
    const newTime = `${selectedDay} ${selectedAppointmentData?.time}`;
    console.log(newTime);
    const data = {
      new_appointment_time: newTime,
    };
    try {
      await processAPI({ data, key, endpoint, methodType: 'PUT' });
    } catch (error) {
      console.log(error);
    }
  }

  function getDay(date) {
    return moment(date).format('ddd');
  }

  function getDate(date) {
    return moment(date).format('MMM D');
  }

  function isAppointmentSelected(time, values, id) {
    const found = values.filter((item) => {
      const appointmentTime = moment
        .utc(item.attributes['appointment-time'])
        .format('YYYY-MM-DD HH:mm');
      const locationId = item.attributes['medtest-location-id'];
      const selectedTime = `${appointmentsData[selectedButton].date} ${time}`;
      return appointmentTime == selectedTime && locationId == id;
    });
    return found.length > 0;
  }

  async function submitInterval() {
    const { jwt } = props.userAuthentication;
    const updatedData = {
      data: {
        id: `${props.userAuthentication.user_id}`,
        type: 'users',
        attributes: {
          'testing-center-location-interval': parseInt(intervalTime),
        },
      },
    };

    setLoadingInterval(true);

    try {
      const data = await updateUserData(updatedData.data.id, updatedData, jwt);
    } catch (error) {}

    setDefaultIntervalTime(intervalTime);
    setLoadingInterval(false);
  }

  function signFamilyForms() {
    history.push('family');
  }

  return (
    <Layout
      // rightText={t("signOut")}
      headerTitle={headerTitle || t('myAura')}
      disablePrimaryUserHipaaPopup={navFamily}
      // loading={loading}
      // onClickRight={logOut}
      tab={activeTab}
    >
      {!showModalUnder13 && (
        <>
          <Alert
            open={showAlert.show}
            handleClose={hideAlert}
            message={showAlert.message}
            type={showAlert.type}
          />

          {/* <div className="maincontainer mt-5">
            <div className={css.header}>
              <h4 className="medium-font">{t("myAccount")}</h4>
            </div>
          </div> */}

          <div className={css.maincontainer}>
            <div className={classNames(css.flex1, css.sideContainer)}>
              {/* My Summary Card */}
              <div className={css.superContainer}>
                <div className={css.othercontainer}>
                  <div className={css.summaryContainer}>
                    <div className={css.cardHeader}>
                      <p className={css.summaryText}>{t('mySummary')}</p>
                    </div>
                    <div className={css.cardHeader}>
                      {lastUpdated && (
                        <p className="small-sub-font">{`${t(
                          'lastUpdated'
                        )} ${convertFrom24To12Format()}`}</p>
                      )}
                    </div>
                  </div>
                  <div className={css.testContainer}>
                    <Link to="/my-tests">
                      <History />
                    </Link>
                    <div className={css.testBox}>{results?.length || 0}</div>
                    <div className={css.testText}>
                      <div>{t('tests')}</div>
                      <div>{t('completed')}</div>
                    </div>
                  </div>
                  <div className={css.testDetailContainer}>
                    <div className={css.testDetailSection}>
                      <div className={css.testText}>
                        {lastTest['medtest-type']
                          ? `${lastTest['medtest-type']} : ${lastTest['test-result']}`
                          : 'No tests yet'}
                      </div>
                      <div className={css.manageText}>Manage</div>
                    </div>
                    <div className={css.detailSection}>
                      {lastTest['medtest-type'] ? (
                        <>
                          <div className={css.calendarSection}>
                            <Calendar />
                            <div className={css.calendarSectionDetail}>
                              <div className={css.textDetail}>
                                {lastTest['test-date']
                                  ? moment(lastTest['test-date']).format('MM/DD/YYYY')
                                  : 'Not Available'}
                              </div>
                              <div className={css.timeDetail}>
                                {lastTest['test-date']
                                  ? moment(lastTest['test-date']).format('hh:mm A')
                                  : 'Not Available'}
                              </div>
                            </div>
                          </div>
                          <div className={css.locationSection}>
                            <Location />
                            <div className={css.calendarSectionDetail}>
                              <div className={css.textDetail}>
                                {lastTest['test-location-name'] || 'Not Available'}
                              </div>
                              <div className={css.timeDetail}>
                                {lastTest['test-location'] || 'Not Available'}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className={css.noTestsDetail} />
                      )}
                    </div>
                    {/* {showSchedule && <p className={classNames("card-font", css.cardFontBlue)} onClick={onClickManage}>Manage</p>} */}
                  </div>
                  <div className="pb-2">
                    {nextAppointment?.map((item, index) => {
                      const { appointmentTime, place, patientName, productNames } = item;
                      const bigText = productNames
                        ? `${productNames} - ${moment.utc(appointmentTime).format('LLLL')}`
                        : moment.utc(appointmentTime).format('LLLL');
                      const subText = place;
                      return (
                        <div onClick={() => handleNextTest(item)} key={`nt-${index}`}>
                          <List
                            bigText={`${patientName} - ${bigText}`}
                            subText={subText}
                            subTextClassName={css.subText}
                            arrowIcon
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <BigButton
                img={LockIcon}
                text={t("account")}
                subText={t("Information")}
                color="#F3F3F3"
                onClick={() => history.push("/account-information")}
                requiresUpdates={doesntHaveRequiredInfo}
              /> */}

              {healthWallet && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={MedicalIcon}
                    color="#f9f9f9"
                    text={t('My Medical ID')}
                    onClick={() => history.push('/my-medical-id')}
                  />
                </div>
              )}

              <div className={css.cardContainer}>
                <BigButton
                  img={MyTestsIcon}
                  color="#f9f9f9"
                  text={t('My Results')}
                  onClick={() => history.push('/my-tests')}
                />
              </div>

              {requireHealthReport && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={HeartIcon}
                    color="#78DA76"
                    text={t('daily')}
                    subText={t('symptom')}
                    tertiaryText={t('checker')}
                    onClick={() => openModal('report')}
                  />
                </div>
              )}

              {navFamily && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={FamilyIcon}
                    text={t('myFamily')}
                    color="#FF77C0"
                    onClick={() => history.push('/family')}
                  />
                </div>
              )}

              {requireInsurance && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={InsuranceIcon}
                    text={t('myInsurance')}
                    color="#F8C820"
                    onClick={() => history.push('/insurance-information')}
                  />
                </div>
              )}

              {navDocuments && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={DocumentIcon}
                    text={t('myDocuments')}
                    color="#F5A860"
                    onClick={() => history.push('/documents')}
                  />
                </div>
              )}

              {navVaccine && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={SyringeIcon}
                    text={t('myVaccination')}
                    subText={t('information')}
                    color="#ADD8E6"
                    onClick={() => history.push('/vaccine-information')}
                  />
                </div>
              )}

              <div className={css.cardContainer}>
                <BigButton
                  img={LockIcon}
                  text={t('myAccount')}
                  subText={t('Information')}
                  color="#F3F3F3"
                  onClick={() => history.push('/account-information')}
                  requiresUpdates={props?.user?.['can-edit-profile'] && doesntHaveRequiredInfo}
                />
              </div>

              <div className={css.cardContainer}>
                <BigButton
                  img={SettingIcon}
                  text={t('technical')}
                  subText={t('support')}
                  color="#BABABA"
                  onClick={() => history.push('/tech-support')}
                />
              </div>

              {navTestKit && (
                <div className={css.cardContainer}>
                  <BigButton
                    img={TestKitIcon}
                    text="Register My"
                    subText="Test Kit"
                    color="#FFA4A7"
                    onClick={() => history.push('/my-tests', { tab: 1 })}
                  />
                </div>
              )}

              <div className={css.cardContainer}>
                <a
                  href="https://www.cdc.gov/"
                  target="_blank"
                  className={css.cdcButton}
                  rel="noreferrer"
                >
                  <p>{t('CDC')}</p>
                  <p>{t('portal')}</p>
                </a>
              </div>

              {/* {user && user.attributes && user.attributes["nav-certification"] && ( */}
              <div className={css.cardContainer}>
                <div
                  className={css.certificationButton}
                  onClick={() => history.push('/certification')}
                >
                  {t('certifications')}
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
          <p className={classNames('small-font text-center', css.bottomText)}>
            {t('allPersonalInformation')}
          </p>

          <Modal
            className={css.modal}
            headerComponent={
              <div
                style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h3>My Appointment</h3>
                <img src={Close} onClick={hideModal} style={{ cursor: 'pointer' }} />
              </div>
            }
            title="My Appointment"
            show={showModal === 'appointment'}
            submitText={t('cancelAppointment')}
            submitDisable={!cancelableAppointment}
            handleSubmit={() => setShowModal('cancelAppointment')}
            footerComponent={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {rescheduleableAppointment && (
                  <StrokeButton
                    title="Reschedule"
                    handlePress={() => {
                      setShowModal('editappointment');
                      loadAppointmentsData(moment(), null, selectedAppointment.locationId);
                    }}
                  />
                )}
                {cancelableAppointment && (
                  <StrokeButton
                    className={css.cancelButton}
                    title="Cancel"
                    handlePress={() => {
                      setShowModal('cancelAppointment');
                    }}
                  />
                )}
              </div>
            }
            loading={loadingUpdate}
            handleClose={hideModal}
          >
            <DataList title="Name" description={selectedAppointment.patientName} />
            <DataList title="Products" description={selectedAppointment?.productNames} />
            <DataList title="Location" description={selectedAppointment.place} />
            <DataList title="Date" description={selectedAppointment.date} />
            <DataList title="Time" description={selectedAppointment.time} />
            {!cancelableAppointment && (
              <div>
                <p>
                  Cancellations require 24 hour notice. If you are looking to make changes to your
                  appointment, please contact us at testnowandgo@genetworx.com
                </p>
              </div>
            )}
          </Modal>
          <Modal
            title="Edit Appointment"
            headerComponent={
              <div
                style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h3>Edit Appointment</h3>
                <img src={Close} onClick={hideModal} style={{ cursor: 'pointer' }} />
              </div>
            }
            show={showModal === 'editappointment'}
            submitText="Reschedule"
            submitDisable={!rescheduleableAppointment || !selectedAppointmentData}
            handleSubmit={() => {
              if (selectedAppointmentData) {
                submitReschedule();
                setShowModal('');
                loadData();
                setSelectedAppointmentData(null);
              } else {
                alert('Please select an appointment time to reschedule.');
              }
            }}
            loading={loadingUpdate}
            handleClose={hideModal}
          >
            <DataList title="Name" description={selectedAppointment.patientName} />
            <DataList title="Products" description={selectedAppointment?.productNames} />
            <div>
              <p>
                If you’d like to schedule a different test, please cancel your existing appointment
                and create a new one
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {availableDays &&
                availableDays.length > 0 &&
                availableDays.map((item, index) => (
                  <MultipleButton
                    className="mb-1"
                    title={moment(item).format('MM/DD')}
                    // subTitle={getDate(appointmentsData[index].date)}
                    selected={selectedButton === index}
                    onClick={async () => {
                      handleButtonPress(index);
                      setSelectedDay(item);
                      setSelectedAppointmentData(null);
                      let times = await getAvailableTimes(
                        item,
                        selectedAppointment.locationId,
                        selectedAppointment.productIds,
                        selectedAppointment.productCounts
                      );
                      setAvailableTimes(times);
                    }}
                  />
                ))}
            </div>
            <div style={{ overflowY: 'scroll', height: '300px' }}>
              {availableTimes &&
                availableTimes.length > 0 &&
                availableTimes.map((item, index) => (
                  <div>
                    <Divider />
                    <div className="mt-2 mb-2">
                      <Checkbox
                        label={moment(item.time, 'hh:mm').format('LT')}
                        selected={item == selectedAppointmentData}
                        handleChange={(value) => updateAppointmentList(item, value)}
                        labelColor={!item.selected && '#777777'}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </Modal>
          <Modal
            title={t('cancelAppointment')}
            headerComponent={
              <div
                style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h3>Cancel Appointment</h3>
                <img src={Close} onClick={hideModal} style={{ cursor: 'pointer' }} />
              </div>
            }
            show={showModal === 'cancelAppointment'}
            submitText={t('confirm')}
            handleSubmit={deleteAppointments}
            loading={loadingUpdate}
            handleClose={hideModal}
            closeText="Back"
          >
            <div className="d-flex flex-column">
              <p className="medium-font">{t('cancelAppointmentMessage')}</p>
            </div>
          </Modal>
          <Modal
            title={t('cancelAppointment')}
            headerComponent={
              <div
                style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h3>Cancel Appointment</h3>
                <img src={Close} onClick={hideModal} style={{ cursor: 'pointer' }} />
              </div>
            }
            show={showModal === 'cancelAppointmentReason'}
            submitText={t('submit')}
            handleSubmit={() => {
              submitCancelReason();
              hideModal();
            }}
            submitDisable={!cancelReason}
            loading={loadingUpdate}
            handleClose={hideModal}
            closeText="Close"
          >
            <div className="d-flex flex-column">
              <p className="medium-font">{t('cancelAppointmentReasonMessage')}</p>
              {/* select dropdown */}
              <div className="d-flex flex-column mt-4">
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column">
                    <select value={cancelReason} onChange={(e) => setCancelReason(e.target.value)}>
                      <option disabled value="">
                        {t('select')}
                      </option>
                      <option value="I have an unavoidable conflict">
                        I have an unavoidable conflict
                      </option>
                      <option value="I no longer need this test">I no longer need this test</option>
                      <option value="I've scheduled an appointment elsewhere">
                        I've scheduled an appointment elsewhere
                      </option>
                      <option value="I cannot afford this test">I cannot afford this test</option>
                      <option value="Other">Other</option>
                    </select>
                    {cancelReason === 'Other' && (
                      <textarea
                        autoFocus
                        className="mt-2"
                        value={cancelReasonOther}
                        onChange={(e) => setCancelReasonOther(e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            title={t('healthReport')}
            show={showModal === 'report'}
            submitDisable={!updatedList.length > 0}
            handleSubmit={multiplePost}
            loading={loadingUpdate}
            handleClose={hideModal}
          >
            <div className="p-4">
              <div className="d-flex justify-content-between">
                <Link to="/family">For family symptoms click here</Link>

                <p className="normal-font">Date: {moment().format('L')}</p>
                {lastUpdated && (
                  <p className="x-small-sub-font">
                    {t('lastUpdated')} {lastUpdated}
                  </p>
                )}
              </div>
              <div className="mt-4 mb-4">
                <p className="normal-font text-center">{t('pleaseCheckAny')}</p>
              </div>
              {loadingList && (
                <div className="d-flex justify-content-center mt-4 mb-4">
                  <Spinner animation="grow" variant="primary" />
                </div>
              )}
              <div>
                {list.map((item, index) => (
                  <div key={`cb-${index}`}>
                    <Divider />
                    <div className="mt-2 mb-2">
                      <Checkbox
                        label={capitalize(item.attributes.name)}
                        selected={item.selected}
                        handleChange={() => updateList(item.id, !item.selected, item.symptomId)}
                        labelColor={!item.selected && '#777777'}
                      />
                    </div>
                  </div>
                ))}
                {list.length > 0 && <Divider />}
              </div>
            </div>
          </Modal>

          <Modal
            show={showModal === 'activate'}
            handleClose={hideModal}
            submitText="Activate Now"
            submitDisable={loading}
            handleSubmit={() => history.push(`/activate/${selectedKit.id}`)}
            loading={loading}
            title="Collecting Sample Now?"
          >
            <div>
              <b>{t('pleaseReadInstructions')}</b>
            </div>
            <ul>
              <li>{t('testKitsMay')}</li>
              <li>{t('pleaseCollect')}</li>
              <li>{t('doNotShip')}</li>
              <li>{t('pleaseDropPackage')}</li>
              <li>{t('preAddressedFedEx')}</li>
              <li>
                {t('visit')}{' '}
                <a href="https://bit.ly/fedexdropbox" target="_blank" rel="noreferrer">
                  bit.ly/fedexdropbox
                </a>{' '}
                {t('onlineToView')}
              </li>
            </ul>
          </Modal>

          <Modal
            title="Required User Information"
            show={showModal === 'requiredInformation'}
            handleClose={hideModal}
            submitText="Update Account"
            handleSubmit={() => history.push('/edit-account-information/')}
          >
            <div>
              <h5>Please update your account information</h5>
            </div>
          </Modal>

          <Modal
            // title="Do you have insurance?"
            show={showInsuranceModal}
            headerComponent={<div />}
            footerComponent={
              <div className="d-flex justify-content-between w-100">
                <Button
                  variant="secondary"
                  data-testid="NO"
                  className="btn btn-orange-gray mr-2 mr-sm-0"
                  onClick={() => {
                    localStorage.removeItem('user_loggedIn');
                    setShowInsuranceModal(false);
                  }}
                >
                  {t('no')}
                </Button>
                <Button
                  variant="primary"
                  data-testid="YES"
                  className="btn btn-orange-med"
                  onClick={() => {
                    localStorage.removeItem('user_loggedIn');
                    history.push('/insurance-information');
                  }}
                >
                  {t('yes')}
                </Button>
              </div>
            }
          >
            <div className="d-flex justify-content-center">
              <h5>Do you have insurance?</h5>
            </div>
          </Modal>

          {unsignedFamilyConsentForms && (
            <Modal
              show={showUnsignedFamilyConsentModal}
              propsStyle={{ zIndex: '9999' }}
              className="unsigned-family-consent"
              headerComponent={
                <div className="unsigned-family-consent-header-container">
                  <img
                    alt="icon"
                    src={FamilyIcon}
                    className="family-image"
                    style={{ height: '90px' }}
                  />
                  <span className="-header">{t('unsignedFamilyForms')}</span>
                </div>
              }
              footerComponent={
                <>
                  <Button
                    className="btn btn-orange-med"
                    data-testid="CONT"
                    type="download"
                    onClick={signFamilyForms}
                  >
                    Continue
                  </Button>
                  <Button
                    className="btn btn-orange-gray"
                    data-testid="CLOSE"
                    onClick={() => setShowUnsignedFamilyConsentModal(false)}
                  >
                    Close
                  </Button>
                </>
              }
            >
              <div id="scroll-div" className="mx-2">
                <p className="modal-content-text mb-3">{t('familyConsentMessage')}</p>
                <p className="modal-content-text italic mb-3">{t('continueToSign')}</p>
              </div>
            </Modal>
          )}
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  userInsurance: state.user.insurance[0],
  results: state.results.results,
  userContacts: state.user.contacts,
  status: state.authentication.status,
  alertCard: state.alert,
  userAuthentication: state.authentication,
  appointments: state.user.appointments,
});

const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setResultsDispatch(payload) {
    dispatch(setResults(payload));
  },
  clearUserDispatch() {
    dispatch(clearUser());
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
  setContactsDispatch(payload) {
    dispatch(setUserContacts(payload));
  },
  logoutDispatch() {
    dispatch(logout());
  },
  setAuthenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setAppointmentDispatch(payload) {
    dispatch(setUserAppointments(payload));
  },
  deleteAppointmentsDispatch(payload) {
    dispatch(deleteAppointments(payload));
  },
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
