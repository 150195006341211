import React, { useState } from 'react';
import './index.css';
import Check from '../../../assets/image/check.png';

export default function TestResult({ onBack, onNext, result }) {
  const [testResult, setTestResult] = useState(result);

  return (
    <>
      <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
        <div className="col-12 col-sm-7 col-md-6 col-lg-5 mb-5 mt-3 px-3 px-sm-0">
          <div className="mb-3 testResult-header">Test Result</div>

          <div className="mb-3 testResult-sub-header">Please choose from one of the following</div>

          <div className="mb-3 my-4 pt-2">
            <div
              onClick={() => setTestResult('Positive')}
              className={` ${testResult === 'Positive' ? 'selectedResult' : 'notSelectResult'}`}
            >
              {testResult === 'Positive' && <img src={Check} alt="Check" className="mr-2" />}
              Positive
            </div>
          </div>

          <div className="mb-3 my-4 pt-2">
            <div
              onClick={() => setTestResult('Negative')}
              className={` ${testResult === 'Negative' ? 'selectedResult' : 'notSelectResult'}`}
            >
              {testResult === 'Negative' && <img src={Check} alt="Check" className="mr-2" />}
              Negative
            </div>
          </div>

          <div className="mb-3 my-4 pt-2">
            <div
              onClick={() => setTestResult('Inconclusive')}
              className={` ${testResult === 'Inconclusive' ? 'selectedResult' : 'notSelectResult'}`}
            >
              {testResult === 'Inconclusive' && <img src={Check} alt="Check" className="mr-2" />}
              Inconclusive
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5 ml-sm-3 px-1 px-sm-0">
        <div className="col-5 col-sm-3 col-lg-2 p-0 pl-3 pl-sm-0">
          <div className="cancel " onClick={onBack}>
            Back
          </div>
        </div>

        <div className="col-7 col-sm-4 col-md-3 pr-3 pr-sm-0">
          <div
            className="begin"
            style={testResult ? {} : { opacity: '0.4' }}
            onClick={() => {
              if (testResult) onNext(testResult);
            }}
          >
            Next
          </div>
        </div>
      </div>
    </>
  );
}
