import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Card, Spinner } from 'react-bootstrap';
import { Layout, ListSimple, Modal } from '../../components';
import { connect } from 'react-redux';
import moment from 'moment';

import { setVaxAppointments, setVaxHistory } from '../../store/action/vax';
import { setUser } from '../../store/action/user';

import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

import { processAPI } from '../../api/models/process';
import { getUserData } from '../../api/models/user';

import stationLogo from '../../assets/image/station.jpg';
import NextIcon from '../../assets/svg/next.svg';

import scheduleIcon from '../../assets/svg/schedule.svg';
import pokeIcon from '../../assets/svg/poke.svg';
import ticketIcon from '../../assets/svg/ticket.svg';
import documentsIcon from '../../assets/svg/documents.svg';

import InsuranceIcon from '../../assets/image/insuranceBg.png';

const Station = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { user, history } = props;

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vax, setVax] = useState([]);
  const [disableCard, setDisableCard] = useState(true);
  const [disablePass, setDisablePass] = useState(true);
  const [disableCheckIn, setDisableCheckIn] = useState(false);
  const [receivedLastVax, setReceivedLastVax] = useState(false);

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (
      appointments &&
      appointments.length > 0 &&
      user?.profile?.attributes['requires-screening-twenty-four-hours'] !== true
    ) {
      setDisableCheckIn(true);
    }
  }, [appointments, user]);

  useEffect(() => {
    loadData();
    // show insurance modal
    if (user?.profile?.attributes['any-insurance-policies'] === false) {
      setVisible(true);
      setDisableCheckIn(true);
    }
  }, []);

  useEffect(() => {
    if (vax.length > 0) {
      setDisableCard(false);
    }
  }, [vax]);

  useEffect(() => {
    setDisablePass(!receivedLastVax);
  }, [receivedLastVax]);

  async function loadData() {
    await loadAppts();
    await loadVax();
    await loadUser();
  }

  async function loadUser() {
    const us = await getUserData(props.authentication.user_id, props.authentication.jwt);
    props.setUserDispatch(us.data);
  }

  async function loadAppts() {
    const { jwt } = props.authentication;

    processAPI({
      endpoint: `medtest-location-appointments?filter[service_type]=vaccination`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        setAppointments(data);
        props.setVaxAppointmentsDispatch(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function loadVax() {
    const { jwt } = props.authentication;
    processAPI({
      endpoint: `vaccinations`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        setVax(data);
        props.setVaxHistoryDispatch(data);
        if (data.length > 0) {
          setReceivedLastVax(!data[data.length - 1].attributes['needs-a-boost']);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function getVaxNowLogo() {
    return <img src="/Aura_VaxNow_Concept_Logo_RGB.png" width="200" alt="Vax Now" className="" />;
  }

  return (
    <Layout tab={activeTab} headerTitle={getVaxNowLogo()}>
      <div className="vaccine">
        <div className={css.subContainer}>
          <Card className="" style={{ border: 'none' }}>
            <img src={stationLogo} className={css.bkg} alt="Logo" style={{ height: 'auto' }} />

            <Card.Body className={css.inset}>
              {loading && (
                <div className="text-center">
                  <Spinner animation="grow" variant="primary" />
                </div>
              )}

              <p className={css.welcome}>{t('welcomeToThe')}</p>
              {appointments && appointments.length === 0 && !receivedLastVax && (
                <>
                  <p className={css.congratulations}>{t('congratulationsYouBelong')}</p>
                  <div className={css.scheduleFirst}>{t('scheduleYour')}</div>
                </>
              )}
              {appointments && appointments.length > 0 && (
                <>
                  <p className={css.welcome}>{t('myVaccineAppointment')}</p>
                  <ListSimple
                    title={t('when')}
                    description={moment
                      .utc(appointments[0].attributes['appointment-time'])
                      .format('LLLL')}
                  />
                  <ListSimple
                    title={t('where')}
                    description={appointments[0].attributes['medtest-location-name']}
                  />
                </>
              )}
              {receivedLastVax && (
                <>
                  <p className={css.congratulations}>{t('congratulationsOnReceiving')}</p>
                  <div className={css.scheduleFirst}>{t('youHaveNow')}</div>
                </>
              )}
            </Card.Body>
          </Card>

          {!receivedLastVax && (
            <div
              className={css.listItem}
              style={disableCheckIn ? { opacity: 0.35 } : {}}
              onClick={() =>
                !disableCheckIn &&
                (appointments?.length > 0
                  ? history.push('/vaccinescan')
                  : history.push('/vaccinenotice'))
              }
            >
              <div className={css.left}>
                <img
                  src={scheduleIcon}
                  alt="Logo"
                  className={classNames(css.scanIcon, css.salmon)}
                />
              </div>
              <div className={css.middle}>
                {!appointments ||
                  (appointments.length === 0 && (
                    <>
                      <div className={css.stationHeader}>{t('schedule')}</div>
                      <p className="sub-font">{t('noAppointmentsScheduled')}</p>
                    </>
                  ))}

                {appointments && appointments.length > 0 && (
                  <>
                    <div className={css.stationHeader}>{t('checkin')}</div>
                    <p className="sub-font">
                      {t('checkinAtSite') +
                        moment
                          .utc(appointments[0].attributes['appointment-time'])
                          .format('MMM Do YY')}
                    </p>
                  </>
                )}
              </div>
              <div className={css.right}>
                <img alt="next" src={NextIcon} />
              </div>
            </div>
          )}

          <div
            className={classNames(css.listItem)}
            style={disableCard ? { opacity: 0.35 } : {}}
            onClick={() => !disableCard && history.push('/vaccinecard')}
          >
            <div className={css.left}>
              <img src={pokeIcon} alt="Logo" className={classNames(css.scanIcon, css.blue)} />
            </div>
            <div className={css.middle}>
              <div className={css.stationHeader}>{t('vaccinationRecordCard')}</div>
              {!vax ||
                (vax.length === 0 && <p className="sub-font">{t('noVaccinationsReceived')}</p>)}
              {!vax ||
                (vax.length > 0 && (
                  <p className="sub-font">
                    {vax.length} vaccination{vax.length > 1 ? 's' : ''} received
                  </p>
                ))}
            </div>
            <div className={css.right}>
              <img alt="next" src={NextIcon} />
            </div>
          </div>

          {/* insurance */}
          <div
            className={classNames(css.listItem)}
            onClick={() => history.push('/edit-insurance-information')}
          >
            <div className={css.left}>
              <img src={InsuranceIcon} alt="Logo" className={classNames(css.scanIcon, css.gold)} />
            </div>
            <div className={css.middle}>
              <div className={css.stationHeader}>{t('editInsuranceInformation')}</div>
              <p className="sub-font">Or use your driver's license or social security number</p>
            </div>
            <div className={css.right}>
              <img alt="next" src={NextIcon} />
            </div>
          </div>

          {false && (
            <div
              className={classNames(css.listItem)}
              style={disablePass ? { opacity: 0.35 } : {}}
              onClick={() => !disablePass && history.push('/vaccinepass')}
            >
              <div className={css.left}>
                <img src={ticketIcon} alt="Logo" className={classNames(css.scanIcon, css.green)} />
              </div>
              <div className={css.middle}>
                <div className={css.stationHeader}>{t('scanVaccinePass')}</div>
                <p className="sub-font">{t('viewMySecurePass')}</p>
              </div>
              <div className={css.right}>
                <img alt="next" src={NextIcon} />
              </div>
            </div>
          )}

          {false && (
            <div className={classNames(css.listItem)} onClick={() => history.push('/vaccinedocs')}>
              <div className={css.left}>
                <img
                  src={documentsIcon}
                  alt="Logo"
                  className={classNames(css.scanIcon, css.gray)}
                />
              </div>
              <div className={css.middle}>
                <div className={css.stationHeader}>{t('consentForms')}</div>
                <p className="sub-font">{t('viewMyDocuments')}</p>
              </div>
              <div className={css.right}>
                <img alt="next" src={NextIcon} />
              </div>
            </div>
          )}
        </div>

        <Modal
          show={isVisible}
          closeButton
          handleClose={() => setVisible(false)}
          handleSubmit={() => {
            setVisible(false);
            history.push('/edit-insurance-information');
          }}
          title="Welcome to VaxNow by Aura"
          submitText="Check Insurance"
          hideSubmit={false}
        >
          <p>
            Help us keep appointment check-in lines moving quickly by adding your insurance
            information (if applicable), driver's license information, or your Social Security
            Number prior to scheduling your test.
          </p>
          <p>
            This information is not required to schedule your appointment or receive your
            vaccination. While we are required to verify whether you have health insurance, you are
            not required to provide a social security number (or a government-issued ID), and your
            immigration status does not prevent you from getting the vaccine. Please check the
            attestation box if you do not have insurance or do not wish to provide your SSN or
            Government Issued ID before scheduling an appointment. The Vaccine will still be
            provided at no cost to you.
          </p>
          <p>Thank you for your cooperation.</p>
        </Modal>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setVaxAppointmentsDispatch(payload) {
    dispatch(setVaxAppointments(payload));
  },
  setVaxHistoryDispatch(payload) {
    dispatch(setVaxHistory(payload));
  },
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Station);
