import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Spinner, Form, Button } from 'react-bootstrap';

import './ResetPassword.css';
import { CommonLogo } from '../../components';

import { useTranslation } from 'react-i18next';

const ResetPassword = (props) => {
  const { t, i18n } = useTranslation();

  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  return (
    <>
      <Form
        // onSubmit={onSubmit}
        className="px-4 py-5 my-5 login-card form-login"
      >
        <div className="mt-2 text-center welcome-text ">{t('resetPassword')}</div>
        <CommonLogo />
        <Form.Group className="form-group pb-3">
          <Form.Label>{t('newPassword')}</Form.Label>

          <Form.Control
            type="password"
            placeholder="e.g. youremail@gmail.com"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            data-cy="password"
            autoCapitalize="none"
            required
            data-testid="CONFPASS"
          />
        </Form.Group>
        <Form.Group className="form-group pb-3">
          <Form.Label>{t('newPasswordConfirmation')}</Form.Label>

          <Form.Control
            type="password"
            placeholder="e.g. youremail@gmail.com"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            data-cy="password"
            autoCapitalize="none"
            required
            data-testid="PASS"
          />
        </Form.Group>
        <div className="form-group justify-content-between flex-row pb-3">
          <Button disabled={loading} type="submit" className="btn btn-login" data-testid="SUBMIT">
            {loading ? <Spinner animation="border" variant="warning" /> : t('submit')}
          </Button>
        </div>
        <div className="form-group justify-content-between flex-row pb-3">
          <Link to="/">
            <Button type="button" variant="outline-primary" className="btn-signup">
              {t('backToLogin')}
            </Button>
          </Link>
        </div>
      </Form>
    </>
  );
};

export default ResetPassword;
