import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Layout, ListResults, Divider, ListSimple, StrokeButton } from '../../components';
import css from './Results.module.css';
import ArrowDownIcon from '../../assets/svg/arrow_down.svg';
import { resultsMedTest } from '../../api/models/medTest';
import { processColor } from '../../api';
import { setResults, setSelectedResult } from '../../store/action/results';
import { authentication } from '../../store/action/auth';
import { getStoredData, storeData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import ArrowUpIcon from '../../assets/svg/arrow_up.svg';
import ResultsCards from '../ResultsCards/ResultsCards';

const ResultsContainer = (props) => {
  const { t, i18n } = useTranslation();

  const {
    userDetail,
    results,
    setResultsDispatch,
    setSelectedResultDispatch,
    selectedData,
    setSelectedData,
    fullHistory,
    setFullHistory,
    status,
    setAuthenticationDispatch,
  } = props;

  const [loading, setLoading] = useState(!(results.length > 0));
  const [ascending, setAscending] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [user, setUser] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadData();
  }, []);

  async function loadData() {
    // if (props.route.params && props.route.params.alert) {
    //   navigation.setParams({ alert: false });
    //   setShowAlert(true);
    // }
    setLoading(!(results.length > 0));
    const userData = await getStoredData(USER_CACHE_NAME);
    setUser(userData);
    if (userData && userData.status === 'login') {
      let result = (await resultsMedTest(userData.jwt)) || [];
      console.log(result);
      if (result && result.length > 0) {
        result = result.sort(compare);
        // result = filterDataPerDay(30, result);
        setResultsDispatch(result);
      }
      setLoading(false);
    } else {
      setShowDialog(true);
      setLoading(false);
    }
  }

  function filterDataPerDay(days, data) {
    return data.filter((item) => {
      const currentDate = moment().format('L');
      const testDate = moment(item.attributes['test-date']).add(days, 'days').format('L');

      return testDate >= currentDate;
    });
  }

  function compare(a, b) {
    const testA = a.attributes['test-date'];
    const testB = b.attributes['test-date'];

    let comparison = 0;
    if (testA > testB) {
      comparison = 1;
    } else if (testA < testB) {
      comparison = -1;
    }

    return comparison * -1;
  }

  async function onRefresh() {
    setRefreshing(true);
    await loadData();
    setRefreshing(false);
  }

  function hideAlert() {
    setShowAlert(false);
  }

  function sortData() {
    console.log('yes');
    setAscending(!ascending);
    results.reverse();
  }

  return (
    <div className={css.container}>
      <div className={css.flex1}>
        {!fullHistory ? (
          <ResultsCards setFullHistory={setFullHistory} results={results} />
        ) : (
          <div className={css.listContainer}>
            {!loading && results.length === 0 ? (
              <p className="medium-font-bold text-center">{t('noResultsWithin')}</p>
            ) : (
              <div className={css.container1}>
                <div className={css.header}>
                  <div className={css.headerText}>
                    {`${userDetail.attributes['first-name'].split(' ')[0]}'s Test History`}{' '}
                  </div>
                  <div className={css.sorter} onClick={sortData}>
                    <p className={css.sorterText}>Date</p>
                    <img
                      src={ascending ? ArrowDownIcon : ArrowUpIcon}
                      alt="down arrow"
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>
            )}
            {loading && (
              <div className="text-center">
                <Spinner animation="grow" variant="primary" />
              </div>
            )}
            {results.map(({ attributes, id }) => {
              let result = attributes['test-result'] || 'Unknown';
              const color = attributes.color.toLowerCase();
              let iconColor = processColor(color, result);

              return (
                <ListResults
                  key={`tr-${id}`}
                  icon={iconColor}
                  type={attributes['medtest-type']}
                  date={moment(attributes['test-date']).format('MM/DD/YYYY')}
                  updatedTime={attributes['updated-at']}
                  location={attributes['test-location']}
                  name={`${attributes['first-name']} ${attributes['last-name']}`}
                  result={attributes['test-result'] || 'Unknown'}
                  onCLick={() => {
                    if (selectedData?.id === id) {
                      setSelectedData(null);
                      return;
                    }
                    setSelectedResultDispatch(attributes);
                    setSelectedData({ ...attributes, iconColor, id });
                  }}
                  selected={selectedData && selectedData.id === id}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetail: state.user.profile,
  results: state.results.results,
  status: state.authentication.status,
});

const mapDispatchToProps = (dispatch) => ({
  setResultsDispatch(payload) {
    dispatch(setResults(payload));
  },
  setAuthenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setSelectedResultDispatch(payload) {
    dispatch(setSelectedResult(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsContainer);
