import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import css from './Scan.module.css';
import LogoBlack from '../../assets/svg/logo_black.svg';
import { getQrCode } from '../../api/models/qr';
import { USER_CACHE_NAME } from '../../api/const';
import { getStoredData } from '../../api/cache';
import { Layout } from '../../components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ScanContainer = (props) => {
  const [svgCode, setSvgCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [setUser] = useState();
  const { t, i18n } = useTranslation();

  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const buff = new Buffer(svgCode);
  const base64data = buff.toString('base64');
  const certStatus =
    props.user &&
    props.user.profile &&
    props.user.profile.attributes &&
    props.user.profile.attributes['certification-status'];
  const certReason =
    props.user &&
    props.user.profile &&
    props.user.profile.attributes &&
    props.user.profile.attributes['certification-reason'];
  const navCertification =
    props.user &&
    props.user.profile &&
    props.user.profile.attributes &&
    props.user.profile.attributes['nav-certification'];

  console.log('certStatus', certStatus);
  console.log('certReason', certReason);
  console.log('navCertification', navCertification);

  useEffect(() => {
    loadQrCode();
  }, []);

  async function loadQrCode() {
    setLoading(true);
    const { authentication } = props;
    const { jwt } = authentication;

    const code = await getQrCode(jwt);
    setSvgCode(`${code}`);
    setLoading(false);

    setLoading(false);
  }

  return (
    <Layout headerTitle="Scan" tab={activeTab}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className={navCertification ? certStatus : 'DENIED'}>
          <div className={css.container}>
            {navCertification && (
              <p className={css.title}>
                {certStatus === 'DENIED' ? `NOT CERTIFIED\n${certReason}` : 'CERTIFIED'}
              </p>
            )}
            <div className={css.scan}>
              {loading && (
                <div className="text-center">
                  <Spinner animation="grow" variant="primary" />
                </div>
              )}
              <img src={`data:image/svg+xml;base64,${base64data}`} alt="" />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img alt="logo" src={LogoBlack} />
              <div className={css.divider} />
              <p className={classNames('small-sub-font', css.smallSubFont)}>
                {t('certificationCode')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

export default connect(mapStateToProps)(ScanContainer);
