import React, { useEffect, useMemo, useState } from 'react';
import './index.css';
import { useHistory } from 'react-router';
import Intro from './Intro';
import TestSite from './TestSite';
import DateTime from './DateTime';
import TestResult from './TestResult';
import UploadFile from './UploadFile';
import FinalSubmit from './FinalSubmit';
import { SmallLogo } from '../../../components';

const labData = {
  labName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
};

export default function UploadTest() {
  const history = useHistory();
  const [testSite, setTestSite] = useState('');
  const [labDetails, setLabDetails] = useState(labData);
  const [dateTime, setDateTime] = useState({ date: '', time: '' });
  const [testResult, setTestResult] = useState('');
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(0);
  const list = useMemo(
    () => ['Intro', 'Testing Site Info', 'Date & Time', 'Test Result', 'File Upload'],
    []
  );

  useEffect(() => {
    if (step === 0) {
      setTestSite('');
      setLabDetails(labData);
      setDateTime({ date: '', time: '' });
      setTestResult('');
      setFiles([]);
    }
  }, [step]);

  const onFinalSubmit = (data) => {
    console.log('onFinalSubmit', data);
  };

  return (
    <div className="row mx-0 position-relative">
      <div className="d-none d-sm-block col-sm-3 col-xl-2 h-screen">
        <div className="d-none d-lg-block mt-4 text-center">
          <SmallLogo />
        </div>

        <div className="ml-0 ml-lg-4 mt-4">
          {list.map((val, i) => (
            <>
              <div key={i} className="d-flex align-items-center">
                <div
                  className={`border rounded-circle round border-5 text-center ${
                    step >= i ? 'border-secondary' : ''
                  }`}
                >
                  {step > i && <span>&#10003;</span>}
                </div>
                <div className="ml-2 side_text" style={step === i ? {} : { color: '#777777' }}>
                  {val}
                </div>
              </div>

              {i !== list?.length - 1 && <div className="ml-2 border h-line" />}
            </>
          ))}
        </div>
      </div>

      <div className="offset-1 offset-sm-0 col-10 col-sm-9 col-xl-10 ">
        <div className="d-flex align-items-center justify-content-center justify-content-sm-start mt-3">
          <div className="header-text mx-1 mx-sm-3">Upload Test Result</div>
        </div>

        {step === 0 ? (
          <Intro onCancel={() => history.push('/my-tests')} onBegin={() => setStep(1)} />
        ) : step === 1 ? (
          <TestSite
            onNext={(data, testSiteLocation) => {
              setTestSite(testSiteLocation);
              setLabDetails(data?.labName ? data : labData);
              setStep(2);
            }}
            onBack={() => {
              setStep(0);
            }}
            labDetails={labDetails}
            testSiteLocation={testSite}
          />
        ) : step === 2 ? (
          <DateTime
            dateTime={dateTime}
            onNext={(data) => {
              setDateTime(data);
              setStep(3);
            }}
            onBack={() => {
              setStep(1);
            }}
          />
        ) : step === 3 ? (
          <TestResult
            result={testResult}
            onNext={(data) => {
              setTestResult(data);
              setStep(4);
            }}
            onBack={() => {
              setStep(2);
            }}
          />
        ) : step === 4 ? (
          <UploadFile
            value={files}
            onNext={(data) => {
              setFiles(data);
              setStep(5);
            }}
            onBack={() => {
              setStep(3);
            }}
          />
        ) : (
          <FinalSubmit
            values={{
              ...labDetails,
              ...dateTime,
              files,
              testResult,
              testSite,
            }}
            onNext={(data) => {
              onFinalSubmit(data);
            }}
            onBack={(val) => {
              setStep(val);
            }}
          />
        )}
      </div>
    </div>
  );
}
