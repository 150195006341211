import React, { useState } from 'react';
import './index.css';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Input from './Atoms/Input';
import SelectInput from './Atoms/SelectInput';
import { stateList } from '../../../utils/utils';
import TestSiteResponse from './TestSiteResponse';

export const TestSiteSchema = Yup.object({
  labName: Yup.string().required('Lab Name is required'),
  addressLine1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string()
    .required('Zip code is required')
    .nullable()
    .test('is-present', 'Invalid Zip Code', async (value) =>
      /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    ),
});

export default function TestSite({ onNext, onBack, labDetails, testSiteLocation }) {
  //
  const [testSite, setTestSite] = useState(testSiteLocation);

  const onSubmitFormData = (data) => {
    onNext(data, testSite);
  };

  const [step, setStep] = useState(labDetails?.labName ? 1 : 0);

  return (
    <>
      {step === 0 ? (
        <TestSiteResponse
          value={testSite}
          onNext={(data) => {
            if (data === 'At Home') {
              onNext('', data);
            } else {
              setTestSite(data);
              setStep(1);
            }
          }}
          onBack={onBack}
        />
      ) : (
        <>
          <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
            <div className="col-12 col-sm-10 mb-5 mt-3 px-3 px-sm-0">
              <div className="mb-3 testsite-header">Testing Site Information</div>

              <div>
                <Formik
                  initialValues={{ ...labDetails }}
                  validationSchema={TestSiteSchema}
                  onSubmit={onSubmitFormData}
                  enableReinitialize
                >
                  {(formikProps) => (
                    <Form>
                      <Field
                        name="labName"
                        label="Lab Name *"
                        placeholder="i.e. CVS, Rite Aid, At Home"
                        component={Input}
                        type="text"
                      />

                      <Field
                        name="addressLine1"
                        label="Address 1 *"
                        placeholder="Address"
                        component={Input}
                        type="text"
                      />

                      <Field
                        name="addressLine2"
                        label={`Address 2: Apartment, suite, etc. (optional) `}
                        placeholder="Address"
                        component={Input}
                        type="text"
                      />

                      <Field
                        name="city"
                        label="City *"
                        placeholder="City"
                        component={Input}
                        type="text"
                      />

                      <Field
                        name="state"
                        label="State *"
                        component={SelectInput}
                        classNamePrefix="form-select"
                        placeholder="State"
                        options={stateList.map((s) => ({
                          value: s.value,
                          label: s.label,
                        }))}
                      />

                      <Field
                        name="zip"
                        label="Zip Code *"
                        placeholder="Enter Zip Code"
                        component={Input}
                        type="text"
                      />

                      <div className="row my-4 pt-3 pb-5">
                        <div className="col-6">
                          <div className="cancel " onClick={() => setStep(0)}>
                            Back
                          </div>
                        </div>

                        <div className="col-6">
                          <div
                            style={
                              !formikProps.isValid || (!formikProps.dirty && !labDetails?.labName)
                                ? { opacity: '0.4' }
                                : {}
                            }
                            type="submit"
                            className="begin"
                            onClick={() => formikProps.submitForm()}
                          >
                            Next
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
