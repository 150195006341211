import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ReactPlayer from 'react-player';
import moment from 'moment';
import ChevronDown from '../../assets/svg/chevron_down';
import YoutubeSvg from '../../assets/svg/youtube.svg';

const useStyles = makeStyles((theme) => ({
  panel: {
    marginBottom: theme.spacing(1),
  },
  summary: {
    background: '#F5F5F5',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  description: {
    fontSize: 17,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(3),
  },
  player: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 640,
    backgroundColor: 'black',
    border: '1px solid #EBEDEF',
  },
}));

const VideoPanel = ({ expanded, handleChange, selectedFamilyMembers, primaryFamilyMembers }) => {
  const classes = useStyles();

  const dob =
    selectedFamilyMembers?.attributes?.['date-of-birth'] ||
    primaryFamilyMembers?.data?.attributes?.['date-of-birth'];
  const age = moment().diff(moment(dob), 'years');

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange} className={classes.panel}>
      <ExpansionPanelSummary
        expandIcon={<ChevronDown color="rgba(255, 153, 0, 0.8)" />}
        className={classes.summary}
      >
        <div className={classes.headerContainer}>
          <img src={YoutubeSvg} alt="youtube" />
          &nbsp;
          <Typography className={classes.header}>Video Instructions</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <Typography className={classes.description}>
          In addition to the step by step instructions listed below, you may view how to collect
          your COVID-19 Nasal Swab sample in the following video.
        </Typography>
        <ReactPlayer
          width="100%"
          controls
          className={classes.player}
          url={
            age > 17
              ? 'https://www.youtube.com/watch?v=wKjTIjNGUU8'
              : 'https://www.youtube.com/watch?v=0O93tQIwsZE&feature=youtu.be'
          }
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapStateToProps = ({ family: { primaryFamilyMembers, selectedFamilyMembers } }) => ({
  primaryFamilyMembers,
  selectedFamilyMembers,
});

export default connect(mapStateToProps, null)(VideoPanel);
