import React from 'react';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import step3 from '../../assets/image/updated_instructions/step_3.svg';
import step4 from '../../assets/image/updated_instructions/step_4.svg';
import step5 from '../../assets/image/updated_instructions/step_5.svg';
import step6 from '../../assets/image/updated_instructions/step_6.svg';
import step7 from '../../assets/image/updated_instructions/step_7.svg';
import step8 from '../../assets/image/updated_instructions/step_8.svg';
import step10 from '../../assets/image/updated_instructions/step_10.svg';
import step11 from '../../assets/image/updated_instructions/step_11.svg';
import ExclamationSvg from '../../assets/svg/exclamationpoint.svg';
import CheckSvg from '../../assets/svg/green_check.svg';
import CloseSvg from '../../assets/svg/red_close.svg';
import ChevronDown from '../../assets/svg/chevron_down';
import FileSvg from '../../assets/svg/file.svg';

const useStyles = makeStyles((theme) => ({
  panel: {
    marginBottom: theme.spacing(1),
  },
  summary: {
    background: '#F5F5F5',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  lightBlue: {
    background: '#F3FAF9',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'SF Pro Display',
    color: '#254659',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    width: '100%',
    fontSize: 15,
    fontFamily: 'SF Pro Display',
    color: '#777777',
  },
  exclamationStep: {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    borderRadius: '50px 0px 0px 50px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  exclamationDescription: {
    fontSize: 13,
    fontFamily: 'SF Pro Display',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  warningDescription: {
    fontWeight: 'bold',
    color: '#254659',
    marginBottom: theme.spacing(2),
  },
  optionContainer: {},
}));

const groupList = [
  {
    title: 'Prepare to take your sample',
    steps: [
      {
        img: step3,
        subtitle: 'Wash hands',
        description: 'Wash hands in warm, soapy water and dry thoroughly.',
      },
      {
        img: step4,
        subtitle: 'Prepare the collection tube & swab',
        description:
          'Screw off the cap of the collection tube & place it with the opening face up on a clean surface. Take the swab out of its packaging.',
      },
      {
        warning:
          'IMPORTANT: Do not touch the tip of the swab with your hands & do not lay the swab down on any surface',
      },
    ],
  },
  {
    title: 'Collect your sample',
    steps: [
      {
        img: step5,
        subtitle: 'Swab both nostrils',
        description: (
          <>
            Hold your swab in one hand & the collection tube in the other. Insert the tip of the
            swab into one nostril until it is barely visible.
            <br />
            <br />
            Rub the swab around the inside of the wall of the nostril at least 5 times. Repeat this
            process for the other nostril.
          </>
        ),
      },
      {
        warning:
          'IMPORTANT: You must collect the sample from both nostrils before placing the swab into the collection tube.',
      },
      {
        img: step6,
        subtitle: 'Place the sample in the collection tube',
        description:
          'Submerge the tip of the swab into the liquid in the collection tube. Break the swab along the pre-scored line by bending it against the collection tube. Discard the broken top part of the swab.',
      },
      {
        img: step7,
        subtitle: 'Close the collection tube',
        description:
          'Screw the top of the collection tube back on, ensuring that it is closed tightly. Place the sample collection tube into the biohazard specimen bag & seal the biohazard specimen bag tightly. Wash and dry hands thoroughly.',
      },
    ],
  },
  {
    title: 'Return the sample',
    steps: [
      {
        img: step8,
        subtitle: 'Pack the sample',
        description: (
          <>
            Place the biohazard specimen bag into the box and close it.
            <br />
            <br />
            Place the box into the pre-addressed FedEx pack. Remove the cover on the adhesive strip
            & seal the return pack.
          </>
        ),
      },
      {
        img: step10,
        subtitle: 'Drop off your sample',
        description: (
          <>
            Within 24 hours of sample collection, mail the pre-addressed FedEx return pack (or FedEx
            UN 3373 Pak) to GENETWORx by:
            <br />
            <ul>
              <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                <img src={CheckSvg} alt="check" />
                <div>
                  Dropping the package off into a FedEx drop box before the last pickup of the day
                  (preferred)
                </div>
              </li>
              <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                <img src={CheckSvg} alt="check" />
                <div>Calling FedEx at 1 (800) 463-3339 to arrange a pickup</div>
              </li>
              <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                <img src={CloseSvg} alt="close" />
                <div style={{ color: '#D23C3C' }}>
                  Do not ship the package late Friday evening, or over the weekend
                </div>
              </li>
              <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                <img src={CloseSvg} alt="close" />
                <div style={{ color: '#D23C3C' }}>
                  Do not take the sample directly to a FedEx Office
                </div>
              </li>
            </ul>
            Visit{' '}
            <a href="https://bit.ly/fedexdropbox" target="_blank" rel="noreferrer">
              bit.ly/fedexdropbox
            </a>{' '}
            online to view FedEx drop box locations and pickup schedules.
          </>
        ),
      },
      {
        img: step11,
        subtitle: 'Receive the results',
        description: (
          <>
            Once the results are available, you will receive an email notification and can login at{' '}
            <a href="https://usability.Auratracker.org" target="_blank" rel="noreferrer">
              Usability.Auratracker.org
            </a>{' '}
            to view your results.
          </>
        ),
      },
    ],
  },
];

const TextPanelUsability = ({ expanded, handleChange }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange} className={classes.panel}>
      <ExpansionPanelSummary
        expandIcon={<ChevronDown color="rgba(255, 153, 0, 0.8)" />}
        className={classes.summary}
      >
        <div className={classes.headerContainer}>
          <img src={FileSvg} alt="youtube" />
          &nbsp;
          <Typography className={classes.header}>Written Instructions</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <ul className={classes.groupContainer}>
          {groupList.map(({ title, steps }, index) => (
            <li className={index % 2 ? classes.lightBlue : ''}>
              <Typography className={classes.title}>{title}</Typography>
              <ul className={classes.stepContainer}>
                {steps.map(({ img, subtitle, description, exclamation, warning }) => {
                  console.log(description);
                  if (exclamation) {
                    return (
                      <div className={classes.exclamationStep}>
                        <img src={ExclamationSvg} alt="exclamation" />
                        <Typography className={classes.exclamationDescription}>
                          {exclamation}
                        </Typography>
                      </div>
                    );
                  }

                  if (warning) {
                    return (
                      <Typography
                        className={classNames(classes.warningDescription, classes.description)}
                      >
                        {warning}
                      </Typography>
                    );
                  }

                  return (
                    <div className={classes.step}>
                      <img src={img} alt="youtube" />
                      <Typography className={classes.subtitle}>{subtitle}</Typography>
                      <Typography className={classes.description}>{description}</Typography>
                    </div>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default TextPanelUsability;
