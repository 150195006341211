import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Header, ProgressBar } from '../../components';
import ExclamationSvg from '../../assets/svg/exclamationpoint.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    flex: '1 1 auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(10),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
    },
  },
  descriptionList: {
    listStyleType: 'number',
    marginLeft: theme.spacing(2),
  },
  description: {
    fontSize: 24,
    letterSpacing: -0.408,
    fontFamily: 'SF Pro Display',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      letterSpacing: -0.408,
    },
  },
  activateButton: {
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flex: 2,
    },
  },
  cancelButton: {
    border: '1.25px solid #A3A3A3',
    flex: 1,
    [theme.breakpoints.down('xs')]: {},
  },
  button: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    letterSpacing: -0.408,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',
    },
  },
  bottom: {
    marginBottom: theme.spacing(5),
  },
  exclamationStep: {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    borderRadius: '50px 50px 50px 50px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  exclamationDescription: {
    fontSize: 17,
    fontFamily: 'SF Pro Display',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}));

const descriptions = [
  'I am able to return test kit within 24 hours of sample collection.',
  <>
    I can return this test kit between Monday morning & Friday evening via FedEx pickup (
    <a href="tel:8004633339">1.800.463.3339</a>) or at a FedEx dropoff location(
    <a href="https://bit.ly/fedexdropbox" target="_blank" rel="noreferrer">
      bit.ly/fedexdropbox
    </a>
    ).
  </>,
  'I am able to store this test kit at room temperature 41-77°F (5-25°C).',
];

const CollectingSample = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  const handleOnSubmit = () => {
    history.push('/collecting-consent', {
      selectedMember: state?.selectedMember,
      selectedMemberName: state?.selectedMemberName,
      kit: state?.kit,
    });
  };

  const handleOnCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!state?.selectedMember) {
      history.push('/select-family-member-activation');
    }
  }, [history, state?.selectedMember]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const list = [
    'Introduction',
    'Testing Consent',
    'Kit Registration',
    'Tracking Number',
    'Instructions',
  ];

  const withProgressBar = () => (
    // replace "classes.root" line below
    <div className="row mx-0 position-relative">
      <ProgressBar list={list} step={1} />
      <div className="col-8 col-sm-9 col-xl-10 " />
    </div>
  );
  return (
    <div className={classes.root}>
      <Header title="Before Collecting Your Sample" progress={1} onBack={() => history.goBack()} />
      <div className={classes.body}>
        <div>
          <Typography className={classes.title}>
            Please read the information below and confirm you are able to follow these guidelines
            before proceeding:
          </Typography>
          <ul className={classes.descriptionList}>
            {descriptions.map((desc) => (
              <li className={classes.description}>{desc}</li>
            ))}
          </ul>
          <div className={classes.exclamationStep}>
            <img src={ExclamationSvg} alt="exclamation" />
            <Typography className={classes.exclamationDescription}>
              Read all directions thoroughly & carefully before you start
            </Typography>
          </div>
          <Typography className={classNames(classes.description, classes.bottom)}>
            If you are unable to follow these guidelines, please postpone collecting a sample until
            you can store it and return it as instructed.
          </Typography>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classNames(classes.button, classes.activateButton)}
            onClick={handleOnSubmit}
          >
            Continue to Consent
          </Button>
          <Button
            className={classNames(classes.button, classes.cancelButton)}
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CollectingSample;
