import * as React from 'react';

function Calendar1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 23" fill="none" {...props}>
      <rect
        x={0.881}
        y={4.221}
        width={19.374}
        height={17.591}
        rx={1.761}
        stroke="#8B8B8B"
        strokeWidth={1.761}
        strokeLinejoin="round"
      />
      <path
        d="M.88 5.982A2.642 2.642 0 013.523 3.34h14.971a2.642 2.642 0 012.642 2.642V7.74H.881V5.982z"
        fill="#8B8B8B"
      />
      <rect x={14.971} y={0.701} width={2.642} height={3.519} rx={0.881} fill="#8B8B8B" />
      <rect x={3.522} y={0.701} width={2.642} height={3.519} rx={0.881} fill="#8B8B8B" />
      <rect
        x={3.963}
        y={9.938}
        width={2.642}
        height={2.638}
        rx={0.44}
        stroke="#8B8B8B"
        strokeWidth={0.881}
      />
      <rect
        x={9.247}
        y={9.938}
        width={2.642}
        height={2.638}
        rx={0.44}
        stroke="#8B8B8B"
        strokeWidth={0.881}
      />
      <rect
        x={14.53}
        y={9.938}
        width={2.642}
        height={2.638}
        rx={0.44}
        stroke="#8B8B8B"
        strokeWidth={0.881}
      />
      <rect
        x={3.963}
        y={15.216}
        width={2.642}
        height={2.638}
        rx={0.44}
        stroke="#8B8B8B"
        strokeWidth={0.881}
      />
      <rect
        x={9.247}
        y={15.216}
        width={2.642}
        height={2.638}
        rx={0.44}
        stroke="#8B8B8B"
        strokeWidth={0.881}
      />
      <rect x={14.09} y={14.776} width={3.523} height={3.519} rx={0.881} fill="#8B8B8B" />
    </svg>
  );
}

export default Calendar1;
