import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';

import { Alert } from '../../components';
import Input from '../../components/Input/Input';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInput';
import CheckboxTwo from '../../components/CheckboxTwo/CheckboxTwo';
import css from './EditNonInsuranceInformation.module.css';
import { processAPI } from '../../api/models/process';
import { uploadFile } from '../../api/models/forms';
import { useTranslation } from 'react-i18next';
import { nonInsuranceSchema } from '../../utils/constraints';
import { setUserProfile, setUser } from '../../store/action/user';
import { getUserData, getUserDataProfile } from '../../api/models/user';

const EditNonInsuranceInformation = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  // const userData = props?.user?.attributes || {};
  const userProfile = props?.profile?.attributes || {};
  // const data = props.userInsurance?.attributes || {};
  const { setUserDispatch, setUserProfileDispatch } = props;
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showAlert, setShowAlert] = useState({ show: false });
  const SSN_FORMAT = '###-##-####';

  async function nonInsuranceSubmit(values) {
    const { jwt, user_id } = props.userAuthentication;
    setLoading(true);
    let meta;
    try {
      if (files?.length > 0) {
        meta = await getUrl();
        await uploadFile(meta.url, files[0], meta.fields);
      }

      const data = await processAPI({
        key: jwt,
        methodType: 'POST',
        data: {
          driversLicense: values.driversLicense,
          ssn: values.ssn,
          hasInsurance: typeof values.hasInsurance === 'boolean' ? !values.hasInsurance : null,
        },
        endpoint: 'no-insurance',
      });

      if (data?.status) {
        await saveFileName(meta?.fields?.key, user_id);
        const userData = await getUserData(user_id, jwt);
        setUserDispatch(userData.data);
        const userProfile = await getUserDataProfile(user_id, jwt);
        setUserProfileDispatch(userProfile);
        setShowAlert({
          show: true,
          message: 'Saved successfully',
          type: 'success',
        });
        history.goBack();
      } else {
        setShowAlert({ show: true, message: 'Failed', type: 'error' });
      }
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }
    setLoading(false);
  }

  async function getUrl() {
    const { jwt } = props.userAuthentication;

    const preSigned = {
      filename: files[0].name,
      image_type: files[0].type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'pre-signed-post',
    });
    return data;
  }

  async function saveFileName(fileName, userID) {
    const { jwt } = props.userAuthentication;

    const data = await processAPI({
      key: jwt,
      methodType: 'PATCH',
      data: {
        filename: fileName,
        imageable_id: userID,
        imageable_type: 'User',
      },
      endpoint: 'image',
    });

    return data;
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  return (
    <>
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.nonInsuranceTitle}>
        <h4 className="small-font">{t('enterNonInsuranceInfo')}</h4>
      </div>
      <div>
        <Formik
          initialValues={{
            driversLicense: userProfile['drivers-license'] || '',
            ssn: userProfile.ssn || '',
            hasInsurance:
              typeof userProfile['has-insurance'] === 'boolean'
                ? !userProfile['has-insurance']
                : null,
          }}
          validationSchema={nonInsuranceSchema}
          onSubmit={nonInsuranceSubmit}
        >
          {(formikProps) => (
            <Form className={css.auraForm}>
              <Field
                name="driversLicense"
                label="Drivers License"
                placeholder="Drivers License Number"
                component={Input}
                type="text"
              />
              <Field
                name="ssn"
                label="SSN"
                component={NumberFormatInput}
                type="text"
                placeholder={SSN_FORMAT}
                format={SSN_FORMAT}
              />
              <p className="small-font my-2">
                If you are uninsured and cannot or do not wish to provide your driver’s
                license/state issued ID number or Social Security Number, please confirm the
                following statement:
              </p>
              <Field
                component={CheckboxTwo}
                name="hasInsurance"
                id="hasInsurance"
                value={formikProps.values.hasInsurance}
              >
                <span>
                  Attestation: I do not have any health insurance coverage, including but not
                  limited to Medicare, Medicaid or any other private or government-funded health
                  benefit plan.
                </span>
              </Field>
              {files && files.length > 0 && (
                <p className="thin-normal-font my-2">{files[0].name}</p>
              )}
              <label htmlFor="cardImage" className={classNames('my-4', css.customFileUpload)}>
                {t('uploadUserImage')}
              </label>
              <input
                id="cardImage"
                accept="image/*"
                title="&nbsp;"
                type="file"
                multiple
                name="cardImage"
                className={css.inputfile}
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
              />
              <Button type="submit" disabled={loading} className="mb-4 mt-4">
                {loading ? <Spinner animation="border" variant="secondary" /> : t('submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userAuthentication: state.authentication,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserProfileDispatch(payload) {
    dispatch(setUserProfile(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(EditNonInsuranceInformation);
