import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button } from '@material-ui/core';
import { GreetingLogo, Footer } from '../../components';
import ArrowLeftBigSvg from '../../assets/svg/arrow_left_big.svg';
import { processAPI } from '../../api/models/process';
import { setPromoCode } from '../../store/action/flow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
    fontFamily: 'SF Pro Display',
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
  },
  main: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(15),
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  formTitle: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginTop: theme.spacing(8),
  },
  formDescription: {
    fontSize: 17,
    letterSpacing: -0.408,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  promoCodeInput: {
    marginBottom: theme.spacing(6),
    fontSize: 17,
    letterSpacing: -0.408,
    fontFamily: 'SF Pro Display',
    '& .Mui-focused': {
      color: 'black',
    },
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(1),
      color: 'black',
      fontSize: 12,
      fontFamily: 'SF Pro Display',
    },
    '& input': {
      fontWeight: 600,
      fontSize: 17,
      fontFamily: 'SF Pro Display',
    },
  },
  submitButton: {
    marginBottom: theme.spacing(4),
    height: 46,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    opacity: 0.4,
    borderRadius: 14,
    border: '1.25px solid #FF9900',
    fontFamily: 'SF Pro Display',
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: -0.408,
    textTransform: 'capitalize',
    '&:disabled': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
    '&:focus': {
      outline: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  buttonSelected: {
    opacity: 1,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
  info: {
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: -0.078,
    marginBottom: theme.spacing(8),
  },
  backButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  backButtonImg: {
    cursor: 'pointer',
  },
  backButtonTitle: {
    fontWeight: 600,
    fontSize: 20,
    color: '#8B8B8B',
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const PromoCode = ({ setPromoCodeDispatch }) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const handleOnBack = () => {
    history.push('/activation');
  };

  const handleOnSubmit = async () => {
    try {
      let response = await processAPI({
        endpoint: 'medtest-kits/validate-promo-code',
        data: {
          data: {
            'promo-code': value,
          },
        },
        methodType: 'POST',
      });
      console.log(response);

      if (response?.error) {
        setError(response?.error.split('_').join(' '));
      } else {
        setPromoCodeDispatch(value);
        history.push(`/activation-signup/${response?.company?.id}`);
      }
    } catch (e) {
      setError('Promo Code should be valid.');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.backButtonContainer}>
        <img
          src={ArrowLeftBigSvg}
          alt="arrow-left"
          className={classes.backButtonImg}
          onClick={handleOnBack}
        />
        <Typography className={classes.backButtonTitle}>Back</Typography>
      </div>
      <div className={classes.main}>
        <div className={classes.form}>
          <GreetingLogo center={false} />
          <Typography className={classes.formTitle}>Register My Test Kit</Typography>
          <Typography className={classes.formDescription}>
            Please remember to register your kit before collecting your sample.
          </Typography>
          <TextField
            error={!!error && !!value}
            helperText={error}
            className={classes.promoCodeInput}
            label="Promo Code"
            placeholder="Enter Promo Code"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setError('');
            }}
          />
          <Button
            className={classNames(classes.submitButton, value ? classes.buttonSelected : '')}
            disabled={!value}
            onClick={handleOnSubmit}
            fullWidth
          >
            Submit Promo Code
          </Button>
          <Typography className={classes.info}>
            IMPORTANT: The lab cannot process your sample if it is not registered
          </Typography>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setPromoCodeDispatch(payload) {
    dispatch(setPromoCode(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
