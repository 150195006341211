import { API } from '../const';

export function search(address, filter) {
  return new Promise(async (res, rej) => {
    try {
      const apiLocations = `${API}medtest-locations?filter[${filter}]=${address}`;
      const body = await fetch(apiLocations);
      const json = await body.json();

      if (json.errors) {
        rej();
      }
      res(json);
    } catch (error) {
      console.log('error ', error);
      rej([]);
    }
  });
}
