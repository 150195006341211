import React from 'react';
import css from './ShopTestKits.module.css';
import Briefcase from '../../assets/svg/briefcase.svg';
import ShopButton from '../../assets/svg/shoptestkits.svg';
import gxlogo from '../../assets/svg/genetworx.svg';

const ShopTestKits = () => (
  <div className={css.container}>
    <div className={css.innerContainer}>
      <img src={Briefcase} alt="briefcase" />
      <h2 className={css.headerText}>Explore Available Test Kits</h2>
      <a href="https://testnowandgo.com/order-at-home-tests/">
        <img src={ShopButton} alt="shop button" className={css.shopLogo} />
      </a>
      <br />
      <img src={gxlogo} alt="Logo" className={css.gxLogo} />
    </div>
  </div>
);

export default ShopTestKits;
