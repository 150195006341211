import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import VideoPanel from './VideoPanel';
import TextPanel from './TextPanel';
import TextPanelUsability from './TextPanelUsability';
import AssistedSampleCollection from './AssistedSampleCollection';
import { Header } from '../../components';
import { isUsability } from '../../api/const';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
  },
  body: {
    flex: '1 1 auto',
    overflowY: 'auto',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(5),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
  },
  footer: {
    flex: '0 1 80px',
    paddingLeft: '15%',
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  submitButton: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
      opacity: 0.4,
    },
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'unset',
    letterSpacing: -0.408,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
}));

const Instructions = () => {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (expandedIndex) => () => {
    setExpanded(expanded === expandedIndex ? 0 : expandedIndex);
  };

  const handleOnSubmit = () => {
    history.push('/my-tests', { tab: 1 });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={classes.root}>
      <Header title="Instructions" progress={100} onBack={() => history.goBack()} />
      <div className={classes.body}>
        {isUsability ? (
          <>
            <VideoPanel expanded={expanded === 1} handleChange={handleChange(1)} />
            <AssistedSampleCollection expanded={expanded === 3} handleChange={handleChange(3)} />
            <TextPanelUsability expanded={expanded === 2} handleChange={handleChange(2)} />
          </>
        ) : (
          <TextPanel expanded={expanded === 2} handleChange={handleChange(2)} />
        )}
      </div>
      <div className={classes.footer}>
        <Button fullWidth className={classNames(classes.submitButton)} onClick={handleOnSubmit}>
          Return to My Tests
        </Button>
      </div>
    </div>
  );
};

export default Instructions;
