import moment from 'moment';

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function calculateAge(data) {
  const birthday = moment(data);
  return moment().diff(birthday, 'years');
}

export function differenceByHours(date) {
  const now = moment(date); // todays date
  const end = moment(new Date()); // another date
  const duration = moment.duration(now.diff(end));

  return duration.asHours();
}

export function processColor(color, result) {
  let iconColor;

  if (color === 'green' && result === 'Positive') {
    iconColor = 'greenPositive';
  } else if (color === 'green') {
    iconColor = 'greenNegative';
  } else if (color === 'red' && result === 'Negative') {
    iconColor = 'redNegative';
  } else if (color === 'red') {
    iconColor = 'redPositive';
  } else {
    iconColor = 'pending';
  }

  return iconColor;
}

export function generateId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

export function calculateDay(data) {
  const day = moment(data);
  return moment(day).diff(moment(), 'days');
}

export function processAddress(data, address) {
  const arr = data?.map((item) => {
    const { id } = item.relationships['medtest-location'].data;
    const addressIndex = address.findIndex((item) => item.id === id);
    const newItem = {
      ...item,
      attributes: {
        ...item.attributes,
        address: `${item.attributes['medtest-location-name']}, ${address[addressIndex].attributes['address-line-1']}`,
      },
    };
    return newItem;
  });

  return arr;
}

export function linkify(text) {
  let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, (url) => `[${url}](${url})`);
}
