import { API } from '../const';

export function registerMedtestKit(data, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}medtest-kits`;
      const body = await fetch(api, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const json = await body.json();

      if (json.errors) {
        rej();
      }
      res(json);
    } catch (error) {
      console.log('error update 1', error);
      rej([]);
    }
  });
}

export function resultsMedTest(key) {
  return new Promise(async (res, rej) => {
    try {
      const apiMedtests = `${API}medtests?&page[size]=100`;
      const body = await fetch(apiMedtests, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/json',
        },
      });
      const json = await body.json();

      if (!json.errors) {
        res(json.data);
      }
      res([]);
    } catch (error) {
      console.log('error fetch medtests', error);
    }
  });
}
