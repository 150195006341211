import { API } from '../const';

export function getUserData(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?include=insurance-policies,user-profile,family-members-insurance-policies`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetch medtests', error);
    }
  });
}

export function getUserDataProfile(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?include=user-profile,insurance-policies,family-members-insurance-policies`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors) {
        if (json.included) {
          res(json.included);
        }
      }
      res([]);
    } catch (error) {
      console.log('error fetch medtests', error);
      res([]);
    }
  });
}

export function getPaymentMethod(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?include=medtest-kits`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetch medtests kits', error);
    }
  });
}

export function getUserTestKits(id, key, include) {
  return new Promise(async (res, rej) => {
    try {
      // const api = 'https://api.auratracker.org/v1-prod/users/1381?include=medtest-kits';
      // const api = API + 'medtest-kits/?filter[patient-id]=' + id;
      // const api = API + 'users/' + id + '?include=medtest-kits'
      const api = `${API}users/${id}?fields[users]=nav-test-kit&include=${
        include || 'medtest-kits'
      }`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetch medtests kits', error);
    }
  });
}

export function createUserTestKits(userid, key) {
  return new Promise(async (res, rej) => {
    try {
      let data = {
        data: {
          type: 'medtest-kits',
          attributes: {
            'patient-id': userid,
          },
        },
      };
      const api = `${API}medtest-kits`;
      const body = await fetch(api, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const json = await body.json();
      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetch medtests kits', error);
    }
  });
}

export function getUserFamilyMembers(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?fields[users]=uuid,first-name,last-name,email,hipaa-release-details,date-of-birth&include=family-members`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetch family members', error);
    }
  });
}

export function updateUserData(id, data, key) {
  return new Promise(async (res, rej) => {
    try {
      const apiMedtests = `${API}users/${id}`;
      const body = await fetch(apiMedtests, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const json = await body.json();

      if (!json.errors) {
        res(json.data);
      }
      res({});
    } catch (error) {
      console.log('error update 1', error);
    }
  });
}

export function userSymptoms({ endpoint, data, methodType, key, familyMemberId }) {
  return new Promise(async (res, rej) => {
    try {
      let apiMedtests;
      if (endpoint) {
        apiMedtests = `${API}user-symptoms/${endpoint}`;
      } else if (familyMemberId) {
        apiMedtests = `${API}user-symptoms/${familyMemberId}`;
      } else {
        apiMedtests = `${API}user-symptoms/`;
      }
      const body = await fetch(apiMedtests, {
        method: methodType,
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const json = await body.json();

      if (!json.errors) {
        if (familyMemberId) {
          res(json?.todays_symptoms);
        } else {
          res(json.data);
        }
      }
      res({});
    } catch (error) {
      console.log('error update 4', error);
      if (familyMemberId) {
        res([]);
      } else {
        res({});
      }
    }
  });
}

export function getUserFamilyMembersFullData(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?include=user-profile,family-members`;
      // const api = API + "users/" + id + "?include=user-profile";
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetch family members', error);
    }
  });
}

export function getAccountUserFullData(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?include=user-profile`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetching data', error);
    }
  });
}

export function getUserandFamilyMembersProfile(id, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}users/${id}?include=user-profile,family-members`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();

      if (!json.errors && Object.keys(json).length > 0) {
        res(json);
      } else {
        rej({ error: 'token expire' });
      }
    } catch (error) {
      console.log('error fetching user and family data', error);
    }
  });
}
