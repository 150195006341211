import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Divider,
  Button as MaterialButton,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogTitle,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Layout, ListForm, Alert, DashedButton, Modal, DataList } from '../../components';
import css from './ScreeningQuestions.module.css';
import { processAPI } from '../../api/models/process';
import { getUserDataProfile, getUserData } from '../../api/models/user';
import { setUser, setUserProfile, deleteAppointments } from '../../store/action/user';
import { useTranslation } from 'react-i18next';

const ScreeningQuestions = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const { id } = props.user;
  const data = props.userInsurance?.attributes || {};
  const { setUserDispatch, setUserProfileDispatch, deleteAppointmentsDispatch, appointments } =
    props;

  const [screeningQuestions, setScreeningQuestions] = useState({});
  const [isSick, setIsSick] = useState(null);
  const [receivedVaccine, setReceivedVaccine] = useState(null);
  const [whichVaccine, setWhichVaccine] = useState(null);
  const [vaccineAllergies, setVaccineAllergies] = useState(null);
  const [otherVaccineAllergies, setOtherVaccineAllergies] = useState(null);
  const [anyAllergies, setAnyAllergies] = useState(null);
  const [recentVaccine, setRecentVaccine] = useState(null);
  const [covidPositive, setCovidPositive] = useState(null);
  const [antibodyTreatment, setAntibodyTreatment] = useState(null);
  const [weakenedImmuneSystem, setWeakenedImmuneSystem] = useState(null);
  const [bloodDisorder, setBloodDisorder] = useState(null);
  const [isPregnant, setIsPregnant] = useState(null);

  const [submitTouched, setSubmitTouched] = useState(false);
  const [showWhichVaccineReceived, setShowWhichVaccineReceived] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState(true);
  const [isOpenQuestions, setOpenQuestions] = useState(true);
  const [successConfirmationModal, setSuccessConfirmationModal] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState({});
  const [riskInfo, setRiskInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const { jwt } = props.userAuthentication;

  const riskMappings = {
    Green: 'May Proceed With Vacination',
    Yellow: 'Precaution To Vacination',
    Red: 'Contradiction To Vacination',
  };

  useEffect(() => {
    getScreeningQuestions();
  }, []);

  async function deleteAppointments(appointmentId) {
    const { jwt: key } = props.userAuthentication;
    const type = 'medtest-location-appointments';
    const endpoint = `${type}/${appointmentId}`;
    const data = {
      data: {
        type,
        appointmentId,
      },
    };

    try {
      await processAPI({ data, key, endpoint, methodType: 'DELETE' });
    } catch (error) {}

    if (appointments && appointments?.length > 0) {
      const index = appointments.findIndex((item) => item.id === appointmentId?.toString());
      if (index) {
        deleteAppointmentsDispatch(index);
      }
    }
  }

  async function getScreeningQuestions() {
    setLoading(true);
    const data = await processAPI({
      key: jwt,
      methodType: 'GET',
      endpoint: 'screening-questions',
    });

    setScreeningQuestions(data?.screening_questions);
    setAppointmentInfo(data?.appointment_info);
    setLoading(false);
  }

  const allRequired =
    isSick &&
    receivedVaccine &&
    vaccineAllergies &&
    otherVaccineAllergies &&
    anyAllergies &&
    recentVaccine &&
    covidPositive &&
    antibodyTreatment &&
    weakenedImmuneSystem &&
    bloodDisorder &&
    isPregnant;

  const optionalRequired =
    (receivedVaccine === 'true' && whichVaccine) || receivedVaccine === 'false';

  async function submit(event) {
    event.preventDefault();
    setSubmitTouched(true);
    if (!allRequired || !optionalRequired) {
      setShowAlert({ show: true, message: 'Please Enter All Required Fields', type: 'error' });
      return false;
    }

    setLoading(true);
    try {
      const postBody = {
        questionResponses: [
          { response: isSick, id: screeningQuestions?.is_sick?.id },
          { response: receivedVaccine, id: screeningQuestions?.received_vaccine?.id },
          { response: whichVaccine, id: screeningQuestions?.which_vaccine?.id },
          { response: vaccineAllergies, id: screeningQuestions?.vaccine_allergies?.id },
          { response: otherVaccineAllergies, id: screeningQuestions?.other_vaccine_allergies?.id },
          { response: anyAllergies, id: screeningQuestions?.any_allergies?.id },
          { response: recentVaccine, id: screeningQuestions?.recent_vaccine?.id },
          { response: covidPositive, id: screeningQuestions?.covid_positive?.id },
          { response: antibodyTreatment, id: screeningQuestions?.antibody_treatment?.id },
          { response: weakenedImmuneSystem, id: screeningQuestions?.weakened_immune_system?.id },
          { response: bloodDisorder, id: screeningQuestions?.blood_disorder?.id },
          { response: isPregnant, id: screeningQuestions?.is_pregnant?.id },
        ],
      };

      const data = await processAPI({
        key: jwt,
        methodType: 'POST',
        data: postBody,
        endpoint: 'screening-question-response',
      });

      if (data?.status) {
        setRiskInfo(data);

        const profile = await getUserData(id, jwt);
        const userProfile = await getUserDataProfile(id, jwt);
        setUserProfileDispatch(userProfile);
        setUserDispatch(profile?.data);

        if (data?.status === 'Red') {
          await deleteAppointments(data?.appointment_id);
        }
        setOpenQuestions(false);
        setInitialPrompt(false);
        setSuccessConfirmationModal(true);
      } else {
        setShowAlert({ show: true, message: data?.errors || 'Failed', type: 'error' });
      }
    } catch (error) {
      setShowAlert({ show: true, message: error || 'Failed', type: 'error' });
    }
    setLoading(false);
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  function receivedVaccineChange(value) {
    setReceivedVaccine(value);
    if (value === 'true') {
      setShowWhichVaccineReceived(true);
    } else if (value === 'false') {
      setWhichVaccine('');
      setShowWhichVaccineReceived(false);
    }
  }

  const statusColor = {
    Green: 'greenText',
    Yellow: 'yellowText',
    Red: 'redText',
  };

  return (
    <Layout
      tab={activeTab}
      headerTitle={t('riskAssessment')}
      onClickBack={() => history.goBack()}
      back
    >
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.subContainer}>
            {initialPrompt && (
              <div className={css.appointmentConfirmation}>
                <h4 className="medium-font">Appointment Location:</h4>
                <h4 className="medium-sub-font mb-4">{appointmentInfo?.appointment_location}</h4>
                <h4 className="medium-font">Appointment Time:</h4>
                <h4 className="medium-sub-font mb-4">{appointmentInfo?.appointment_time}</h4>
                <p>
                  Please complete this questionnaire to allow our onsite team to be aware of any
                  conditions that may require special attention.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>Please note:</span> Vaccinations will not be
                  administered to any patient currently not feeling well or those with a positive
                  COVID-19 test result in the last 14 days. In these cases, please cancel your
                  appointment and reschedule for a later date.
                </p>
              </div>
            )}
            {isOpenQuestions && (
              <form onSubmit={submit}>
                <div>
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={isSick === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.is_sick?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="isSick"
                        name="isSick1"
                        value={isSick}
                        onChange={(e) => setIsSick(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {isSick === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={receivedVaccine === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.received_vaccine?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="recievedVaccine"
                        name="recievedVaccine1"
                        value={receivedVaccine}
                        onChange={(e) => receivedVaccineChange(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {receivedVaccine === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  {showWhichVaccineReceived && (
                    <>
                      <div className={css.listSimpleContainer}>
                        <FormControl
                          required
                          component="fieldset"
                          error={receivedVaccine !== null && whichVaccine === null && submitTouched}
                        >
                          <FormLabel component="legend">
                            {screeningQuestions?.which_vaccine?.prompt}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="whichVaccine"
                            name="whichVaccine1"
                            value={whichVaccine}
                            onChange={(e) => setWhichVaccine(e.target.value)}
                          >
                            <FormControlLabel
                              value={screeningQuestions?.which_vaccine?.possible_answers[0]}
                              control={<Radio />}
                              label={screeningQuestions?.which_vaccine?.possible_answers[0]}
                            />
                            <FormControlLabel
                              value={screeningQuestions?.which_vaccine?.possible_answers[1]}
                              control={<Radio />}
                              label={screeningQuestions?.which_vaccine?.possible_answers[1]}
                            />
                            <FormControlLabel
                              value={screeningQuestions?.which_vaccine?.possible_answers[2]}
                              control={<Radio />}
                              label={screeningQuestions?.which_vaccine?.possible_answers[2]}
                            />
                          </RadioGroup>
                          <FormHelperText className={css.helper}>
                            {receivedVaccine !== null &&
                              whichVaccine === null &&
                              submitTouched &&
                              'Required'}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <Divider className={css.questionDivider} />
                    </>
                  )}
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={vaccineAllergies === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.vaccine_allergies?.prompt}
                      </FormLabel>
                      <ul className={css.anyVaccineAllergies}>
                        <li>{screeningQuestions?.vaccine_allergies?.possible_answers[0]}</li>
                        <li>{screeningQuestions?.vaccine_allergies?.possible_answers[1]}</li>
                        <li>{screeningQuestions?.vaccine_allergies?.possible_answers[2]}</li>
                      </ul>
                      <RadioGroup
                        row
                        aria-label="vaccineAllergies"
                        name="vaccineAllergies1"
                        value={vaccineAllergies}
                        onChange={(e) => setVaccineAllergies(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {vaccineAllergies === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={otherVaccineAllergies === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.other_vaccine_allergies?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="otherVaccineAllergies"
                        name="otherVaccineAllergies1"
                        value={otherVaccineAllergies}
                        onChange={(e) => setOtherVaccineAllergies(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {otherVaccineAllergies === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={anyAllergies === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.any_allergies?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="anyAllergies"
                        name="anyAllergies1"
                        value={anyAllergies}
                        onChange={(e) => setAnyAllergies(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {anyAllergies === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={recentVaccine === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.recent_vaccine?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="recentVaccine"
                        name="recentVaccine1"
                        value={recentVaccine}
                        onChange={(e) => setRecentVaccine(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {recentVaccine === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={covidPositive === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.covid_positive?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="covidPositive"
                        name="covidPositive1"
                        value={covidPositive}
                        onChange={(e) => setCovidPositive(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {covidPositive === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={antibodyTreatment === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.antibody_treatment?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="antibodyTreatment"
                        name="antibodyTreatment1"
                        value={antibodyTreatment}
                        onChange={(e) => setAntibodyTreatment(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {antibodyTreatment === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={weakenedImmuneSystem === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.weakened_immune_system?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="weakenedImmuneSystem"
                        name="weakenedImmuneSystem1"
                        value={weakenedImmuneSystem}
                        onChange={(e) => setWeakenedImmuneSystem(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {weakenedImmuneSystem === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={bloodDisorder === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.blood_disorder?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="bloodDisorder"
                        name="bloodDisorder1"
                        value={bloodDisorder}
                        onChange={(e) => setBloodDisorder(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {bloodDisorder === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <div className={css.listSimpleContainer}>
                    <FormControl
                      required
                      component="fieldset"
                      error={isPregnant === null && submitTouched}
                    >
                      <FormLabel component="legend">
                        {screeningQuestions?.is_pregnant?.prompt}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="isPregnant"
                        name="isPregnant1"
                        value={isPregnant}
                        onChange={(e) => setIsPregnant(e.target.value)}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={css.helper}>
                        {isPregnant === null && submitTouched && 'Required'}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider className={css.questionDivider} />
                  <Button type="submit" disabled={loading} className="mb-4">
                    {loading ? <Spinner animation="border" variant="secondary" /> : t('submit')}
                  </Button>
                </div>
              </form>
            )}
            {successConfirmationModal && (
              <div>
                <div className={css.riskInfoGraphic}>
                  <Card className={css.greenCard} variant="outlined">
                    <CardContent>
                      <Typography className={css.cardTitle}>
                        MAY PROCEED WITH VACCINATION
                      </Typography>
                      <Typography variant="body2" component="p">
                        1. 30-minute observation period: Persons with a history of anaphylaxis (due
                        to any cause)
                      </Typography>
                      <Typography variant="body2" component="p">
                        2. 15-minute observation period: All other persons
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card className={css.yellowCard} variant="outlined">
                    <CardContent>
                      <Typography className={css.cardTitle}>PRECAUTION TO VACCINATION</Typography>
                      <Typography variant="body2" component="p">
                        1. Consider deferral of vaccination and/or referral to
                        allergist-immunologist
                      </Typography>
                      <Typography variant="body2" component="p">
                        2. 30-minute observation period if vaccinated
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card className={css.redCard} variant="outlined">
                    <CardContent>
                      <Typography className={css.cardTitle}>
                        CONTRADICTION TO VACCINATION
                      </Typography>
                      <Typography variant="body2" component="p">
                        1. Do not vaccinate
                      </Typography>
                      <Typography variant="body2" component="p">
                        2. Consider referral to allergist-immunologist
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className={css.riskConfirmation}>
                  {riskInfo?.status !== 'Red' && (
                    <>
                      <MaterialButton
                        className={css.checkInButton}
                        onClick={() => history.push('/vaccinescan')}
                        variant="outlined"
                      >
                        Check-in
                      </MaterialButton>
                      <DataList
                        title="Appointment Location"
                        description={appointmentInfo?.appointment_location}
                      />
                      <DataList
                        title="Appointment Time"
                        description={appointmentInfo?.appointment_time}
                      />
                      <DataList title="Risk Status" description={riskMappings[riskInfo?.status]} />
                      <DataList title="Risk Wait Time" description={riskInfo?.wait_time} />
                    </>
                  )}
                  {riskInfo?.status === 'Red' && (
                    <div>
                      <p className="medium-font mb-4 mt-4">
                        Based upon your responses to the vaccine risk assessment, we advise you not
                        to get vaccinated at this time. As a result we have cancelled your
                        appointment. Please contact
                        <a
                          href="https://auratracker.zendesk.com/hc/en-us/requests/new"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          customer service
                        </a>{' '}
                        if you have any questions.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  userInsurance: state.user.insurance[0],
  userAuthentication: state.authentication,
  appointments: state.user.appointments,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserProfileDispatch(payload) {
    dispatch(setUserProfile(payload));
  },
  deleteAppointmentsDispatch(payload) {
    dispatch(deleteAppointments(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(ScreeningQuestions);
