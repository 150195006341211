import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ChevronDown from '../../assets/svg/chevron_down';
import Assist from '../../assets/svg/assist.svg';

const useStyles = makeStyles((theme) => ({
  panel: {
    marginBottom: theme.spacing(1),
  },
  summary: {
    background: '#F5F5F5',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
  },
  title: {
    fontSize: 20,
    fontFamily: 'SF Pro Display',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontSize: 16,
    fontFamily: 'SF Pro Display',
    fontWeight: 600,
  },
  description: {
    fontSize: 15,
    fontFamily: 'SF Pro Display',
  },
  step: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    borderBottom: '1px solid #BFBFBF',
    color: '#254659',
  },
}));

const AssistedSampleCollection = ({ expanded, handleChange }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange} className={classes.panel}>
      <ExpansionPanelSummary
        expandIcon={<ChevronDown color="rgba(255, 153, 0, 0.8)" />}
        className={classes.summary}
      >
        <div className={classes.headerContainer}>
          <img src={Assist} alt="youtube" />
          &nbsp;
          <Typography className={classes.header}>Assisted Sample Collection</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <Box className={classes.step} style={{ background: '#EDF5FA' }}>
          <Typography className={classes.title}>Tips & Techniques</Typography>
          <Typography className={classes.subTitle} style={{ marginBottom: 16 }}>
            Before you begin collecting, please read the below instructions carefully.
          </Typography>
          <Typography className={classes.description} style={{ fontWeight: 600 }}>
            If you are assisting another individual with sample collection, always be sure to
            collect your sample first.
          </Typography>
        </Box>
        <Box className={classes.step}>
          <Typography className={classes.title}>Communicate</Typography>
          <Typography className={classes.description} style={{ marginBottom: 10 }}>
            Ensure you remain calm and confident. Position the individual who is being tested
            comfortably. If possible, ask for assistance from another adult to comfort and/or
            distract during collection.
          </Typography>
          <Typography className={classes.description}>
            If you are getting assitance, determine beforehand who will collect the sample and who
            will offer support.
          </Typography>
        </Box>
        <Box className={classes.step}>
          <Typography className={classes.title}>Get ready</Typography>
          <Typography className={classes.description} style={{ marginBottom: 10 }}>
            If the individual is old enough, take time to discuss and walk through the collection
            process prior to beginning.
          </Typography>
          <Typography className={classes.description}>
            Talk through the different steps and make this a fun activity or, if needed, offer a
            distraction.
          </Typography>
        </Box>
        <Box className={classes.step}>
          <Typography className={classes.title}>Follow instructions</Typography>
          <Typography className={classes.description} style={{ marginBottom: 10 }}>
            Begin with step 1 of the COVID-19 PCR test instructions.
          </Typography>
          <Typography className={classes.subTitle}>
            During collection, if the individual has any pain or asks to stop, please do not
            continue with collection.
          </Typography>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AssistedSampleCollection;
