import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import IllustrationSuccess from '../../../assets/image/illustration_success.png';
import './index.css';

const InsuranceCompleted = (props) => {
  const history = useHistory();

  setTimeout(() => {
    history.push(`/vaccine-information/${props.user.id}/newUser`);
  }, 2000);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-center my-5 py-sm-5">
        <img src={IllustrationSuccess} alt="IllustrationSuccess" />
        <div className="InsuranceCompleted my-4">Insurance Completed!</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state?.user?.userProfile?.[0],
  userInsurance: state?.user?.insurance?.[0],
  userAuthentication: state.authentication,
});

export default connect(mapStateToProps)(InsuranceCompleted);
