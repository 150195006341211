import { SET_VAX_APPOINTMENTS, SET_VAX_HISTORY } from '../types';

export function setVaxAppointments(payload) {
  return {
    type: SET_VAX_APPOINTMENTS,
    payload,
  };
}

export function setVaxHistory(payload) {
  return {
    type: SET_VAX_HISTORY,
    payload,
  };
}
