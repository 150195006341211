import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import MaskedInput from 'react-text-mask';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Logo from '../../assets/image/GenetworxLogo.jpg';

import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import './Register.css';
import { setUser, setUserInsurance } from '../../store/action/user';
import { authentication } from '../../store/action/auth';
import { signUp, signIn } from '../../api/auth';
import { ListForm } from '../../components';
import { validateUserInformation } from '../../api/validation';
import { storeData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getCompanyNameSignUp } from '../../api/models/process';
import { getUserData } from '../../api/models/user';
import SelectInput from '../../components/SelectInput/SelectInput';

import MetaTags from 'react-meta-tags';

import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskDOB(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function RegisterPage(props) {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();

  const [phone, setPhoneNumber] = useState('');
  const [birthday, setBirthday] = useState();
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState();
  const [ethnic, setEthnic] = useState();
  const [race, setRace] = useState();
  const [mothersMaidenName, setMothersMaidenName] = useState();
  const [companyName, setcompanyName] = useState('');
  const { cid } = useParams();
  const location = useLocation();
  const { search } = useLocation();

  const [userType, setUserType] = useState();
  const [policyId, setPolicyId] = useState();
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;

  const history = useHistory();
  const { setUserDispatch, setUserInsuranceDispatch, authenticationDispatch } = props;

  async function onSubmit(e) {
    e.preventDefault();

    const { invalid, message } = validateUserInformation({
      firstName,
      middleName,
      lastName,
      email,
      emailConfirmation,
      phone,
      birthday,
      password,
      address,
      zip,
      gender,
      race,
      ethnic,
      city,
      state,
      mothersMaidenName,
    });
    setValidation({ invalid, message });

    if (!invalid) {
      try {
        setLoading(true);
        const managed = !!(phone && !email);
        const data = {
          email,
          password,
          'first-name': firstName,
          'middle-name': middleName,
          'last-name': lastName,
          'address-line-1': address,
          state,
          city,
          zip,
          'phone-number': phone,
          'date-of-birth': moment(birthday, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          'password-confirmation': password,
          gender,
          ethnicity: ethnic,
          race,
          'company-id': cid || '',
          'managed-account': managed,
          'user-type-id': userType,
          'company-policy-id': policyId,
          'mothers-maiden': mothersMaidenName,
        };

        const response = await signUp(data);

        if (response.errors) {
          const detail = response.errors[0];
          setValidation({ invalid: true, message: detail });
        } else {
          login();
        }
      } catch (error) {
        console.log('error', error);
      }
      setLoading(false);
    }
  }

  async function login() {
    try {
      setLoading(true);
      const response = await signIn(email, phone, password);
      setLoading(false);

      if (response.jwt) {
        storeData(USER_CACHE_NAME, { ...response, status: 'login' });
        const profile = await getUserData(response.user_id, response.jwt);
        setUserDispatch(profile.data);
        setUserInsuranceDispatch(profile.included || []);
        authenticationDispatch({
          ...response,
          status: 'login',
          loading: false,
        });
        history.push('/profile');
      } else {
        const { message } = response;

        if (message) {
          setValidation({ invalid: true, message });
        } else {
          setValidation({ invalid: true, message: 'error' });
        }
      }
    } catch (error) {
      setLoading(false);
      setValidation({ invalid: true, message: 'something went wrong' });
    }
  }

  useEffect(() => {
    getCompanyName();
  }, []);

  useEffect(() => {
    setPolicyId(new URLSearchParams(location.search).get('policy_id'));
    setUserType(new URLSearchParams(location.search).get('user_type'));
  }, [location]);

  async function getCompanyName() {
    if (cid) {
      setLoading(true);
      const companyName = await getCompanyNameSignUp(cid);

      setcompanyName(companyName.company_name);
      setLoading(false);
    }
  }

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <Container fluid className="splash-container">
        <Row>
          <Col className="welcome-text">WELCOME TO</Col>
        </Row>
        <Row className="logo pt-5 pb-5">
          <Col>
            <Image src={Logo} style={{ width: 300 }} />
          </Col>
        </Row>
        <Row>
          <Col className="subtitle-1">Good Health Starts Here</Col>
        </Row>
        <Row>
          <Col className="subtitle-2 pt-3 pb-5">Early Detection is at your fingertips.</Col>
        </Row>

        <Row className="button-container pt-5">
          <Col>
            <Button variant="activate">
              <Link to="/register/unauth" className="clean-link">
                <span className="btn-activate-text">Activate Your Test</span>
              </Link>
            </Button>
          </Col>
        </Row>

        <Row className="button-container pt-2">
          <Col>
            <Button variant="signin">
              <Link to="/login" className="clean-link">
                <span className="btn-signin-text">Sign In</span>
              </Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateTopProps = (state) => ({
  authentication: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(RegisterPage);
