import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { signIn, gsignIn } from '../../api/auth';
import { storeData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getUserData, getPaymentMethod } from '../../api/models/user';
import { setUser, setUserInsurance, setUserPaymentMethod } from '../../store/action/user';
import { authentication } from '../../store/action/auth';
import { TextField, LogoImageUrl } from '../../components';
import Appstore from '../../assets/svg/Appstore.svg';
import Playstore from '../../assets/svg/Playstore.svg';

import hiddenIcon from '../../assets/svg/hidden.svg';
import eyeIcon from '../../assets/svg/eye.svg';
import './Login.css';

import { Form, Spinner } from 'react-bootstrap';
import { GoogleLogin } from 'react-google-login';
import { useTranslation } from 'react-i18next';

import loginImage from '../../assets/image/loginImage.png';
import loginImage2 from '../../assets/image/loginImage2.png';
import phoneIcon from '../../assets/image/phone.png';
import emailIcon from '../../assets/image/email2.png';
// import loginImage from "../../assets/image/";
import { isUsability } from '../../api/const';

function LoginPageIndex(props) {
  console.log('roman test');
  const {
    authenticationDispatch,
    setUserDispatch,
    setUserInsuranceDispatch,
    setUserPaymentMethodDispatch,
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [tabKey, setKey] = useState('email');

  const [validation, setValidation] = useState({ invalid: false, message: '' });
  const [hidden, setHidden] = useState(true);

  const { invalid, message: errorMessage } = validation;

  const history = useHistory();

  const urlPath = window.location.pathname;

  async function onSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await signIn(email, phone, password);
      setLoading(false);

      if (response.jwt) {
        storeData(USER_CACHE_NAME, { ...response, status: 'login' });
        const profile = await getUserData(response.user_id, response.jwt);
        const paymentMethod = await getPaymentMethod(response.user_id, response.jwt);
        setUserDispatch(profile.data);
        setUserInsuranceDispatch(profile.included || []);
        setUserPaymentMethodDispatch(paymentMethod.included || []);
        authenticationDispatch({
          ...response,
          status: 'login',
          loading: false,
        });

        nextPage();
      } else {
        const { message } = response;

        if (message) {
          setValidation({ invalid: true, message });
        } else {
          setValidation({ invalid: true, message: 'error' });
        }
      }
    } catch (error) {
      setLoading(false);
      setValidation({ invalid: true, message: 'something went wrong' });
    }
  }

  async function glogin(gdata) {
    try {
      setLoading2(true);
      const response = await gsignIn(gdata);
      setLoading2(false);

      if (response.jwt) {
        storeData(USER_CACHE_NAME, { ...response, status: 'login' });
        const profile = await getUserData(response.user_id, response.jwt);
        const paymentMethod = await getPaymentMethod(response.user_id, response.jwt);
        setUserDispatch(profile.data);
        setUserInsuranceDispatch(profile.included || []);
        setUserPaymentMethodDispatch(paymentMethod.included || []);
        authenticationDispatch({
          ...response,
          status: 'login',
          loading: false,
        });

        nextPage();
      } else {
        const { message } = response;

        if (message) {
          setValidation({ invalid: true, message });
        } else {
          setValidation({ invalid: true, message: 'error' });
        }
      }
    } catch (error) {
      setLoading2(false);
      setValidation({ invalid: true, message: 'something went wrong' });
    }
  }

  async function handleLoginFailure(data) {
    let msg = (data && data.details) || 'An error occurred';
    alert(msg);
  }

  function nextPage() {
    if (urlPath === '/activation-login' || isUsability) {
      history.push('/select-family-member-activation');
    } else {
      history.push('/profile');
    }
  }

  return (
    <>
      <div className="">
        <div className="row mx-1 " style={{ height: 'screen' }}>
          <div className="col-12  col-sm-6 col-md-7 col-lg-6">
            <div className="login_form">
              <div>
                <div className="mt-5 pt-3">
                  <div className="login_header ml-3 mb-2">{t('welcome')}</div>
                  <img alt="Logo" src={LogoImageUrl()} width="180" height="64" className="mb-3" />
                </div>

                <div className="mt-2 login_form_welcome_text">{t('signin')}</div>
                <div className="mt-2 login_form_welcome_text_des">{t('signinDes')}</div>

                <div>
                  <Form onSubmit={onSubmit}>
                    {tabKey === 'phone' ? (
                      <TextField
                        required
                        id="phone"
                        label={t('phone')}
                        type="tel"
                        placeholder="just the number no () or -"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        data-cy="phone"
                        autoCapitalize="none"
                        data-testid="PHONE"
                      />
                    ) : (
                      <TextField
                        required
                        id="email"
                        label={t('email')}
                        type="email"
                        placeholder="e.g. youremail@gmail.com"
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        value={email}
                        data-cy="email"
                        autoCapitalize="none"
                        data-testid="EMAIL1"
                      />
                    )}

                    <TextField
                      required
                      id="filled-required"
                      label={t('password')}
                      type={hidden ? 'password' : 'text'}
                      placeholder="enter your password"
                      name="password"
                      data-cy="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      className="pb-3 w-100"
                      data-testid="PASS"
                    />
                    <Form.Group
                      controlId="formBasicCheckbox"
                      className="d-flex justify-content-between align-items-center pb-3"
                    >
                      <p
                        className="medium-sub-font-blue"
                        onClick={() => history.push('/forgot-password')}
                      >
                        {t('forgotPassword')}
                      </p>
                      <img
                        alt=""
                        src={hidden ? hiddenIcon : eyeIcon}
                        width="20"
                        height="20"
                        className=""
                        onClick={() => setHidden((prev) => !prev)}
                      />
                    </Form.Group>

                    {invalid && (
                      <div className="my-3">
                        <Alert severity="error">{errorMessage}</Alert>
                      </div>
                    )}

                    <div className=" pb-3">
                      <button
                        disabled={loading}
                        type="submit"
                        className="btn-signin_"
                        data-testid="LOGIN"
                      >
                        {loading ? (
                          <Spinner animation="border" variant="secondary" />
                        ) : (
                          t('signinBtn')
                        )}
                      </button>
                    </div>

                    <div
                      className="d-flex align-items-center my-4 text-center"
                      style={{ alignContent: 'space-between' }}
                    >
                      <div className="border  border-bottom divider" />
                      <div style={{ width: '6%' }}>OR</div>
                      <div className="border  border-bottom divider" />
                    </div>

                    <div className=" my-3">
                      <GoogleLogin
                        className="googlebutton"
                        clientId="814079886124-0seg2j8e74gs86s0866g3dln5nh6en7m.apps.googleusercontent.com"
                        onSuccess={glogin}
                        onFailure={handleLoginFailure}
                        cookiePolicy="single_host_origin"
                        responseType="code,token"
                      >
                        {loading2 ? (
                          <Spinner animation="" variant="warning" />
                        ) : (
                          <span className="__text mt-2">{t('signinGoogle')}</span>
                        )}
                      </GoogleLogin>
                    </div>

                    <div
                      className="googlebutton my-3"
                      onClick={() => {
                        if (tabKey === 'email') {
                          setEmail('');
                          setKey('phone');
                        } else {
                          setPhone('');
                          setKey('email');
                        }
                      }}
                    >
                      {tabKey === 'email' ? (
                        <>
                          <img src={phoneIcon} alt="phoneIcon" />
                          <span className="ml-2">{t('signinPhone')}</span>
                        </>
                      ) : (
                        <>
                          <img src={emailIcon} alt="emailIcon" width={30} height="30" />
                          <span className="ml-2">{t('signinEmail')}</span>
                        </>
                      )}
                    </div>

                    <div className="account_ d-flex justify-content-center align-items-center text-center my-4">
                      {t('dont_have_account')}
                    </div>

                    <div className=" d-flex justify-content-center align-items-center text-center my-4">
                      <div
                        className="register_btn"
                        onClick={() => {
                          if (urlPath === '/activation-login') {
                            history.push('/activation-signup');
                          } else {
                            history.push('/signup');
                          }
                        }}
                      >
                        Create an Account
                      </div>
                    </div>

                    <Form.Group
                      controlId="formBasicCheckbox"
                      className="d-flex justify-content-around justify-content-sm-center text-center align-items-center pb-3"
                    >
                      <a
                        className="medium-sub-font-blue pr-1 pr-sm-3"
                        href="https://auratracker.org/privacy_policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="a__text">{t('privacyPolicy')}</span>
                      </a>
                      |
                      <a
                        className="medium-sub-font-blue pl-1 pl-sm-3"
                        href="https://auratracker.zendesk.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="a__text">{t('technicalSupport')}</span>
                      </a>
                    </Form.Group>
                  </Form>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-5 col-lg-6 p-0 m-0 ">
            <div className="d-none d-sm-flex justify-content-end position-relative">
              <div className="position-absolute">
                <img className="" src={loginImage} height="1000vh" width="100%" alt="logo" />
              </div>

              <div className="position-absolute landing_note_container p-2">
                <div>
                  <div className="landing_note_header">Take Aura on the Go with our Mobile App</div>

                  <div className="landing_note_description justify-content-center text-center">
                    {isUsability ? (
                      <>
                        <div>Need instant access to download your results? </div>

                        <div>Download the Aura app today! </div>
                      </>
                    ) : (
                      <>
                        <div>Belong to an organization using Aura?</div>

                        <div>Download the Aura application today for the best experience. </div>

                        <div>Proudly being used by over 600K+ users. </div>
                      </>
                    )}
                  </div>

                  <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center">
                    <img alt="Appstore" src={Appstore} height="100" width="130" className="mx-2" />
                    <img
                      alt="Playstore"
                      src={Playstore}
                      height="100"
                      width="140"
                      className="mx-2"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* for smaller screen */}
            <div className="d-block d-sm-none w-100 position-relative">
              <div>
                <img className="" src={loginImage2} width="100%" alt="logo" />
              </div>

              <div className="position-absolute landing_note_container p-2">
                <div>
                  <div className="landing_note_header my-4">
                    Take Aura on the Go with our Mobile App
                  </div>

                  <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center">
                    <img alt="Appstore" src={Appstore} width="140" className="mx-2" />
                    <img alt="Playstore" src={Playstore} width="140" className="mx-2 my-2" />
                  </div>

                  <div className="landing_note_description">
                    {isUsability
                      ? 'Need instant access to download your results? Download the Aura app today!'
                      : 'Proudly being used by over 500K+ users.'}{' '}
                  </div>
                </div>
              </div>
            </div>
            {/* for smaller screen */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateTopProps = (state) => ({
  authentication: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
  setUserPaymentMethodDispatch(payload) {
    dispatch(setUserPaymentMethod(payload));
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(LoginPageIndex);
