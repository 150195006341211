import { API } from '../const';

export function getTestLocationAvailability(date, id) {
  return new Promise(async (res, rej) => {
    try {
      const apiLocations = `${API}medtest-locations/${id}/upcoming-availability?date=${date}`;
      const body = await fetch(apiLocations);
      const json = await body.json();

      if (json.errors) {
        rej([]);
      }
      res(json);
    } catch (error) {
      console.log('error ', error);
      rej([]);
    }
  });
}

export function getAvailableDays(date, id, products, counts) {
  return new Promise(async (res, rej) => {
    try {
      const apiLocations = `${API}location-products/available-days?date=${date}&ids=${products}&counts=${counts}&time_span_months=1`;
      const body = await fetch(apiLocations);
      const json = await body.json();

      if (json.errors) {
        rej([]);
      }
      res(json);
    } catch (error) {
      console.log('error ', error);
      rej([]);
    }
  });
}

export function getAvailableTimes(date, id, products, counts) {
  return new Promise(async (res, rej) => {
    try {
      // https://test.auratracker.org/api/v1/location-products/available-times?ids=7&counts=1&date=2022-2-2&formatted=true
      const apiLocations = `${API}location-products/available-times?date=${date}&ids=${products}&counts=${counts}&formatted=true`;
      const body = await fetch(apiLocations);
      const json = await body.json();

      if (json.errors) {
        rej([]);
      }
      res(json);
    } catch (error) {
      console.log('error ', error);
      rej([]);
    }
  });
}
