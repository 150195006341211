import { API } from './const';

export function signIn(email, phone, password) {
  return new Promise(async (res, rej) => {
    const loginAPI = `${API}sessions`;

    try {
      const response = await fetch(loginAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, phone, password }),
      });
      const body = await response.json();
      if (body?.jwt) {
        localStorage.setItem('user_loggedIn', true);
      }
      res(body);
    } catch (error) {
      console.log('error', error);
      rej({});
    }
  });
}

export function signUp(data) {
  return new Promise(async (res, rej) => {
    const signUpAPI = `${API}signups`;

    try {
      const response = await fetch(signUpAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const body = await response.json();
      res(body);
    } catch (error) {
      console.log('error', error);
      rej({});
    }
  });
}

export function gsignIn(data) {
  return new Promise(async (res, rej) => {
    const loginAPI = `${API}sessions?oauth=google`;

    try {
      const response = await fetch(loginAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const body = await response.json();
      res(body);
    } catch (error) {
      console.log('error', error);
      rej({});
    }
  });
}
