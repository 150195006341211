import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

function ModalComponent({
  children,
  className,
  closeButton,
  closeText,
  footerComponent,
  handleClose,
  handleSubmit,
  headerComponent,
  hideSubmit,
  loading,
  show,
  size,
  submitDisable,
  submitText,
  title,
  secondaryVariant,
  primaryVariant,
  propsStyle,
}) {
  const myHeaderComponent = headerComponent || (
    <Modal.Header closeButton={!closeButton}>
      <Modal.Title className="warning-title">{title}</Modal.Title>
    </Modal.Header>
  );

  const myFooterComponent = footerComponent || (
    <div data-testid="CLOSEMODALBTN">
      <Button variant={secondaryVariant || 'secondary'} className="mr-3" onClick={handleClose}>
        {closeText || 'Close'}
      </Button>
      {!hideSubmit && (
        <Button
          data-testid="SAVEMODALBTN"
          variant={primaryVariant || 'primary'}
          onClick={handleSubmit}
          disabled={submitDisable}
        >
          {loading ? (
            <Spinner animation="border" variant="secondary" />
          ) : (
            submitText || 'Save Changes'
          )}
        </Button>
      )}
    </div>
  );
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={className}
      data-testid="TESTMODALCLASS"
      size={size}
      style={propsStyle}
    >
      {myHeaderComponent}

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>{myFooterComponent}</Modal.Footer>
    </Modal>
  );
}

export default ModalComponent;
