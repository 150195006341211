import React, { useState } from 'react';
import { processAPI } from '../../api/models/process';
import ExclaimMark from '../../assets/svg/exclaimation_black.svg';
import { Divider, Checkbox } from '../../components';
import Check from '../../assets/svg/check.svg';
import css from './AssignInsurance.module.css';

const InsurancePolicyList = ({
  item,
  includesItem,
  insuranceId,
  name,
  selectedPlans,
  setSelectedPlans,
  id,
  setSelectedRelationsList,
  selectedRelationsList,
  jwt,
  setShowAlert,
}) => {
  const relationshipValues = [
    { name: 'Self', value: 'SEL' },
    { name: 'Spouse', value: 'SPO' },
    { name: 'Child', value: 'CHD' },
    { name: 'Other', value: 'OTH' },
    { name: 'Unknown', value: 'UNK' },
  ];

  const [selectedRelation, setSelectedRelation] = useState({
    name: '',
    value: '',
    insuranceId: '',
  });
  let filteredRelationsList = [];
  const [editRelation, setEditRelation] = useState(false);

  async function submitInsuranceRelation(insurancePolicyId, relationToPolicyHolder) {
    try {
      const myData = {
        data: {
          type: 'insurance-policy-members',
          attributes: {
            'user-id': parseInt(id),
            'insurance-policy-id': parseInt(insurancePolicyId),
            'relationship-to-policy-holder': relationToPolicyHolder,
          },
        },
      };
      console.log(myData?.data?.attributes?.['relationship-to-policy-holder'], 'mydata');
      const data = await processAPI({
        key: jwt,
        methodType: 'POST',
        // myData?.data?.attributes?.["relationship-to-policy-holder"] === "Self"
        //   ? "PATCH"
        //   : "POST",
        data: myData,
        endpoint: 'insurance-policy-members',
      });

      if (data.id) {
        setShowAlert({
          show: true,
          message: 'Saved successfully',
          type: 'success',
        });
        return true;
      }
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
      return false;
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
      console.log(error);
    }
    return false;
  }

  return (
    <>
      <div className={css.policyItemInner}>
        <div className={css.policyItemLeft}>
          <h4 className={css.carrierName}>
            {item.attributes.carrier || item.attributes['free-form-name']}
          </h4>
          <p className={css.policyHolder}>
            <span>Policy Holder: {item.attributes.name}</span>
            {selectedRelation.name && includesItem && (
              <div className={css.editLableSpan}>
                {window.innerWidth > 768 && (
                  <span style={{ marginLeft: '4px', marginRight: '4px' }}>|</span>
                )}
                <span>
                  {name}'s Relationship to Insured: {selectedRelation.name}
                </span>
                {!editRelation ? (
                  <span className={css.editRelation} onClick={() => setEditRelation(true)}>
                    Edit Relationship
                  </span>
                ) : (
                  <span className={css.editRelation} onClick={() => setEditRelation(false)}>
                    Cancel
                  </span>
                )}
              </div>
            )}
          </p>
        </div>
        <div className={css.relationWarningContainer}>
          {!selectedRelation.name && (
            <div>
              <p className={css.relationWarningText}>
                <img
                  src={ExclaimMark}
                  alt="Exclaim"
                  className="mr-2"
                  style={{ marginBottom: '2px' }}
                />
                Patient Relationship to Policy Holder Required
              </p>
            </div>
          )}
          {!selectedRelation.name ? (
            <Checkbox
              selected={includesItem && selectedRelation.name}
              handleChange={() => {
                !includesItem
                  ? setSelectedPlans([...selectedPlans, item])
                  : setSelectedPlans(
                      selectedPlans.filter((selectedPlan) => selectedPlan.id !== item.id)
                    );
                if (includesItem && selectedRelation.name) {
                  const newRelationsList = selectedRelationsList?.filter(
                    (element) => element.insuranceId !== selectedRelation.insuranceId
                  );
                  setSelectedRelationsList(newRelationsList);
                  setSelectedRelation({
                    name: '',
                    value: '',
                    insuranceId: '',
                  });
                }
              }}
            />
          ) : (
            <Checkbox selected handleChange={() => {}} />
          )}
        </div>
      </div>

      {selectedPlans.length > 0 && includesItem ? (
        <div>
          {(editRelation || !selectedRelation.name) && (
            <div className={css.relationshipText}>Relationship to the Insured*</div>
          )}
          <div className={css.relationshipValuescontainer}>
            {(editRelation || !selectedRelation.name) &&
              relationshipValues.map((relation) => (
                <span
                  key={relation.name}
                  onClick={() => {
                    // if (
                    //   relation?.name === "Self" &&
                    //   (item?.attributes["insureds-relationship-to-patient"] ===
                    //     "SEL" ||
                    //     item?.relationships?.user?.data?.id !== id)
                    // ) {
                    //   return;
                    // }

                    setSelectedRelation({
                      name: relation.name,
                      value: relation.value,
                      insuranceId,
                    });
                    filteredRelationsList = selectedRelationsList.filter(
                      (value) => value.insuranceId !== insuranceId
                    );
                    setSelectedRelationsList([
                      ...filteredRelationsList,
                      {
                        name: relation.name,
                        value: relation.value,
                        insuranceId,
                      },
                    ]);
                    submitInsuranceRelation(insuranceId, relation.name);
                    setEditRelation(!setEditRelation);
                  }}
                  className={
                    // relation?.name === "Self" &&
                    // (item?.attributes["insureds-relationship-to-patient"] ===
                    //   "SEL" ||
                    //   item?.relationships?.user?.data?.id !== id)
                    //   ? css.selfDisabledRelation
                    selectedRelation.name === relation.name &&
                    selectedRelation.insuranceId === insuranceId
                      ? css.selectedRelationshipValue
                      : css.relationshipValues
                  }
                >
                  {selectedRelation.name === relation.name &&
                    selectedRelation.insuranceId === insuranceId && (
                      <img src={Check} alt="Check" className="mr-2" />
                    )}
                  {relation.name}
                </span>
              ))}
          </div>
        </div>
      ) : null}
      <Divider />
    </>
  );
};

export default InsurancePolicyList;
