import React from 'react';
import './index.css';
import IsVaccinated from './IsVaccinated';
import Arrow from '../../../assets/image/Arrow.png';

const Index = ({ onSubmit, children, goBack }) => (
  <>
    <div className=" d-flex justify-content-center my-4 px-3">
      <div className="d-flex justify-content-center align-items-center px-3">
        {goBack && (
          <img
            src={Arrow}
            alt="Arrow"
            style={{ cursor: 'pointer' }}
            className="mr-3"
            onClick={goBack}
          />
        )}

        <span className="IsVaccinated_header">Vaccination Information</span>
      </div>
    </div>

    <div className="border mt-4" />

    {children ? (
      <div className="my-4">{children}</div>
    ) : (
      <div className="mt-2 mt-sm-5 pt-5">
        <IsVaccinated
          onSubmit={(val) => {
            onSubmit(val);
          }}
        />
      </div>
    )}
  </>
);
export default Index;
