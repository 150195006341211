import { SET_ALERT, CLEAR_ALERT } from '../types';

export function setAlert(payload) {
  return {
    type: SET_ALERT,
    payload,
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT,
  };
}
