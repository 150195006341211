import moment from 'moment';

export function validateEmail(mail) {
  const found = mail.match(/\w/g);
  if (found) {
    return !!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(mail);
  }

  return true;
}

export function validatePhoneNumber(number) {
  const found = number.match(/\d/g);
  if (found) {
    const { length } = found;
    return length === 10;
  }

  return true;
}

export function validateCollectionDate(date) {
  let strongRegex =
    /(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]/;
  return strongRegex.test(date);
}

export function validateTrackingNumber(trackingNumber) {
  let strongRegex = /^[0-9]{12}$/;
  return strongRegex.test(trackingNumber.replace(/\s+/g, ''));
}

export function validateSpecimenCode(code) {
  if (/^[Gg][Xx]\d{8}$/.test(code)) return true;
  return false;
}

export function validateZip(number) {
  const found = number.match(/\d/g);
  if (found) {
    const { length } = found;
    return length === 5;
  }

  return false;
}

export function validateName(name) {
  if (/^[a-z-A-Z ]+$/.test(name)) return true;
  return false;
}

export function validateDate(date) {
  return moment(date, 'MM/DD/YYYY', true).isValid();
}

export function validatePassword(password) {
  let strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  return strongRegex.test(password);
}

export function validateKitInformation(data) {
  if (!data.specimenCode) {
    return { invalid: 'specimenCode', message: 'Activation Code is required' };
  }

  if (data.specimenCode && !validateSpecimenCode(data.specimenCode)) {
    return { invalid: 'specimenCode', message: 'Invalid Code, e.g. Gx12345678' };
  }

  if (!data.collectionDate) {
    return { invalid: 'collectionDate', message: 'Collection Date is required' };
  }

  if (
    data.collectionDate &&
    !validateCollectionDate(moment(data.collectionDate).format('MM/DD/YYYY HH:mm'))
  ) {
    return { invalid: 'collectionDate', message: 'Invalid Collection Date' };
  }

  const dateFrom = moment().subtract(3, 'days').format('YYYY/MM/DD HH:mm');
  const dateTo = moment().add(3, 'days').format('YYYY/MM/DD HH:mm');
  const collectionDate = moment(data.collectionDate).format('YYYY/MM/DD HH:mm');

  if (collectionDate < dateFrom || collectionDate > dateTo) {
    return {
      invalid: 'collectionDate',
      message: 'Date cannot be more than 56 hours in the past and 72 hours in the future',
    };
  }

  if (!data.returnTrackingNumber) {
    return { invalid: 'trackingNumber', message: 'Tracking Number is required' };
  }

  if (data.returnTrackingNumber && !validateTrackingNumber(data.returnTrackingNumber)) {
    return { invalid: 'trackingNumber', message: 'Invalid Tracking Number, 12 digits expected' };
  }

  return { invalid: '', message: '' };
}

export function validateUserInformation(data) {
  if (!data.firstName) {
    return { invalid: 'firstName', message: 'First name is required' };
  }

  if (!data.lastName) {
    return { invalid: 'lastName', message: 'Last name is required' };
  }

  if (!data.address) {
    return { invalid: 'address', message: 'Address is required' };
  }

  if (!data.zip) {
    return { invalid: 'zip', message: 'Zip is required' };
  }

  if (!data.city) {
    return { invalid: 'city', message: 'City is required' };
  }

  if (!data.state) {
    return { invalid: 'state', message: 'State is required' };
  }

  if (!data.phone) {
    return { invalid: 'phone', message: 'Invalid Phone Number' };
  }

  if (!validatePhoneNumber(data.phone)) {
    return { invalid: 'phone', message: 'Invalid Phone Number' };
  }

  if (!data.gender) {
    return { invalid: 'gender', message: 'Gender is required' };
  }

  if (!data.race) {
    return { invalid: 'race', message: 'Race is required' };
  }

  if (!data.ethnic) {
    return { invalid: 'ethnic', message: 'Ethnicity is required' };
  }

  if (!validateName(`${data.firstName} ${data.lastName}`)) {
    return { invalid: 'firstName', message: 'Invalid full name' };
  }

  if (!data.email && !data.phone) {
    return { invalid: 'email', message: 'Email or Phone Number required' };
  }

  if (data.email !== data.emailConfirmation) {
    return { invalid: 'emailConfirmation', message: 'Email Confirmation does not match Email' };
  }

  if (!validateEmail(data.email)) {
    return { invalid: 'email', message: 'Invalid Email' };
  }

  if (!data.birthday) {
    return { invalid: 'birthday', message: 'Birthday required' };
  }

  if (data.birthday && !validateDate(moment(data.birthday).format('MM/DD/YYYY'))) {
    return { invalid: 'birthday', message: 'Invalid Date' };
  }

  if ((data.age < 10 || data.age > 120) && data.age) {
    return { invalid: 'age', message: 'Invalid age' };
  }

  if (data.password === '') {
    return { invalid: 'password', message: 'Password Required' };
  }

  if (data.password && !validatePassword(data.password)) {
    return {
      invalid: 'password',
      message:
        'Length should be 8-70 characters and include: 1 uppercase, 1 lowercase, 1 digit and 1 special character',
    };
  }

  return { invalid: '', message: '' };
}

export function validateUserInformationShort(data) {
  if (!data.firstName) {
    return { invalid: 'firstName', message: 'First name is required' };
  }

  if (!data.lastName) {
    return { invalid: 'lastName', message: 'Last name is required' };
  }

  if (!data.gender) {
    return { invalid: 'gender', message: 'Gender is required' };
  }

  if (!data.race) {
    return { invalid: 'race', message: 'Race is required' };
  }

  if (!data.ethnicity) {
    return { invalid: 'ethnicity', message: 'Ethnicity is required' };
  }

  if (!validatePhoneNumber(data.phone)) {
    return { invalid: 'phone', message: 'Invalid Phone Number' };
  }

  if (!validateName(`${data.firstName} ${data.lastName}`)) {
    return { invalid: 'firstName', message: 'Invalid full name' };
  }

  if (!data.email && !data.phone) {
    return { invalid: 'email', message: 'Email or Phone Number required' };
  }

  if (!validateEmail(data.email)) {
    return { invalid: 'email', message: 'Invalid Email' };
  }

  if (!data.birthday) {
    return { invalid: 'birthday', message: 'Birthday required' };
  }

  if (data.birthday && !validateDate(moment(data.birthday).format('MM/DD/YYYY'))) {
    return { invalid: 'birthday', message: 'Invalid Date' };
  }

  if ((data.age < 10 || data.age > 120) && data.age) {
    return { invalid: 'age', message: 'Invalid age' };
  }

  if (data.password === '') {
    return { invalid: 'password', message: 'Password Required' };
  }

  if (data.password && !validatePassword(data.password)) {
    return {
      invalid: 'password',
      message:
        'Length should be 8-70 characters and include: 1 uppercase, 1 lowercase, 1 digit and 1 special character',
    };
  }

  return { invalid: '', message: '' };
}

export function validateFamilyMemberInformation(data) {
  if (!data.firstName) {
    return { invalid: 'firstName', message: 'First name is required' };
  }

  if (!data.lastName) {
    return { invalid: 'lastName', message: 'Last name is required' };
  }

  if (!data.gender) {
    return { invalid: 'gender', message: 'Gender is required' };
  }

  if (!validateName(`${data.firstName} ${data.lastName}`)) {
    return { invalid: 'firstName', message: 'Invalid full name' };
  }

  if (!data.birthday) {
    return { invalid: 'birthday', message: 'Birthday required' };
  }

  if (data.birthday && !validateDate(moment(data.birthday).format('MM/DD/YYYY'))) {
    return { invalid: 'birthday', message: 'Invalid Date' };
  }

  if ((data.age < 10 || data.age > 120) && data.age) {
    return { invalid: 'age', message: 'Invalid age' };
  }

  return { invalid: '', message: '' };
}

export function validateUserContacts(data) {
  if (!data.givenName) {
    return { invalid: 'givenName', message: 'Given name is required' };
  }

  if (!data.familyName) {
    return { invalid: 'familyName', message: 'Family name is required' };
  }

  if (!validateName(`${data.familyName} ${data.givenName}`)) {
    return { invalid: 'familyName', message: 'Invalid full name' };
  }

  if (!data.email) {
    return { invalid: 'email', message: 'Email required' };
  }

  if (!validateEmail(data.email)) {
    return { invalid: 'email', message: 'Invalid Email' };
  }

  if (validatePhoneNumber(data.phoneNumber)) {
    return { invalid: 'phoneNumber', message: 'Invalid Phone Number' };
  }

  return { invalid: '', message: '' };
}

export function validateMinor(data) {
  if (!data.parentName) {
    return { invalid: 'parentName', message: 'Parent name is required' };
  }

  if (!data.parentBirthday) {
    return { invalid: 'parentBirthday', message: 'Date of birth is required' };
  }

  if (data.parentBirthday && !validateDate(moment(data.parentBirthday).format('MM/DD/YYYY'))) {
    return { invalid: 'parentBirthday', message: 'Invalid Date' };
  }

  return { invalid: '', message: '' };
}
