import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getUserFamilyMembers } from '../../api/models/user';
import { Alert, Checkbox, StrokeButton } from '../../components';
import { Form as BootstrapForm, Button, Spinner } from 'react-bootstrap';
import css from './AddFamilyMemberForTestKitActivation.module.css';
import Back from '../../assets/svg/back.svg';
import { useHistory, useLocation } from 'react-router';
import AuraLogo from '../../assets/image/aura_logo.png';
import { Field, Form, Formik } from 'formik';
import Input from '../../components/Input/Input';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import { ethnicList, genderList, raceList, stateList } from '../../utils/utils';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { familySignupSchema } from '../../utils/constraints';
import { signUp } from '../../api/auth';
import ConsentForm from '../ConsentForm/ConsentForm';
import { connect } from 'react-redux';

function AddFamilyMemberForTestKitActivation(props) {
  const [user, setUser] = useState(null);
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    phoneNumber: '',
    gender: '',
    race: '',
    ethnicity: '',
  });
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { state } = useLocation();

  useEffect(() => {
    loadData();
  }, []);
  async function loadData() {
    const userDataCache = await getStoredData(USER_CACHE_NAME);
    setUser(userDataCache);
    let familyMemberList =
      (await getUserFamilyMembers(userDataCache.user_id, userDataCache.jwt)).included || [];
  }

  function onNext(values) {
    setStep(2);
    setFormValues(values);
  }

  async function onSubmit(values) {
    console.log('values', values);
    console.log('company id', props);

    console.log('company id', props?.user?.profile?.attributes?.['company-id']);
    try {
      setLoading(true);
      const managed = !!(values.phoneNumber && !values.email);
      const data = {
        'primary-user-id': user.user_id,
        'first-name': values.firstName,
        'middle-name': values.middleName,
        'last-name': values.lastName,
        'address-line-1': values.addressLine1,
        'address-line-2': values.addressLine2,
        state: values.state,
        city: values.city,
        zip: values.zip,
        'phone-number': values.phoneNumber,
        'date-of-birth': moment(values.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        gender: values.gender,
        ethnicity: values.ethnicity,
        race: values.race,
        'company-id': props?.user?.profile?.attributes?.['company-id'] || '',
        'managed-account': managed,
        // 'user-type-id': userType,
        // 'company-policy-id': policyId,
      };

      const response = await signUp(data);

      if (response.errors) {
        const detail = response.errors[0];
        setValidation({ invalid: true, message: detail });
        alert(detail);
      } else {
        // login(values?.email, values?.phoneNumber, values?.password);
        // if url contains "insurance"
        const is18 = moment(values.dateOfBirth, 'MM/DD/YYYY').isBefore(
          moment().subtract(18, 'years')
        );
        if (window.location.href.includes('insurance') && is18) {
          history.push(`/familyinsurancecheck/${response?.user?.id}`);
        } else {
          history.push('/select-family-member-activation', {
            kit: state?.kit,
          });
        }
      }
    } catch (error) {
      console.log('error', error);
      alert('An error occurred while signing up.', error);
    }
    setLoading(false);
  }

  if (step === 2) {
    return (
      <ConsentForm
        goBack={() => {
          setStep(1);
        }}
        onSubmit={() => onSubmit(formValues)}
        errorMessage={errorMessage}
        loading={loading}
        data={formValues}
      />
    );
  }

  return (
    <div>
      <div className={css.header}>
        <img src={Back} alt="Back" className={css.back} onClick={() => history.goBack()} />
        <h1 className={css.headerText}>Add a Family Member</h1>
      </div>
      <div className={css.body}>
        <img src={AuraLogo} alt="Aura Logo" className={css.auraLogo} />
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={familySignupSchema}
          onSubmit={onNext}
          className="px-4 my-4 py-4 login-card form-login"
        >
          {(formikProps) => (
            <Form
              className="px-4 my-4 py-4 login-card form-login aura-form"
              style={{ maxWidth: 400 }}
            >
              <div className="user-signup-form-container">
                <div className="user-signup-form-sub-container">
                  <Field
                    name="firstName"
                    label="First Name *"
                    placeholder="First Name"
                    component={Input}
                    type="text"
                  />
                  <Field
                    name="middleName"
                    label="Middle Name"
                    placeholder="Middle Name"
                    component={Input}
                    type="text"
                  />
                  <Field
                    name="lastName"
                    label="Last Name *"
                    placeholder="Last Name"
                    component={Input}
                    type="text"
                  />
                  <Field
                    name="dateOfBirth"
                    label="Date of Birth *"
                    component={NumberFormatInput}
                    type="text"
                    placeholder="MM/DD/YYYY"
                    format="##/##/####"
                  />
                  <Field
                    name="addressLine1"
                    label="Address *"
                    placeholder="Address"
                    component={Input}
                    type="text"
                  />
                  <Field
                    name="addressLine2"
                    label="Address 2"
                    placeholder="Address 2"
                    component={Input}
                    type="text"
                  />
                  <Field
                    name="city"
                    label="City *"
                    placeholder="City"
                    component={Input}
                    type="text"
                  />
                  <Field
                    name="state"
                    label="State *"
                    component={SelectInput}
                    classNamePrefix="form-select"
                    placeholder="State"
                    options={stateList.map((s) => ({
                      value: s.value,
                      label: s.label,
                    }))}
                  />
                  <Field name="zip" label="Zip *" placeholder="Zip" component={Input} type="text" />
                </div>
                <div className="user-signup-form-sub-container">
                  <Field
                    name="phoneNumber"
                    label="Phone Number *"
                    component={NumberFormatInput}
                    type="text"
                    placeholder="(###) ###-####"
                    format="(###) ###-####"
                  />
                  <Field
                    name="gender"
                    label="Gender *"
                    component={SelectInput}
                    classNamePrefix="form-select"
                    placeholder="Gender"
                    options={genderList.map((s) => ({
                      value: s.value,
                      label: s.label,
                    }))}
                  />
                  <Field
                    name="race"
                    label="Race *"
                    component={SelectInput}
                    classNamePrefix="form-select"
                    placeholder="Race"
                    options={raceList.map((s) => ({
                      value: s.value,
                      label: s.label,
                    }))}
                  />
                  <Field
                    name="ethnicity"
                    label="Ethnicity *"
                    component={SelectInput}
                    classNamePrefix="form-select"
                    placeholder="Ethnicity"
                    options={ethnicList.map((s) => ({
                      value: s.value,
                      label: s.label,
                    }))}
                  />
                </div>
              </div>
              <div className="mt-2 text-left text-inactive font-avenir-300 letter-tight fw-500 fs-14">
                Your privacy is important to us. By clicking Create Account, you confirm that you
                have reviewed our{' '}
                <a
                  href="https://auratracker.org/privacy_policy"
                  target="_blank"
                  style={{ color: 'blue' }}
                  rel="noreferrer"
                >
                  privacy policy
                </a>{' '}
                and agree to our terms of use and that your information may be shared with RCA and
                affiliated companies, who may contact you to keep you updated with important
                health-related information.
                <br /> By entering your mobile number and clicking next, you also consent that RCA
                and affiliated companies may send you SMS messages using autodialing technology from
                our primary messaging code 1-484-209-3355 for health-related information and as
                described in our terms of use in our privacy policy. Std. msg and data rates apply.
                Reply HELP or help, STOP to cancel. Msg freq may vary.
              </div>

              <div className="form-group justify-content-between flex-row pt-2">
                <Button
                  disabled={loading}
                  type="submit"
                  className="btn btn-login"
                  text="Create Account"
                >
                  {t('createAccount')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  profile: state.user.profile,
  familyMembers: state.family.familyMembers,
  authentication: state.authentication,
  status: state.authentication.status,
});

export default connect(mapStateToProps)(AddFamilyMemberForTestKitActivation);
