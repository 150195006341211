import React from 'react';
import Iframe from 'react-iframe';

import { Layout } from '../../components';
import './Map.css';

const MapContainer = (props) => {
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];

  return (
    <Layout headerTitle="Heat Map" tab={activeTab}>
      <Iframe className="iframe" title="map" url="./heatmap.html" />
    </Layout>
  );
};

export default MapContainer;
