import { SEARCH_RESULTS, SEARCH_SELECTED_RESULTS } from '../types';

export function setSearchResults(payload) {
  return {
    type: SEARCH_RESULTS,
    payload,
  };
}

export function setSelectedResults(payload) {
  return {
    type: SEARCH_SELECTED_RESULTS,
    payload,
  };
}
