import { API } from '../const';

export function processAPI({ endpoint, data, methodType, key, dataIncluded }) {
  return new Promise(async (res, rej) => {
    try {
      const apiMedtests = API + endpoint;
      const body = await fetch(apiMedtests, {
        method: methodType,
        headers: key
          ? {
              Authorization: `Bearer ${key}`,
              'Content-Type': 'application/vnd.api+json',
            }
          : {
              'Content-Type': 'application/vnd.api+json',
            },
        body: JSON.stringify(data),
      });
      const json = await body.json();

      if (!json.errors) {
        if (json.data && !dataIncluded) {
          res(json.data);
        } else {
          res(json);
        }
      } else if (json.errors) {
        rej(json.errors);
      }
      res({});
    } catch (error) {
      console.log('error update 2', error);
      rej();
    }
  });
}

export function processAPIDelete({ endpoint, data, methodType, key, dataIncluded }) {
  return new Promise(async (res, rej) => {
    try {
      const apiMedtests = API + endpoint;
      const body = await fetch(apiMedtests, {
        method: methodType,
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      res({});
    } catch (error) {
      console.log('error update 2', error);
      rej();
    }
  });
}

export function getCompanyNameSignUp(id) {
  return new Promise(async (res, rej) => {
    try {
      const apiLocations = `${API}company_name_signup/${id}`;
      const body = await fetch(apiLocations);
      const json = await body.json();

      if (json.errors) {
        rej();
      }
      res(json);
    } catch (error) {
      console.log('error ', error);
      rej([]);
    }
  });
}
