import { API } from '../const';

export function getQrCode(key) {
  return new Promise(async (res, rej) => {
    let k = `${API}certificate_qr_code`;
    // let k = 'https://auratracker.org/api/v1/certificate_qr_code';

    const body = await fetch(k, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${key}`,
        'Content-Type': 'application/vnd.api+json',
      },
    });
    const text = await body.text();
    const indexOpeningSvg = text.indexOf('<svg');
    const indexClosingSvg = text.indexOf('</svg>');
    const svgCode = text.slice(indexOpeningSvg, indexClosingSvg + 6);

    res(svgCode);
  });
}

export function getApptQr(key, id) {
  return new Promise(async (res, rej) => {
    let k = `${API}appointment-qr-codes/${id}`;
    const body = await fetch(k, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${key}`,
        'Content-Type': 'application/vnd.api+json',
      },
    });
    const json = await body.json();

    if (json.errors) {
      res({ error: json.errors });
      return;
    }

    const text = await json.data.attributes.svg;
    const indexOpeningSvg = text.indexOf('<svg');
    const indexClosingSvg = text.indexOf('</svg>');
    const svgCode = text.slice(indexOpeningSvg, indexClosingSvg + 6);

    res({
      svgCode,
      waitTime: json.data.attributes['vaccination-wait-time'],
      assessment: json.data.attributes['vaccination-risk-assessment'],
    });
  });
}

export function getQrByUuid(key, uuid) {
  return new Promise(async (res, rej) => {
    let k = `${API}qr-codes/${uuid}`;
    const body = await fetch(k, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${key}`,
        'Content-Type': 'application/vnd.api+json',
      },
    });
    const json = await body.json();

    if (json.errors) {
      res({ error: json.errors });
      return;
    }

    const text = await json.data.attributes.svg;
    const indexOpeningSvg = text.indexOf('<svg');
    const indexClosingSvg = text.indexOf('</svg>');
    const svgCode = text.slice(indexOpeningSvg, indexClosingSvg + 6);

    res({
      svgCode,
    });
  });
}

export function validateActivationCode(data, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}activation-codes`;
      const body = await fetch(api, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const json = await body.json();

      if (json.errors) {
        rej();
      }
      res(json);
    } catch (error) {
      console.log('error update 1', error);
      rej([]);
    }
  });
}
