import i18n from 'i18next';
// import Backend from "i18next-xhr-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    /* resources would be bundled with webpack */
    detection: {
      order: ['navigator'],
    },

    resources: {
      en: {
        translation: {
          signin: 'Sign in to Aura',
          signinDes: 'Please enter you details below',
          signinBtn: 'Sign Into My Account',
          signinGoogle: 'Sign in with Google',
          signinPhone: 'Sign in with Phone Number',
          signinEmail: 'Sign in with Email',
          dont_have_account: 'Don’t have an account?',
          register: 'Register',
          technicalSupport: 'Technical Support',
          welcome: 'Welcome To',
          didYouForget: 'Did you forget your password?',
          didYouForgethelp: 'We’ll help you reset it and get back on track.',
          resetmyPassword: 'Reset My Password',
          myTestKits: 'My Test Kits',
          collectionInstructions: 'Click to view Collection Instructions',
          schedule: 'Schedule',
          scan: 'Scan',
          results: 'Results',
          consentForms: 'Consent Forms',
          family: 'Family',
          myAura: 'My Aura',
          useEmail: 'Use Email',
          usePhone: 'Use Phone',
          email: 'Email',
          emailAddress: 'Email Address',
          employedByComcast: 'Are you currently employed by Comcast?',
          employedByComcastRelative: 'Have any relatives who are currently employed by Comcast?',
          requestResetLink: 'Request reset link',
          backToLogin: 'Back to Login',
          password: 'Password',
          phone: 'Phone',
          forgotPassword: 'Forgot Password',
          signInToAura: 'Sign In',
          privacyPolicy: 'Privacy Policy',
          support: 'Support',
          signOut: 'Sign out',
          account: 'Account',
          information: 'Information',
          certifications: 'Certifications',
          CDC: 'CDC',
          portal: 'Portal',
          daily: 'Daily',
          symptom: 'Symptoms',
          checker: 'Checker',
          dailySymptomChecker: 'Daily Symptom Checker',
          mySummary: 'My Summary',
          myInsurance: 'Insurance',
          symptomChecker: 'Symptom Checker',
          appointments: 'Appointments',
          test: 'Test',
          results: 'Results',
          allPersonalInformation:
            'All personal information is secure and only you have access to view.',
          healthReport: 'Health Report',
          date: 'Date',
          pleaseCheckAny: 'Please check any symptoms you have at this moment.',
          close: 'Close',
          saveChanges: 'Save Changes',
          noCertification: 'No certification',
          certifications: 'Certifications',
          family: 'Family',
          myFamily: 'My Family',
          addFamilyMember: 'Add Family Member',
          firstName: 'First Name',
          middleName: 'Middle Name',
          lastName: 'Last Name',
          mothersMaidenName: "Mother's Maiden Name",
          dateOfBirth: 'Date of Birth',
          address: 'Address',
          address2: 'Address 2',
          city: 'City',
          state: 'State',
          zipCode: 'Zip Code',
          gender: 'Gender',
          race: 'Race',
          ethnicity: 'Ethnicity',
          save: 'Save',
          documents: 'Documents',
          printDocument: 'Print Document',
          signedOn: 'Signed on',
          testResults: 'Test Results',
          negative: 'Negative',
          positive: 'Positive',
          lastUpdated: 'Last Updated',
          name: 'Name',
          dateOfTest: 'Date of Test',
          locationOfTesting: 'Locaton of Testing',
          certificed: 'CERTIFIED',
          notCertificed: 'NOT CERTIFIED',
          findATesting: 'Find a Testing Location',
          searchResults: 'Search Results',
          editLocation: 'Edit Location',
          familyMember: 'Family Member',
          availableTimslots: 'Available Timeslots for',
          bookAppointment: 'Book Appointment',
          readAllDirections: 'Read all directions thoroughly and carefully before you start.',
          activateKitReminder:
            'You must activate your test kit prior to collecting your sample.  If you have not already activated your test kit, ',
          clickHere: 'click here',
          toActivate: ' to do so.',
          testKitInstructions: 'Test Kit Instructions',
          viewVideoInstructions: 'View Video Instructions',
          inAdditionTo:
            'In addition to the step by step instructions listed above, you may view how to collect your COVID-19 Nasal Swab sample in the following video',
          readAllInstructions:
            'Read all instructions and plan same-day Fedex pickup before you start. Follow steps 1-18 in order.',
          washAndDry: 'Wash and dry your hands before handling the contents of the collection kit.',
          usingANon:
            'Using a non-smudging blue or black pen/marker, write your first and last name and date of birth on the collection tube.',
          nextUsingThe:
            'Next, using the same non-smudging pen/marker, fill out the Specimen Confirmation Form.',
          screwOffThe:
            'Screw off the top of the collection tube. Place the cap with the opening face up on a clean surface. Do not drink the liquid.',
          takeTheSwab:
            "Take the swab out of it's package. Do not touch the tip of the swab with your hands, and do not lay it down on any surface.",
          holdSwabIn:
            'Hold swab in one hand and collection tube in the other, being careful not to spill the liquid.',
          insertTheTip:
            'Insert the tip of the swab into one nostril, just until the tip of the swab is no longer visible.',
          rotateTheSwab:
            'Rotate the swab while gently circling and pressing around the entire inside edge of your nostril at least 5 times. When finished, take the swab out of your nostril.',
          usingTheSame:
            'Using the same end of the swab, repeat step 8 in your other nostril. When finished, take the swab out of your nostril.',
          placeTheSwab:
            'Place the swab in the collection tube, so that it is submerged in the liquid inside the tube.',
          breakTheSwab:
            'Break the swab along the pre-scored line by bending it against the collection tube.',
          screwTheTop:
            'Screw the top of the collection tube back on, ensuring that it is closed tightly.',
          discardTheBroken: 'Discard the broken top part of the swab.',
          washAndDryAgain: 'Wash and dry your hands thoroughly again.',
          placeTheCollection:
            'Place the collection tube into the absorbent pad inside the biohazard specimen bag.',
          sealTheBiohazard: 'Seal the biohazard specimen bag by closing the zip lock seal.',
          foldTheBiohazard:
            'Fold the biohazard specimen bag so that it fits into the GENETWORx box that this at-home kit came in. Place it inside the box, along with your Specimen Confirmation Form.',
          placeTheShipping:
            'Place the shipping box into the FedEx return pack, remove the adhesive cover strip and seal the FedEx return pack closed.',
          activateMyTest: 'Activate My Test Kit',
          activate: 'Activate',
          collect: 'Collect',
          pickATest: 'Pick a test kit to activate',
          iHaveCompleted:
            'I have completed my specimen collection and I am ready to ship my sample.',
          scanBarcode: 'Scan Bar Code on Tube',
          activationCode: 'Activation Code (e.g. Gx12345678)',
          enterBarcodeNumber: 'Barcode Number on Collection Tube',
          collectionDate: 'Collection Date',
          activateKitAnd: 'Activate Kit and Begin Collecting',
          editInsuranceInformation: 'Edit Insurance Information',
          editVaccineInformation: 'Edit Vaccine Information',
          riskAssessment: 'Vaccine Risk Assessment',
          appointmentConfirmation: 'Would you like to confirm your appointment?',
          hasInsurance: 'Do you currently have health insurance?',
          enterInsuranceInfo: 'Please fill out your insurance information below',
          enterNonInsuranceInfo:
            'Please Enter Your Drivers License Number OR Social Security Number',
          driversLicense: 'Drivers License',
          ssn: 'Social Security Number',
          planName: 'Plan Name (Type to search)',
          freeFormPlanName: 'Add Your Insurance Plan Name Here',
          yourPolicyNumber: 'Your Policy Number',
          relationshipToPatient: 'Relationship to Patient',
          guarantorRelationship: 'Gurantor Relationship',
          effectiveDate: 'Effective Date',
          expirationDate: 'Expiration Date',

          emailConfirmation: 'Email Confirmation',
          createAccount: 'Create Account',
          heatMap: 'Heat Map',
          clickToAgree: 'Click to Agree',
          parentAgreement:
            'By clicking this box I certify that I am the parent or authorized guardian of ',
          informedConsent: ' and I hereby agree to the above informed consent to treat.',
          dob: 'Date of Birth',
          parentGuardianDOB: 'Parent/Guardian DOB:',
          parentGuardianName: 'Parent/Guardian Name:',
          submit: 'Submit',
          agreementForm: 'Agreement Form',
          minorAccount: 'Minor Account',
          allParents13:
            'All parents/guardians of patients under the age of 13 years old must complete this Consent Form.',
          instructionsCanBe: 'Instructions can be found in the document.',
          childAccount: 'Child Account',
          myAccount: 'My Account',
          myAccountInfo: 'My Account Info',
          noFamilyMembers: 'No family members',
          newPasswordConfirmation: 'New Password Confirmation',
          resetPassword: 'Reset Password?',
          newPassword: 'New Password',
          noAvailableTiem: 'No Available Time',
          successBookedAppointment: 'Success booked appointment',
          cantDetermineLocation: "Can't determine your location",
          logSymptomsText: 'Log symptoms or view their symptom history',
          noAvailableTime: 'No Avaialble Time',
          denied: 'DENIED',
          certificationCode: 'Certification Code',
          viewPdfResult: 'View PDF result',
          noResultsWithin: 'No test results within 30 days',
          activationCodeRequired: 'Activation Code is required.',
          registrationFailedValidation:
            'Registration code must start with "GX81" followed by 6 more digits ex. "GX81994423".',
          registrationFailedActivation: 'Registration Failed, Activation code is already in use.',
          registrationFailed: 'Registration Failed',
          registeredTestKit: 'Registered Test Kit was successful',
          registrationFailedContact: 'Registration Failed. Please contact Aura Support',
          kitActivation: 'Kit Activation',
          confirmAndProceed: 'Confirm & Proceed',
          confirm: 'Confirm',
          readyToShip: 'Ready to Ship',
          goBack: 'Go Back',
          shippingInstructions: 'Shipping Instructions',
          returnTrackingNumber: 'FedEx Return Tracking Number (12 digits)',
          enterTrackingNumber: 'On FedEx return label following TRK#',
          returnTheTest: 'RETURNING THE TEST KIT',
          ifNotActivated: 'If you have not activated your test kit, please activate them ',
          yourKitActivated: 'Your test kit is now activated.',
          yourTestKit:
            'Your test kit must be stored at room temperature and sent back within 24 hours of sample collection.',
          pleaseDropYour:
            'Please drop your package off before the last pickup of the day Monday through Friday. Do not ship your package over the weekend. Do not take it to a FedEx office.',
          onlineToView: 'online to view FedEx drop box locations and pickup schedules.',
          visit: 'Visit',
          thePreAddressed:
            'The pre-addressed FedEx return pack can be mailed to GENETWORx via calling FedEx to arrange a pick up or by dropping it in a FedEx drop box.',
          forInVitro:
            'For In Vitro Diagnostic Use Only. For Emergency Use Authorization Only. Rx Only. For Use by Individuals 18 Years or Older.',
          thisProductHome:
            'This product (home collection kit in combination with the authorized test) has not been FDA cleared or approved, but has been authorized for emergency use by FDA under an EUA for use by authorized laboratories.',
          thisProductHas:
            'This product has been authorized only for the detection of nucleic acid from SARS-COV-2, not for any other viruses or pathogens.',
          theEmergencyUse:
            'The emergency use of this product is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of in vitro diagnostics for detection and/or diagnosis of COVID-19 under Section 564(b)(1) of the Federal, Food, Drug, and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1), unless the declaration is terminated or authorization is revoked sooner.',
          sampleCollected: 'Sample Collected',
          pleaseReadInstructions: 'Please read the instructions below carefully before proceeding:',
          testKitsMay:
            'Test Kits may only be activated once and should only be activated right before collecting sample.',
          pleaseCollect:
            'Please collect your sample on a weekday, Monday through Friday, and plan for same-day FedEx pickup before you start.',
          doNotShip: 'Do not ship your package late Friday evening or over the weekend.',
          pleaseDropPackage:
            'Please drop your package off before the last pickup of the day.  Do not take it to a FedEx office.',
          preAddressedFedEx:
            'The pre-addressed FedEx return pack can be returned via calling FedEx to arrange a pick-up or by dropping it in a FedEx drop box.',
          lastTest: 'Last Test',
          youAre: 'You are ',
          lateForYour: 'late for your test',
          beforeYourTest: 'before your test',
          its: "It's ",
          pending: 'Pending',
          collectionDateRequired: 'Collection Date is required.',
          specimenCodeRequired: 'Specimen Code is required.',
          trackingNumberRequired: 'Tracking Number is required.',
          confirmed: 'Confirmed!',
          confirmationFailed: 'Confirmation Failed',
          pleaseConstactAura: 'Please contact Aura Support.',
          thankYou: 'Thank You!',
          trackingNumberWas: 'Trackng Number was not updated',
          my: 'My',
          info: 'Information',
          myOrg: 'My Organization',
          insuranceInfo: 'Insurance Info',
          vaccineInfo: 'Vaccine Info',
          policyNumber: 'Policy Number',
          parentConsent: 'Parent Consent',
          age: 'Age',
          zip: 'Zip',
          birthday: 'Birthday',
          cancelAppointment: 'Cancel Appointment',
          cancelAppointmentMessage:
            'You are about to cancel your appointment. Please click "Confirm" to proceed. ',
          cancelAppointmentReasonMessage:
            'Can you please provide us a reason for why you’re cancelling this appointment?',
          appointmentSchedule: 'Appointment Schedule',
          Information: 'Information',
          uploadImage: 'Upload Insurance Card',
          uploadUserImage: 'Upload Photo ID',
          uploadVaccineCard: 'Upload Vaccine Card',
          guarantorRelationship: 'Guarantor Relationship',
          selectImages: 'Select Image',

          vaccinationStation: 'Vaccination Station',
          welcomeToThe: 'Welcome to the\n Aura Vaccination Program',
          congratulationsYouBelong:
            'Congratulations you belong to \nan organization that is conducting \nCOVID-19 vaccinations.',
          scheduleYour: 'Schedule your appointment below',
          noAppointmentsScheduled: 'No appointments scheduled',
          vaccinationRecordCard: 'Vaccination Record Card',
          noVaccinationReceived: 'No Vaccination Received',
          scanVaccinePass: 'Scan Vaccine Pass',
          viewMySecurePass: 'My Secure Pass',
          pleaseNote: 'Please Note',
          notice: 'NOTICE',
          iAgreeTo: 'I agree to the conditions stated.',
          proceedToScheduling: 'Proceed to Scheduling',
          youWillNot:
            'You will not be able to be vaccinated if you are currently showing symptoms or are Positive for COVID-19.',
          theVaccineType:
            'The COVID-19 vaccine type and brand will be determined based on availability at appoinement of your testing location.',
          proceedToScheduling: 'Proceed to Scheduling',
          selectVaccinationSite: 'Select Vaccination Site',
          vaccinationPass: 'Vaccination Pass',
          myVaccinationRecord: 'My Vaccination Record',
          selectAnAvailable: 'Select an Available Appointment',
          vaccination: 'Vaccination',
          noVaccinationsReceived: 'No Vaccinations Received',
          checkInEligibility: 'Check In: Eligibility',
          howAreYou: 'How are you feeling today?',
          iAmIn: 'I am in great health',
          iFeelOkay: 'I Feel Okay',
          notMyGreatest: 'Not My Greatest',
          iDoNot: 'I do not feel well',
          poorHealth: 'I am in poor health',
          bookAppointment: 'Book Appointment',
          vaccinationPass: 'Vaccination Pass',
          checkInRead: 'Check In: Read Docs',
          checkInScan: 'Check In: Scan',
          availableTimeslots: 'Available Timeslots',
          mustSelectApptTime: 'You must select an appointment time.',
          selectAvailableAppointment: 'Select an Available Appointment',
          weAreSorry:
            'We are sorry but there are no\n available appointments at this \nlocation for this day.\n\nPlease select another date.',
          vaxNow: 'Vax Now',
          fullName: 'Full Name',
          vaccinationSite: 'Vaccination Site',
          patientNumber: 'Patient Number (medical record or IIS record number)',
          vaccine: 'Vaccine',
          brand: 'Brand',
          viewLotNumber: 'View Lot Number',
          dose: 'Dose',
          healthcareProfessional: 'Healthcare Professional or Clinic Site',
          vaccinationSite: 'Vaccination Site',
          time: 'Time',
          latestVaccination: 'Latest Vaccination',
          COVID19Testing: 'COVID-19 Testing',
          testedWithinLast: 'Tested within last 72 hours',
          noSymptoms: 'No Symptoms',
          showingNoSymptoms: 'Showing no Symptoms',
          dismiss: 'Dismiss',
          appointmentBooked: 'Appointment Booked',
          youScheduledAnAppointment: 'You scheduled an appointment for',
          myVaccineAppointment: 'My Vaccination Appointment',
          myVaccine: 'My Vaccine Info',
          myVaccination: 'My Vaccination',
          techSupport: 'Tech Support',
          technicalSupport: 'Technical Support',
          technical: 'Technical',
          techSupportHeaderText: 'Need app help from the Aura Staff?',
          techSupportText: 'Choose one of our contact methods and',
          techSupportText2: 'a member of our staff will reach out ASAP',
          familyHeader: 'Members of your Aura Family',
          when: 'When',
          where: 'Where',
          hello: 'Hello',
          showToCode: 'Show to code vaccinator to scan',
          congratulationsOnReceiving: 'Congratulations on receiving \nyour last vaccination!',
          youHaveNow:
            'You have now received all required doses\n for this vaccination. \nYou are now vaccinated for COVID-19',
          elgibility: 'Eligibility',
          readDocs: 'Read Docs',
          waitingRoom: 'Waiting Room',
          minutesRemaining: 'MINUTES REMAINING',
          iUnderstandAnd: 'I understand and agree to the conditions stated.',
          emailMeThese: 'Email me these documents to my account’s primary email address',
          readyForMy: 'Ready for my Vaccine',
          continueReading: 'Continue Reading',
          checkinAtSite: 'Check in at site ',
          checkin: 'Check-In',
          checkInWaitingRoom: 'Check-In Waiting Room',
          timeRemaining: 'TIME REMAINING',
          lotNumber: 'Lot Number',
          firstDose: '1st Dose',
          secondDose: '2nd Dose',
          thirdDose: '3rd Dose',
          lastVaccination: 'Last Vaccination',
          myDocuments: 'My Documents',
          viewMyDocuments: 'View My Documents',
          unsignedFamilyForms: 'Consent Forms',
          noFamily: 'Would you like to add a family member to your account?',
          familyConsentMessage: 'You have consent forms to sign.',
          continueToSign: 'Please proceed to sign your required consent documents.',
        },
      },
      es: {
        translation: {
          welcome: 'Bienvenido a',
          didYouForget: '¿Olvidaste tu contraseña?',
          myTestKits: 'Mis kits de prueba',
          collectionInstructions: 'Haga clic para ver las instrucciones de la colección',
          schedule: 'Calendario',
          scan: 'Escanear',
          consentForms: 'Formularios de consentimiento',
          family: 'Familia',
          myAura: 'Mi aura',
          useEmail: 'Usar email',
          usePhone: 'Usar teléfono',
          email: 'Email',
          emailAddress: 'Dirección Email',
          requestResetLink: 'Solicitar Restablecer Enlace',
          backToLogin: 'Atrás',
          password: 'Contraseóa',
          phone: 'TelÈfono',
          forgotPassword: '¿Se te olvidó tu contraseña?',
          signInToAura: 'Iniciar sesión en Aura',
          privacyPolicy: 'Política de privacidad',
          support: 'Apoyo',
          signOut: 'desconectar',
          account: 'Cuenta',
          information: 'Información',
          certifications: 'Certificaciones',
          CDC: 'CDC Portal',
          portal: 'Portal',
          daily: 'Diario',
          symptomChecker: 'Comprobador de Síntomas',
          mySummary: 'Mi Resumen',
          appointments: 'Equipo',
          test: 'Prueba',
          results: 'Resultados',
          allPersonalInformation:
            'Toda la información personal est· segura y solo usted tiene acceso para verla.',
          healthReport: 'Reporte de salud',
          date: 'Fecha',
          pleaseCheckAny: 'Marque cualquier sntoma que tenga en este momento.',
          close: 'Cerca',
          saveChanges: 'Guardar cambios',
          noCertification: 'Sin certificaciín',
          certifications: 'Certificaciones',
          family: 'Familia',
          addFamilyMember: 'Agregar miembro de la familia',
          firstName: 'Primer nombre',
          middleName: 'Segundo nombre',
          lastName: 'Apellido',
          mothersMaidenName: 'Nombre de soltera de la madre',
          dateOfBirth: 'Fecha de nacimiento',
          address: 'Habla a',
          city: 'Ciudad',
          state: 'Estado',
          zipCode: 'Cdigo postal',
          gender: 'GÈnero',
          race: 'Raza',
          ethnicity: 'Etnicidad',
          save: 'Salvar',
          documents: 'Documentos',
          printDocument: 'Imprimir documento',
          signedOn: 'Firmado',
          testResults: 'Resultados de la prueba',
          negative: 'Negativo',
          positive: 'Positivo',
          lastUpdated: 'última actualización',
          name: 'Nombre',
          dateOfTest: 'Fecha de la prueba',
          locationOfTesting: 'Ubicacin de las pruebas',
          certificed: 'CERTIFICADO',
          notCertificed: 'No certificado',
          findATesting: 'Encuentre una ubicación de prueba',
          searchResults: 'Resultados de la Búsqueda',
          editLocation: 'Editar ubicación',
          familyMember: 'Miembro de la familia',
          availableTimslots: 'Horarios disponibles para',
          bookAppointment: 'Reservar una cita',
          readAllDirections:
            'Lea todas las instrucciones detenidamente y con cuidado antes de comenzar.',
          activateKitReminder:
            'Debe activar su kit de prueba antes de recolectar su muestra. Si aún no ha activado su kit de prueba, ',
          clickHere: 'haga clic aquí',
          toActivate: ' para hacerlo.',
          testKitInstructions: 'Instrucciones del kit de prueba',
          viewVideoInstructions: 'Ver instrucciones en video',
          inAdditionTo:
            'Adem·s de las instrucciones paso a paso enumeradas anteriormente, puede ver cómo recolectar su muestra de recolección de hisopo nasal COVID-19 en el siguiente video',
          readAllInstructions:
            'Lea todas las instrucciones y planifique la recogida de Fedex el mismo día antes de comenzar. Siga los pasos del 1 al 18 en orden.',
          washAndDry:
            'L·vese y sÈquese las manos antes de manipular el contenido del kit de recolección.',
          usingANon:
            'Con un bolígrafo / marcador azul o negro que no manche, escriba su nombre, apellido y fecha de nacimiento en el tubo de recolección.',
          nextUsingThe:
            'A continuación, con el mismo bolígrafo / marcador que no mancha, llene el Formulario de confirmación de muestra.',
          screwOffThe:
            'Desenrosque la parte superior del tubo colector. Coloque la tapa con la abertura hacia arriba sobre una superficie limpia. No beba el líquido.',
          takeTheSwab:
            'Saque el hisopo de su paquete. No toque la punta del hisopo con las manos y no lo coloque sobre ninguna superficie.',
          holdSwabIn:
            'Sostenga el hisopo en una mano y el tubo de recolección en la otra, teniendo cuidado de no derramar el líquido.',
          insertTheTip:
            'Inserte la punta del hisopo en una fosa nasal, solo hasta que la punta del hisopo ya no sea visible.',
          rotateTheSwab:
            'Gire el hisopo mientras gira suavemente y presiona alrededor de todo el borde interior de su fosa nasal al menos 5 veces. Cuando termine, saque el hisopo de su fosa nasal.',
          usingTheSame:
            'Con el mismo extremo del hisopo, repita el paso 8 en la otra fosa nasal. Cuando termine, saque el hisopo de su fosa nasal.',
          placeTheSwab:
            'Coloque el hisopo en el tubo de recolección, de modo que quede sumergido en el líquido dentro del tubo.',
          breakTheSwab:
            'Rompa el hisopo a lo largo de la línea marcada previamente dobl·ndolo contra el tubo de recolección.',
          screwTheTop:
            'Vuelva a atornillar la parte superior del tubo de recogida, asegur·ndose de que estÈ bien cerrado.',
          discardTheBroken:
            'Vuelva a atornillar la parte superior del tubo de recogida, asegur·ndose de que estÈ bien cerrado.',
          washAndDryAgain: 'L·vese y sÈquese bien las manos nuevamente.',
          placeTheCollection:
            'Coloque el tubo de recolección en la almohadilla absorbente dentro de la bolsa de muestras de riesgo biológico.',
          sealTheBiohazard:
            'Selle la bolsa de muestras de riesgo biológico cerrando el cierre con cremallera.',
          foldTheBiohazard:
            'Doble la bolsa de muestras de riesgo biológico para que encaje en la caja GENETWORx en la que vino este kit para el hogar. Colóquela dentro de la caja, junto con su formulario de confirmación de muestras.',
          placeTheShipping:
            'Coloque la caja de envío en el paquete de devolución de FedEx, retire la tira de cubierta adhesiva y cierre el paquete de devolución de FedEx.',
          activateMyTest: 'Activar mi kit de prueba',
          activate: 'Activar',
          collect: 'Recoger',
          pickATest: 'Elija un kit de prueba para activar',
          iHaveCompleted:
            'He completado mi recolecciÛn de muestras y estoy listo para enviar mi muestra.',
          scanBarcode: 'Escanear cÛdigo de barras en el tubo',
          activationCode: 'CÛdigo de activaciÛn (e.g. Gx12345678)',
          enterBarcodeNumber: 'Número de código de barras en el tubo de recogida',
          collectionDate: 'Fecha de colecciÛn',
          activateKitAnd: 'Active el kit y comience a recolectar',
          editInsuranceInformation: 'Editar la información del seguro',
          riskAssessment: 'Cuestionario de evaluación de riesgos de vacunas',
          appointmentConfirmation: '¿Quieres confirmar tu cita?',
          hasInsurance: '¿Tiene actualmente seguro médico?',
          planName: 'Nombre del plan',
          yourPolicyNumber: 'Su n˙mero de póliza',
          relationshipToPatient: 'Relación con el paciente',
          guarantorRelationship: 'Relación de Gurantor',
          effectiveDate: 'Fecha efectiva',
          expirationDate: 'Fecha de caducidad',

          emailConfirmation: 'Confirmación de Email',
          createAccount: 'Crear una Cuenta',
          heatMap: 'Mapa de calor',
          clickToAgree: 'Clic para Aceptar',
          parentAgreement:
            'Al hacer clic en esta casilla, certifico que soy el padre o tutor autorizado de ',
          informedConsent: ' y por la presente acepto el consentimiento informado para tratar.',
          parentGuardianDOB: 'Tutor DOB:',
          parentGuardianName: 'Parent/Guardian Nombre:',
          submit: 'Enviar',
          agreementForm: 'Formulario de acuerdo',
          minorAccount: 'Cuenta Infantil',
          allParents13:
            'Todos los padres / tutores de pacientes menores de 13 años deben completar este formulario de consentimiento.',
          instructionsCanBe: 'Las instrucciones se pueden encontrar en el documento.',
          childAccount: 'Cuenta Infantil',
          myAccount: 'Mi cuenta',
          noFamilyMembers: 'Sin miembros de la familia',
          newPasswordConfirmation: 'Nueva confirmación de contraseña',
          resetPassword: '¿Restablecer la contraseña?',
          newPassword: 'Nueva contraseña',
          noAvailableTiem: 'No hay tiempo disponible',
          successBookedAppointment: 'Éxito, cita reservada',
          cantDetermineLocation: 'No puedo determinar tu ubicación',
          denied: 'NEGADA',
          certificationCode: 'Código de Certificación',
          viewPdfResult: 'Ver resultado en PDF',
          noResultsWithin: 'Sin resultados de prueba en 30 días',
          activationCodeRequired: 'Se requiere el código de activación.',
          registrationFailedActivation: 'Registro fallido, el código de activación está en uso.',
          registrationFailed: 'Registro fallido.',
          registeredTestKit: 'El kit de prueba registrado fue exitoso.',
          registrationFailedContact: 'Registro fallido. Póngase en contacto con el soporte de Aura',
          kitActivation: 'Activación del kit',
          confirmAndProceed: 'Confirmar y Continuar',
          readyToShip: 'Listo para enviar',
          goBack: 'Regresa',
          shippingInstructions: 'Instrucciones de envío',
          returnTrackingNumber: 'Número de seguimiento de devolución',
          enterTrackingNumber: 'En la etiqueta de devolución de FedEx después de TRK #',
          returnTheTest: 'Devolver el kit de prueba',
          yourKitActivated: 'Su kit de prueba ahora está activado.',
          yourTestKit:
            'Su kit de prueba debe almacenarse a temperatura ambiente y enviarse dentro de las 24 horas posteriores a la recolección de la muestra.',
          pleaseDropYour:
            'Deje su paquete antes de la última recogida del día de lunes a viernes. No envíe su paquete durante el fin de semana. No lo lleve a una oficina de FedEx.',
          onlineToView:
            'en línea para ver las ubicaciones de los buzones de entrega de FedEx y los horarios de recogida.',
          visit: 'Visitar',
          thePreAddressed:
            'El paquete de devolución de FedEx con la dirección previa se puede enviar por correo a GENETWORx llamando a FedEx para organizar una recogida o dejándolo en un buzón de entrega de FedEx.',
          forInVitro:
            'Sólo para uso diagnóstico in vitro. Solo para autorización de uso de emergencia. Solo Rx. Para uso de personas mayores de 18 años.',
          thisProductHome:
            'Este producto (kit de recolección en el hogar en combinación con la prueba autorizada) no ha sido autorizado o aprobado por la FDA, pero ha sido autorizado para uso de emergencia por la FDA bajo  nan EUA para su uso por laboratorios autorizados.',
          thisProductHas:
            'Este producto ha sido autorizado solo para la detección de ácido nucleico del SARS-COV-2, no para otros virus o patógenos.',
          theEmergencyUse:
            'El uso de emergencia de este producto solo está autorizado mientras dure la declaración de que existen circunstancias que justifiquen la autorización del uso de emergencia de diagnóstico in vitro / n para la detección y / o diagnóstico de COVID-19 bajo la Sección 564 (b) (1) de la Ley Federal, de Alimentos, Medicamentos y Cosméticos, 21 USC § 360bbb-3 (b) (1), a menos que la declaración / n se termine o la autorización se revoque antes.',
          sampleCollected: 'Muestra Recogida',
          pleaseReadInstructions:
            'Lea atentamente las instrucciones a continuación antes de continuar:',
          testKitsMay:
            'Los kits de prueba solo se pueden activar una vez y solo deben activarse justo antes de recolectar la muestra.',
          pleaseCollect:
            'Recoja su muestra en un día laborable, de lunes a viernes, y planifique la recogida por FedEx el mismo día antes de comenzar.',
          doNotShip: 'No envíe su paquete el viernes por la noche o durante el fin de semana.',
          pleaseDropPackage:
            'Deje su paquete antes de la última recogida del día. No lo lleve a una oficina de FedEx.',
          preAddressedFedEx:
            'El paquete de devolución de FedEx con la dirección anticipada se puede devolver llamando a FedEx para organizar una recogida o dejándolo en un buzón de entrega de FedEx.',
          lastTest: 'Última prueba',
          youAre: 'Usted está ',
          lateForYour: 'tarde para tu prueba',
          beforeYourTest: 'antes de tu prueba',
          its: 'Sus ',
          pending: 'Pendiente',
          collectionDateRequired: 'Se requiere la fecha de recolección.',
          specimenCodeRequired: 'Se requiere el código de muestra.',
          trackingNumberRequired: 'Se requiere un número de seguimiento.',
          confirmed: 'Confirmada!',
          confirmationFailed: 'Confirmación fallida',
          pleaseConstactAura: 'Póngase en contacto con el soporte de Aura.',
          thankYou: '¡Gracias!',
          trackingNumberWas: 'No se actualizó el número de seguimiento',
          my: 'Mi',
          insuranceInfo: 'Información del seguro',
          policyNumber: 'Número de póliza',

          appointmentSchedue: 'Calendario de citas',
          parentConsent: 'Consentimiento',
          cancelAppointment: 'Cancelar cita',
          uploadImage: 'Cargar imagen',
          selectImages: 'Seleccionar imágene',
          unsignedFamilyForms: 'Formularios de consentimiento',
          familyConsentMessage: 'Tiene formularios de consentimiento para firmar.',
          continueToSign: 'Proceda a firmar los documentos de consentimiento requeridos.',
          tests: 'tests',
          completed: 'completed',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
i18n.changeLanguage();
export default i18n;
