import { API } from '../const';

export function uploadFile(url, file, fields) {
  return new Promise(async (res, rej) => {
    let form = new FormData();
    Object.keys(fields).forEach((key) => form.append(key, fields[key]));
    form.append('file', file);

    try {
      const body = await fetch(url, {
        method: 'POST',
        body: form,
      });

      res({});
    } catch (error) {
      console.log('error update 1', error);
      rej([]);
    }
  });
}

export function updateImageData(id, data, key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}image`;
      const body = await fetch(api, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data),
      });
      const json = await body.json();

      if (!json.errors) {
        res(json.data);
      }
      res({});
    } catch (error) {
      console.log('error update 1', error);
    }
  });
}
