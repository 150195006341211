export async function storeData(key, value) {
  try {
    await localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log('error storing data to cache');
  }
}

export async function getStoredData(key) {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }

    return null;
  } catch (error) {
    console.log('error geting stored data');
  }
}

export async function clearCache(key) {
  try {
    await localStorage.removeItem(key);
  } catch (error) {
    console.log(' error clearing cache', error);
  }
}
