import React, { useState } from 'react';
import './index.css';
import ShieldIcon from '../../../assets/image/insuranceshield.png';
import Check from '../../../assets/image/check.png';

const IsInsured = ({ onSubmit }) => {
  const [isInsured, setIsInsured] = useState(true);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <div className="text-center">
            <img src={ShieldIcon} alt="ShieldIcon" />
          </div>

          <div className="mt-4 px-2 IsInsured_ask">Do you have medical insurance?</div>

          <div className="mt-5 p-3">
            <div
              onClick={() => setIsInsured(true)}
              className={` ${isInsured ? 'IsInsured_selectedBtn' : 'IsInsured_notSelectBtn'}`}
            >
              {isInsured && <img src={Check} alt="Check" className="mr-2" />}
              Yes, I have Medical Insurance
            </div>

            <div
              onClick={() => {
                localStorage.removeItem('user_loggedIn');
                setIsInsured(false);
              }}
              className={`mt-4 ${!isInsured ? 'IsInsured_selectedBtn' : 'IsInsured_notSelectBtn'}`}
            >
              {!isInsured && <img src={Check} alt="Check" className="mr-2" />}
              No, I do not have Medical Insurance
            </div>
          </div>

          <div className="my-5 pt-5 px-3">
            <div
              className="IsInsured_next"
              onClick={() => {
                onSubmit(isInsured);
              }}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IsInsured;
