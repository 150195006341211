import React, { useEffect } from 'react';
import { object } from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import cn from 'classnames';
import css from './AsyncCreatableSelectInput.css';

const AsyncCreatableSelectInput = ({
  field,
  label,
  placeholder,
  disabled,
  options,
  classNamePrefix,
  handleChange,
  handleOnCreate,
  asyncLoadOptions,
  isSearchable,
  defaultList,
  freeFormPlanName,
  form: { touched, errors, status, setFieldValue, setFieldTouched },
}) => {
  useEffect(() => {
    if (options.length === 1) {
      setFieldValue(options[0]?.label, options[0]?.value);
    }
  }, []);

  return (
    <div className="auraField">
      {label && <label>{label}</label>}
      <div>
        <AsyncCreatableSelect
          {...field}
          name={field.name}
          options={options}
          placeholder="Search Plan Name..."
          classNamePrefix={classNamePrefix}
          className={cn({
            'with-error':
              (touched[field.name] && status && status[field.name]) ||
              (touched[field.name] && errors && errors[field.name]),
          })}
          isDisabled={disabled}
          isClearable
          value={
            options?.length > 0
              ? options.find((option) => option.value === field.value)
              : { value: freeFormPlanName, label: freeFormPlanName } || ''
          }
          onChange={(option) => {
            setFieldValue(field?.name, option?.value);
            handleChange && handleChange(option);
          }}
          onCreateOption={(option) => {
            setFieldValue(field?.name, option);
            handleOnCreate && handleOnCreate(option);
          }}
          loadOptions={asyncLoadOptions}
          onBlur={() => setFieldTouched(field.name, true)}
          isSearchable={isSearchable}
          defaultOptions={defaultList}
          defaultValue={options[0]}
          cacheOptions
        />
        {touched[field.name] && status && status[field.name] && <span>{status[field.name]}</span>}
        {touched[field.name] && errors && errors[field.name] && <span>{errors[field.name]}</span>}
      </div>
    </div>
  );
};

AsyncCreatableSelectInput.propTypes = {
  field: object.isRequired,
};

export default AsyncCreatableSelectInput;
