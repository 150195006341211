import moment from 'moment';

export const parseInputErrors = (error) => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  if (error instanceof Object) {
    return error.error;
  }
  return error;
};

export const genderObject = {
  M: 'Male',
  F: 'Female',
  U: 'Unknown',
};

export const genderList = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'U', label: 'Unknown' },
];

export const stateList = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const raceList = [
  { value: 'AA', label: 'African American/Black' },
  { value: 'A', label: 'Asian' },
  { value: 'C', label: 'Caucasian/White' },
  { value: 'DA', label: 'Declined to Answer' },
  { value: 'H', label: 'Hispanic/Latino' },
  { value: 'NA', label: 'Native American/Alaska Native' },
  { value: 'O', label: 'Native Hawaiian or Other Pacific Islander' },
  { value: 'O', label: 'Other Race' },
  { value: 'MR', label: 'Two or More Races' },
];

export const raceObject = {
  AA: 'African American/Black',
  A: 'Asian',
  C: 'Caucasian/White',
  DA: 'Declined to Answer',
  H: 'Hispanic/Latino',
  NA: 'Native American/Alaska Native',
  O: 'Native Hawaiian or Other Pacific Islander',
  O: 'Other Race',
  MR: 'Two or More Races',
};

export const ethnicityObject = {
  DA: 'Declined to Answer',
  H: 'Hispanic',
  NH: 'Non-Hispanic',
};

export const ethnicList = [
  { value: 'DA', label: 'Declined to Answer' },
  { value: 'H', label: 'Hispanic' },
  { value: 'NH', label: 'Non-Hispanic' },
];

export const getTimeSinceTest = (value) => {
  const date = new Date(value);
  const starts = moment(value)
    ?.year(date?.getUTCFullYear())
    ?.month(date?.getUTCMonth())
    ?.date(date?.getUTCDate())
    ?.hours(date?.getUTCHours())
    ?.minutes(date?.getUTCMinutes())
    ?.seconds(0);

  if (!starts?.isValid()) return 'Invalid Date';

  const ends = moment()?.seconds(0);
  const duration = moment.duration(ends.diff(starts));

  let { days, hours, minutes, months, years } = duration?._data;

  let ago = '';

  if (
    ends?.month() === starts?.month() &&
    ends?.date() === starts?.date() &&
    ends?.year() !== starts?.year()
  ) {
    years += 1;
    months = 0;
  }

  if (years && years === 1) ago += `${years} year `;
  if (years && years > 1) ago += `${years} years `;

  if (months && months === 1) ago += `${months} month `;
  if (months && months > 1) ago += `${months} months `;

  if (!years) {
    if (days && days === 1) ago += `${days} day `;
    if (days && days > 1) ago += `${days} days `;
  }

  if (!months && !years) {
    if (hours && hours === 1) ago += `${hours} hour `;
    if (hours && hours > 1) ago += `${hours} hours `;
  }

  if (!hours && !days && !months && !years) {
    if (minutes && minutes === 1) ago += `${minutes} minute `;
    if (minutes && minutes > 1) ago += `${minutes} minutes `;
  }
  ago += 'ago';

  return ago;
};
