import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import ReactPlayer from 'react-player';
import InstructionsNew from '../InstructionsNew/Instructions';
import { Header } from '../../components';
import YoutubeSvg from '../../assets/svg/youtube.svg';
import FileSvg from '../../assets/svg/file.svg';
import ChevronUp from '../../assets/svg/chevron_up';
import step1 from '../../assets/image/test_kit_instructions/step_1.png';
import step2 from '../../assets/image/test_kit_instructions/step_2.png';
import step3 from '../../assets/image/test_kit_instructions/step_3.png';
import step4 from '../../assets/image/test_kit_instructions/step_4.png';
import step5 from '../../assets/image/test_kit_instructions/step_5.png';
import step6 from '../../assets/image/test_kit_instructions/step_6.png';
import step7 from '../../assets/image/test_kit_instructions/step_7.png';
import step8 from '../../assets/image/test_kit_instructions/step_8.png';
import step9 from '../../assets/image/test_kit_instructions/step_9.png';
import step10 from '../../assets/image/test_kit_instructions/step_10.png';
import step11 from '../../assets/image/test_kit_instructions/step_11.png';
import step12 from '../../assets/image/test_kit_instructions/step_12.png';
import step13 from '../../assets/image/test_kit_instructions/step_13.png';
import step14 from '../../assets/image/test_kit_instructions/step_14.png';
import step15 from '../../assets/image/test_kit_instructions/step_15.png';
import step16 from '../../assets/image/test_kit_instructions/step_16.png';
import step17 from '../../assets/image/test_kit_instructions/step_17.png';
import step18 from '../../assets/image/test_kit_instructions/step_18.png';
import { isUsability } from '../../api/const';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    flex: '1 1 auto',
    overflowY: 'auto',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(5),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
  },
  footer: {
    flex: '0 1 80px',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  titleContainer1: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  titleContainer2: {
    display: 'flex',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
  },
  description: {
    fontSize: 17,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
      opacity: 0.4,
    },
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'unset',
    letterSpacing: -0.408,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
  player: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 640,
    backgroundColor: 'black',
    border: '1px solid #EBEDEF',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  step: {},
  imgContainer: {
    textAlign: 'center',
  },
  descriptionContainer: {
    display: 'flex',
  },
  lightBlue: {
    background: '#F3FAF9',
  },
  italic: {
    fontStyle: 'italic',
  },
}));

const stepList = [
  {
    img: step1,
    description: 'Wash and dry your hands before handling the contents of the collection kit.',
  },
  {
    img: step2,
    description:
      'Using a non-smudging blue or black pen/marker, write your first and last name and date of birth on the collection tube.',
  },
  {
    img: step3,
    description:
      'Next, using the same non-smudging pen/marker, fill out the Specimen Confirmation Form.',
  },
  {
    img: step4,
    description:
      'Screw off the top of the collection tube. Place the cap with the opening face up on a clean surface. Do not drink the liquid.',
  },
  {
    img: step5,
    description:
      "Take the swab out of it's package. Do not touch the tip of the swab with your hands, and do not lay it down on any surface.",
  },
  {
    img: step6,
    description:
      'Hold swab in one hand and collection tube in the other, being careful not to spill the liquid.',
  },
  {
    img: step7,
    description:
      'Insert the tip of the swab into one nostril, just until the tip of the swab is no longer visible.',
  },
  {
    img: step8,
    description:
      'Rotate the swab while gently circling and pressing around the entire inside edge of your nostril at least 5 times. When finished, take the swab out of your nostril.',
  },
  {
    img: step9,
    description:
      'Using the same end of the swab, repeat step 8 in your other nostril. When finished, take the swab out of your nostril.',
  },
  {
    img: step10,
    description:
      'Place the swab in the collection tube, so that it is submerged in the liquid inside the tube.',
  },
  {
    img: step11,
    description:
      'Break the swab along the pre-scored line by bending it against the collection tube.',
  },
  {
    img: step12,
    description:
      'Screw the top of the collection tube back on, ensuring that it is closed tightly.',
  },
  {
    img: step13,
    description: 'Discard the broken top part of the swab.',
  },
  {
    img: step14,
    description: 'Wash and dry your hands thoroughly again.',
  },
  {
    img: step15,
    description:
      'Place the collection tube into the absorbent pad inside the biohazard specimen bag.',
  },
  {
    img: step16,
    description: 'Seal the biohazard specimen bag by closing the zip lock seal',
  },
  {
    img: step17,
    description:
      'Fold the biohazard specimen bag so that it fits into the GENETWORx box that this at-home kit came in. Place it inside the box, along with your Specimen Confirmation Form.',
  },
  {
    img: step18,
    description:
      'Place the shipping box into the FedEx return pack, remove the adhesive cover strip and seal the FedEx return pack closed.',
  },
];

const Instructions = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleOnSubmit = () => {
    history.push('/my-tests', { tab: 1 });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={classes.root}>
      <Header title="Instructions" />
      <div className={classes.body}>
        <div className={classes.titleContainer1}>
          <img src={YoutubeSvg} alt="youtube" />
          &nbsp;
          <Typography className={classes.title}>Video Instructions</Typography>
        </div>
        <Typography className={classes.description}>
          In addition to the step by step instructions listed above, you may view how to collect
          your COVID-19 Nasal Swab sample in the following video.
        </Typography>
        <ReactPlayer
          width="100%"
          controls
          className={classes.player}
          url="https://www.youtube.com/watch?v=ZBCIQVpobGI&feature=youtu.be%27"
        />
        <div className={classes.titleWrapper}>
          <div className={classes.titleContainer2}>
            <img src={FileSvg} alt="file" />
            &nbsp;
            <Typography className={classes.title}>Written Instructions</Typography>
          </div>
          <ChevronUp color="rgba(255, 153, 0, 0.8)" />
        </div>
        <Typography className={classes.description}>
          Read all instructions and plan same-day FedEx pickup before you start. Follow steps 1-18
          in order.
        </Typography>
        <ul className={classes.stepContainer}>
          {stepList.map(({ img, description }, index) => (
            <li className={classNames(classes.step, index % 2 ? classes.lightBlue : '')}>
              <div className={classes.imgContainer}>
                <img src={img} alt={`img-${index}`} width={200} height={120} />
              </div>
              <div className={classes.descriptionContainer}>
                <Typography className={classes.description}>{`${index + 1}. `}</Typography>
                &nbsp;
                <Typography className={classes.description}>{description}</Typography>
              </div>
            </li>
          ))}
        </ul>
        <Typography className={classes.title}>Returning the Test Kit</Typography>
        <Typography className={classNames(classes.description, classes.italic)}>
          Your test kit must be stored at room temperature and sent back within 24 hours of sample
          collection.
        </Typography>
        <Typography className={classes.description}>
          Please drop your package off before the last pickup of the day Monday through Friday. Do
          not ship your package over the weekend. Do not take it to a FedEx office.
        </Typography>
        <Typography className={classes.description}>
          The pre-addressed FedEx return pack can be mailed to GENETWORx via calling FedEx to
          arrange a pick up or by dropping it in a FedEx drop box.
        </Typography>
        <Typography className={classes.description}>
          <a href="Visit bit.ly/fedexdropbox">Visit bit.ly/fedexdropbox</a> online to view FedEx
          drop box locations and pickup schedules.
        </Typography>
      </div>
      <div className={classes.footer}>
        <Button fullWidth className={classNames(classes.submitButton)} onClick={handleOnSubmit}>
          Return to My Tests
        </Button>
      </div>
    </div>
  );
};

const InstructionsMain = () => (isUsability ? <InstructionsNew /> : <Instructions />);

export default InstructionsMain;
