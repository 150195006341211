import React, { useState, useLayoutEffect, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';
import { animateScroll } from 'react-scroll';
import Markdown from 'react-markdown';

import './Layout.css';
import Sidebar from '../Sidebar/Sidebar';
import Divider from '../Divider/Divider';
import BackIcon from '../../assets/svg/back.svg';
import { Modal, Checkbox, ListForm, Logo, SmallLogo } from '..';
import { validateMinor } from '../../api/validation';
import { processAPI } from '../../api/models/process';
import { setUser } from '../../store/action/user';
import FamilyIcon from '../../assets/image/aura_family_icon.png';
import PreviewIcon from '../../assets/image/pdf_preview.png';
import { getUserData, updateUserData } from '../../api/models/user';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../ProgressBar/ProgressBar';

const MAX_MOBILE_SCREEN_WIDTH = 768;

function Layout(props) {
  const {
    tab,
    headerTitle,
    children,
    rightText,
    onClickRight,
    back,
    onClickBack,
    loading,
    heatmap,
    user,
    userAuthentication,
    setUserDispatch,
    disablePrimaryUserHipaaPopup,
    progressBar,
    progressBarStepsList,
    progressBarCurrentStep,
  } = props;

  const { t, i18n } = useTranslation();

  const [agree, setAgree] = useState(false);
  const [unsigned, setUnsigned] = useState(
    (user && user.attributes && user.attributes['hipaa-release-details'].unsigned) || []
  );
  const [unsignedIndex, setUnsignedIndex] = useState(0);
  const [minorChecked, setMinorChecked] = useState(false);
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const [parentName, setParentName] = useState('');
  const [parentBirthday, setParentBirthday] = useState('1970-01-01');
  const [loadingModal, setLoadingModal] = useState(false);

  const { invalid, message: errorMessage } = validation;
  const hipaShow = user && user.attributes && user.attributes['hipaa-consent'];
  const isMinor = user && user.attributes && user.attributes['is-a-minor'];
  const showMinor =
    user && user.attributes && user.attributes['parent-guardian-checkbox-date'] == null;
  const companyId = user && user.attributes && user.attributes['company-id'];
  const modalHipaShow = !hipaShow && companyId !== null;
  const lastUnsigned = unsignedIndex === unsigned.length - 1;

  const isUnder13 = user && user.attributes && user.attributes['under-13'] === true;
  const showModalUnder13 =
    user && user.attributes && user.attributes['under-13-signed'] !== true && isUnder13;
  const under13Text = (user && user.attributes && user.attributes['under-13-text']) || '';

  const [width, height] = useWindowSize(window.innerWidth, window.innerHeight);
  const navTesting = user && user.attributes && user.attributes['nav-testing'];
  const navTestKit = true; // user && user.attributes && user.attributes['nav-test-kit'];
  const navScan = user && user.attributes && user.attributes['nav-scan'];
  const navResults = user && user.attributes && user.attributes['nav-results'];
  const navDocuments = user && user.attributes && user.attributes['nav-documents'];
  const navMyAura = user && user.attributes && user.attributes['nav-my-aura'];
  const navFamily = user && user.attributes && user.attributes['nav-family-groups'];
  const navVaccine = user && user.attributes && user.attributes['nav-vaccines'];

  let content = children;

  if (loading) {
    content = (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h1 className="large-font">
          Loading... <Spinner animation="border" variant="warning" />
        </h1>
      </div>
    );
  }

  function useWindowSize(w, h) {
    const [size, setSize] = useState([w, h]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);
      window.addEventListener('orientationchange', updateSize);

      updateSize();
      return () => {
        window.removeEventListener('resize', updateSize);
        window.removeEventListener('orientationchange', updateSize);
      };
    }, []);
    return size;
  }

  async function submitMinor() {
    const { invalid, message } = validateMinor({ parentName, parentBirthday });
    const id = userAuthentication.user_id;
    setValidation({ invalid, message });

    if (!invalid) {
      const { jwt } = userAuthentication;
      const updatedData = {
        data: {
          id,
          type: 'users',
          attributes: {
            'parent-guardian-name': parentName,
            'parent-guardian-dob': parentBirthday,
            'parent-guardian-checkbox-date': new Date(),
          },
        },
      };
      setLoadingModal(true);
      const data = await updateUserData(id, updatedData, jwt);
      setUserDispatch(data);
      setLoadingModal(false);
    }
  }

  async function downloadConsentForm() {
    // window.location.href = "AuraMinorConsentForm.pdf";
    window.open('AuraMinorConsentForm.pdf', 'Download');
  }

  function bottomScroll() {
    animateScroll.scrollToBottom({
      containerId: 'scroll-div',
    });
  }

  async function hipaaApproved() {
    const { id } = props.user;
    const { jwt } = userAuthentication;

    setLoadingModal(true);

    await Promise.all(
      unsigned.map(async (item) => {
        const updatedData = {
          data: {
            type: 'user-hipaa-releases',
            attributes: {
              'hipaa-release-id': item.id,
              'user-id': id,
            },
          },
        };

        await processAPI({
          key: jwt,
          endpoint: 'user-hipaa-releases',
          methodType: 'POST',
          data: updatedData,
        });
      })
    );

    const profile = await getUserData(id, jwt);

    setUserDispatch(profile.data);
    setUnsigned([]);
    setLoadingModal(false);
  }

  function nextUnsigned() {
    setAgree(false);
    setUnsignedIndex(unsignedIndex + 1);
  }

  function modal() {
    return (
      <>
        {showModalUnder13 && (
          <Modal
            className="under13"
            headerComponent={
              <div className="child-header-container">
                <img alt="icon" src={FamilyIcon} className="family-image" />
                <span className="child-header">{t('childAccount')}</span>
              </div>
            }
            show
            closeButton
            footerComponent={
              <Button className="btn btn-orange" type="download" onClick={downloadConsentForm}>
                Download Consent Form
              </Button>
            }
          >
            <div id="scroll-div" className="mx-2">
              <p className="modal-header-2 mt-3 mb-2">{t('minorAccount').toUpperCase()}</p>
              <p className="modal-content-text mb-3">{t('allParents13')}</p>
              <p className="modal-content-text italic mb-3">{t('instructionsCanBe')}</p>

              <div className="preview-container">
                <img alt="icon" src={PreviewIcon} className="preview-image" />
              </div>
            </div>
          </Modal>
        )}

        <Modal
          show={!modalHipaShow && isMinor && showMinor}
          title={t('agreementForm')}
          closeButton
          footerComponent={
            <Button disabled={!minorChecked} onClick={submitMinor}>
              {loadingModal ? (
                <Spinner animation="border" variant="warning" />
              ) : (
                t('submit').toUpperCase()
              )}
            </Button>
          }
        >
          <div className="d-md-flex justify-content-between">
            <ListForm
              required
              id="parent-name"
              label={t('parentGuardianName')}
              placeholder="input your name"
              name="parent-name"
              value={parentName}
              onChange={(e) => setParentName(e.target.value)}
              error={invalid === 'parentName'}
              errorMessage={errorMessage}
            />
            <ListForm
              required
              id="first-name"
              label={t('parentGuardianDOB')}
              placeholder=""
              onChange={(e) => setParentBirthday(e.target.value)}
              name="first-name"
              type="date"
              value={parentBirthday}
            />
          </div>
          <div className="mt-4">
            <Checkbox
              label={`${t('parentConsent')} ${
                user && user.attributes && user.attributes['first-name']
              } ${user && user.attributes && user.attributes['last-name']} ${t('informedConsent')}`}
              // selected={item.selected}
              handleChange={() => setMinorChecked(!minorChecked)}
              labelColor={!minorChecked && '#777777'}
              error={invalid === 'lastName'}
              errorMessage={errorMessage}
            />
          </div>
        </Modal>
        {/* {unsigned.length > 0 && unsigned[unsignedIndex].title && !showModalUnder13 && !disablePrimaryUserHipaaPopup && (
          <Modal
            title={unsigned[unsignedIndex].title}
            show={true}
            closeButton={true}
            footerComponent={
              <div className="d-flex flex-column flex-sm-row align-items-md-center justify-content-between w-100">
                <Checkbox
                  selected={agree}
                  handleChange={() => setAgree(!agree)}
                  label={t('clickToAgree')}
                  labelColor={!agree && '#777777'}
                />
                <div className="mt-3">
                  <Button
                    variant="outline-primary w-100"
                    onClick={bottomScroll}
                    >
                      Scroll To Bottom
                  </Button>
                </div>
                <div className="mt-3">
                  {loadingModal ? (
                    <Spinner animation="border" variant="secondary" />
                  ) : (
                    <Button
                      variant="primary w-100"
                      disabled={!agree}
                      onClick={lastUnsigned ? hipaaApproved : nextUnsigned}
                      >
                      {lastUnsigned ? 'OK' : 'NEXT'}
                    </Button>
                  )}
                </div>
              </div>
            }
          >
            <div id="scroll-div" className="hippa-container">
                <p className="norma-font">
                  <Markdown source={unsigned[unsignedIndex]['release_text'].replace(/my employer/g, user.attributes['company-name'])} />
                </p>
            </div>
          </Modal>
        )} */}
      </>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      const element = document?.getElementById('layout_Id');
      element?.scrollIntoView(true);
    }, 400);
  }, []);

  if (MAX_MOBILE_SCREEN_WIDTH >= width && !showModalUnder13) {
    return (
      <div className="mobile-nav">
        <div id="layout_Id" style={{ height: '5px' }} />
        <Navbar bg="light" expand="lg">
          <SmallLogo />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {navTestKit && (
                <>
                  <Nav.Link href="#">
                    <Link to="/my-tests">
                      <span className="normal-font">{t('myTestKits')}</span>
                    </Link>
                  </Nav.Link>
                  {/* <Nav.Link href="#">
                    <Link to="/kit-instructions">
                      <span className="normal-font">
                        {t("testKitInstructions")}
                      </span>
                    </Link>
                  </Nav.Link> */}
                </>
              )}
              {navTesting && (
                <Nav.Link href="#">
                  <Link to="/testing">
                    <span className="normal-font">{t('schedule')}</span>
                  </Link>
                </Nav.Link>
              )}
              {navScan && (
                <Nav.Link href="#">
                  <Link to="/scan">
                    <span className="normal-font">{t('scan')}</span>
                  </Link>
                </Nav.Link>
              )}
              {heatmap && (
                <Nav.Link href="#">
                  <Link to="/map">
                    <span className="normal-font">{t('heatMap')}</span>
                  </Link>
                </Nav.Link>
              )}
              {navDocuments && (
                <Nav.Link href="#">
                  <Link to="/documents">
                    <span className="normal-font">{t('documents')}</span>
                  </Link>
                </Nav.Link>
              )}
              {navVaccine && (
                <Nav.Link href="#">
                  <Link to="/vaccine-information">
                    <span className="normal-font">{t('vaccination')}</span>
                  </Link>
                </Nav.Link>
              )}

              {navMyAura && (
                <Nav.Link href="#">
                  <Link to="/profile">
                    <span className="normal-font">{t('myAura')}</span>
                  </Link>
                </Nav.Link>
              )}
              {navFamily && (
                <Nav.Link href="#">
                  <Link to="/family">
                    <span className="normal-font">{t('family')}</span>
                  </Link>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="d-flex flex-wrap justify-content-between align-items-center mt-2 mb-2">
          <h3 className="header-title x-large-font" onClick={onClickBack}>
            {back && <img alt="back" className="back-icon" src={BackIcon} />}
            {headerTitle}
          </h3>
          {rightText && (
            <p className="header-right-title" onClick={onClickRight}>
              {rightText}
            </p>
          )}
        </div>

        <div className="content">{content}</div>
        {modal()}
      </div>
    );
  }

  return (
    <>
      <div id="layout_Id" style={{ height: '5px' }} />
      <div className="gridContainer">
        <div className="logo">
          <Logo />
        </div>
        <div className="header">
          <div>
            <h3 className="header-title x-large-font" onClick={onClickBack}>
              {back && <img alt="back" className="back-icon" src={BackIcon} />}
              {headerTitle}
            </h3>
            {rightText && (
              <p className="header-right-title" onClick={onClickRight}>
                {rightText}
              </p>
            )}
          </div>
        </div>
        <div className="sidebar">
          {progressBar ? (
            <ProgressBar list={progressBarStepsList} step={progressBarCurrentStep} />
          ) : (
            <Sidebar activeTab={tab} />
          )}
        </div>
        <div className="content">{content}</div>
        {modal()}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    heatmap: state.user.profile?.attributes?.heatmap,
    user: state.user.profile,
    userAuthentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
