import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Ellipse from '../../assets/image/Kebab.png';
import closeButton from '../../assets/svg/close_button.svg';
import historyicon from '../../assets/svg/result-history.svg';
import symptomcheckericon from '../../assets/svg/symptom-checker.svg';
import insuranceicon from '../../assets/svg/insurance-icon.svg';
import documentsicon from '../../assets/svg/documents-icon.svg';
import vaxicon from '../../assets/svg/vax-icon.svg';
import qricon from '../../assets/svg/qr-icon.svg';
import './SideMenu.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { getQrByUuid } from '../../api/models/qr';
import { setQRCode } from '../../store/action/family';

const SideMenu = (props) => {
  const [showModal, setShowModal] = useState(false);
  let { id } = useParams();
  if (!id) {
    id = props.user.id;
  }
  const history = useHistory();

  const [navResults, setNavResults] = useState(false);
  const [navHealthReport, setNavHealthReport] = useState(false);
  const [navVaccines, setNavVaccines] = useState(false);
  const [navInsurance, setNavInsurance] = useState(false);
  const [navDocuments, setNavDocuments] = useState(false);
  const [navQRCode, setNavQRCode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = props?.user;
    setNavHealthReport(user?.attributes['health-report']);
    setNavResults(user?.attributes['nav-results']);
    setNavVaccines(user?.attributes['nav-vaccines']);
    setNavInsurance(user?.attributes['requires-insurance']);
    setNavDocuments(user?.attributes['nav-documents']);
    setNavQRCode(user?.attributes['nav-scan']);
  }, []);

  async function loadQrCodeByUUID() {
    if (loading) return;
    setLoading(true);

    const jwt = props?.authentication?.jwt;
    const name = props?.userDetail?.attributes?.['first-name'];
    const uuid = props?.userDetail?.attributes?.uuid;

    const data = await getQrByUuid(jwt, uuid);
    props?.setQrCodeDispatch(data.svgCode);
    setLoading(false);
    history.push({
      pathname: '/qr-code',
      state: { name },
    });
  }

  const goToResultsHistory = () => {
    history.push(`/results-history/${id}`);
  };

  const goToSymptomChecker = () => {
    history.push(`/daily-symptom-checker/${id}`);
  };

  const goToInsurance = () => {
    history.push(`/insurance-information/${id}`);
  };

  const goToVaccination = () => {
    history.push(`/vaccine-information/${id}`);
  };

  const goToDocuments = () => {
    history.push(`/documents/${id}`);
  };

  const hideEllipse = useMemo(() => {
    if (
      !navDocuments &&
      !navHealthReport &&
      !navInsurance &&
      !navQRCode &&
      !navResults &&
      !navVaccines
    )
      return true;
    return false;
  }, [navDocuments, navHealthReport, navInsurance, navQRCode, navResults, navVaccines]);

  return (
    <>
      {!hideEllipse && (
        <div
          onClick={(e) => {
            setShowModal(!showModal);
          }}
        >
          <img src={Ellipse} alt="Ellipse" />
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="bb"
      >
        <div className="bb2">
          <div className="modalHeader d-flex align-items-center">
            <p className="modalHeaderTitle flex-grow-1 mr-2 mr-sm-0">
              {props?.userDetail?.attributes?.['first-name']}{' '}
              {props?.userDetail?.attributes?.['last-name']}
            </p>

            <img
              src={closeButton}
              alt="close"
              className="modalCloseButton"
              onClick={() => setShowModal(false)}
            />
          </div>

          <div className="">
            {[
              navResults && {
                text: 'Results History',
                icon: <img src={historyicon} alt="result-history" />,
                action: goToResultsHistory,
              },
              navHealthReport && {
                text: 'Symptom Checker',
                icon: <img src={symptomcheckericon} alt="symptoms" />,
                action: goToSymptomChecker,
              },
              navInsurance && {
                text: 'Insurance',
                icon: <img src={insuranceicon} alt="insurance" />,
                action: goToInsurance,
              },
              navDocuments && {
                text: 'Documents',
                icon: <img src={documentsicon} alt="documents" />,
                action: goToDocuments,
              },
              navVaccines && {
                text: 'Vaccination Info',
                icon: <img src={vaxicon} alt="vaccination-Info" />,
                action: goToVaccination,
              },
              navQRCode && {
                text: 'QR Code',
                icon: <img src={qricon} alt="QR Code" />,
                action: loadQrCodeByUUID,
              },
            ]
              .filter((val) => val)
              .map((item, index) => (
                <div className="modalItem" key={index} onClick={item?.action}>
                  {item?.icon}
                  <p className="modalItemText">{item?.text}</p>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state?.user?.profile,
  authentication: state?.authentication,
});

const mapDispatchToProps = (dispatch) => ({
  setQrCodeDispatch(payload) {
    dispatch(setQRCode(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
