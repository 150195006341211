import React from 'react';
import { Card } from 'react-bootstrap';
import { Layout, ListSimple } from '../../components';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

const Pass = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { user, history, vax } = props;

  return (
    <Layout
      tab={activeTab}
      headerTitle={t('vaccinationPass')}
      onClickBack={() => history.goBack()}
      back
    >
      <div className="vaccine">
        <div className={css.subContainer}>
          <Card>
            <Card.Body>
              <div className="d-flex flex-row justify-content-between">
                <ListSimple
                  title={t('fullName')}
                  description={
                    user && `${user.attributes['first-name']} ${user.attributes['last-name']}`
                  }
                />
                <ListSimple title={t('vaccinationSite')} description="Genetworx" />
              </div>
              <div className="d-flex flex-row justify-content-between">
                <ListSimple
                  title={t('lastVaccination')}
                  description={moment(vax && vax[0] && vax[0].attributes['created-at']).format(
                    'll'
                  )}
                />
                <ListSimple
                  title={t('time')}
                  description={
                    vax && vax[0] && moment(vax[0].attributes['created-at']).format('LT')
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
    profile: state.user.userProfile,
    authentication: state.authentication,
    vax: state.vax.history,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Pass);
