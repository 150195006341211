import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Fab,
} from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { Header } from '../../components';
import { isUsability } from '../../api/const';
import ChevronDownSvg from '../../assets/svg/chevron_down';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    position: 'relative',
    flex: '1 1 auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(10),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
  },
  descriptionList: {
    listStyleType: 'disc',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  descriptionHeader: {
    fontSize: 22,
    letterSpacing: 0.352,
    fontFamily: 'SF Pro Display',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 20,
    letterSpacing: -0.408,
    fontFamily: 'SF Pro Text',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
      opacity: 0.4,
    },
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'unset',
    letterSpacing: -0.408,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
  checkboxWrapper: {
    alignSelf: 'start',
    '& .MuiTypography-body1': {
      fontSize: 20,
      letterSpacing: 0.75,
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  fab: {
    background: 'rgba(255, 153, 0)',
    margin: 0,
    top: 'auto',
    right: '20%',
    bottom: '20%',
    left: 'auto',
    position: 'fixed',
    '&:hover': {
      background: 'rgba(255, 153, 0)',
    },
    '&:focus': {
      outline: 0,
    },
    [theme.breakpoints.down('xs')]: {
      right: 30,
    },
  },
}));

const CustomCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'black',
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);

const formData = [
  {
    header:
      'I understand that the information released will include any of the following Protected Health Information, as available:',
    list: [
      'COVID-19 test results, including to detect the presence of COVID-19 and/or COVID-19 antibodies',
      'data captured from me during the registration process for services',
      'data provided by my employer on an eligibility file for services',
    ],
  },
  {
    header: 'PURPOSE',
    description:
      'I understand that my Protected Health Information may be used and disclosed for one or more of the following purposes:',
    list: [
      'identifying individuals who may have been or were exposed to COVID-19',
      'alerting other employees and contractors of possible exposure to COVID-19 (without revealing my identity of the affected employee)',
      'monitoring and surveilling of workplace to ensure a safe work environment for all employees',
      'identifying possible risks to other employees of COVID-19 exposure',
      'notifying relevant public health authorities as permitted or required by law.',
    ],
  },
  {
    header: 'CONDITIONS OF AUTHORIZATION',
    content: (
      <>
        I understand that RCA GENETWORx is providing this COVID-19 screening at my employer's
        request and for purposes of disclosing the results to my employer and, therefore, if I
        refuse to sign this authorization, then I will not be eligible to receive the COVID-19
        screening.
      </>
    ),
  },
  {
    header: 'RE-DISCLOSURE',
    content: (
      <>
        I understand that the information disclosed pursuant to this authorization may be subject to
        re-disclosure by the recipient and no longer be protected under the terms of this
        authorization or federal privacy law. My employer will be subject to applicable employment
        laws.
      </>
    ),
  },
  {
    header: 'RIGHT TO REVOKE AUTHORIZATION',
    content: (
      <>
        I understand that I have the right to revoke this authorization in writing. I understand
        that my request to revoke this authorization will not affect any disclosures that were made
        prior to RCA GENETWORx receiving my revocation request or future disclosures to the extent
        that LES GENETWORx has already taken action in reliance on the authorization. To revoke this
        authorization, send a written request to: Privacy Officer, GENETWORx, 2701 Renaissance Blvd.
        King of Prussia, PA 19063 [RCA to provide mailing address and contact]
      </>
    ),
  },
  {
    header: 'EXPIRATION',
    content: (
      <>
        This authorization will expire one year from the date of consent. I understand that I will
        be provided a copy of this authorization upon request.
      </>
    ),
  },
];

const ConsentForm = ({ goBack, onSubmit, loading, data, primaryFamilyMember }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [agree, setAgree] = useState(false);
  const [agree2, setAgree2] = useState(false);

  const primaryFamilyName = `${primaryFamilyMember?.data?.attributes?.['first-name']} ${primaryFamilyMember?.data?.attributes?.['last-name']}`;
  const userName = `${data.firstName} ${data.lastName}`;
  const age = moment().diff(moment(data.dateOfBirth), 'years');
  const showExtraFields = isUsability && age >= 4 && age <= 17;

  const buttonDisabled = loading || showExtraFields ? !agree || !agree2 : !agree;

  const handleOnScrollDown = () => {
    scrollToBottom();
  };

  const scrollToBottom = () => {
    ref.current?.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={classes.root}>
      <Header onBack={goBack} title="Consent Form" />
      <div ref={ref} className={classes.body}>
        {/* <Fab className={classes.fab} onClick={handleOnScrollDown}>
          <ChevronDownSvg />
        </Fab> */}
        <Typography className={classes.title}>
          AUTHORIZATION TO RELEASE PROTECTED HEALTH INFORMATION
        </Typography>
        {showExtraFields && (
          <Typography className={classes.subTitle}>
            {primaryFamilyName}, please carefully read and accept the following Informed Consent on
            behalf of {userName}:
          </Typography>
        )}
        <Typography className={classes.description}>
          I authorize RCA Laboratory Services, LLC Recovery Centers of America ("GENETWORx") ("RCA")
          to release my individually identifiable health information ("Protected Health
          Information") for the purposes all as described below, to my employer and employer
          sponsored health plan.
        </Typography>
        <Typography className={classes.descriptionHeader}>INFORMATION TO BE RELEASED</Typography>
        {formData.map((item, index) => (
          <>
            <Typography className={classes.description} style={{ marginBottom: 0 }}>
              {index === 0 ? item.header : `## ${item.header}`}
            </Typography>
            {item.description && (
              <Typography className={classes.description} style={{ margin: 0 }}>
                {item.description}
              </Typography>
            )}
            {item.list && (
              <ul className={classes.descriptionList}>
                {item.list.map((desc) => (
                  <li className={classes.description} style={{ margin: 0 }}>
                    {desc}
                  </li>
                ))}
              </ul>
            )}
            {item.content && (
              <Typography className={classes.description} style={{ marginTop: 0 }}>
                {item.content}
              </Typography>
            )}
          </>
        ))}
        {showExtraFields && (
          <FormControlLabel
            className={classes.checkboxWrapper}
            control={
              <CustomCheck
                checked={agree2}
                onChange={({ target: { checked } }) => setAgree2(checked)}
                style={{
                  color: 'rgba(255, 153, 0)',
                }}
              />
            }
            label={
              <span>
                Click here to confirm that you, <b>{primaryFamilyName}</b> are the authorized parent
                or guardian of <b>{userName}</b>
              </span>
            }
          />
        )}
        <FormControlLabel
          className={classes.checkboxWrapper}
          control={
            <CustomCheck
              checked={agree}
              onChange={({ target: { checked } }) => setAgree(checked)}
              style={{
                color: 'rgba(255, 153, 0)',
              }}
            />
          }
          label={<b>Check to Agree</b>}
        />
        <Button
          fullWidth
          className={classNames(classes.submitButton)}
          disabled={buttonDisabled}
          onClick={onSubmit}
        >
          {loading ? <CircularProgress color="primary" /> : 'Sign and Submit Document'}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  primaryFamilyMember: state.family.primaryFamilyMembers,
});

export default connect(mapStateToProps)(ConsentForm);
