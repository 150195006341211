import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Spinner, Button as Button2 } from 'react-bootstrap';
import LogoBlack from '../../assets/svg/logo_black.svg';
import { processAPI } from '../../api/models/process';
import { getApptQr } from '../../api/models/qr';
import { Layout, Button, Checkbox, Modal } from '../../components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { animateScroll } from 'react-scroll';

import css from './Vaccination.module.css';

const ScanContainer = (props) => {
  const { user, history } = props;

  const [svgCode, setSvgCode] = useState('');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [assessment, setAssessment] = useState('Default');
  const [waitTime, setWaitTime] = useState(0);

  const activeTab = 'vaccine';
  const buff = new Buffer(svgCode);
  const base64data = buff.toString('base64');
  const [unsigned, setUnsigned] = useState();
  const [unsignedIndex, setUnsignedIndex] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const [lastUnsigned, setLastUnsigned] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [agree, setAgree] = useState(false);

  const appts = props.vaxAppointments;

  const [appointmentId, setAppointmentId] = useState();

  useEffect(() => {
    loadQrCode();
    if (user?.profile?.attributes['requires-screening-twenty-four-hours-assessed'] === true) {
      history.push('/screening-questions');
    }
  }, []);

  useEffect(() => {
    if (appts && appts.length > 0) {
      let appt = appts[0];
      setAppointmentId(appt && appt.id);

      const us = appt && appt.attributes && appt.attributes['hipaa-release-details'].unsigned;
      setUnsigned(us);

      if (us) {
        setLastUnsigned(unsignedIndex === us.length - 1);
      }
    } else {
      history.push('./vaccine');
    }
  }, [appts]);

  useEffect(() => {
    unsigned && setLastUnsigned(unsignedIndex === unsigned.length - 1);
  }, [unsignedIndex]);

  function bottomScroll() {
    animateScroll.scrollToBottom({
      containerId: 'scroll-div',
    });
  }

  async function hipaaApproved() {
    const { id } = props.user.profile;
    const { jwt } = props.authentication;

    setLoadingModal(true);

    await Promise.all(
      unsigned.map(async (item) => {
        const updatedData = {
          data: {
            type: 'user-hipaa-releases',
            attributes: {
              'appointment-id': appointmentId,
              'hipaa-release-id': item.id,
              'user-id': id,
            },
          },
        };

        await processAPI({
          key: jwt,
          endpoint: 'user-hipaa-releases',
          methodType: 'POST',
          data: updatedData,
        });
      })
    );

    setUnsigned([]);
    setLoadingModal(false);
  }

  function nextUnsigned() {
    setAgree(false);
    setUnsignedIndex(unsignedIndex + 1);
  }

  async function goNext() {
    let wait = waitTime || 15;
    history.push(`vaccinewait/${wait}`);
  }

  async function loadQrCode() {
    setLoading(true);
    const { authentication } = props;
    const { jwt } = authentication;
    const qr = appts && appts.length > 0 && appts[0].id;
    const data = await getApptQr(jwt, qr);

    console.log('...data QR  ', data);
    setSvgCode(`${data.svgCode}`);
    setWaitTime(data?.waitTime?.split(' ', 1));
    setAssessment(data.assessment || 'Default');
    setLoading(false);

    setLoading(false);
  }

  const statusColor = {
    Default: 'scanContainerDefault',
    Green: 'scanContainerGreen',
    Yellow: 'scanContainerYellow',
    Red: 'scanContainerRed',
  };

  return (
    <Layout headerTitle="Check In Scan" tab={activeTab} onClickBack={() => history.goBack()} back>
      <div className="vaccine scan" style={{ maxWidth: 375 }}>
        <div className={css.subContainer}>
          <div className={classNames(css.scanContainer, css[statusColor[assessment]])}>
            {loading && (
              <div className="text-center">
                <Spinner animation="grow" variant="primary" />
              </div>
            )}

            <img src={`data:image/svg+xml;base64,${base64data}`} alt="" />

            <div className="d-flex align-items-center mt-1">
              <img alt="logo" src={LogoBlack} />
              <div className={css.divider} />
              <p className={classNames('small-sub-font', css.smallSubFont)}>
                {t('certificationCode')}
              </p>
            </div>
          </div>

          <Button
            disabled={false}
            className={classNames('mt-4 mb-2')}
            text="Go to Waiting Room"
            loading={loading}
            onClick={goNext}
          />
        </div>
      </div>

      {unsigned && unsigned.length > 0 && unsigned[unsignedIndex].title && (
        <Modal
          show
          closeButton
          handleClose={() => setVisible(false)}
          title={unsigned[unsignedIndex].title}
          footerComponent={
            <div className="d-flex flex-column flex-sm-row align-items-md-center justify-content-between w-100">
              <Checkbox
                selected={agree}
                handleChange={() => setAgree(!agree)}
                label={t('clickToAgree')}
                labelColor={!agree && '#777777'}
              />
              <div className="mt-3">
                <Button2 variant="outline-primary w-100" onClick={bottomScroll}>
                  Scroll To Bottom
                </Button2>
              </div>
              <div className="mt-3">
                {loadingModal ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  <Button2
                    variant="primary w-100"
                    disabled={!agree}
                    onClick={lastUnsigned ? hipaaApproved : nextUnsigned}
                  >
                    {lastUnsigned ? 'OK' : 'NEXT'}
                  </Button2>
                )}
              </div>
            </div>
          }
        >
          <div id="scroll-div" className="hippa-container">
            <p className="norma-font">
              <Markdown source={unsigned[unsignedIndex].release_text} />
            </p>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
    vaxAppointments: state.vax.appointments,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ScanContainer);
