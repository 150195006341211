import React from 'react';
import { useHistory } from 'react-router-dom';
import AuraLogo from '../assets/image/aura_logo.png';
import css from './AddNewUserWelcome.module.css';

const AuraNewuserWelcome = () => {
  const history = useHistory();

  setTimeout(() => {
    history.push('/profile');
  }, 2000);

  return (
    <div className={css.container}>
      <div className={css.welcomeText}>Welcome To</div>
      <img src={AuraLogo} alt="aura logo" className={css.auraLogo} />
    </div>
  );
};

export default AuraNewuserWelcome;
