import {
  FAMILY_MEMBERS,
  SET_QRCODE,
  SET_SELECTED_FAMILY_MEMBERS,
  SET_PRIMARY_FAMILY_MEMBERS,
} from '../types';

export function setFamilyMembers(payload) {
  return {
    type: FAMILY_MEMBERS,
    payload,
  };
}

export function setSelectedFamilyMember(payload) {
  return {
    type: SET_SELECTED_FAMILY_MEMBERS,
    payload,
  };
}

export function setPrimaryFamilyMember(payload) {
  return {
    type: SET_PRIMARY_FAMILY_MEMBERS,
    payload,
  };
}

export function setQRCode(payload) {
  console.log('setQRCode', payload);
  return {
    type: SET_QRCODE,
    payload,
  };
}
