import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, StrokeButton, Alert } from '../../components';
import css from './AssignInsurance.module.css';
import {
  getUserData,
  getUserFamilyMembers,
  getUserFamilyMembersFullData,
} from '../../api/models/user';
import InsuranceShield from '../../assets/svg/insurance-shield-outline.svg';
import CirclePlus from '../../assets/svg/circle-plus.svg';
import ButtonComponent from '../../components/Button/Button';
import Modal from 'react-bootstrap/Modal';
import FamilyIcon from '../../assets/image/aura_family_icon_full.png';
import AddNewInsuranceForm from './AddNewInsuranceForm';
import Toaster from '../../components/Toaster/Toaster';
import InsurancePolicyList from './InsurancePolicyList';

import { addFamilyMemberStepList } from '../../utils/sidebarSteps';

const AssignInsurance = (props) => {
  let { id } = useParams();
  if (!id) {
    id = props.user.id;
  }

  const history = useHistory();
  const [insuranceData, setInsuranceData] = useState([]);
  const [showAlert, setShowAlert] = useState({ show: false });
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [backModal, setBackModal] = useState(false);
  const navVaccine = props.user && props.user.attributes && props.user.attributes['nav-vaccines'];
  const [newInsuranceForm, setNewInsuranceForm] = useState(false);
  const [selectedRelationsList, setSelectedRelationsList] = useState([]);
  const [familyMemberDetails, setFamilyMemberDetails] = useState([]);

  function hideAlert() {
    setShowAlert({ show: false });
  }

  const loadData = async () => {
    const data = await getUserData(props.user.id, props.authentication.jwt);
    setInsuranceData(
      data.included.filter(
        (item) =>
          item.type === 'insurance-policies' || item.type === 'family-members-insurance-policies'
      )
    );
  };

  async function getFamily() {
    const family = (await getUserFamilyMembers(props.user.id, props.authentication.jwt)).included;
    setName(family?.find((item) => item.id == id)?.attributes['first-name']);
    setLastName(family?.find((item) => item.id == id)?.attributes['last-name']);

    const familyMemberData = (
      await getUserFamilyMembersFullData(props.user.id, props.authentication.jwt)
    ).included;
    setFamilyMemberDetails(familyMemberData?.find((item) => item.id == id)?.attributes);
  }

  useEffect(() => {
    loadData();
    getFamily();
  }, []);

  useEffect(() => {
    const root = document?.getElementById('root');
    root?.scrollTo(0, 0);
    window?.scrollTo(0, 0);
  }, []);

  const navInsurance = props?.user?.attributes?.['requires-insurance'];
  const navVaccines = props?.user?.attributes?.['nav-vaccines'];
  const stepList = addFamilyMemberStepList(!navInsurance, !navVaccines);

  return (
    <>
      {!newInsuranceForm && (
        <Toaster
          icon={FamilyIcon}
          show
          header="Welcome to the family!"
          sub_header={`${name} ${lastName} added`}
        />
      )}
      <Layout
        onClickBack={() => {
          newInsuranceForm ? setNewInsuranceForm(false) : setBackModal(true);
        }}
        progressBar
        progressBarStepsList={stepList}
        progressBarCurrentStep={3}
        headerTitle="Insurance Information"
        tab="family"
        back
      >
        {newInsuranceForm ? (
          <AddNewInsuranceForm
            familyMemberId={id}
            setNewInsuranceForm={setNewInsuranceForm}
            loadData={loadData}
            familyMemberDetails={familyMemberDetails}
          />
        ) : (
          <>
            <Alert
              open={showAlert.show}
              handleClose={hideAlert}
              message={showAlert.message}
              type={showAlert.type}
            />
            <div className={css.container}>
              <div className={css.flex1}>
                {name && (
                  <h3 className={css.titleText}>
                    Assign an insurance(s) for {name} or add new insurance carrier
                  </h3>
                )}
                <div className={css.subContainer}>
                  {insuranceData
                    // filter duplicates out
                    // .filter(
                    //   (v, i, a) =>
                    //     a.findIndex(
                    //       (t) =>
                    //         t.attributes["policy-number"] ===
                    //         v.attributes["policy-number"]
                    //     ) === i
                    // )
                    .map((item, index) => {
                      const includesItem = selectedPlans.includes(item);
                      const insuranceId = item.id;
                      return (
                        <div key={index} className={css.policyItem}>
                          <InsurancePolicyList
                            item={item}
                            includesItem={includesItem}
                            insuranceId={insuranceId}
                            name={name}
                            selectedPlans={selectedPlans}
                            setSelectedPlans={setSelectedPlans}
                            id={id}
                            userId={props.user.id}
                            selectedRelationsList={selectedRelationsList}
                            setSelectedRelationsList={setSelectedRelationsList}
                            jwt={props.authentication.jwt}
                            setShowAlert={setShowAlert}
                          />
                        </div>
                      );
                    })}

                  <Modal
                    show={backModal}
                    onHide={() => setBackModal(false)}
                    backdrop="static"
                    keyboard={false}
                    style={{
                      borderRadius: '30px',
                      border: 'none',
                      marginTop: '10%',
                    }}
                  >
                    <div className={css.backModalContainer}>
                      <div>
                        <img src={FamilyIcon} className={css.backModalIcon} alt="home_icon" />
                      </div>
                      <div className={css.modalTextContainer}>
                        <div className={css.modalHeader}>
                          <p className={css.modalHeaderTitle}>Welcome to the family!</p>
                        </div>
                        <div className={css.modalBodyText}>
                          Your new Family Member has successfully been added. Please remember to add
                          their insurance and vaccination information soon.
                        </div>
                      </div>
                    </div>
                    <div className={css.exitModalButtonContainer}>
                      <StrokeButton
                        title="Stay"
                        className={css.stayModalButton}
                        handlePress={() => setBackModal(false)}
                      />
                      <StrokeButton
                        title="Exit this Page"
                        className={css.exitModalButton}
                        handlePress={() => history.push(`/family`)}
                      />
                    </div>
                  </Modal>

                  <div className={css.header}>
                    <img src={InsuranceShield} alt="Assign Insurance" />
                    <div className={css.headerRight}>
                      <p className={css.headerText}>Have another insurance to add?</p>
                      <StrokeButton
                        title="Add an Insurance"
                        icon={CirclePlus}
                        className={css.addInsuranceButton}
                        handlePress={() => setNewInsuranceForm(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.buttonContainer}>
                  {selectedPlans.length === 0 ? (
                    <StrokeButton
                      title="Not Insured"
                      height={50}
                      handlePress={() => {
                        if (navVaccine) {
                          if (id != props?.user?.id) {
                            history.push(`/vaccine-information/${id}`, {
                              progressBar: true,
                              notInsured: true,
                            });
                          } else history.push(`/vaccine-information/${id}/newUser`);
                        } else if (id != props?.user?.id) history.push('/family');
                        else history.push('/profile');
                      }}
                    />
                  ) : null}
                  <ButtonComponent
                    text="Apply"
                    height={50}
                    className={
                      selectedPlans.length > 0 && selectedRelationsList.length > 0
                        ? css.saveButton
                        : selectedPlans.length > 0 && selectedRelationsList.length === 0
                        ? css.saveButtonDisabledWide
                        : css.saveButtonDisabled
                    }
                    onClick={() => {
                      if (selectedPlans.length > 0 && selectedRelationsList.length > 0) {
                        if (navVaccine) {
                          if (id != props?.user?.id) {
                            history.push(`/vaccine-information/${id}`, {
                              progressBar: true,
                            });
                          } else history.push(`/vaccine-information/${id}/newUser`);
                        } else if (id != props?.user?.id) history.push('/family');
                        else history.push('/profile');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userInsurance: state.user.insurance[0],
});

export default connect(mapStateToProps)(AssignInsurance);
