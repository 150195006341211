import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import PrintProvider, { NoPrint } from 'react-easy-print';

import {
  TestingPage,
  ProfilePage,
  ScanPage,
  ResultsPage,
  MapPage,
  LoginPage,
  GoogleLogin,
  Signup,
  AccountInformation,
  InsuranceInformation,
  VaccineInformation,
  EditAccountInformation,
  EditInsuranceInformation,
  EditVaccineInformation,
  Certification,
  ScreeningQuestions,
  Schedule,
  ForgotPassword,
  ResetPassword,
  Contacts,
  Documents,
  AddContact,
  TestKits,
  ActivateKit,
  KitInstructions,
  CollectingInstructions,
  FamilyPage,
  VaccinePage,
  VaccineNotice,
  VaccinePass,
  VaccineCard,
  VaccineElgible,
  VaccineScan,
  VaccineDocs,
  VaccineSchedule,
  VaccineSite,
  VaccineWait,
  TechSupport,
  FamilyHistory,
  Activation,
  PromoCode,
  CollectingSample,
  CollectingConsent,
  Instructions,
} from '../containers';
import IsInsured from '../containers/InsuranceInformation/IsInsured';
import { getStoredData, clearCache } from '../api/cache';
import { USER_CACHE_NAME, USER_CONTACT_KEY } from '../api/const';
import { authentication, logout } from '../store/action/auth';
import {
  setUser,
  setUserInsurance,
  setUserProfile,
  clearUser,
  setUserPaymentMethod,
} from '../store/action/user';
import { Loading } from '../components';
import { clearIntervalByKey } from '../api/geolocation';
import AtHomeActivateKit from '../containers/TestKits/AtHomeActivateKit';
import { getPaymentMethod, getUserData, getUserDataProfile } from '../api/models/user';
import Confirmation from '../containers/Schedule/Confirmation';
import QRCode from '../containers/QRCode/QRCode';
import AddInsurance from '../containers/InsuranceInformation/AddInsurance';
import DailySymptomChecker from '../containers/DailySymptomChecker/DailySymptomChecker';
import DailySymptomCheckerHistory from '../containers/DailySymptomChecker/DailySymptomCheckerHistory';
import MyOrganization from '../containers/Organization/MyOrganization';
import SignDoc from '../containers/Documents/SignDoc';
import AssignInsurance from '../containers/InsuranceInformation/AssignInsurance';
import MyTests from '../containers/MyTests/MyTests';
import UploadTest from '../containers/MyTests/UploadTest';
import SelectFamilyMember from '../containers/TestKits/SelectFamilyMember';
import AddFamilyMemberForTestKitActivation from '../containers/Family/AddFamilyMemberForTestKitActivation';
import ActivationFlowActivateKit from '../containers/TestKits/ActivationFlowActivateKit';
import Eligibility from '../containers/Eligibility/Eligibility';
import NotEligible from '../containers/Eligibility/NotEligible';
import ActivationInsurance from '../containers/TestKits/ActivationInsurance';
import RegistrationIneligible from '../containers/Eligibility/RegistrationIneligible';
import NoCostTesting from '../containers/Eligibility/NoCost';
import FamilyInsuranceCheck from '../containers/InsuranceInformation/FamilyInsuranceCheck';
import UsabilityKitTracking from '../containers/TestKits/UsabilityKitTracking';
import UsabilityKitRegistration from '../containers/TestKits/UsabilityKitRegistration';
import AuraNewuserWelcome from '../containers/AddNewUserWelcome';
import MedicalID from '../containers/MedicalID/MedicalID';

const Routes = (props) => {
  const {
    status,
    loading,
    setAuthenticationDispatch,
    setUserDispatch,
    setUserInsuranceDispatch,
    setUserProfileDispatch,
    logoutDispatch,
    clearUserDispatch,
    setUserPaymentMethodDispatch,
    user,
  } = props;

  let idleTimer;

  useEffect(() => {
    checkLoginUser();
  }, [status]);

  const displayHeatMap = user && user.attributes && user.attributes.heatmap;
  const navTesting = user && user.attributes && user.attributes['nav-testing'];
  const navScan = user && user.attributes && user.attributes['nav-scan'];
  const navResults = user && user.attributes && user.attributes['nav-results'];
  const navDocuments = user && user.attributes && user.attributes['nav-documents'];
  const navMyAura = user && user.attributes && user.attributes['nav-my-aura'];
  const navTestKit = user && user.attributes && user.attributes['nav-test-kit'];
  const healthWallet = user && user.attributes && user.attributes['health-wallet'];
  const navFamily = user && user.attributes && user.attributes['nav-family-groups'];
  const navVaccine =
    user &&
    user.attributes &&
    (user.attributes['nav-vaccines'] || user.attributes['manual-vaccine-upload']);
  const canEditProfile = user && user.attributes && user.attributes['can-edit-profile'];
  const requireHealthReport = user && user.attributes && user.attributes['health-report'];

  const isUnder13 = user && user.attributes && user.attributes['under-13'] === true;
  const showModalUnder13 =
    user && user.attributes && user.attributes['under-13-signed'] !== true && isUnder13;
  const under13Text = (user && user.attributes && user.attributes['under-13-text']) || '';

  // useEffect(() => {
  //   window.onunload = (e) => {
  //     logOut();
  //   }
  // }, [])

  async function checkLoginUser() {
    const user = await getStoredData(USER_CACHE_NAME);
    if (user && user.status === 'login') {
      // setupPush(user);
      try {
        const profile = await getUserData(user.user_id, user.jwt);
        const userProfile = await getUserDataProfile(user.user_id, user.jwt);
        const paymentMethod = await getPaymentMethod(user.user_id, user.jwt);

        setUserProfileDispatch(userProfile);
        setUserDispatch(profile.data);
        setUserInsuranceDispatch(profile.included || []);
        setAuthenticationDispatch({ ...user, loading: false });
        setUserPaymentMethodDispatch(paymentMethod.included || []);
        return;
      } catch (error) {
        console.log('error login', error);
        logOut();
      }
    } else {
      logOut();
      setAuthenticationDispatch({ loading: false, status });
    }
  }

  if (loading) {
    return <Loading />;
  }

  async function logOut() {
    await clearCache(USER_CACHE_NAME);
    await clearCache(USER_CONTACT_KEY);
    clearUserDispatch();
    clearIntervalByKey('locationTimeout'); // to clear setInterval save user location
    logoutDispatch();
  }

  function onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', idleTimer.getRemainingTime())
  }

  function onAction(e) {
    // console.log('user did something', e)
  }

  function onIdle(e) {
    // console.log('user is idle', e)
    // console.log('last active', idleTimer.getLastActiveTime())
    logOut();
  }

  return (
    <>
      {status === 'login' && (
        <IdleTimer
          ref={(ref) => {
            idleTimer = ref;
          }}
          element={document}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={1000 * 60 * 10}
        />
      )}
      <Router>
        {status === 'login' ? (
          <PrintProvider>
            <NoPrint>
              <Switch>
                {navTesting && (
                  <Route exact path="/">
                    <Redirect to="/profile" />
                  </Route>
                )}
                {navTesting && <Route exact path="/testing" component={TestingPage} />}
                {navScan && <Route exact path="/scan" component={ScanPage} />}
                {navResults && <Route exact path="/results" component={ResultsPage} />}
                {navResults && <Route exact path="/my-tests/upload-test" component={UploadTest} />}
                {healthWallet && <Route exact path="/my-medical-id" component={MedicalID} />}
                <Route exact path="/my-tests" component={MyTests} />
                {displayHeatMap && <Route exact path="/map" component={MapPage} />}
                <Route exact path="/new-user-welcome" component={AuraNewuserWelcome} />
                <Route exact path="/profile" component={ProfilePage} />
                <Route exact path="/account-information" component={AccountInformation} />
                <Route exact path="/new-user-insurance" component={IsInsured} />
                <Route exact path="/insurance-information" component={InsuranceInformation} />
                <Route exact path="/insurance-information/:id" component={InsuranceInformation} />
                <Route exact path="/add-insurance/:id" component={AddInsurance} />
                <Route
                  exact
                  path="/vaccine-information/:id/:newUser?"
                  component={VaccineInformation}
                />
                <Route exact path="/vaccine-information" component={VaccineInformation} />
                {canEditProfile && (
                  <Route
                    exact
                    path="/edit-account-information"
                    component={EditAccountInformation}
                  />
                )}
                <Route
                  exact
                  path="/edit-insurance-information"
                  component={EditInsuranceInformation}
                />
                <Route exact path="/edit-vaccine-information" component={EditVaccineInformation} />
                <Route exact path="/certification" component={Certification} />
                <Route exact path="/screening-questions" component={ScreeningQuestions} />
                {navTesting && <Route exact path="/schedule/:id/:name" component={Schedule} />}
                {navTesting && <Route exact path="/schedule" component={Schedule} />}
                {navTesting && <Route exact path="/confirmation" component={Confirmation} />}
                <Route exact path="/contacts" component={Contacts} />
                {navDocuments && <Route exact path="/documents" component={Documents} />}
                {navDocuments && <Route exact path="/documents/:id" component={Documents} />}
                {requireHealthReport && (
                  <Route exact path="/daily-symptom-checker" component={DailySymptomChecker} />
                )}
                {requireHealthReport && (
                  <Route exact path="/daily-symptom-checker/:id" component={DailySymptomChecker} />
                )}
                {requireHealthReport && (
                  <Route
                    exact
                    path="/daily-symptom-checker-history"
                    component={DailySymptomCheckerHistory}
                  />
                )}
                {requireHealthReport && (
                  <Route
                    exact
                    path="/daily-symptom-checker-history/:id"
                    component={DailySymptomCheckerHistory}
                  />
                )}
                {/* <Route exact path="/my-org" component={MyOrganization} /> */}
                <Route exact path="/add-contact" component={AddContact} />
                <Route exact path="/sign-doc/:id" component={SignDoc} />
                <Route exact path="/assign-insurance/:id" component={AssignInsurance} />
                {/* {navTestKit && <Route exact path="/testkits" component={TestKits} />}
                {navTestKit && <Route exact path="/activate/:testKitId" component={ActivateKit} />}
                {navTestKit && <Route exact path="/instructions/:testKitId" component={CollectingInstructions} />}
                {navTestKit && <Route exact path="/kit-instructions" component={KitInstructions} />} */}
                <Route exact path="/testkits" component={TestKits} />
                <Route exact path="/activate/:testKitId" component={ActivateKit} />
                <Route exact path="/activate-unassisted" component={AtHomeActivateKit} />
                <Route exact path="/instructions/:testKitId" component={CollectingInstructions} />
                <Route exact path="/kit-instructions" component={KitInstructions} />
                <Route exact path="/tech-support" component={TechSupport} />
                <Route exact path="/qr-code" component={QRCode} />
                {navFamily && <Route exact path="/results-history/:id" component={FamilyHistory} />}
                <Route exact path="/activate/:testKitId" component={ActivateKit} />
                {/* non-usability */}
                <Route
                  exact
                  path="/activate-unassisted/:familyMemberId"
                  component={ActivationFlowActivateKit}
                />
                {/* usability */}
                <Route
                  exact
                  path="/kit-registration/:familyMemberId"
                  component={UsabilityKitRegistration}
                />
                {/* usability */}
                <Route
                  exact
                  path="/kit-tracking/:familyMemberId"
                  component={UsabilityKitTracking}
                />
                <Route
                  exact
                  path="/select-family-member-activation"
                  component={SelectFamilyMember}
                />
                <Route
                  exact
                  path="/addfamilymemberactivation"
                  component={AddFamilyMemberForTestKitActivation}
                />
                <Route
                  exact
                  path="/addfamilymemberactivationinsurance"
                  component={AddFamilyMemberForTestKitActivation}
                />
                <Route
                  exact
                  path="/familyinsurancecheck/:familyMemberId"
                  component={FamilyInsuranceCheck}
                />
                <Route exact path="/instructions/:testKitId" component={CollectingInstructions} />
                <Route exact path="/kit-instructions" component={KitInstructions} />
                <Route exact path="/collecting-sample" component={CollectingSample} />
                <Route exact path="/collecting-consent" component={CollectingConsent} />
                <Route
                  exact
                  path="/activation-insurance/:familyMemberId"
                  component={ActivationInsurance}
                />
                <Route exact path="/no-cost-testing" component={Eligibility} />
                <Route exact path="/ineligible" component={NotEligible} />
                <Route exact path="/ineligible-hrsa" component={RegistrationIneligible} />
                <Route exact path="/nocosttesting/:familyMemberId" component={NoCostTesting} />
                <Route exact path="/instructions" component={Instructions} />
                {navFamily && <Route exact path="/family" component={FamilyPage} />}
                {navVaccine && <Route exact path="/vaccine" component={VaccinePage} />}
                {navVaccine && <Route exact path="/vaccinenotice" component={VaccineNotice} />}
                {navVaccine && <Route exact path="/vaccineelgible" component={VaccineElgible} />}
                {navVaccine && (
                  <Route exact path="/vaccinewait/:waitTime" component={VaccineWait} />
                )}

                {navFamily && <Route exact path="/family" component={FamilyPage} />}
                {navVaccine && <Route exact path="/vaccine" component={VaccinePage} />}
                {navVaccine && <Route exact path="/vaccinenotice" component={VaccineNotice} />}
                {navVaccine && <Route exact path="/vaccineelgible" component={VaccineElgible} />}
                {navVaccine && (
                  <Route exact path="/vaccinewait/:waitTime" component={VaccineWait} />
                )}

                {navVaccine && <Route exact path="/vaccinedocs" component={VaccineDocs} />}
                {navVaccine && <Route exact path="/vaccinescan" component={VaccineScan} />}
                {navVaccine && <Route exact path="/vaccinecard" component={VaccineCard} />}
                {navVaccine && <Route exact path="/vaccinepass" component={VaccinePass} />}
                {navVaccine && <Route exact path="/vaccinesite" component={VaccineSite} />}
                {navVaccine && <Route exact path="/vaccineelgible" component={VaccineElgible} />}

                {navVaccine && <Route exact path="/vaccineschedule" component={VaccineSchedule} />}
                {navVaccine && (
                  <Route
                    exact
                    path="/vaccineschedule/:service/:medtest"
                    component={VaccineSchedule}
                  />
                )}

                {navTesting ? (
                  <Route path="*">
                    <Redirect to="/profile" />
                  </Route>
                ) : (
                  <Route path="*">
                    <Redirect to="/profile" />
                  </Route>
                )}
              </Switch>
            </NoPrint>
          </PrintProvider>
        ) : (
          <Switch>
            <Route exact path="/activation" component={Activation} />
            <Route exact path="/promo-code" component={PromoCode} />
            <Route path="/" exact component={LoginPage} />
            <Route path="/activation-login" exact component={LoginPage} />
            <Route exact path="/login/:brand" component={GoogleLogin} />
            <Route path="/signup/:cid" component={Signup} />
            <Route path="/signup" component={Signup} />
            <Route path="/activation-signup" component={Signup} />
            <Route path="/activation-signup/:cid" component={Signup} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        )}
      </Router>
    </>
  );
};

const mapStateTopProps = (state) => ({
  loading: state.authentication.loading,
  status: state.authentication.status,
  user: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
  setUserProfileDispatch(payload) {
    dispatch(setUserProfile(payload));
  },
  setUserPaymentMethodDispatch(payload) {
    dispatch(setUserPaymentMethod(payload));
  },
  logoutDispatch() {
    dispatch(logout());
  },
  clearUserDispatch() {
    dispatch(clearUser());
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(Routes);
