import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Layout, Button } from '../../components';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

const Wait = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { history } = props;

  const [waitTime, setWaitTime] = useState(15 * 60);

  const [resetRequested, setResetRequested] = useState(false);
  const [timerIsComplete, setTimerIsComplete] = useState(false);
  const [logMilliseconds, setLogMilliseconds] = useState(true);
  const [timerTime, setTimerTime] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setTimerTime((+waitTime || 15) * 60);
  }, []);

  function onComplete(status) {
    setTimerIsComplete(status);
    // history.push('/vaccine');
  }

  function onReset() {
    setResetRequested(false);
  }

  function timerValue(value) {
    if (logMilliseconds) {
      // console.log(value);
    }
  }

  function onResetRequest() {
    setResetRequested(true);
  }

  async function goNext() {
    history.push('/vaccine');
  }

  const renderTime = (dimension, time) => (
    <div className={css.timeWrapper}>
      <div className={css.timeText}>{time}</div>
      <div className={css.timerText}>Minutes</div>
      <div className={css.timerText}>Remaining</div>
    </div>
  );
  return (
    <Layout
      tab={activeTab}
      headerTitle={t('checkInWaitingRoom')}
      onClickBack={() => history.goBack()}
      back
    >
      <div className={css.subContainer}>
        <div className={css.timerContainer}>
          <CountdownCircleTimer
            isPlaying
            size={320}
            strokeWidth={20}
            colors={[['#ff9900']]}
            trailColor="#777"
            duration={waitTime}
            onComplete={(totalElapsedTime) => setDisabled(false)}
            children={({ remainingTime }) => {
              const minutes = Math.floor(remainingTime / 60);
              const seconds = remainingTime % 60;

              return renderTime('Minutes', `${minutes}:${seconds}`);
            }}
          />
        </div>

        <Button
          disabled={disabled}
          className={classNames('mt-4 mb-2')}
          text="Done Waiting"
          onClick={goNext}
        />
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Wait);
