import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import MaskedInput from 'react-text-mask';
import { useParams, useHistory } from 'react-router-dom';
import css from './TestKits.module.css';
import { Alert, Checkbox, Layout, ListForm, Modal, TextField } from '../../components';
import { connect } from 'react-redux';
import { InstructionList, InstructionVideo } from '..';

import { useTranslation } from 'react-i18next';

const CollectingInstructions = (props) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const [showAlert, setShowAlert] = useState({ show: false });
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isShippingInfoVisible, setShippingInfoVisible] = useState(false);
  const [acknowledgedCollection, setAcknowledgedCollection] = useState(false);
  const [returnTrackingNumber, setReturnTrackingNumber] = useState();
  const [specimenCode, setSpecimenCode] = useState();
  const [collectionDate, setCollectionDate] = useState();
  const { testKitId } = useParams();
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;
  const history = useHistory();

  function hideAlert() {
    setShowAlert({ show: false });
  }

  return (
    <Layout tab={activeTab} headerTitle="GENETWORx COVID-19 Nasal Swab Test Kit Instructions">
      <Alert
        className="mb-"
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.instructionsContainer}>
        <div className="mx-2 my-4">
          <div className={css.tableContainer}>
            <h4 className="medium-font mb-4">{t('yourKitActivated')}</h4>
            <h5>{t('yourTestKit')}</h5>
            <h4 className="medium-font mb-4 pt-4">{t('readAllDirections')}</h4>
            {window.location.hostname.endsWith('discoverearly.auratracker.org') ? null : (
              <InstructionList />
            )}
          </div>
          {window.location.hostname.endsWith('discoverearly.auratracker.org') ? null : (
            <div className={css.videoContainer}>
              <InstructionVideo />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

export default connect(mapStateToProps)(CollectingInstructions);
