export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const AUTHENTICATION = 'AUTHENTICATION';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const RESULTS = 'RESULTS';
export const SET_SELECTED_RESULTS = 'SET_SELECTED_RESULTS';
export const SET_USER_CONTACTS = 'SET_USER_CONTACTS';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const ADD_CONTACTS = 'ADD_CONTACTS';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const SET_USER_INSURANCE = 'SET_USER_INSURANCE';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SET_APPOINTMENT = 'SET_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_SELECTED_RESULTS = 'SEARCH_SELECTED_RESULTS';
export const SET_USER_TEST_KIT = 'SET_USER_TEST_KIT';
export const SET_USER_TEST_KITS = 'SET_USER_TEST_KITS';
export const FAMILY_MEMBERS = 'FAMILY_MEMBERS';
export const SET_SELECTED_FAMILY_MEMBERS = 'SET_SELECTED_FAMILY_MEMBERS';
export const SET_PRIMARY_FAMILY_MEMBERS = 'SET_PRIMARY_FAMILY_MEMBERS';
export const SET_QRCODE = 'SET_QRCODE';
export const QR_CODE = 'QR_CODE';

export const SET_USER_PAYMENT_METHOD = 'SET_USER_PAYMENT_METHOD';
export const SET_VAX_APPOINTMENTS = 'SET_VAX_APPOINTMENTS';
export const SET_VAX_HISTORY = 'SET_VAX_HISTORY';
