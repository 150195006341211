import { SET_ALERT, CLEAR_ALERT } from '../types';

const initialState = {
  status: '',
  text: '',
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return { ...state, ...action.payload };
    case CLEAR_ALERT:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
