import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Header } from '../../components';
import CollectingSampleNew from './CollectingSampleNew';
import { isUsability } from '../../api/const';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    flex: '1 1 auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(10),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
    },
  },
  descriptionList: {
    listStyleType: 'disc',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  description: {
    fontSize: 24,
    letterSpacing: -0.408,
    fontFamily: 'SF Pro Display',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      letterSpacing: -0.408,
    },
  },
  activateButton: {
    backgroundColor: '#FF9900',
    '&:hover': {
      backgroundColor: '#FF9900',
    },
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flex: 2,
    },
  },
  cancelButton: {
    border: '1.25px solid #A3A3A3',
    flex: 1,
    [theme.breakpoints.down('xs')]: {},
  },
  button: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    letterSpacing: -0.408,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',
    },
  },
}));

const descriptions = [
  'Test Kits may only be registered once and should only be registered right before collecting sample.',
  'Please collect your sample on a weekday, Monday through Friday, and plan for same-day FedEx pickup before you start.',
  'Do not ship your package late Friday evening or over the weekend.',
  'The pre-addressed FedEx return pack can be returned via calling FedEx to arrange a pick-up or by dropping it in a FedEx drop box.',
  <>
    Visit{' '}
    <a href="https://bit.ly/fedexdropbox" target="_blank" rel="noreferrer">
      bit.ly/fedexdropbox
    </a>{' '}
    online to view FedEx drop box locations and pickup schedules.
  </>,
];

const CollectingSample = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  const handleOnSubmit = () => {
    history.push('/collecting-consent', {
      selectedMember: state?.selectedMember,
      selectedMemberName: state?.selectedMemberName,
      kit: state?.kit,
    });
  };

  const handleOnCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!state?.selectedMember) {
      history.push('/select-family-member-activation');
    }
  }, [history, state?.selectedMember]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Header title="Collecting Sample" />
        <div className={classes.body}>
          <div>
            <Typography className={classes.title}>
              Please read the instructions below carefully before proceeding:
            </Typography>
            <ul className={classes.descriptionList}>
              {descriptions.map((desc) => (
                <li className={classes.description}>{desc}</li>
              ))}
            </ul>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              className={classNames(classes.button, classes.activateButton)}
              onClick={handleOnSubmit}
            >
              Register Now
            </Button>
            <Button
              className={classNames(classes.button, classes.cancelButton)}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const CollectingSampleMain = () => (isUsability ? <CollectingSampleNew /> : <CollectingSample />);

export default CollectingSampleMain;
