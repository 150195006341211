import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';

import moment from 'moment';
import Image from 'react-bootstrap/Image';

import { Layout, Alert, StrokeButton, CommonLogo } from '../../components';
import css from './EditVaccineInformation.module.css';
import { processAPI, processAPIDelete } from '../../api/models/process';
import { uploadFile } from '../../api/models/forms';
import { setUser } from '../../store/action/user';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input/Input';
import SelectInput from '../../components/SelectInput/SelectInput';
import { vaccineSchema } from '../../utils/constraints';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getUserData } from '../../api/models/user';

const EditVaccineInformation = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = props.user;

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const [files, setFiles] = useState([]);
  const [availableVaccines, setAvailableVaccines] = useState([]);

  const [vaccinations, setVaccinations] = useState([]);
  const [vaccinationIds, setVaccinationIds] = useState([]);
  const [photoUrl, setPhotoUrl] = useState('');

  // Whether we are are editing
  const [editing, setEditing] = useState(false);
  // Which vaccination we are editing
  const [editingId, setEditingId] = useState(null);
  // Which vaccination we are viewing
  const [viewingId, setViewingId] = useState(null);

  const [singleDoseVaccineId, setSingleDoseVaccineId] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [viewingShow, setViewingShow] = useState(false);

  const handleViewingClose = () => setViewingShow(false);
  const handleViewingShow = () => setViewingShow(true);

  const [isImageError, setIsImageError] = useState(false);

  // Retrieve list of available vaccines (Moderna, Pfizer, etc)
  // vaccines - array of
  //      - id - "1"
  //      - name - "Moderna"
  function getAvailableVaccines() {
    const { jwt } = props.userAuthentication;
    processAPI({
      endpoint: 'all-vaccines',
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        let vaccines = data?.vaccines;
        setAvailableVaccines(vaccines);
        let janssen = vaccines.find((obj) => obj.name === 'Janssen COVID-19 Vaccine');
        setSingleDoseVaccineId(janssen?.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function getVaccinationById(id) {
    const { jwt } = props.userAuthentication;
    const data = await processAPI({
      endpoint: `vaccinations/${id}`,
      methodType: 'GET',
      key: jwt,
    });
    return data;
  }

  function getPreviousVaccinations() {
    const { jwt } = props.userAuthentication;
    processAPI({
      endpoint: `vaccinations`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        // For each vaccination add to state, along with id
        setVaccinations([]);
        setVaccinationIds([]);
        data.forEach((vaccination) => {
          setVaccinations((vaccinations) => [...vaccinations, vaccination.attributes]);
          setVaccinationIds((ids) => [...ids, vaccination.id]);
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function createOrUpdateVaccination(vaccinationDate, lotNumber, vaccineId, vaccinationId) {
    const { jwt } = props.userAuthentication;
    const myData = {
      data: {
        type: 'vaccinations',
        attributes: {
          'administered-at': moment(vaccinationDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          'lot-number': lotNumber,
          'manual-upload': true,
        },
        relationships: {
          user: {
            data: { type: 'users', id: parseInt(id) },
          },
          vaccine: {
            data: { type: 'vaccines', id: parseInt(vaccineId) },
          },
        },
      },
    };

    const shouldCreate = !vaccinationId;
    if (!shouldCreate) {
      myData.data.id = vaccinationId;
    }
    const data = await processAPI({
      key: jwt,
      methodType: shouldCreate ? 'POST' : 'PATCH',
      data: myData,
      endpoint: shouldCreate ? 'vaccinations' : `vaccinations/${vaccinationId}`,
    });
    return data;
  }

  useEffect(() => {
    setLoading(true);
    getAvailableVaccines();
    getPreviousVaccinations();
  }, []);

  async function vaccinationSubmit(values) {
    setLoading(true);
    let meta;
    try {
      if (files?.length > 0) {
        setIsImageError(false);
        meta = await getFileNameURL();
        await uploadFile(meta.url, files[0], meta.fields);
        const VaccineResponse = await createOrUpdateVaccination(
          values?.doseDate,
          values?.lotNumber,
          values?.vaccineType,
          vaccinationIds[editingId]
        );
        if (VaccineResponse?.id) {
          await saveFileName(meta?.fields?.key, VaccineResponse?.id);
          await loadUser();
          let curVaxData = await getVaccinationById(VaccineResponse.id);
          if (curVaxData.attributes['valid-vax-card']) {
            getPreviousVaccinations();
            setEditing(false);
            setShowAlert({
              show: true,
              message: 'Save successfully',
              type: 'success',
            });
          } else {
            autoDeleteVaccination(curVaxData.id);
            setFiles([]);
            setShowAlert({
              show: true,
              message:
                'Your image could not be read. Please upload a clear image of your vaccination card.',
              type: 'error',
            });
          }
        } else {
          setShowAlert({ show: true, message: 'Failed', type: 'error' });
        }
      } else {
        setIsImageError(true);
      }
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }

    setLoading(false);
  }

  async function getFileNameURL() {
    const { jwt } = props.userAuthentication;

    const preSigned = {
      filename: files[0].name,
      image_type: files[0].type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'pre-signed-post',
    });
    return data;
  }

  async function saveFileName(fileName, vaccinationId) {
    const { jwt } = props.userAuthentication;

    const data = await processAPI({
      key: jwt,
      methodType: 'PATCH',
      data: {
        filename: fileName,
        imageable_id: vaccinationId,
        imageable_type: 'Vaccination',
      },
      endpoint: 'image',
    });

    return data;
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  async function loadUser() {
    const user = await getUserData(props.userAuthentication.user_id, props.userAuthentication.jwt);
    props.setUserDispatch(user.data);
  }

  function addVaccination() {
    setFiles([]);
    handleViewingShow();
    setEditing(true);
    setEditingId(vaccinations.length);
  }

  function editVaccination(index) {
    handleViewingShow();
    setEditing(true);
    setEditingId(index);
  }

  async function deleteVaccinationById(id) {
    try {
      const { jwt } = props.userAuthentication;
      const response = await processAPIDelete({
        endpoint: `vaccinations/${id}`,
        methodType: 'DELETE',
        key: jwt,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteVaccination(index) {
    if (window.confirm('Are you sure you want to delete this vaccination record?')) {
      await deleteVaccinationById(vaccinationIds[index]);
      getPreviousVaccinations();
    } else {
      // Do nothing
    }
  }

  async function autoDeleteVaccination(id) {
    const { jwt } = props.userAuthentication;
    processAPI({
      endpoint: `vaccinations/${id}`,
      methodType: 'DELETE',
      key: jwt,
    });
  }

  async function viewVaccination(index) {
    setViewingId(index);
    await getPhoto(index).then(() => {
      handleShow();
    });
  }

  async function getPhoto(index) {
    return new Promise((resolve, reject) => {
      const { jwt } = props.userAuthentication;
      processAPI({
        endpoint: `vaccinations/${vaccinationIds[index]}/vaccine-cards`,
        methodType: 'GET',
        key: jwt,
      })
        .then((data) => {
          setPhotoUrl(data[0]?.attributes?.url);
          resolve();
        })
        .catch((error) => console.log(error));
    });
  }

  function ListEntry(vax, index) {
    return (
      <tr style={{ justifyContent: 'space-between' }} key={index}>
        <td>{vax.name}</td>
        <td>{vax['administered-at'] && moment(vax['administered-at']).format('MM/DD/YYYY')}</td>
        <td>Lot #{vax['lot-number'] || ''}</td>
        {/* <td>{vax['valid-vax-card'] ? "Valid" : "Invalid"}</td> */}
        <td align="right">
          {/* <Button type="submit" disabled={loading} className="pl-8 mb-4 mt-2" onClick={() => editVaccination(index)}>
            {t('edit')}
      </Button> */}
          <Button
            type="submit"
            disabled={loading}
            className="mb-4 mt-2 ml-2"
            onClick={() => viewVaccination(index)}
          >
            {t('view')}
          </Button>
          <Button
            type="submit"
            disabled={loading}
            className="mb-4 mt-2 ml-2"
            onClick={() => deleteVaccination(index)}
          >
            {t('delete')}
          </Button>
        </td>
      </tr>
    );
  }

  function VaccinationForm(vax, index) {
    const DatePickerField = ({ ...props }) => {
      const { setFieldValue } = useFormikContext();
      const [field] = useField(props);
      return (
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
        />
      );
    };

    return (
      <Formik
        key={index}
        initialValues={{
          vaccineType: (vax && vax['vaccine-id']) || '',
          doseDate:
            (vax &&
              vax['administered-at'] &&
              moment(vax['administered-at']).format('MM/DD/YYYY')) ||
            '',
          lotNumber: (vax && vax['lot-number']) || '',
          id: vaccinationIds[index],
        }}
        validationSchema={vaccineSchema}
        onSubmit={vaccinationSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form className={css.auraForm} autoComplete="off">
            <CommonLogo />
            <div className="insurance-form-container">
              <div className="insurance-form-sub-containers">
                <Field
                  name="vaccineType"
                  label="Vaccine Type *"
                  component={SelectInput}
                  classNamePrefix="form-select"
                  placeholder="Vaccine Type"
                  options={availableVaccines.map((v) => ({
                    key: v.id,
                    value: v.id,
                    label: v.name,
                  }))}
                />
                <label className="datePickerLabel" style={{ fontSize: '.85rem', opacity: '.5' }}>
                  Dose Date
                </label>
                <DatePickerField
                  name="doseDate"
                  required
                  label="Dose Date"
                  wrapperClassName="datePicker"
                />
                <Field
                  name="lotNumber"
                  label="Lot Number"
                  placeholder="Lot Number"
                  component={Input}
                  type="text"
                />
              </div>
            </div>
            {files && files.length > 0 && <p className="thin-normal-font my-2">{files[0].name}</p>}
            <label htmlFor="cardImage" className={classNames('my-4', css.customFileUpload)}>
              {t('uploadVaccineCard')}
            </label>
            <input
              id="cardImage"
              accept="image/*"
              title="&nbsp;"
              type="file"
              multiple
              name="cardImage"
              className={isImageError ? css.inputfileerror : css.inputfile}
              onChange={(e) => {
                setFiles(e.target.files);
              }}
            />

            {isImageError && (
              <p style={{ color: 'red', fontSize: '.8rem', fontWeight: '600' }}>
                Vaccination card upload required
              </p>
            )}
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              <Button
                type="submit"
                disabled={loading}
                className="submit-vaccine-button"
                style={{
                  background: 'rgba(255, 153, 0, 0.8)',
                  border: '1.25px solid #FF9900',
                  borderRadius: '14px',
                  width: '326px',
                  color: 'black',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                {loading ? <Spinner animation="border" variant="secondary" /> : t('submit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  return (
    <Layout
      onClickBack={() => history.goBack()}
      headerTitle={t('editVaccineInformation')}
      tab="vaccine"
      back
    >
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.subContainer}>
            {!loading && (
              <div>
                <StrokeButton
                  className="mb-4 mt-4 ml-2 add-vaccine-button"
                  style={{ width: '175px' }}
                  title={t('Add Vaccine')}
                  handlePress={() => addVaccination()}
                />
                <table className={css.vaxTable}>
                  <tbody>{vaccinations.map((vax, index) => ListEntry(vax, index))}</tbody>
                </table>
                {!vaccinations.length && (
                  <p>You currently have no vaccines uploaded. Please upload vaccines in order.</p>
                )}
              </div>
            )}
          </div>
        </div>
        {/* Adding a Vaccination */}
        {editing && (
          <Modal show={viewingShow} onHide={handleViewingClose}>
            <Modal.Body>
              {vaccinations
                ? VaccinationForm(vaccinations[editingId], editingId)
                : VaccinationForm({}, 0)}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleViewingClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {/* Viewing Vaccination */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{vaccinations?.[viewingId]?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li>
                <b>Type: </b>
                {vaccinations?.[viewingId]?.name}
              </li>
              <li>
                <b>Administered: </b>
                {vaccinations?.[viewingId]?.['administered-at'] &&
                  moment(vaccinations?.[viewingId]?.['administered-at']).format('MM/DD/YYYY')}
              </li>
              <li>
                <b>Lot Number: </b>
                {vaccinations?.[viewingId]?.['lot-number']}
              </li>
              <Image style={{}} src={photoUrl} fluid />
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userAuthentication: state.authentication,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(EditVaccineInformation);
