import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Alert, Checkbox, Divider, Layout, StrokeButton } from '../../components';
import css from './DailySymptomChecker.module.css';
import { useParams } from 'react-router';
import { getUserData, getUserFamilyMembers, userSymptoms } from '../../api/models/user';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { symptoms } from '../../api/models/symptoms';
import { authentication } from '../../store/action/auth';
import { useTranslation } from 'react-i18next';
import { resultsMedTest } from '../../api/models/medTest';
import History from '../../assets/svg/symptom-history-button.svg';
import AddNewSymptom from '../../assets/svg/add-new-symptom.svg';
import Close from '../../assets/svg/close.svg';
import { Modal } from 'react-bootstrap';
import { capitalize } from '../../api';

const DailySymptomChecker = (props) => {
  const { t, i18n } = useTranslation();
  const { user, history } = props;
  let { id } = useParams();
  if (!id) {
    id = props.user.id;
  }
  const { pathname } = history.location;
  const activeTab = 'dailySymptomChecker';
  const [list, setList] = useState([]);
  const [family, setFamily] = useState([]);
  const [familyMemberResults, setFamilyMemberResults] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [checkedSymptoms, setCheckedSymptoms] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });

  async function loadUser() {
    const us = await getUserData(props.authentication.user_id, props.authentication.jwt);
    return us.data;
  }

  const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x.attributes[key]] = rv[x.attributes[key]] || []).push(x);
      return rv;
    }, {});
  };

  const loadData = async () => {
    const symptomsResults = await symptoms(props.authentication.jwt);
    setList(symptomsResults);
    const userData = await getStoredData(USER_CACHE_NAME);
    console.log('symptomsResults', symptomsResults);
    let familyMemberList =
      (await getUserFamilyMembers(userData.user_id, userData.jwt)).included || [];
    const user = await loadUser();
    familyMemberList.unshift(user);
    setFamily(familyMemberList);
    let results =
      (await userSymptoms({
        key: props.authentication.jwt,
        methodType: 'GET',
      })) || [];
    let groupedResults = groupBy(results, 'user-id');
    console.log('results', groupedResults);
    setFamilyMemberResults(groupedResults);
  };

  function updateList(id, selected, symptomId) {
    const newList = [...list];
    const index = newList.findIndex((item) => item.id === id);

    newList[index] = { ...newList[index], selected };
    setList(newList);

    let data = checkedSymptoms;
    const indexUpdatedList = data.findIndex((item) => item.id == id);

    // postSymptoms(userAuth.jwt, userAuth['user_id'], id);
    // !selected &&  symptomId && symptomId > -1 && deleteSymptoms(userAuth.jwt, symptomId);

    if (indexUpdatedList > -1) {
      data.splice(indexUpdatedList, 1);
    } else {
      data.push({ id, symptomId, selected });
    }

    setCheckedSymptoms(data);
  }

  async function postSymptoms(key, userId, symptomId) {
    const data = {
      data: {
        type: 'user-symptoms',
        attributes: {
          'user-id': userId,
          'symptom-id': symptomId,
        },
      },
    };

    await userSymptoms({ data, key, methodType: 'POST' });
  }

  async function deleteSymptoms(key, symptomId) {
    const data = {
      data: {
        type: 'user-symptoms',
        id: symptomId,
      },
    };

    await userSymptoms({
      data,
      key,
      methodType: 'DELETE',
      endpoint: symptomId,
    });
  }

  async function multiplePost() {
    try {
      const userData = await getStoredData(USER_CACHE_NAME);
      const postData = checkedSymptoms.filter((item) => item.selected);
      const deleteData = checkedSymptoms.filter((item) => !item.selected);
      setLoadingUpdate(true);
      await Promise.all(
        postData.map(async (item) => {
          await postSymptoms(userData.jwt, selectedMember.id, item.id);
        })
      );

      await Promise.all(
        deleteData.map(async (item) => {
          await deleteSymptoms(userData.jwt, item.symptomId);
        })
      );
      setLoadingUpdate(false);
      setCheckedSymptoms([]);
      setShowAddModal(false);
      setShowAlert({
        show: true,
        message: 'Save successfully',
        type: 'success',
      });
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Layout
      headerTitle="Daily Symptoms Checker"
      onClickBack={() => history.goBack()}
      tab={activeTab}
      back
    >
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <h3 className={css.title}>Today {moment().format('MM/DD/YYYY')}</h3>
        <h5 className={css.subTitle}>{t('logSymptomsText')}</h5>
        <div className={css.familyGrid}>
          {family.map(({ attributes, id }, index) => {
            let symptoms = familyMemberResults[id] || [];
            console.log('symptoms', symptoms);
            let latest = new Date(
              Math.max(...symptoms.map((e) => new Date(e.attributes['date-reported'])))
            );
            console.log('latest', latest);
            return (
              <div
                className={
                  index !== 0
                    ? `${css.familyCard}`
                    : `${css.familyCard} ${css.familyCard} ${css.familyCardPrimary}`
                }
              >
                <div className={css.familyCardTop}>
                  <div className={css.familyCardLeft}>
                    <h4 className={css.name}>
                      {attributes['first-name']} {attributes['last-name']}
                    </h4>
                    {index == 0 ? (
                      <div>
                        <h5 className={css.primaryAccount}>Primary Account Holder</h5>
                      </div>
                    ) : (
                      <div>
                        <h5 className={css.dob}>
                          DOB: {moment(attributes['date-of-birth']).format('M/D/yy').toString()}
                        </h5>
                      </div>
                    )}
                    <h5 className={css.dob}>{symptoms.length} Items</h5>
                    {moment(latest).isValid() ? (
                      <h5 className={css.dob}>Last Entry {moment(latest).format('MM/DD/YY')}</h5>
                    ) : (
                      <h5 className={css.dob}>No Symptoms Logged</h5>
                    )}
                  </div>
                </div>
                <div className={css.familyCardButtonContainer}>
                  <img
                    src={History}
                    className={css.historyButton}
                    alt="history"
                    onClick={() => {
                      history.push(`/daily-symptom-checker-history/${id}`);
                    }}
                  />
                  <img
                    src={AddNewSymptom}
                    className={css.addNewSymptomButton}
                    onClick={() => {
                      setSelectedMember({ attributes, id });
                      setShowAddModal(true);
                    }}
                    alt="add new symptom"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        className={css.modal}
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
      >
        <Modal.Header className={css.modalHeader}>
          <img
            src={Close}
            alt="close"
            className={css.closeButton}
            onClick={() => {
              console.log('we clicled');
              setShowAddModal(false);
            }}
          />
          <p className={css.modalTitle}>
            {selectedMember?.attributes?.['first-name']}'s Daily Symptoms Checker
          </p>
          <Divider />
        </Modal.Header>
        <Modal.Body className={css.modalBody}>
          <div className="p-3">
            <p className={css.modalTitle}>Date: {moment().format('L')}</p>
            <p className={css.modalSubtitle}>
              {'Please check any symptoms\n you have at this moment'}
            </p>
          </div>
          <Divider />
          <div className={css.symptomsList}>
            {list.map((item) => (
              <div className={css.symptomItem} key={item.id}>
                <div className={css.symptomItemLeft}>
                  <Checkbox
                    label={capitalize(item.attributes.name)}
                    selected={item.selected}
                    handleChange={() => updateList(item.id, !item.selected, item.symptomId)}
                    labelColor={!item.selected && '#777777'}
                  />
                </div>
                <Divider />
              </div>
            ))}
            <div className="my-3">
              <StrokeButton
                title="Submit Health Report"
                disabled={checkedSymptoms.length < 1}
                handlePress={multiplePost}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  authentication: state.authentication,
});

export default connect(mapStateToProps)(DailySymptomChecker);
