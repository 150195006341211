import { AUTHENTICATION, LOGOUT } from '../types';

export function authentication(payload) {
  return {
    type: AUTHENTICATION,
    payload,
  };
}

export function logout(payload) {
  return {
    type: LOGOUT,
  };
}
