import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, DataList, StrokeButton } from '../../components';
import css from './AccountInformation.module.css';
import { useTranslation } from 'react-i18next';
import { genderObject, raceObject, ethnicityObject } from '../../utils/utils';
import moment from 'moment';
import EditPencil from '../../assets/svg/edit_profile_pencil.svg';
import { logout } from '../../store/action/auth';
import { clearCache } from '../../api/cache';
import { USER_CACHE_NAME, USER_CONTACT_KEY } from '../../api/const';
import { clearUser } from '../../store/action/user';
import { clearIntervalByKey } from '../../api/geolocation';
import SignOut from '../../assets/svg/sign_out.svg';

const AccountInformation = (props) => {
  const history = useHistory();
  const { attributes: userData, id } = props.user;
  const { userProfile } = props;
  console.log('userProfile', props);
  const { t, i18n } = useTranslation();
  const [middleName, setMiddleName] = useState('');

  async function signOut() {
    history.push('/');
    await clearCache(USER_CACHE_NAME);
    await clearCache(USER_CONTACT_KEY);
    props.clearUserDispatch();
    clearIntervalByKey('locationTimeout'); // to clear setInterval save user location
    props.logoutDispatch();
    console.log('signOut');
    history.push('/');
  }

  useEffect(() => {
    console.log(props.user);
    userData['middle-name'] == null
      ? console.log('no middle name')
      : setMiddleName(userData['middle-name']);
  }, []);

  const name = {
    name: t('name'),
    value: `${userData?.['first-name']} ${middleName} ${userData?.['last-name']}`,
  };

  const data = [
    {
      name: t('email'),
      value: userData?.email,
    },
    {
      name: t('phone'),
      value: userProfile?.attributes['phone-number'],
    },
    {
      name: t('dob'),
      value: moment(userProfile?.attributes['date-of-birth']).format('MM/DD/YYYY'),
    },
    {
      name: t('age'),
      value: moment().diff(userProfile?.attributes['date-of-birth'], 'years', false),
    },
    {
      name: t('gender'),
      value: userProfile?.attributes.gender && genderObject[userProfile?.attributes.gender],
    },
    {
      name: 'Ethnicity',
      value:
        userProfile?.attributes.ethnicity && ethnicityObject[userProfile?.attributes.ethnicity],
    },
    {
      name: 'Race',
      value: userProfile?.attributes.race && raceObject[userProfile?.attributes.race],
    },
  ];

  return (
    <Layout
      onClickBack={() => history.push('/profile')}
      headerTitle="My Account Information"
      tab="account"
      back
    >
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.subContainer}>
            <div className={css.header}>
              <p className={css.name}>{name.value}</p>
              <p className={css.primaryAccount}>Primary Account Owner</p>
            </div>
            <div className={css.contentContainer}>
              {data.map((item, index) => {
                const { name, value } = item;
                return <DataList title={name} description={value} key={index} />;
              })}
            </div>
          </div>
        </div>
        <div className={css.flex1} />
      </div>

      <div className={`${css.container} mt-0 mt-lg-4`}>
        <div className={css.flex1}>
          <div className={css.subContainer} style={{ border: 'none', marginTop: '-30px' }}>
            {userData['can-edit-profile'] && (
              <StrokeButton
                title="Edit Profile"
                icon={EditPencil}
                className={css.editProfileButton}
                handlePress={() => history.push('/edit-account-information')}
              />
            )}
            <div className={css.signOut} onClick={() => signOut()}>
              <img className={css.signOutImage} src={SignOut} alt="sign out" />
              {t('signOut')}
            </div>
          </div>
        </div>

        <div className={css.flex1} />
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutDispatch() {
    dispatch(logout());
  },
  clearUserDispatch() {
    dispatch(clearUser());
  },
});

const mapStateToProps = (state) => ({
  user: state.user.profile,
  userProfile: state.user.userProfile[0],
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
