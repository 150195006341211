import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import css from './TestKits.module.css';
import { connect } from 'react-redux';
import { setUserTestKits } from '../../store/action/user';
import Chance from 'chance';
import { isUsability } from '../../api/const';
import { Checkbox, withStyles } from '@material-ui/core';

const TestKitCard = (props) => {
  const [selectedKit, setKit] = useState();

  const CustomCheck = withStyles({
    root: {
      '&$checked': {
        '& .MuiIconButton-label': {
          position: 'relative',
          zIndex: 0,
        },
        '& .MuiIconButton-label:after': {
          content: '""',
          left: 4,
          top: 4,
          height: 15,
          width: 15,
          position: 'absolute',
          backgroundColor: 'black',
          zIndex: -1,
        },
      },
    },
    checked: {},
  })(Checkbox);
  let paymentMethodLabel;
  let paymentMethodText;
  let paymentMethodLabelColor;
  const ordered = props?.kit?.attributes.status === 'ordered';
  let chance = new Chance();
  const assignedPatient = `${chance.first()} ${chance.last()}`;
  switch (props?.kit?.attributes?.['payment-method']) {
    case 'C':
      paymentMethodLabel = 'Cash';
      paymentMethodLabelColor = '#78DA76';
      paymentMethodText = 'Acquired with Cash Payment';
      break;
    case 'I':
      paymentMethodLabel = 'Insurance';
      paymentMethodLabelColor = '#F8C820';
      paymentMethodText = isUsability && `Acquired through Insurance for ${assignedPatient}`;
      break;
    case 'H':
      paymentMethodLabel = 'HRSA';
      paymentMethodLabelColor = '#EBEBEB';
      paymentMethodText = isUsability && `Acquired through HRSA for ${assignedPatient}`;
      break;
    default:
      paymentMethodLabel = 'Cash';
      paymentMethodLabelColor = '#78DA76';
      paymentMethodText = 'Acquired with Cash Payment';
      break;
  }
  let cardHeaderText = isUsability
    ? 'GENETWORx COVID-19 Nasal Swab Test'
    : props?.kit?.attributes?.['test-type'] == 'COVIDDIRECT'
    ? 'PCR Test Kit'
    : props?.kit?.attributes?.['test-type'];

  return (
    <>
      <Card
        style={selectedKit && selectedKit !== props?.kit?.id ? { opacity: '0.5' } : {}}
        onClick={() => {
          if (ordered) {
            setKit(props?.kit?.id);
          }
        }}
        key={props?.kit?.id}
        className={selectedKit === props?.kit?.id ? css.testKitCardSelected : css.testKitCard}
      >
        <div className={css.testKitCardHeader}>
          <div className={css.testKitCardHeaderLeft}>
            <div className={css.testKitCardHeaderText}>
              <p className={css.testKitCardTitle}>{cardHeaderText}</p>
              {props?.kit?.attributes?.['return-tracking'] ? (
                <p className={css.testKitCardSubtitleBlue}>
                  TK {props?.kit?.attributes?.['return-tracking']}
                </p>
              ) : (
                !isUsability &&
                props?.kit?.attributes['order-date'] && (
                  <p className={css.testKitCardSubtitle}>
                    Order Date{' '}
                    {new Date(
                      props?.kit?.attributes['order-date'] ||
                        new Date(props?.kit?.attributes['created-at'])
                    ).toLocaleDateString()}
                  </p>
                )
              )}
              {isUsability && props?.kit?.attributes.status === 'activated' && (
                <p className={css.testKitCardSubtitle}>
                  Registered on{' '}
                  {new Date(
                    props?.kit?.attributes['registered-on'] || props?.kit?.attributes['created-at']
                  ).toLocaleDateString()}
                </p>
              )}
            </div>

            {/* <div className={css.testKitCardOrderDate}>
                            Ordered Date:{" "}
                            {moment()
                              .subtract(Math.floor(Math.random() * 10), "days")
                              .format("MM/DD/YYYY")}
                          </div> */}
          </div>
          <div className={css.testKitCardHeaderRight}>
            <CustomCheck
              checked={selectedKit === props?.kit?.id}
              onChange={({ target: { checked } }) => setKit(checked ? props?.kit?.id : null)}
              style={{
                visibility: ordered ? 'visible' : 'hidden',
                color: 'rgba(255, 153, 0)',
              }}
            />
          </div>
        </div>
        <div className={css.testKitCardBody}>
          <div className={css.testKitCardBodyLeft}>
            <div className={css.testKitCardBodyLeftText}>{paymentMethodText}</div>
          </div>
          <div className={css.testKitCardBodyRight}>
            <div
              className={css.testKitCardBodyRightText}
              style={{ backgroundColor: paymentMethodLabelColor }}
            >
              {paymentMethodLabel}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TestKitCard);
