import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME, isUsability } from '../../api/const';
import { getUserData, getUserFamilyMembers } from '../../api/models/user';
import { Checkbox, StrokeButton } from '../../components';
import ButtonComponent from '../../components/Button/Button';
import {
  setSelectedFamilyMember as setSelectedFamilyMemberAction,
  setPrimaryFamilyMember as setPrimaryFamilyMemberAction,
} from '../../store/action/family';
import css from './SelectFamilyMember.module.css';
import { useHistory } from 'react-router';

function SelectFamilyMember(props) {
  const {
    paymentMethod,
    promoCode,
    setSelectedFamilyMemberDispatch,
    setPrimaryFamilyMemberDispatch,
  } = props;
  const [familyMembers, setFamilyMembers] = useState([]);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [selectedMember, setSelectedFamilyMember] = useState(null);
  const [selectedMemberName, setSelectedFamilyMemberName] = useState(null);
  const history = useHistory();
  let kit = history?.location?.state?.kit;
  const selectedKit = paymentMethod.find((item) => item.id == kit);

  const checkCashFlow = ({ attributes }) =>
    (attributes['payment-method'] === 'C' && attributes.status === 'ordered') ||
    !attributes['payment-method'];
  const checkInsurance = ({ attributes }) =>
    attributes['payment-method'] === 'I' && attributes.status === 'ordered';
  const checkHRSA = ({ attributes }) =>
    attributes['payment-method'] === 'H' && attributes.status === 'ordered';
  useEffect(() => {
    loadData();
  }, []);
  async function loadData() {
    const userDataCache = await getStoredData(USER_CACHE_NAME);
    setUser(userDataCache);
    let familyMemberList =
      (await getUserFamilyMembers(userDataCache.user_id, userDataCache.jwt)).included || [];
    setFamilyMembers(familyMemberList);
    let userData = await getUserData(userDataCache.user_id, userDataCache.jwt);
    setUserData(userData);
    setPrimaryFamilyMemberDispatch(userData);
  }
  const primaryDob = userData?.data?.attributes?.['date-of-birth'];
  const primaryAge = moment().diff(moment(primaryDob), 'years');
  return (
    <div>
      <div className={css.header}>
        <h1 className={css.headerText}>Who is being tested?</h1>
      </div>
      <div className={css.body}>
        <div className={css.bodyText1}>
          <p>Will you or someone else be using the COVID-19 PCR Test Kit?</p>
          <br />
        </div>
        <div className={css.bodyText2}>
          <p>
            {isUsability
              ? 'This test may only be registered for use on individuals 2+ years old'
              : ' This test may only be activated for those 18 years and older'}
          </p>
        </div>
        <div>
          <div className={css.familyMemberListContainer}>
            <div
              className={css.familyMemberCardPrimary}
              onClick={() => {
                setSelectedFamilyMember(user?.user_id);
              }}
            >
              <div className={css.familyMemberCardLeft}>
                <p className={css.familyMemberCardTitle}>
                  Myself ({userData?.data?.attributes?.['first-name']}{' '}
                  {userData?.data?.attributes?.['last-name']})
                </p>
                <p className={css.familyMemberCardSubtitle}>Primary Account Holder</p>
                <p className={css.familyMemberCardSubtitle2}>
                  DOB: {moment(primaryDob).format('MM/DD/YYYY')}
                  {(isUsability ? primaryAge < 2 : primaryAge < 18) && (
                    <span> &bull; Not Eligible for Test</span>
                  )}
                </p>
              </div>
              <div className={css.familyMemberCardRight}>
                <Checkbox
                  selected={selectedMember === user?.user_id}
                  handleChange={() => setSelectedFamilyMember(user?.user_id)}
                />
              </div>
            </div>
          </div>
          {familyMembers.map((familyMember) => {
            const dob = familyMember?.attributes?.['date-of-birth'];
            const age = moment().diff(moment(dob), 'years');
            return (
              <div
                className={css.familyMemberCard}
                key={familyMember.id}
                onClick={() => {
                  if (isUsability ? age < 2 : age < 18) {
                    alert(`You must be ${isUsability ? 2 : 18} years or older to use this test`);
                  } else {
                    setSelectedFamilyMember(familyMember?.id);
                    setSelectedFamilyMemberName(
                      `${familyMember?.attributes['first-name']} ${familyMember?.attributes['last-name']}`
                    );
                  }
                }}
              >
                <div className={css.familyMemberCardLeft}>
                  <p className={css.familyMemberCardTitle}>
                    {familyMember?.attributes['first-name']} {familyMember?.attributes['last-name']}
                  </p>
                  <p className={css.familyMemberCardSubtitle}>Family Member</p>
                  <p className={css.familyMemberCardSubtitle2}>
                    DOB: {moment(dob).format('MM/DD/YYYY')}
                    {(isUsability ? age < 2 : age < 18) && (
                      <span> &bull; Not Eligible for Test</span>
                    )}
                  </p>
                </div>
                <div className={css.familyMemberCardRight}>
                  {(isUsability ? age >= 2 : age >= 18) && (
                    <Checkbox
                      selected={selectedMember === familyMember?.id}
                      handleChange={() => {
                        setSelectedFamilyMember(familyMember?.id);
                        setSelectedFamilyMemberName(
                          `${familyMember?.attributes['first-name']} ${familyMember?.attributes['last-name']}`
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <StrokeButton
            title="Add A Family Member"
            className={css.addFamilyButton}
            handlePress={() => {
              if ((paymentMethod || []).find(checkCashFlow)) {
                history.push('/addfamilymemberactivation', { kit });
              } else if ((paymentMethod || []).find(checkCashFlow)) {
                history.push('/addfamilymemberactivationinsurance', {
                  kit,
                });
              } else {
                history.push('/addfamilymemberactivation', { kit });
              }
            }}
          />
          <ButtonComponent
            text="Continue"
            disabled={!selectedMember}
            onClick={() => {
              setSelectedFamilyMemberDispatch(
                familyMembers.find(({ id }) => id === selectedMember)
              );
              if (selectedKit) {
                switch (selectedKit.attributes['payment-method']) {
                  case 'C':
                    history.push('/collecting-sample', {
                      selectedMember,
                      selectedMemberName,
                      kit,
                    });
                    return;
                  case 'I':
                    history.push(`/activation-insurance/${selectedMember}`, {
                      selectedMember,
                      selectedMemberName,
                      kit,
                    });
                    return;
                  case 'H':
                    history.push(`/nocosttesting/${selectedMember}`, {
                      selectedMember,
                      selectedMemberName,
                      kit,
                    });
                    return;
                  default:
                    history.push('/collecting-sample', {
                      selectedMember,
                      selectedMemberName,
                      kit,
                    });
                    return;
                }
              }
              if (promoCode) {
                if (kit) {
                  history.push('/collecting-sample', {
                    selectedMember,
                    selectedMemberName,
                    kit,
                  });
                } else {
                  history.push('/collecting-sample', {
                    selectedMember,
                    selectedMemberName,
                  });
                }
              } else {
                console.log(paymentMethod);
                if ((paymentMethod || []).find(checkCashFlow)) {
                  if (!kit) {
                    kit = (paymentMethod || []).find(checkCashFlow)?.id;
                  }
                  history.push('/collecting-sample', {
                    selectedMember,
                    kit,
                    selectedMemberName,
                  });
                } else if ((paymentMethod || []).find(checkInsurance)) {
                  if (!kit) {
                    kit = (paymentMethod || []).find(checkInsurance)?.id;
                  }
                  if (kit) {
                    history.push(`/activation-insurance/${selectedMember}`, {
                      selectedMember,
                      kit,
                      selectedMemberName,
                    });
                  } else {
                    history.push(`/activation-insurance/${selectedMember}`, {
                      selectedMember,
                      selectedMemberName,
                    });
                  }
                } else if ((paymentMethod || []).find(checkHRSA)) {
                  if (!kit) {
                    kit = (paymentMethod || []).find(checkHRSA)?.id;
                  }
                  if (kit) {
                    history.push(`/nocosttesting/${selectedMember}`, {
                      selectedMember,
                      selectedMemberName,
                      kit,
                    });
                  } else {
                    history.push(`/nocosttesting/${selectedMember}`, {
                      selectedMember,
                      selectedMemberName,
                    });
                  }
                } else if (kit) {
                  history.push('/collecting-sample', {
                    selectedMember,
                    selectedMemberName,
                    kit,
                  });
                } else {
                  history.push('/collecting-sample', {
                    selectedMember,
                    selectedMemberName,
                  });
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ flow: { promoCode }, user: { paymentMethod } }) => ({
  promoCode,
  paymentMethod,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedFamilyMemberDispatch(payload) {
    dispatch(setSelectedFamilyMemberAction(payload));
  },
  setPrimaryFamilyMemberDispatch(payload) {
    dispatch(setPrimaryFamilyMemberAction(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFamilyMember);
