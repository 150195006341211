import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button, Spinner, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';

import MaskedInput from 'react-text-mask';
import css from './TestKits.module.css';
import moment from 'moment';
import {
  Alert,
  Checkbox,
  Layout,
  TextField,
  DashedButton,
  ListForm,
  Modal,
} from '../../components';
import { connect } from 'react-redux';
import { processAPI } from '../../api/models/process';
import { getUserTestKits, createUserTestKits } from '../../api/models/user';
import { validateKitInformation } from '../../api/validation';
import ScanIcon from '../../assets/svg/scan_icon.svg';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { animateScroll } from 'react-scroll';
import { setUserTestKits } from '../../store/action/user';

function TextMaskActivationCode(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/G/i, /X/i, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskCollectionDate(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /[0-2]/,
        /\d/,
        ':',
        /[0-5]/,
        /[0-9]/,
      ]}
      // mask={[/2/, /0/, /[2]/, /\d/, '-', /[01]/, /[\d]/, '-', /[0-3]/, /\d/, " ", /[0-2]/, /\d/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskTrackingNumber(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

const ActivateContainer = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const [specimenCode, setSpecimenCode] = useState('');
  const [collectionDate, setCollectionDate] = useState(
    moment(new Date()).format('MM/DD/YYYY HH:mm')
  );
  const [returnTrackingNumber, setReturnTrackingNumber] = useState();
  const [newTestKit, setNewTestKit] = useState([]);
  const [registeredTestKit, setRegisteredTestKit] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  //   const { testKitId } = useParams();
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;
  const history = useHistory();

  const { testkits } = props;
  const [unsigned, setUnsigned] = useState();
  const [unsignedIndex, setUnsignedIndex] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const [lastUnsigned, setLastUnsigned] = useState();
  const [hipaaModal, setHipaaModal] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [agree, setAgree] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { name: 'This kit was sent directly to my home', value: '1' },
    { name: 'This kit was sent to my office location', value: '2' },
  ];

  useEffect(() => {
    const kits = props.user.testkits;
    console.log(kits);
    // const kit = kits && kits.find(x => x.id === testKitId);
    // const us = kit && kit.attributes && kit.attributes['hipaa-release-details'].unsigned;
    // setUnsigned(us);

    // if(us){
    //   setLastUnsigned( unsignedIndex === (us.length - 1));
    // }
  }, [props.user]);

  //   useEffect(() => {
  //     unsigned && setLastUnsigned(unsignedIndex === (unsigned.length - 1));
  //   }, [unsignedIndex])

  function bottomScroll() {
    animateScroll.scrollToBottom({
      containerId: 'scroll-div',
    });
  }

  async function loadTestKits() {
    const { authentication } = props;
    const { jwt: key, user_id } = authentication;
    const response = await getUserTestKits(user_id, key);

    if (response && response.included && response.included[0]) {
      props.setUserTestKitsDispatch(response.included);
    }
  }

  //   async function hipaaApproved() {
  //     const { id } = props.user.profile;
  //     const { jwt } = props.authentication;

  //     setLoadingModal(true);

  //     await Promise.all(unsigned.map(async (item) => {
  //       const updatedData = {
  //         data: {
  //           type: 'user-hipaa-releases',
  //           attributes: {
  //             'medtest-kit-id': testKitId,
  //             'hipaa-release-id': item.id,
  //             'user-id': id
  //           },
  //         },
  //       };

  //       await processAPI({
  //         key: jwt,
  //         endpoint: 'user-hipaa-releases',
  //         methodType: 'POST',
  //         data: updatedData,
  //       })
  //     }));

  //     //loadTestKits();
  //     setUnsigned([]);
  //     setLoadingModal(false);
  //   }

  function nextUnsigned() {
    setAgree(false);
    setUnsignedIndex(unsignedIndex + 1);
  }

  async function createKit() {
    const { authentication } = props;
    const { jwt: key, user_id } = authentication;
    let result = await createUserTestKits(user_id, key);
    let id = result?.data?.id;
    return id;
  }

  async function onSubmit(event) {
    event.preventDefault();
    const kits = props.user.testkits;
    const { invalid, message } = validateKitInformation({
      specimenCode,
      collectionDate,
      returnTrackingNumber,
    });
    setValidation({ invalid, message });

    if (!invalid) {
      const thekit = kits?.find(
        (x) => x.attributes['specimen-code'] === specimenCode.toUpperCase()
      );
      if (thekit) {
        setShowAlert({
          show: true,
          message: t('registrationFailedActivation'),
          type: 'error',
        });
        return;
      }

      const testKitId = await createKit();
      const myData = {
        data: {
          id: parseInt(testKitId),
          type: 'medtest-kits',
          attributes: {
            'specimen-code': specimenCode.toUpperCase(),
            'collected-at': moment(collectionDate, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
            'return-tracking': returnTrackingNumber.replace(/\s+/g, '').toUpperCase(),
            status: 'shipped',
            'ship-to': radioValue === '1' ? 'user' : 'company',
          },
        },
      };

      setLoading(true);

      try {
        const data = await processAPI({
          key: props.authentication.jwt,
          methodType: 'PATCH',
          data: myData,
          endpoint: `medtest-kits/${parseInt(testKitId)}`,
        });

        if (data.id) {
          setShowAlert({
            show: true,
            message: t('registeredTestKit'),
            type: 'success',
          });
          history.push(`/instructions/${testKitId}`);
        } else {
          setShowAlert({
            show: true,
            message: t('registrationFailed'),
            type: 'error',
          });
        }
      } catch (error) {
        setShowAlert({
          show: true,
          message: t('registrationFailedContact'),
          type: 'error',
        });
      }

      setLoading(false);
    }
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  return (
    <Layout
      tab="testkits"
      headerTitle={t('kitActivation')}
      onClickBack={() => history.goBack()}
      back
    >
      <Alert
        className="mb-"
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <div>
          <div className={css.subContainer}>
            <Form style={{ height: '100%' }} className="d-flex flex-column" onSubmit={onSubmit}>
              <DashedButton
                className={classNames('align-self-center my-4', css.dashedButton)}
                title={t('scanBarcode')}
                icon={ScanIcon}
                handlePress={() => setShowScanner(!showScanner)}
              />

              {showScanner && (
                <div id="scanner" className="align-self-center mx-2">
                  <BarcodeScannerComponent
                    width={380}
                    onUpdate={(err, result) => {
                      if (result) {
                        setSpecimenCode(result.text);
                      }
                    }}
                  />
                </div>
              )}

              <div className={css.ortext}>
                <span>or</span>
              </div>

              <div className={classNames('py-4 mx-2')}>
                <ListForm
                  focused
                  required
                  title={t('activationCode')}
                  label={t('activationCode')}
                  inputcomponent={TextMaskActivationCode}
                  name="activationCode"
                  placeholder={t('enterBarcodeNumber')}
                  onChange={(e) => {
                    const kits = props.user.testkits;
                    const thekit = kits?.find(
                      (x) => x.attributes['specimen-code'] === e.target.value.toUpperCase()
                    );
                    if (thekit) {
                      setValidation({
                        invalid: 'specimenCode',
                        message: t('registrationFailedActivation'),
                      });
                      return;
                    }
                    setValidation({ invalid: '', message: '' });

                    setSpecimenCode(`${e.target.value}`);
                  }}
                  value={specimenCode}
                  error={invalid === 'specimenCode'}
                  errorMessage={errorMessage}
                  inputProps={{ 'data-testid': 'ACTKIT' }}
                />
                <ListForm
                  focused
                  required
                  id="collection-date"
                  label={t('collectionDate')}
                  inputcomponent={TextMaskCollectionDate}
                  name="collectionDate"
                  placeholder="mm/dd/yyyy hh:mm"
                  onChange={(e) => setCollectionDate(e.target.value)}
                  value={collectionDate}
                  error={invalid === 'collectionDate'}
                  errorMessage={errorMessage}
                  inputProps={{ 'data-testid': 'DATEKIT' }}
                />
                <ListForm
                  focused
                  required
                  id="trackingNumber"
                  label={t('returnTrackingNumber')}
                  inputcomponent={TextMaskTrackingNumber}
                  placeholder={t('enterTrackingNumber')}
                  name="trackingNumber"
                  onChange={(e) => setReturnTrackingNumber(`${e.target.value}`)}
                  value={returnTrackingNumber}
                  error={invalid === 'trackingNumber'}
                  errorMessage={errorMessage}
                  inputProps={{ 'data-testid': 'TRAKIT' }}
                />
                <br />
                {window.location.hostname.endsWith('discoverearly.auratracker.org') ? null : (
                  <ButtonGroup>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-dark"
                        // variant={idx % 2 ? 'outline-primary' : 'outline-secondary'}
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                )}
              </div>

              <Button
                data-testid="BTNKIT"
                disabled={loading}
                type="submit"
                className={classNames(css.btnActivate, 'align-self-center justify-content-end')}
              >
                {loading ? <Spinner animation="border" variant="warning" /> : t('activateKitAnd')}
              </Button>
            </Form>

            <Modal
              show={hipaaModal}
              closeButton
              handleClose={() => setVisible(false)}
              title="GENETWORx COVID-19 NASAL SWAB TEST KIT CONSENT  "
              footerComponent={
                <div className="d-flex flex-column flex-sm-row align-items-md-center justify-content-between w-100">
                  <Checkbox
                    selected={agree}
                    handleChange={() => setAgree(!agree)}
                    label={t('clickToAgree')}
                    labelColor={!agree && '#777777'}
                  />
                  <div className="mt-3" data-testid="SCROLLBTN">
                    <Button variant="outline-primary w-100" onClick={bottomScroll}>
                      Scroll To Bottom
                    </Button>
                  </div>
                  <div className="mt-3" data-testid="OKBTN">
                    {loadingModal ? (
                      <Spinner animation="border" variant="secondary" />
                    ) : (
                      <Button
                        variant="primary w-100"
                        disabled={!agree}
                        onClick={() => setHipaaModal(false)}
                        //   onClick={lastUnsigned ? hipaaApproved : nextUnsigned}
                      >
                        OK
                      </Button>
                    )}
                  </div>
                </div>
              }
            >
              <div id="scroll-div" className="hippa-container">
                <p className="norma-font">
                  {window.location.hostname.endsWith('discoverearly.auratracker.org') ? (
                    <Markdown
                      source={
                        'Please carefully read and accept the following Informed Consent:\n\nA. I am aware that information about the DiscoverEarly COVID-19 Nasal Swab Test Kit including Frequently Asked Questions and complete Instructions for Use are available on the DiscoverEarly website.\n\nB. I authorize RCA Laboratory Services, LLC d/b/a GENETWORx to perform laboratory testing services on the specimen I provide.\n\nC. I authorize GENETWORx to disclose the results of my GENETWORx COVID-19 Nasal Swab Test Kit to any county, state, or to other governmental entity as required by law.\n\nD. I understand that GENETWORx is not acting as my medical provider and that this testing does not replace treatment by my medical provider. I assume complete and full responsibility to seek medical advice and treatment from a medical provider regarding my test results if I receive a positive test result, if my symptoms or condition worsens, or if I have any questions or concerns.\n\nE. I acknowledge that a positive test result is an indication that I must self-isolate and/or wear a mask or face covering to reduce the risk of infecting others.\n\nF. I understand that, as with any medical test, there is the potential for a false positive or false negative COVID-19 test result.\n'
                      }
                    />
                  ) : (
                    <Markdown
                      source={
                        'Please carefully read and accept the following Informed Consent:\n\nA. I am aware that information about the GENETWORx COVID-19 Nasal Swab Test Kit including Frequently Asked Questions and complete Instructions for Use are available on the GENETWORx website.\n\nB. I authorize RCA Laboratory Services, LLC d/b/a GENETWORx to perform laboratory testing services on the specimen I provide.\n\nC. I authorize GENETWORx to disclose the results of my GENETWORx COVID-19 Nasal Swab Test Kit to any county, state, or to other governmental entity as required by law.\n\nD. I understand that GENETWORx is not acting as my medical provider and that this testing does not replace treatment by my medical provider. I assume complete and full responsibility to seek medical advice and treatment from a medical provider regarding my test results if I receive a positive test result, if my symptoms or condition worsens, or if I have any questions or concerns.\n\nE. I acknowledge that a positive test result is an indication that I must self-isolate and/or wear a mask or face covering to reduce the risk of infecting others.\n\nF. I understand that, as with any medical test, there is the potential for a false positive or false negative COVID-19 test result.\n'
                      }
                    />
                  )}
                </p>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateContainer);
