import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, FormControlLabel, Checkbox, Card } from '@material-ui/core';
import { Header, StrokeButton } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    position: 'relative',
    flex: '1 1 auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(10),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      letterSpacing: 0.8,
    },
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
  },
  subTitle2: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
      opacity: 0.4,
    },
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'unset',
    letterSpacing: -0.408,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
  checkboxWrapper: {
    alignSelf: 'center',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
      fontSize: 20,
      letterSpacing: 0.75,
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
    },
  },
  optionCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: theme.spacing(2),
    overflow: 'unset',
    border: '1px solid #E3E3E3',
  },
  optionCardSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: theme.spacing(2),
    overflow: 'unset',
    border: '1px solid #FF9900',
  },
  optionCardSymptomatic: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  yesNoBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  yesNoButton: {
    marginInline: theme.spacing(1),
    maxWidth: '10rem',
  },
  yesNoButtonSelected: {
    marginInline: theme.spacing(1),
    backgroundColor: '#FF9900',
    maxWidth: '10rem',
  },
}));

const CustomCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'black',
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);

const Eligibility = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const ref = useRef(null);
  const [option, setOption] = useState('');
  const [symptomatic, setIsSymptomatic] = useState('');
  const [contact, setIsContact] = useState('');
  const [testedPositive, setIsTestedPositive] = useState('');

  const handleOnSubmit = () => {
    if (symptomatic === 'yes' || contact === 'yes' || testedPositive === 'yes') {
      history.push('/collecting-sample', {
        selectedMember: state?.selectedMember,
        kit: state?.kit,
      });
    } else {
      history.push('/ineligible', {
        selectedMember: state?.selectedMember,
      });
    }
  };

  const handleOnScrollDown = () => {
    scrollToBottom();
  };

  const scrollToBottom = () => {
    ref.current?.scrollTo({
      left: 0,
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!state?.selectedMember) {
      history.push('/select-family-member-activation');
    }
  }, [history, state?.selectedMember]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={classes.root}>
      <Header title="Eligibility" />
      <div ref={ref} className={classes.body}>
        <Typography className={classes.subTitle}>
          Please note, to take this test as part of our No Cost test Program you must be
          symptomatic, exposed or recently tested positive for COVID-19 in order to take this test.
        </Typography>

        <Card
          className={option === 'symptomatic' ? classes.optionCardSelected : classes.optionCard}
        >
          <div className={classes.optionCardSymptomatic}>
            <Typography className={classes.subTitle}>
              Is this person currently experiencing any of the symptoms below?{' '}
            </Typography>
            <ul style={{ listStyleType: 'disc' }}>
              <li>Fever between 100.4°F and 102°F, feeling feverish, or feel warm to the touch</li>
              <li>New or worsening cough</li>
              <li>New or worsening sore throat</li>
              <li>
                Flu-like symtoms (chills, runny or stuffy nose, whole body aches, headache, and/or
                feeling tired)
              </li>
              <li>Shortness of breath that is not limiting the ability to speak</li>
              <li>New loss of taste or smell</li>
              <li>New nausea or vomiting </li>
              <li>New diarrhea</li>
            </ul>
            <div className={classes.yesNoBox}>
              <StrokeButton
                title="No"
                handlePress={() => setIsSymptomatic('no')}
                className={symptomatic == 'no' ? classes.yesNoButtonSelected : classes.yesNoButton}
              />
              <StrokeButton
                title="Yes"
                handlePress={() => setIsSymptomatic('yes')}
                className={symptomatic == 'yes' ? classes.yesNoButtonSelected : classes.yesNoButton}
              />
            </div>
          </div>
        </Card>
        <Card
          className={option === 'symptomatic' ? classes.optionCardSelected : classes.optionCard}
        >
          <div className={classes.optionCardSymptomatic}>
            <Typography className={classes.subTitle}>
              Have they recently been in contact with someone with COVID-19 or had an suspected
              exposure?{' '}
            </Typography>
            <div className={classes.yesNoBox}>
              <StrokeButton
                title="No"
                handlePress={() => setIsContact('no')}
                className={contact == 'no' ? classes.yesNoButtonSelected : classes.yesNoButton}
              />
              <StrokeButton
                title="Yes"
                handlePress={() => setIsContact('yes')}
                className={contact == 'yes' ? classes.yesNoButtonSelected : classes.yesNoButton}
              />
            </div>
          </div>
        </Card>
        <Card
          className={option === 'symptomatic' ? classes.optionCardSelected : classes.optionCard}
        >
          <div className={classes.optionCardSymptomatic}>
            <Typography className={classes.subTitle}>
              Have they tested positive for COVID-19 in the last 14 days?{' '}
            </Typography>
            <div className={classes.yesNoBox}>
              <StrokeButton
                title="No"
                handlePress={() => setIsTestedPositive('no')}
                className={
                  testedPositive == 'no' ? classes.yesNoButtonSelected : classes.yesNoButton
                }
              />
              <StrokeButton
                title="Yes"
                handlePress={() => setIsTestedPositive('yes')}
                className={
                  testedPositive == 'yes' ? classes.yesNoButtonSelected : classes.yesNoButton
                }
              />
            </div>
          </div>
        </Card>
        <Button
          fullWidth
          disabled={!testedPositive || !contact || !symptomatic}
          className={classNames(classes.submitButton)}
          onClick={handleOnSubmit}
        >
          Submit Eligibility
        </Button>
      </div>
    </div>
  );
};

export default Eligibility;
