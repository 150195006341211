import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { List, BigButton } from '..';
import css from './Card.module.css';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import History from './History';
import Calendar from './Calendar';
import Location from './Location1';
import InsuranceIcon from '../../assets/image/insurance_icon.png';
import DocumentIcon from '../../assets/image/documents_icon.png';
import LockIcon from '../../assets/image/myaccount_icon.png';
import SettingIcon from '../../assets/image/tech_support_icon.png';
import { useHistory, Link } from 'react-router-dom';

function Card({
  onClick,
  testCompleted,
  list,
  onClickManage,
  onClickResults,
  lastUpdated,
  nextAppointment,
  handleNextTest,
  days,
  requireHealthReport,
  lastTest,
  showSchedule,
  navTestDue,
  pendingResults,
  navResults,
  user,
}) {
  const { t, i18n } = useTranslation();
  const requireInsurance = user && user.attributes && user.attributes['requires-insurance'];
  const history = useHistory();

  const doesntHaveRequiredInfo =
    user &&
    user.attributes &&
    (user.attributes['has-required-information'] === null ||
      user.attributes['has-required-information'] === false);
  const convertFrom24To12Format = () => {
    let dt = new Date();
    let hours = dt.getHours(); // gives the value in 24 hours format
    let AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    let minutes = dt.getMinutes();
    let finalTime = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${AmOrPm}`;
    return finalTime;
  };
  const [showModal, setShowModal] = useState('');

  useEffect(() => {
    if (doesntHaveRequiredInfo) {
      setShowModal('requiredInformation');
    }
  }, [user]);
  return (
    <div className={css.superContainer}>
      <div className={css.othercontainer}>
        <div className={css.summaryContainer}>
          <div className={css.header}>
            <p className={css.summaryText}>{t('mySummary')}</p>
          </div>
          <div className={css.header}>
            {lastUpdated && (
              <p className="small-sub-font">{`${t('lastUpdated')} ${convertFrom24To12Format()}`}</p>
            )}
          </div>
        </div>
        <div className={css.testContainer}>
          <Link to="/my-tests">
            <History />
          </Link>
          <div className={css.testBox}>{testCompleted?.length || 0}</div>
          <div className={css.testText}>
            <div>{t('tests')}</div>
            <div>{t('completed')}</div>
          </div>
        </div>
        <div className={css.testDetailContainer}>
          <div className={css.testDetailSection}>
            <div className={css.testText}>
              {lastTest['medtest-type']
                ? `${lastTest['medtest-type']} : ${lastTest['test-result']}`
                : 'No tests yet'}
            </div>
            <div className={css.manageText}>Manage</div>
          </div>
          <div className={css.detailSection}>
            {lastTest['medtest-type'] ? (
              <>
                <div className={css.calendarSection}>
                  <Calendar />
                  <div className={css.calendarSectionDetail}>
                    <div className={css.textDetail}>
                      {lastTest['test-date']
                        ? moment(lastTest['test-date']).format('MM/DD/YYYY')
                        : 'Not Available'}
                    </div>
                    <div className={css.timeDetail}>
                      {lastTest['test-date']
                        ? moment(lastTest['test-date']).format('hh:mm A')
                        : 'Not Available'}
                    </div>
                  </div>
                </div>
                <div className={css.locationSection}>
                  <Location />
                  <div className={css.calendarSectionDetail}>
                    <div className={css.textDetail}>
                      {lastTest['test-location-name'] || 'Not Available'}
                    </div>
                    <div className={css.timeDetail}>
                      {lastTest['test-location'] || 'Not Available'}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={css.noTestsDetail} />
            )}
          </div>
          {/* {showSchedule && <p className={classNames("card-font", css.cardFontBlue)} onClick={onClickManage}>Manage</p>} */}
        </div>
        <div className="pb-2">
          {list?.map((item, index) => {
            const { appointmentTime, place, patientName, productNames } = item;
            const bigText = productNames
              ? `${productNames} - ${moment.utc(appointmentTime).format('LLLL')}`
              : moment.utc(appointmentTime).format('LLLL');
            const subText = place;
            return (
              <div onClick={() => handleNextTest(item)} key={`nt-${index}`}>
                <List
                  bigText={`${patientName} - ${bigText}`}
                  subText={subText}
                  subTextClassName={css.subText}
                  arrowIcon
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex  justify-content-between flex-parent">
        <div className="mt-4">
          <BigButton
            img={InsuranceIcon}
            text={t('myInsurance')}
            subText={t('info')}
            color="#F8C820"
            onClick={() => history.push('/insurance-information')}
          />
        </div>

        <div className="mt-4">
          <BigButton
            img={DocumentIcon}
            text={t('myDocuments')}
            color="#F5A860"
            onClick={() => history.push('/documents')}
          />
        </div>
      </div>
      <div className="d-flex  justify-content-between mt-4 flex-parent">
        <BigButton
          img={LockIcon}
          text={t('myAccount')}
          subText={t('Information')}
          color="#F3F3F3"
          onClick={() => history.push('/account-information')}
          requiresUpdates={doesntHaveRequiredInfo}
        />

        <BigButton
          img={SettingIcon}
          text={t('technical')}
          subText={t('support')}
          color="#BABABA"
          onClick={() => history.push('/tech-support')}
          requiresUpdates={doesntHaveRequiredInfo}
        />
      </div>
    </div>
  );
}

export default Card;
