import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { processAPI } from '../../api/models/process';
import { Layout } from '../../components';
import css from './MyOrganization.module.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const MyOrganization = (props) => {
  const history = useHistory();
  const [timeSpan, setTimeSpan] = useState('All');
  const [orgData, setOrgData] = useState({});

  const loadData = useCallback(async () => {
    const { authentication } = props;
    const { jwt: key } = authentication;
    const response = await processAPI({
      key,
      methodType: 'GET',
      endpoint: `company_reports?time_span=${timeSpan.toLowerCase()}`,
    });
    setOrgData(response);
  }, [setOrgData, props, timeSpan]);

  useEffect(() => {
    loadData();
  }, [timeSpan, loadData]);

  const data = {
    labels: [],
    datasets: [
      {
        label: '# of Votes',
        data: [
          orgData?.total_positive,
          orgData?.total_negative,
          orgData?.total_users - (orgData?.total_positive + orgData?.total_negative),
        ],
        backgroundColor: ['#78DA76', '#D23C3C', '#f2f2f2'],
      },
    ],
  };

  return (
    <Layout headerTitle="My Organization" tab="myOrg" back onClickBack={() => history.goBack()}>
      <div className={css.container}>
        <div className={css.header}>
          {['Today', 'Week', 'Month', 'All'].map((item, index) => (
            <div
              key={index}
              onClick={() => setTimeSpan(item)}
              className={timeSpan === item ? css.headerItemSelected : css.headerItem}
            >
              {item}
            </div>
          ))}
        </div>
        <div className={css.content}>
          <div className={css.contentLeft}>
            <div className={css.peopleTextContainer}>
              <div className={css.peopleTextValue}>
                <div className={css.peopleText}>People In Your Organization: </div>
                <div className={css.peopleTextSub}>{orgData?.total_users || 0}</div>
              </div>
              <Doughnut data={data} />
            </div>
          </div>
          <div className={css.contentMedium}>
            <div className={css.contentMediumTop}>
              <div>
                <div className={css.sectionText1}>{orgData?.total_tested || 0}</div>
                <div className={css.sectionText2}>{`${orgData?.total_tested_percents || 0}%`}</div>
              </div>
              <div className={css.mediumTopBottomSection}>
                <div className={css.headingText1}>People</div>
                <div className={css.headingText2}>Tested</div>
              </div>
            </div>
            <div className={css.contentMediumBottom}>
              <div>
                <div className={css.sectionText1}>{orgData?.total_symptomatic || 0}</div>
                <div className={css.sectionText2}>{`${
                  orgData?.total_symptomatic_percents || 0
                }%`}</div>
              </div>
              <div className={css.mediumBottomSection}>
                <div className={css.headingText1}>Result</div>
                <div className={css.headingText2}>Symptomatic</div>
              </div>
            </div>
          </div>
          <div className={css.contentRight}>
            <div className={css.contentRightTop}>
              <div>
                <div className={css.sectionText1}>{orgData?.total_negative || 0}</div>
                <div className={css.sectionTextRight2}>{`${
                  orgData?.total_negative_percents || 0
                }%`}</div>
              </div>
              <div className={css.rightTopBottomSection}>
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#78DA76',
                  }}
                />
                <div>
                  <div className={css.headingText1}>Result</div>
                  <div className={css.headingText2}>Negative</div>
                </div>
              </div>
            </div>
            <div className={css.contentRightBottom}>
              <div>
                <div className={css.sectionText1}>{orgData?.total_positive || 0}</div>
                <div className={css.sectionTextRight2}>{`${
                  orgData?.total_positive_percents || 0
                }%`}</div>
              </div>
              <div className={css.rightBottomSection}>
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#D23C3C',
                  }}
                />
                <div>
                  <div className={css.headingText1}>Result</div>
                  <div className={css.headingText2}>Positive</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  authentication: state.authentication,
});

export default connect(mapStateToProps)(MyOrganization);
