import { RESULTS, SET_SELECTED_RESULTS } from '../types';

export function setResults(payload) {
  return {
    type: RESULTS,
    payload,
  };
}

export function setSelectedResult(payload) {
  return {
    type: SET_SELECTED_RESULTS,
    payload,
  };
}
