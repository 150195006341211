import React from 'react';
import './index.css';
import CartIcon from '../../../assets/image/CartIcon.png';
import Arrow from '../../../assets/image/Arrow_1.png';
import { useHistory } from 'react-router';

export default function RegistrationIneligible() {
  const history = useHistory();
  return (
    <>
      <div className="mt-5 pt-2 d-flex justify-content-center align-items-center">
        <div className="mr-3">
          <img src={Arrow} alt="Arrow" style={{ cursor: 'pointer' }} />
        </div>

        <div className="text-center Ineligible-header">Registration Ineligible</div>
      </div>

      <div className="border m-0 my-3" />

      <div className=" mt-5">
        <div className="my-3 py-1 px-1 px-sm-0 text-center  Ineligible-text ">
          You have indicated that you do not currently have insurance.
        </div>

        <div className="my-3 py-1 px-1 px-sm-0 text-center Ineligible-sub-header ">
          Public funds are no longer available to support COVID-19 testing.
        </div>

        <div className="mt-5 py-1 px-1 px-sm-0 text-center Ineligible-light-text ">
          In order to take the GENETWORx COVID-19 PCR Test Kit, you will need to order one online.
          Please visit the GENETWORx Store to purchase one.
        </div>

        <div className="my-2 py-1 px-1 px-sm-0 text-center Ineligible-light-text ">
          We apologize for any inconvenience.
        </div>
      </div>

      <div className="row m-0 p-0 mt-2">
        <div className="col-10 offset-1 col-sm-6 offset-sm-3 ">
          <div
            className="mt-5 d-flex justify-content-center align-items-center shop-button"
            onClick={() => {
              // redirect to https://store.genetworx.com/order_testkit/symptoms
              window.open(' https://store.genetworx.com/order_testkit/symptoms', '_blank');
            }}
          >
            <img src={CartIcon} alt="CartIcon" className="mr-2" />
            Shop GENETWORx Store
          </div>

          <div className="my-5 py-5">
            <div
              className="mt-4 d-flex justify-content-center align-items-center u-button"
              onClick={() => {
                history.goBack();
              }}
            >
              I Understand
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
