import React, { useEffect, useState } from 'react';
import './Toaster.css';

const Toaster = ({ icon, header, sub_header, show, background, border }) => {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (show) {
      setHide(false);
      setTimeout(() => {
        setHide(true);
      }, 3000);
    }
  }, [show]);

  return (
    <div
      className={`toaster_modal px-4 py-4 ${hide ? 'd-none' : ''}`}
      style={{
        background: background || '#FFFFFF',
        border: border || '1px solid #FFD233',
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <img src={icon} alt="icon" width="50px" height="50px" />

        <div className="ml-3">
          <div className="toaster_modal_header">{header}</div>

          <div className="my-2 toaster_modal_subHeader">{sub_header}</div>
        </div>
      </div>
    </div>
  );
};

export default Toaster;
