import {
  SET_USER,
  SET_USER_INSURANCE,
  SET_USER_PAYMENT_METHOD,
  CLEAR_USER,
  ADD_CONTACTS,
  SET_USER_CONTACTS,
  SET_USER_PROFILE,
  SET_APPOINTMENT,
  DELETE_APPOINTMENT,
  REMOVE_CONTACT,
  SET_USER_TEST_KITS,
} from '../types';

const initialization = {
  profile: {},
  insurance: [],
  userProfile: [],
  contacts: [],
  appointments: [],
  paymentMethod: [],
};

const user = (state = initialization, action) => {
  let newState;
  switch (action.type) {
    case SET_USER:
      return { ...state, profile: action.payload };
    case SET_USER_INSURANCE:
      return { ...state, insurance: action.payload };
    case SET_USER_PROFILE:
      return { ...state, userProfile: action.payload };
    case SET_USER_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case CLEAR_USER:
      return initialization;
    case SET_USER_CONTACTS:
      return { ...state, contacts: action.payload };
    case ADD_CONTACTS:
      return { ...state, contacts: [...state.contacts, ...action.payload] };
    case REMOVE_CONTACT:
      newState = [...state.contacts];
      newState.splice(action.index, 1);
      return { ...state, contacts: newState };
    case SET_APPOINTMENT:
      return { ...state, appointments: action.payload };
    case SET_USER_TEST_KITS:
      return { ...state, testkits: action.payload };
    case DELETE_APPOINTMENT:
      newState = [...state.appointments];
      newState.splice(action.index, 1);
      return { ...state, appointments: newState };
    default:
      return state;
  }
};

export default user;
