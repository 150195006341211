import React, { useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#000000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `#000000`,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: `#f44336`,
    },
  },
})(TextField);

const useStyles = makeStyles({
  cssLabel: {
    color: '#000000',
    fontWeight: 600,
    '&$cssFocused': {
      color: `#000000 !important`,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#000000 !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#c4c4c4',
  },
});

function TextFieldComponent(props) {
  const classes = useStyles();
  const textRef = useRef(null);

  function onFocus() {
    if (props.setCursor) {
      const data = textRef.current.querySelector(`#${props.id}`);
      data.setSelectionRange(0, 3);
    }
  }

  return (
    <CssTextField
      ref={textRef}
      label="First Name"
      variant="standard"
      onFocus={onFocus}
      className={classNames('pb-3 w-100', props.className)}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
        shrink: props.shrink,
      }}
      InputProps={{
        ...props.InputProps,

        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          // notchedOutline: classes.notchedOutline,
          input: props.classnameinput,
        },
        inputcomponent: props.inputcomponent,
      }}
      {...props}
    />
  );
}

export default TextFieldComponent;
