import {
  SET_USER,
  SET_USER_INSURANCE,
  SET_USER_PAYMENT_METHOD,
  CLEAR_USER,
  ADD_CONTACTS,
  SET_USER_CONTACTS,
  SET_USER_PROFILE,
  SET_APPOINTMENT,
  REMOVE_CONTACT,
  DELETE_APPOINTMENT,
  SET_USER_TEST_KIT,
  SET_USER_TEST_KITS,
} from '../types';

export function setUser(payload) {
  return {
    type: SET_USER,
    payload,
  };
}

export function setUserInsurance(payload) {
  return {
    type: SET_USER_INSURANCE,
    payload,
  };
}

export function setUserProfile(payload) {
  return {
    type: SET_USER_PROFILE,
    payload,
  };
}

export function setUserPaymentMethod(payload) {
  return {
    type: SET_USER_PAYMENT_METHOD,
    payload,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export function addNewContact(payload) {
  return {
    type: ADD_CONTACTS,
    payload,
  };
}

export function setUserContacts(payload) {
  return {
    type: SET_USER_CONTACTS,
    payload,
  };
}

export function deleteUserContact(index) {
  return {
    type: REMOVE_CONTACT,
    index,
  };
}

export function setUserAppointments(payload) {
  return {
    type: SET_APPOINTMENT,
    payload,
  };
}

export function deleteAppointments(index) {
  return {
    type: DELETE_APPOINTMENT,
    index,
  };
}

export function setUserTestKit(payload) {
  return {
    type: SET_USER_TEST_KIT,
    payload,
  };
}

export function setUserTestKits(payload) {
  return {
    type: SET_USER_TEST_KITS,
    payload,
  };
}
