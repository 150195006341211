import { API } from '../const';

export function certification(key) {
  return new Promise(async (res, rej) => {
    try {
      const api = `${API}certs`;
      const body = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${key}`,
          'Content-Type': 'application/vnd.api+json',
        },
      });
      const json = await body.json();
      console.log('certs', json);
      if (!json.errors) {
        res(json.data);
      }
      res([]);
    } catch (error) {
      console.log('error fetch medtests', error);
    }
  });
}
