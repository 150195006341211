import React, { useState, useEffect } from 'react';
import './ActivationFlowActivateKit.css';
import classNames from 'classnames';
import { Button, Spinner, Form } from 'react-bootstrap';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';

import MaskedInput from 'react-text-mask';
import css from './TestKits.module.css';
import moment from 'moment';
import { Alert, Layout, DashedButton, ListForm } from '../../components';
import { connect } from 'react-redux';
import { processAPI } from '../../api/models/process';
import { createUserTestKits } from '../../api/models/user';
import { validateKitInformation } from '../../api/validation';
import ScanIcon from '../../assets/svg/scan_icon.svg';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setUserTestKits } from '../../store/action/user';
import SampleTestKit from '../../assets/image/sampletestkit.png';
import SampleTrackingNumber from '../../assets/image/sampletrackingnumber.png';

function TextMaskActivationCode(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/G/i, /X/i, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskCollectionDate(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /[0-2]/,
        /\d/,
        ':',
        /[0-5]/,
        /[0-9]/,
      ]}
      // mask={[/2/, /0/, /[2]/, /\d/, '-', /[01]/, /[\d]/, '-', /[0-3]/, /\d/, " ", /[0-2]/, /\d/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskTrackingNumber(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

const ActivationFlowActivateContainer = (props) => {
  const { t, i18n } = useTranslation();
  const { familyMemberId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const [specimenCode, setSpecimenCode] = useState('');
  const [collectionDate, setCollectionDate] = useState(
    moment(new Date()).format('MM/DD/YYYY HH:mm')
  );
  const [returnTrackingNumber, setReturnTrackingNumber] = useState('');
  const [newTestKit, setNewTestKit] = useState([]);
  const [registeredTestKit, setRegisteredTestKit] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  //   const { testKitId } = useParams();
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;
  const history = useHistory();
  const { state } = useLocation();

  const kitId = state?.kit;

  const { testkits } = props;
  const [radioValue, setRadioValue] = useState('1');

  const [page, setPage] = useState(1);

  const [promoCode, setPromoCode] = useState('');

  useEffect(() => {
    const kits = props.user.testkits;
    console.log(kits);
    const promoCode = localStorage.getItem('promo-code');
    promoCode && setPromoCode(promoCode);
  }, [props.user]);

  async function createKit() {
    const { authentication } = props;
    const { jwt: key, user_id } = authentication;
    let result = await createUserTestKits(familyMemberId, key);
    let id = result?.data?.id;
    return id;
  }

  async function onSubmit(event) {
    event.preventDefault();
    const kits = props.user.testkits;
    const { invalid, message } = validateKitInformation({
      specimenCode,
      collectionDate,
      returnTrackingNumber,
    });
    setValidation({ invalid, message });

    if (!invalid) {
      const thekit = kits?.find(
        (x) => x.attributes['specimen-code'] === specimenCode.toUpperCase()
      );
      if (thekit) {
        setShowAlert({
          show: true,
          message: t('registrationFailedActivation'),
          type: 'error',
        });
        return;
      }

      //       let testKitId;
      //       if (kitId) {
      //         testKitId = kitId;
      //       } else {
      let testKitId = await createKit();
      // }
      const myData = {
        data: {
          id: parseInt(testKitId),
          type: 'medtest-kits',
          attributes: {
            'specimen-code': specimenCode.toUpperCase(),
            'collected-at': moment().format('YYYY-MM-DD HH:mm'),
            'return-tracking': returnTrackingNumber.replace(/\s+/g, '').toUpperCase(),
            status: 'shipped',
            'location-code': props.flow.promoCode,
            'ship-to': 'user',
          },
        },
      };

      setLoading(true);

      try {
        const data = await processAPI({
          key: props.authentication.jwt,
          methodType: 'PATCH',
          data: myData,
          endpoint: `medtest-kits/${parseInt(testKitId)}`,
        });

        if (data.id) {
          setShowAlert({
            show: true,
            message: t('registeredTestKit'),
            type: 'success',
          });
          history.push('/instructions');
        } else {
          setShowAlert({
            show: true,
            message: t('registrationFailed'),
            type: 'error',
          });
        }
      } catch (error) {
        setShowAlert({
          show: true,
          message: t('registrationFailedContact'),
          type: 'error',
        });
      }

      setLoading(false);
    }
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  // this automatically spaces out the input into chunks of 4
  // and prevents typing after all 12 characters are entered
  const displayTrackingNumber = (input) => {
    if (returnTrackingNumber.length === 14) {
      if (input.length < 14) {
        setReturnTrackingNumber(input);
      } else {
        setReturnTrackingNumber(returnTrackingNumber);
      }
    } else {
      let value = '';
      if (input.length) {
        value = input
          .replace(/\s/g, '')
          .match(/.{1,4}/g)
          .join(' ');
      }
      setReturnTrackingNumber(`${value}`);
    }
  };

  const [focusedInput, setFocusedInput] = useState(false);
  useEffect(() => {
    if (page === 2) setFocusedInput(false);
  }, [page]);

  return (
    <Layout
      tab="testkits"
      headerTitle={t('kitActivation')}
      onClickBack={() => history.goBack()}
      disablePrimaryUserHipaaPopup
      back
    >
      <Alert
        className="mb-"
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />

      <div className={css.container}>
        <div>
          <div className={css.subContainer}>
            <Form style={{ height: '100%' }} className="d-flex flex-column" onSubmit={onSubmit}>
              {page == 1 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div className={classNames('py-4 mx-2')}>
                    <br />

                    <p className={css.headerText}>Enter the Registration Code</p>

                    <p className={css.headerText2}>
                      Locate the barcode on the test tube of your test kit and scan or enter the
                      information below.
                    </p>
                    <img src={SampleTestKit} alt="sample test kit" />
                    <ListForm
                      focused
                      required
                      title={t('activationCode')}
                      label={t('activationCode')}
                      inputcomponent={TextMaskActivationCode}
                      name="activationCode"
                      placeholder={t('enterBarcodeNumber')}
                      onChange={(e) => {
                        const kits = props.user.testkits;
                        const thekit = kits?.find(
                          (x) => x.attributes['specimen-code'] === e.target.value.toUpperCase()
                        );
                        if (thekit) {
                          setValidation({
                            invalid: 'specimenCode',
                            message: t('registrationFailedActivation'),
                          });
                          return;
                        }
                        setValidation({ invalid: '', message: '' });

                        setSpecimenCode(`${e.target.value}`);
                      }}
                      value={specimenCode}
                      error={invalid === 'specimenCode'}
                      errorMessage={errorMessage}
                      inputProps={{ 'data-testid': 'ACTKIT' }}
                    />
                    <br />
                  </div>
                  <div className={css.ortext}>
                    <span>or</span>
                  </div>
                  <DashedButton
                    className={classNames(
                      'align-self-center my-4',
                      css.dashedButton,
                      css.dashedButton2
                    )}
                    title={t('scanBarcode')}
                    icon={ScanIcon}
                    handlePress={() => setShowScanner(!showScanner)}
                  />

                  {showScanner && (
                    <div
                      id="scanner"
                      className="align-self-center mx-2"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <BarcodeScannerComponent
                        width={380}
                        onUpdate={(err, result) => {
                          if (result) {
                            setSpecimenCode(result.text);
                          }
                        }}
                      />
                    </div>
                  )}

                  <Button
                    data-testid="BTNKIT"
                    disabled={loading || specimenCode.length != 10}
                    onClick={() => setPage(2)}
                    // type="submit"
                    className={classNames(
                      css.btnActivate,
                      css.btnActivate2,
                      'align-self-center justify-content-end'
                    )}
                  >
                    {loading ? <Spinner animation="border" variant="warning" /> : 'Continue'}
                  </Button>
                </div>
              )}
              {page == 2 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <p className={css.headerText}>Enter the Tracking Number</p>
                  <p className={css.headerText2}>
                    Locate and enter the 12 digit return tracking number found on the FedEx return
                    label.
                  </p>
                  <img
                    src={SampleTrackingNumber}
                    alt="sample tracking number"
                    style={{ maxWidth: 600 }}
                  />
                  <ListForm
                    focused
                    required
                    id="trackingNumber"
                    label={t('returnTrackingNumber')}
                    inputcomponent={TextMaskTrackingNumber}
                    placeholder={t('enterTrackingNumber')}
                    name="trackingNumber"
                    onChange={(e) => displayTrackingNumber(e.target.value)}
                    value={returnTrackingNumber}
                    error={invalid === 'trackingNumber'}
                    errorMessage={errorMessage}
                    inputProps={{ 'data-testid': 'TRAKIT' }}
                  />
                  <Button
                    data-testid="BTNKIT"
                    disabled={loading || returnTrackingNumber?.length != 14}
                    type="submit"
                    className={classNames(
                      css.btnActivate,
                      css.btnActivate3,
                      'align-self-center justify-content-end'
                    )}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      <>Activate Kit & Begin Collecting</>
                    )}
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
    flow: state.flow,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivationFlowActivateContainer);
