import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
// import { yellow } from "@material-ui/core/colors";
// import { createMuiTheme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import './Signup.css';
import { setUser, setUserInsurance } from '../../store/action/user';
import { authentication } from '../../store/action/auth';
import { signUp, signIn } from '../../api/auth';
import { storeData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getCompanyNameSignUp } from '../../api/models/process';
import { stateList, genderList, ethnicList, raceList } from '../../utils/utils';
import { signupSchema } from './signupSchema';
import Input from '../../components/Input/InputSignup';
import SelectInput from '../../components/SelectInput/SelectInputSignup';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInputSignup';
import { getUserData } from '../../api/models/user';
import { LogoImageUrl } from '../../components';
import Arrow from './Arrow';

function Page(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // Warning: this is a hack to get the company id since normal useParams() doesn't work for some reason
  let cid =
    !isNaN(props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1)) &&
    props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1);
  // if cid is not a number
  if (isNaN(cid)) {
    cid = 3684;
  }
  console.log(cid);
  const location = useLocation();

  // For comcast employee users
  // const [isComcastEmployee, setIsComcastEmployee] = useState("false");
  // const [isComcastEmployeeRelative, setIsComcastEmployeeRelative] = useState("false");

  const [, setUserType] = useState();
  const [, setPolicyId] = useState();
  const [companyName, setcompanyName] = useState('');
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;

  const urlPath = window.location.pathname;

  const history = useHistory();
  const { setUserDispatch, setUserInsuranceDispatch, authenticationDispatch } = props;

  async function onSubmit(values) {
    try {
      setLoading(true);
      const data = {
        email: values.email,
        password: values.password,
        'first-name': values.firstName,
        'middle-name': values.middleName || '',
        'last-name': values.lastName,
        'address-line-1': values.addressLine1,
        'address-line-2': values.addressLine2 || '',
        state: values.state,
        city: values.city,
        zip: values.zip,
        'phone-number': values.phoneNumber,
        'date-of-birth': moment(values.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        'password-confirmation': values.password,
        gender: values.gender,
        ethnicity: values.ethnicity,
        race: values.race,
        'company-id': cid || 3684,
        'managed-account': false,
        'user-type-id': null,
        'company-policy-id': null,
        'mothers-maiden': values.mothersMaidenName || '',
        // "is-comcast-employee": isComcastEmployee === "true" ? true : false,
        // "is-comcast-employee-relative":
        //   isComcastEmployeeRelative === "true" ? true : false,
      };

      const response = await signUp(data);

      if (response?.errors) {
        const detail = response?.errors[0];
        setValidation({ invalid: true, message: detail });
        setLoading(false);
      } else {
        setValidation({ invalid: '', message: '' });
        await login(values?.email, values?.phoneNumber, values?.password);
      }
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  async function login(email, phone, password) {
    try {
      setLoading(true);
      const response = await signIn(email, phone, password);
      // setLoading(false);

      if (response?.jwt) {
        storeData(USER_CACHE_NAME, { ...response, status: 'login' });
        const profile = await getUserData(response.user_id, response?.jwt);
        if (profile?.data) {
          setUserDispatch(profile?.data);
          setUserInsuranceDispatch(profile.included || []);
          authenticationDispatch({
            ...response,
            status: 'login',
            loading: false,
          });
        }
        if (urlPath.includes('/activation-signup')) {
          history.push(`/familyinsurancecheck/${response?.user_id}`);
        } else {
          history.push('/profile');
        }
      } else {
        const { message } = response;

        if (message) {
          setValidation({ invalid: true, message });
          setLoading(false);
        } else {
          setValidation({ invalid: true, message: 'error' });
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setValidation({ invalid: true, message: 'something went wrong' });
    }
  }

  async function getCompanyName() {
    if (cid) {
      const companyName = await getCompanyNameSignUp(cid);
      setcompanyName(companyName?.company_name);
    }
  }

  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    phoneNumber: '',
    gender: '',
    race: '',
    ethnicity: '',
    mothersMaidenName: '',
    email: '',
    emailConfirmation: '',
    password: '',
    'company-id': '',
    'managed-account': false,
    'user-type-id': null,
    'company-policy-id': null,
    // "is-comcast-employee": null,
    // "is-comcast-employee-relative": null,
  });

  const onSubmitFormData = (data) => {
    setFormData(data);
    onSubmit(data);
  };

  useEffect(() => {
    getCompanyName();
  }, []);

  useEffect(() => {
    setPolicyId(new URLSearchParams(location?.search).get('policy_id'));
    setUserType(new URLSearchParams(location?.search).get('user_type'));
  }, [location]);

  // const theme = createMuiTheme({
  //   palette: {
  //     primary: { main: yellow[800] },
  //   },
  // });

  return (
    <>
      <div className="d-flex justify-content-between mx-3 mt-3">
        <div onClick={() => history.goBack()} className="back-button d-flex align-items-center">
          <div className="mr-2" style={{ width: '25px' }}>
            <Arrow />
          </div>

          <div className="back-button-text">Back</div>
        </div>

        <div
          className="Sign_btn"
          onClick={() => {
            if (urlPath.includes('/activation-signup')) {
              history.push('/activation-login');
            } else {
              history.push('/');
            }
          }}
        >
          Sign Into My Account
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4 pt-3">
        <img alt="Logo" src={LogoImageUrl()} width="180" height="64" />
      </div>

      <div className="d-flex justify-content-center mt-5 mx-4">
        <div className="form_container">
          <h3>{companyName}</h3>
          <div className="form_container_header">Create A New Account</div>

          <div className="form_container_sub-header mt-2 mb-4">
            Please complete the required fields below
          </div>

          <Formik
            initialValues={{ ...formData }}
            validationSchema={signupSchema}
            onSubmit={onSubmitFormData}
            enableReinitialize
          >
            {(formikProps) => (
              <Form style={{ maxWidth: 600 }}>
                <Field
                  name="firstName"
                  label="First Name *"
                  placeholder="First Name"
                  component={Input}
                  type="text"
                />

                <Field
                  name="middleName"
                  label="Middle Name"
                  placeholder="Middle Name"
                  component={Input}
                  type="text"
                />

                <Field
                  name="lastName"
                  label="Last Name *"
                  placeholder="Last Name"
                  component={Input}
                  type="text"
                />

                <Field
                  name="dateOfBirth"
                  label="Date of Birth *"
                  component={NumberFormatInput}
                  type="text"
                  placeholder="MM/DD/YYYY"
                  format="##/##/####"
                />

                <Field
                  name="addressLine1"
                  label="Address *"
                  placeholder="Address"
                  component={Input}
                  type="text"
                />

                <Field
                  name="addressLine2"
                  label={`Address 2 :  Apartment, suite, etc. `}
                  placeholder="Address"
                  component={Input}
                  type="text"
                />

                <Field
                  name="city"
                  label="City *"
                  placeholder="City"
                  component={Input}
                  type="text"
                />

                <Field
                  name="state"
                  label="State *"
                  component={SelectInput}
                  classNamePrefix="form-select"
                  placeholder="State"
                  options={stateList.map((s) => ({
                    value: s.value,
                    label: s.label,
                  }))}
                />

                <Field name="zip" label="Zip *" placeholder="Zip" component={Input} type="text" />

                <Field
                  name="phoneNumber"
                  label="Phone Number *"
                  component={NumberFormatInput}
                  type="text"
                  placeholder="(###) ###-####"
                  format="(###) ###-####"
                />

                <Field
                  name="gender"
                  label="Gender *"
                  component={SelectInput}
                  classNamePrefix="form-select"
                  placeholder="Gender"
                  options={genderList.map((s) => ({
                    value: s.value,
                    label: s.label,
                  }))}
                />

                <Field
                  name="race"
                  label="Race *"
                  component={SelectInput}
                  classNamePrefix="form-select"
                  placeholder="Race"
                  options={raceList.map((s) => ({
                    value: s.value,
                    label: s.label,
                  }))}
                />

                <Field
                  name="ethnicity"
                  label="Ethnicity *"
                  component={SelectInput}
                  classNamePrefix="form-select"
                  placeholder="Ethnicity"
                  options={ethnicList.map((s) => ({
                    value: s.value,
                    label: s.label,
                  }))}
                />

                <Field
                  name="mothersMaidenName"
                  label="Mother's Maiden Name"
                  placeholder="Mother's Maiden Name"
                  component={Input}
                  type="text"
                />

                <Field
                  name="email"
                  label="Email Address *"
                  placeholder="Email Address"
                  component={Input}
                  type="text"
                />

                <Field
                  name="emailConfirmation"
                  label="Email Address Confirmation *"
                  placeholder="Email Address Confirmation"
                  component={Input}
                  type="text"
                />

                <Tooltip
                  title="Must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character"
                  arrow
                  className=" w-100"
                  placement="top-start"
                >
                  <div>
                    <Field
                      name="password"
                      label="Password *"
                      placeholder="Password"
                      component={Input}
                      type="password"
                    />
                  </div>
                </Tooltip>

                {/* <ThemeProvider theme={theme}>
                  <FormControl
                    component="fieldset"
                    error={isComcastEmployee === null}
                  >
                    <FormLabel
                      component="legend"
                      className="font-weight-bold text-body"
                    >
                      {t("employedByComcast")}
                    </FormLabel>
                    <RadioGroup
                      column
                      aria-label="isComcastEmployee"
                      name="isComcastEmployee1"
                      value={isComcastEmployee}
                      onChange={(e) => setIsComcastEmployee(e.target.value)}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Yes, I am currently a Comcast employee"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="No, I am not a Comcast employee"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl
                    component="fieldset"
                    error={isComcastEmployeeRelative === null}
                  >
                    <FormLabel
                      component="legend"
                      className="font-weight-bold text-body"
                    >
                      {t("employedByComcastRelative")}
                    </FormLabel>
                    <RadioGroup
                      column
                      aria-label="isComcastEmployeeRelative"
                      name="isComcastEmployeeRelative1"
                      value={isComcastEmployeeRelative}
                      onChange={(e) =>
                        setIsComcastEmployeeRelative(e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Yes, I have relatives that work for Comcast"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="No relatives work for Comcast"
                      />
                    </RadioGroup>
                  </FormControl>
                </ThemeProvider> */}

                <div className="text_">
                  <div className="mb-4">
                    Your privacy is important to us. By clicking Create Account, you confirm that
                    you have reviewed our{' '}
                    <a
                      href="https://auratracker.org/privacy_policy"
                      target="_blank"
                      style={{ color: 'blue' }}
                      rel="noreferrer"
                    >
                      privacy policy
                    </a>{' '}
                    and agree to our terms of use and that your information may be shared with RCA
                    and affiliated companies, who may contact you to keep you updated with important
                    health-related information.
                  </div>

                  <div>
                    By entering your mobile number and clicking next, you also consent that RCA and
                    affiliated companies may send you SMS messages using autodialing technology from
                    our primary messaging code 1-484-209-3355 for health-related information and as
                    described in our terms of use in our privacy policy. Std. msg and data rates
                    apply. Reply HELP or help, STOP to cancel. Msg freq may vary.
                  </div>
                </div>

                {invalid === true && (
                  <div className=" mt-4">
                    <Alert severity="error" className="mb-2">
                      {errorMessage}
                    </Alert>
                  </div>
                )}

                <div className="mt-4 mb-5 pt-3 pb-5">
                  <button
                    disabled={loading}
                    style={!formikProps.isValid || !formikProps.dirty ? { opacity: '0.4' } : {}}
                    type="submit"
                    className="btn_submit_ "
                  >
                    {loading ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      t('createAccount')
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

const mapStateTopProps = (state) => ({
  authentication: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(Page);
