import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import css from './Button.module.css';

export function MultipleButton({ selected, title, subTitle, onClick }) {
  return (
    <div
      className={classNames(css.multipleButton, selected && css.multipleButtonSelected)}
      onClick={onClick}
    >
      <p className="card-font font-weight-bold">{title}</p>
      <p className="card-font">{subTitle}</p>
    </div>
  );
}

export function SmallButton({ text, onClick, className }) {
  return (
    <Button className={classNames(css.smallButton, className)} onClick={onClick}>
      {text}
    </Button>
  );
}

export function BigButton({ img, color, text, subText, tertiaryText, onClick, requiresUpdates }) {
  return (
    <div
      onClick={onClick}
      className={css.bigButtonContainer}
      style={{ backgroundColor: color || '#78DA76' }}
    >
      <div className={css.imgButton}>
        <img alt="" src={img || ''} />
      </div>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <div className={css.buttonsTextContainer}>
          <div />
          <div className={css.buttonHeader}>
            <p className="medium-font-bold">{text}</p>
            {subText && <p className="medium-font-bold">{subText}</p>}
          </div>
          {requiresUpdates && (
            <div className={css.needsUpdatesButton}>
              <p className={css.needsUpdatesText}>Update</p>
            </div>
          )}
          {!requiresUpdates && <div />}
        </div>
      </div>
    </div>
  );
}

export function StrokeButton({ className, title, handlePress, height, icon, disabled }) {
  return (
    <div
      data-testid="ADDTEST"
      style={{ height }}
      className={classNames(css.strokeContainer, className)}
      onClick={handlePress}
    >
      {icon && <img alt="icon" src={icon} />}
      <p className={classNames('normal-font', disabled && css.disabledStrokeButton)}>{title}</p>
    </div>
  );
}

export function DashedButton({ className, title, handlePress, height, icon }) {
  return (
    <div
      style={{ height }}
      className={classNames(css.dashedContainer, className)}
      onClick={handlePress}
    >
      {icon && <img alt="icon" src={icon} />}
      <p className="normal-font">{title}</p>
    </div>
  );
}

export default function ButtonComponent({ text, onClick, className, disabled, loading, height }) {
  return (
    <div
      style={{ height }}
      className={classNames(css.buttonContainer, className, disabled && css.buttonDisabled)}
      onClick={!disabled ? onClick : undefined}
    >
      <p className="normal-font">
        {' '}
        {loading ? <Spinner animation="border" variant="secondary" /> : text}{' '}
      </p>
    </div>
  );
}
