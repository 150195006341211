import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ListResults } from '../../components';
import css from './FamHistoryResults.module.css';
import ArrowDownIcon from '../../assets/svg/arrow_down.svg';
import { resultsMedTest } from '../../api/models/medTest';
import { processColor } from '../../api';
import { setResults, setSelectedResult } from '../../store/action/results';
import { authentication } from '../../store/action/auth';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import ArrowUpIcon from '../../assets/svg/arrow_up.svg';
import { useParams } from 'react-router';

const FamHistory_Results = (props) => {
  const { t } = useTranslation();

  const {
    results,
    setResultsDispatch,
    setSelectedResultDispatch,
    selectedData,
    setSelectedData,
    UserData,
  } = props;

  const [loading, setLoading] = useState(true);
  const [ascending, setAscending] = useState(true);
  const [user, setUser] = useState();
  const [resultHistory, setResultHistory] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    loadData();
  }, []);

  const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x.attributes[key]] = rv[x.attributes[key]] || []).push(x);
      return rv;
    }, {});
  };

  async function loadData() {
    setLoading(true);
    const userData = await getStoredData(USER_CACHE_NAME);

    if (userData && userData.status === 'login') {
      let result = (await resultsMedTest(userData.jwt)) || [];

      let groupedResults = groupBy(result, 'user-id');
      setResultHistory(groupedResults?.[id] || []);
      setUser(groupedResults?.[id]?.[0]);
      UserData(groupedResults?.[id]?.[0]);
      //
      if (result && result.length > 0) {
        result = result.sort(compare);
        setResultsDispatch(result);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  function filterDataPerDay(days, data) {
    return data.filter((item) => {
      const currentDate = moment().format('L');
      const testDate = moment(item.attributes['test-date']).add(days, 'days').format('L');

      return testDate >= currentDate;
    });
  }

  function compare(a, b) {
    const testA = a.attributes['test-date'];
    const testB = b.attributes['test-date'];

    let comparison = 0;
    if (testA > testB) {
      comparison = 1;
    } else if (testA < testB) {
      comparison = -1;
    }

    return comparison * -1;
  }

  function sortData() {
    setAscending(!ascending);
    resultHistory.reverse();
  }

  return (
    <div className={css.container}>
      <div className={css.flex1}>
        <div className={css.listContainer}>
          {!loading && resultHistory.length === 0 ? (
            <p className="medium-font-bold text-center">{t('noResultsWithin')}</p>
          ) : (
            <div className={css.container1}>
              <div className={css.header}>
                {user && (
                  <div className={css.headerText}>
                    {user?.attributes?.['first-name']}'s Test History
                  </div>
                )}

                {resultHistory?.length > 0 && (
                  <div className={css.sorter} onClick={sortData}>
                    <p className={css.sorterText}>Date</p>
                    <img src={ascending ? ArrowDownIcon : ArrowUpIcon} alt="down arrow" />
                  </div>
                )}
              </div>
            </div>
          )}
          {loading && (
            <div className="text-center">
              <Spinner animation="grow" variant="primary" />
            </div>
          )}
          {resultHistory.map(({ attributes, id }) => {
            let result = attributes['test-result'] || 'Unknown';
            const color = attributes.color.toLowerCase();
            let iconColor = processColor(color, result);

            return (
              <ListResults
                key={`tr-${id}`}
                icon={iconColor}
                type={attributes['medtest-type']}
                date={moment(attributes['test-date']).format('MM/DD/YYYY')}
                updatedTime={attributes['updated-at']}
                location={attributes['test-location']}
                name={`${attributes['first-name']} ${attributes['last-name']}`}
                result={attributes['test-result'] || 'Unknown'}
                onCLick={() => {
                  setSelectedResultDispatch(attributes);
                  setSelectedData({ ...attributes, iconColor, id });
                }}
                selected={selectedData && selectedData.id === id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  results: state.results.results,
  status: state.authentication.status,
});

const mapDispatchToProps = (dispatch) => ({
  setResultsDispatch(payload) {
    dispatch(setResults(payload));
  },
  setAuthenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setSelectedResultDispatch(payload) {
    dispatch(setSelectedResult(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FamHistory_Results);
