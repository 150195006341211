let APIenv;

if (process.env.REACT_APP_ENV === 'local') {
  APIenv = 'http://localhost:3000/api/v1/';
} else if (process.env.REACT_APP_ENV === 'production') {
  APIenv = 'https://api.auratracker.org/v1-prod/';
} else if (process.env.REACT_APP_ENV === 'staging') {
  APIenv = 'https://staging.auratracker.org/api/v1/';
} else if (process.env.REACT_APP_ENV === 'qa') {
  APIenv = 'https://test.auratracker.org/api/v1/';
} else if (process.env.REACT_APP_ENV === 'test') {
  APIenv = 'https://test.auratracker.org/api/v1/';
} else {
  APIenv = 'https://auratracker.org/api/v1/';
}

export const API = APIenv;
export const USER_CACHE_NAME = '@user_key';
export const GEOLOCATION_KEY = '@geolocation_key';
export const USER_CONTACT_KEY = '@user_contact_key';
export const isUsability = window.location.href.includes('usability.auratracker.org');
