import {
  FAMILY_MEMBERS,
  QR_CODE,
  SET_QRCODE,
  SET_SELECTED_FAMILY_MEMBERS,
  SET_PRIMARY_FAMILY_MEMBERS,
} from '../types';

const initialState = {
  familyMembers: [],
  selectedFamilyMembers: {},
  primaryFamilyMembers: {},
  qrCode: '',
};

const familyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAMILY_MEMBERS:
      return { ...state, familyMembers: action.payload };
    case SET_SELECTED_FAMILY_MEMBERS:
      return { ...state, selectedFamilyMembers: action.payload };
    case SET_PRIMARY_FAMILY_MEMBERS:
      return { ...state, primaryFamilyMembers: action.payload };
    case SET_QRCODE:
      return { ...state, qrCode: action.payload };
    case QR_CODE:
      return { ...state, qrCode: action.payload };
    default:
      return state;
  }
};

export default familyReducer;
