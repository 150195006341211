import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './ExitModal.css';

const ExitModal = ({ icon, exitText, stayText, header, sub_header, onExit, onStay, open }) => (
  <>
    <Modal show={open} backdrop="static" keyboard={false} dialogClassName="exit-modal">
      <div className="py-4 px-3 px-sm-4 exit-modal_content">
        <div className="row">
          <div className="col-4 p-0 d-flex justify-content-center">
            <img src={icon} alt="Shield" width="75px" height="75px" />
          </div>
          <div className="col-8">
            <div className="exit-modal-header">{header}</div>
            <div className="my-2 exit-modal-subHeader text-left pt-1">{sub_header}</div>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-5">
            <div className="exit-modal-cancel" onClick={onStay}>
              {stayText || 'Stay'}
            </div>
          </div>
          <div className="col-7">
            <div className="exit-modal-cancel" onClick={onExit}>
              {exitText || 'Exit this Page'}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
);

export default ExitModal;
