import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, FormControlLabel, Checkbox, Fab } from '@material-ui/core';
import { Header } from '../../components';
import ChevronDownSvg from '../../assets/svg/chevron_down';
import { connect } from 'react-redux';
import { getUserData, getUserFamilyMembers } from '../../api/models/user';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME, isUsability } from '../../api/const';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    position: 'relative',
    flex: '1 1 auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(10),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      letterSpacing: 0.8,
    },
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
  },
  descriptionList: {
    listStyleType: 'upper-alpha',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  description: {
    fontSize: 20,
    letterSpacing: 0.38,
    fontFamily: 'SF Pro Display',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  },
  submitButton: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
      opacity: 0.4,
    },
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'unset',
    letterSpacing: -0.408,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
  checkboxWrapper: {
    alignSelf: 'start',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
      fontSize: 20,
      letterSpacing: 0.75,
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
    },
  },
  fab: {
    background: 'rgba(255, 153, 0)',
    margin: 0,
    top: 'auto',
    right: '20%',
    bottom: '20%',
    left: 'auto',
    position: 'fixed',
    '&:hover': {
      background: 'rgba(255, 153, 0)',
    },
    '&:focus': {
      outline: 0,
    },
    [theme.breakpoints.down('xs')]: {
      right: 30,
    },
  },
}));

const CustomCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'black',
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);

const descriptions = [
  `I am aware that information about the GENETWORx COVID-19 ${
    isUsability ? 'PCR' : 'Nasal Swab'
  } Test Kit including Frequently Asked Questions and complete Instructions for Use are available on the GENETWORx website.`,
  'I authorize RCA Laboratory Services, LLC d/b/a GENETWORx to perform laboratory testing services on the specimen I provide.',
  `I authorize GENETWORx to disclose the results of my GENETWORx COVID-19 ${
    isUsability ? 'PCR' : 'Nasal Swab'
  } Test Kit to any county, state, or to other governmental entity as required by law.`,
  'I understand that GENETWORx is not acting as my medical provider and that this testing does not replace treatment by my medical provider. I assume complete and full responsibility to seek medical advice and treatment from a medical provider regarding my test results if I receive a positive test result, if my symptoms or condition worsens, or if I have any questions or concerns.',
  'I acknowledge that a positive test result is an indication that I must self-isolate and/or wear a mask or face covering to reduce the risk of infecting others.',
  'I understand that, as with any medical test, there is the potential for a false positive or false negative COVID-19 test result.',
];

const CollectingConsent = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const ref = useRef(null);
  const [agree, setAgree] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [patientName, setPatientName] = useState(state?.selectedMemberName);
  const [patientIsMinor, setPatientIsMinor] = useState(false);
  const [guardianName, setGuardianName] = useState(
    `${props?.profile?.attributes?.['first-name']} ${props?.profile?.attributes?.['last-name']}`
  );
  const [familyMembers, setFamilyMembers] = useState([]);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const isNonPrimaryUser = state?.selectedMember != props?.profile?.id;

  const handleOnSubmit = () => {
    const route = isUsability ? '/kit-registration/' : '/activate-unassisted/';
    history.push(route + state?.selectedMember, {
      kit: state?.kit,
    });
  };

  const handleOnScrollDown = () => {
    scrollToBottom();
  };

  const scrollToBottom = () => {
    ref.current?.scrollTo({
      left: 0,
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    loadData();
  }, []);
  async function loadData() {
    const userDataCache = await getStoredData(USER_CACHE_NAME);
    setUser(userDataCache);
    let familyMemberList =
      (await getUserFamilyMembers(userDataCache.user_id, userDataCache.jwt)).included || [];
    setFamilyMembers(familyMemberList);
    let userData = await getUserData(userDataCache.user_id, userDataCache.jwt);
    setUserData(userData);
    let patient = familyMemberList.find((member) => member.id == state?.selectedMember);
    // setPatientName(
    //   patient?.attributes?.["first-name"] +
    //     " " +
    //     patient?.attributes?.["last-name"]
    // );
    const dateOfBirth = moment(patient?.attributes?.['date-of-birth']);
    setPatientIsMinor(dateOfBirth.isAfter(moment().subtract(18, 'years')));
    setGuardianName(
      `${props?.profile?.attributes?.['first-name']} ${props?.profile?.attributes?.['last-name']}`
    );
  }

  useEffect(() => {
    if (!state?.selectedMember) {
      history.push('/select-family-member-activation');
    }
  }, [history, state?.selectedMember]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={classes.root}>
      <Header title="Collecting Consent" progress={25} onBack={() => history.goBack()} />
      <div ref={ref} className={classes.body}>
        <Fab className={classes.fab} onClick={handleOnScrollDown}>
          <ChevronDownSvg />
        </Fab>
        <Typography className={classes.title}>
          GENETWORx COVID-19 {isUsability ? 'PCR' : 'Nasal Swab'} Test Kit Consent
        </Typography>
        <Typography className={classes.subTitle}>
          {isNonPrimaryUser && patientIsMinor && patientName && guardianName
            ? `${guardianName}, please carefully read and accept the following Informed Consent on behalf of ${patientName}:`
            : 'Please carefully read and accept the following Informed Consent:'}
        </Typography>
        <ul className={classes.descriptionList}>
          {descriptions.map((desc) => (
            <li className={classes.description}>{desc}</li>
          ))}
        </ul>
        {isNonPrimaryUser && patientIsMinor && isUsability && patientIsMinor && (
          <FormControlLabel
            className={classes.checkboxWrapper}
            control={
              <CustomCheck
                checked={agree2}
                onChange={({ target: { checked } }) => setAgree2(checked)}
                style={{
                  color: 'rgba(255, 153, 0)',
                }}
              />
            }
            label={`Click here to to confirm that you, ${guardianName}, are the authorized parent or guardian of ${patientName}`}
          />
        )}
        <FormControlLabel
          className={classes.checkboxWrapper}
          control={
            <CustomCheck
              checked={agree}
              onChange={({ target: { checked } }) => setAgree(checked)}
              style={{
                color: 'rgba(255, 153, 0)',
              }}
            />
          }
          label="Check to Agree"
        />
        <Button
          fullWidth
          disabled={!agree || (isUsability && isNonPrimaryUser && patientIsMinor && !agree2)}
          className={classNames(classes.submitButton)}
          onClick={handleOnSubmit}
        >
          Sign and Submit Document
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.user,
  profile: state?.user?.profile,
  familly: state?.familly,
});

export default connect(mapStateToProps)(CollectingConsent);
