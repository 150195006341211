import React, { useState, useMemo, useEffect } from 'react';
import './ActivationInsurance.css';

import { Layout } from '../../components';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import Check from '../../assets/image/check2.png';
import aura_icon_camera from '../../assets/image/aura-icon-camera.png';
import { setUser, setUserInsurance } from '../../store/action/user';
import { processAPI } from '../../api/models/process';
import { uploadFile } from '../../api/models/forms';
import { useHistory, useLocation, useParams } from 'react-router';
import { getUserData } from '../../api/models/user';
import { connect } from 'react-redux';
import Replace from '../../assets/svg/Replace.svg';
import { API } from '../../api/const';
import { stateList } from '../../utils/utils';

const RegEx = {
  name: /^[a-zA-Z ]*$/,
};
function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

const relationshipMapping = {
  Self: 'SEL',
  Spouse: 'SPO',
  Child: 'CHD',
  Other: 'OTH',
  Unknown: 'UNK',
};

const ActivationInsurance = (props) => {
  const { setUserInsuranceDispatch } = props;
  const { t } = useTranslation();
  const { familyMemberId } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const [insuranceData, setInsuranceData] = useState({});
  const [insuranceNotListed, setInsuranceNotListed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [planList, setPlanList] = useState([]);
  const insuranceId = props.userInsurance?.id || '';
  const [hasData, setHasData] = useState(true);
  const [files, setFiles] = useState([]);
  const [formValues, setFormValues] = useState({
    Insurance_Name: '',
    relationship: 'SEL',
    Insured_First_Name: '',
    Insured_Last_Name: '',
    gender: '',
    Insured_Phone: '',
    Insured_DOB: '',
    Insured_Group_Number: '',
    Insured_ID_Number: '',
    Address_1: '',
    Address_2: '',
    City: '',
    Zip: '',
    State: '',
    not_primary_address: false,
  });

  useEffect(() => {
    if (Object.keys(insuranceData).length) {
      setInsuranceNotListed(insuranceData['free-form-name']);

      const fullAddress = insuranceData['insureds-address'];
      const addressArray = fullAddress && fullAddress.split('^');
      let address = '';
      let address2 = '';
      let city = '';
      let state = '';
      let zip = '';
      if (addressArray && addressArray.length === 5) {
        address = addressArray[0].trim();
        address2 = addressArray[1].trim();
        city = addressArray[2].trim();
        state = addressArray[3].trim();
        zip = addressArray[4].trim();
      }
      console.log(fullAddress, addressArray, city, state, zip);
      const relationshipProperty = insuranceData['insureds-relationship-to-patient'];
      const mappedRelationship = relationshipMapping[relationshipProperty];
      setFormValues({
        Insurance_Name:
          planList[insuranceData['insurance-plan-id']]?.label ||
          insuranceData['free-form-name'] ||
          '',
        relationship: relationshipProperty || 'SEL',
        Insured_First_Name: insuranceData['first-name-of-insured'] || '',
        Insured_Last_Name: insuranceData['last-name-of-insured'] || '',
        gender: insuranceData['insureds-gender'] || '',
        Insured_Phone: insuranceData['insureds-phone-number'] || '',
        Insured_DOB: insuranceData['insureds-date-of-birth'] || '',
        Insured_Group_Number: insuranceData['group-number'] || '',
        Insured_ID_Number: insuranceData['policy-number'] || '',
        Address_1: insuranceData['insureds-address-1'] || '',
        Address_2: insuranceData['insureds-address-2'] || '',
        City: insuranceData['insureds-city'] || '',
        Zip: insuranceData['insureds-zip-code'] || '',
        State: insuranceData['insureds-state'] || '',
        not_primary_address: !!insuranceData['insureds-address-1'],
      });

      if (insuranceData['insurance-company-name']) {
        setSelectedOption({
          value: insuranceData['insurance-plan-id'],
          label: insuranceData['insurance-company-name'],
        });
      }
    }
  }, [insuranceData, planList]);

  useEffect(() => {
    (async () => {
      await searchPlan('');
      await loadData();
    })();
  }, []);

  const loadData = async () => {
    const response = await getUserData(familyMemberId);
    const insuranceDataObject = response?.included?.[0]?.attributes;
    setInsuranceData(insuranceDataObject || {});
    if (insuranceDataObject) {
      setHasData(true);
    }
  };

  const notReadyToSubmit = useMemo(() => {
    let res = false;
    res =
      !formValues.Insured_ID_Number ||
      !formValues.Insured_Group_Number ||
      !formValues.Insurance_Name ||
      (files.length < 1 && !insuranceData['last-insurance-id-url']) ||
      (formValues.relationship !== 'SEL' &&
        (!formValues.Insured_First_Name ||
          !formValues.Insured_Last_Name ||
          !formValues.gender ||
          !formValues.Insured_DOB ||
          !formValues.Insured_Phone)) ||
      (formValues.not_primary_address &&
        (!formValues.Address_1 ||
          !formValues.City ||
          !formValues.Zip ||
          !formValues.State ||
          formValues.Zip.length !== 5));
    return res;
  }, [formValues, files]);

  async function loadUser() {
    const user = await getUserData(props.userAuthentication.user_id, props.userAuthentication.jwt);
    props.setUserDispatch(user.data);
    setUserInsuranceDispatch(user.included);
  }

  const onChange = (e) => {
    const { name, value } = e?.target;

    if (name === 'Insured_First_Name' || name === 'Insured_Last_Name') {
      if (!RegEx.name.test(value)) return;
    }

    if (name === 'Insured_Phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      return setFormValues({ ...formValues, [name]: formattedPhoneNumber });
    }

    if (name === 'Zip') {
      if (isNaN(value) || value.length >= 6) return;
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const onSubmitForm = () => {
    const formData = { ...formValues };
    delete formData.not_primary_address;
    delete formData.Address_2;

    if (formValues.relationship === 'SEL') {
      delete formData.Insured_First_Name;
      delete formData.Insured_Last_Name;
      delete formData.gender;
      delete formData.Insured_Phone;
      delete formData.Insured_DOB;
    }

    if (!formValues.not_primary_address) {
      delete formData.Address_1;
      delete formData.City;
      delete formData.Zip;
      delete formData.State;
    }

    let res = false;
    Object.keys(formData).forEach((val) => {
      if (!formData[val]) return (res = true);
    });

    handleSubmit({ ...formData });
  };

  console.log('files', files);

  async function getUrl() {
    const { jwt } = props.userAuthentication;

    const preSigned = {
      filename: files[0]?.name,
      image_type: files[0]?.type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'pre-signed-post',
    });
    return data;
  }

  async function saveFileName(fileName, insurancePlanId) {
    const { jwt } = props.userAuthentication;

    const data = await processAPI({
      key: jwt,
      methodType: 'PATCH',
      data: {
        filename: fileName,
        imageable_id: insurancePlanId,
        imageable_type: 'InsurancePolicy',
      },
      endpoint: 'image',
    });

    return data;
  }

  async function searchPlan(planLabel) {
    const endpoint = `insurance-plans?filter[plan-name]=${planLabel}`;
    const { jwt: key } = props.userAuthentication;

    const data = await processAPI({ endpoint, key, methodType: 'GET' });
    const plans = data.map((item) => {
      const { attributes } = item;
      const label =
        attributes['company-name'] === attributes['plan-name']
          ? attributes['plan-name']
          : `${attributes['plan-name']}-${attributes['company-name']}`;
      return { value: item.id, label };
    });

    setPlanList(plans);
    return plans;
  }

  async function handleSubmit(values) {
    const { jwt } = props.userAuthentication;

    let meta;
    try {
      if (files?.length > 0) {
        meta = await getUrl();
        await uploadFile(meta.url, files?.[0], meta.fields);
      }
      console.log('values', values);
      const myData = {
        data: {
          type: 'insurance-policies',
          attributes: {
            'policy-number': values?.Insured_ID_Number,
            'group-number': values?.Insured_Group_Number,
            'first-name-of-insured': values?.Insured_First_Name,
            'last-name-of-insured': values?.Insured_Last_Name,
            'insureds-address': `${values?.Address_1}^ ${values?.Address_2}^ ${values?.City}^ ${values?.State?.label}^ ${values?.Zip}`,
            'insureds-address-1': values?.Address_1,
            'insureds-address-2': values?.Address_2 && values?.Address_2,
            'insureds-city': values?.City,
            'insureds-state': values?.State,
            'insureds-zip-code': values?.Zip,
            'insureds-gender': values?.gender,
            'insureds-phone-number': values?.Insured_Phone,
            'insureds-relationship-to-patient': values?.relationship,
            'insureds-date-of-birth': values?.Insured_DOB,
            'insurance-plan-id': (selectedOption && parseInt(selectedOption?.value)) || null,
            'free-form-name': insuranceData['free-form-name'] || null,
          },
          relationships: {
            user: {
              data: { type: 'users', id: parseInt(familyMemberId) },
            },
          },
        },
      };

      !hasData && (myData.data.id = insuranceId);
      const data = await processAPI({
        key: jwt,
        methodType: hasData ? 'POST' : 'PATCH',
        data: myData,
        endpoint: hasData ? 'insurance-policies' : `insurance-policies/${insuranceId}`,
      });

      if (data.id) {
        await saveFileName(meta?.fields?.key, data.id);
        await loadUser();
        history.push('/no-cost-testing', {
          selectedMember: familyMemberId,
          kit: state?.kit,
        });
      }
    } catch (error) {}
  }

  return (
    <>
      <Layout headerTitle={t('editInsuranceInformation')} onClickBack={() => history.goBack()} back>
        <div className="row m-0 p-0 mt-3 pt-1 ml-sm-5">
          <div className="col-12 col-sm-7">
            <div className="insurance-header">Insurance Information</div>
            {/*  */}
            <div className=" my-4">
              <div className="insurance-label">Insurance Name</div>

              {insuranceNotListed && (
                <>
                  <div className="my-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      checked={insuranceNotListed}
                      onChange={(e) => {
                        setInsuranceNotListed(e?.target?.checked);
                      }}
                      className="check mr-2"
                    />
                    <div className="not-listed">Insurance not listed?</div>
                  </div>

                  <div className="mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Insurance_Name"
                      placeholder="Please type in the insurance name"
                      value={formValues?.Insurance_Name}
                      onChange={onChange}
                    />
                  </div>
                </>
              )}

              {!insuranceNotListed && (
                <div className="position-relative  mb-3 pb-1">
                  <Select
                    classNamePrefix="form-select"
                    name="Insurance_Name"
                    placeholder="Please type in the insurance name"
                    value={selectedOption}
                    options={planList}
                    onChange={(e) => {
                      console.log(e);
                      setFormValues({
                        ...formValues,
                        Insurance_Name: e?.value,
                      });
                      setSelectedOption(e);
                    }}
                  />
                </div>
              )}
            </div>

            {!insuranceNotListed && (
              <div className="my-4 d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={insuranceNotListed}
                  onChange={(e) => {
                    setInsuranceNotListed(e?.target?.checked);
                  }}
                  className="check mr-2"
                />
                <div className="not-listed">Insurance not listed?</div>
              </div>
            )}

            <div className="my-4">
              <div className="insurance-label">Relationship to the Insured</div>

              <div className="d-flex flex-wrap flex-sm-nowrap  align-items-center">
                <div
                  className={`mt-3 ml-3 ml-sm-0 d-flex justify-content-center align-items-center ${
                    formValues?.relationship === 'SEL' ? 'selected-button' : 'unSelected-button'
                  }`}
                  onClick={() => setFormValues({ ...formValues, relationship: 'SEL' })}
                >
                  {formValues?.relationship === 'SEL' && (
                    <img src={Check} alt="Check" className="mr-2" />
                  )}
                  Self
                </div>

                <div
                  className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                    formValues?.relationship === 'SPO' ? 'selected-button' : 'unSelected-button'
                  }`}
                  onClick={() => setFormValues({ ...formValues, relationship: 'SPO' })}
                >
                  {formValues?.relationship === 'SPO' && (
                    <img src={Check} alt="Check" className="mr-2" />
                  )}
                  Spouse
                </div>

                <div
                  className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                    formValues?.relationship === 'CHD' ? 'selected-button' : 'unSelected-button'
                  }`}
                  onClick={() => setFormValues({ ...formValues, relationship: 'CHD' })}
                >
                  {formValues?.relationship === 'CHD' && (
                    <img src={Check} alt="Check" className="mr-2" />
                  )}
                  Child
                </div>

                <div
                  className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                    formValues?.relationship === 'OTH' ? 'selected-button' : 'unSelected-button'
                  }`}
                  onClick={() => setFormValues({ ...formValues, relationship: 'OTH' })}
                >
                  {formValues?.relationship === 'OTH' && (
                    <img src={Check} alt="Check" className="mr-2" />
                  )}
                  Other
                </div>

                <div
                  className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                    formValues?.relationship === 'UNK' ? 'selected-button' : 'unSelected-button'
                  }`}
                  onClick={() => setFormValues({ ...formValues, relationship: 'UNK' })}
                >
                  {formValues?.relationship === 'UNK' && (
                    <img src={Check} alt="Check" className="mr-2" />
                  )}
                  Unknown
                </div>
              </div>
            </div>
            {formValues?.relationship && formValues?.relationship !== 'SEL' && (
              <>
                <div className=" my-4">
                  <div className="insurance-label">Insured First Name</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Insured_First_Name"
                      placeholder="Enter First Name"
                      value={formValues?.Insured_First_Name}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">Insured Last Name</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Insured_Last_Name"
                      placeholder="Enter Last Name"
                      value={formValues?.Insured_Last_Name}
                      onChange={onChange}
                    />
                  </div>
                </div>

                {/* Gender */}
                <div className="my-4">
                  <div className="insurance-label">Insured Gender</div>

                  <div className="d-flex flex-wrap flex-sm-nowrap  align-items-center">
                    <div
                      className={`mt-3 ml-3 ml-sm-0 d-flex justify-content-center align-items-center ${
                        formValues?.gender === 'Male' ? 'selected-button' : 'unSelected-button'
                      }`}
                      onClick={() => setFormValues({ ...formValues, gender: 'Male' })}
                    >
                      {formValues?.gender === 'Male' && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Male
                    </div>

                    <div
                      className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                        formValues?.gender === 'Female' ? 'selected-button' : 'unSelected-button'
                      }`}
                      onClick={() => setFormValues({ ...formValues, gender: 'Female' })}
                    >
                      {formValues?.gender === 'Female' && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Female
                    </div>

                    <div
                      className={`mt-3 ml-3  d-flex justify-content-center align-items-center ${
                        formValues?.gender === 'Other' ? 'selected-button' : 'unSelected-button'
                      }`}
                      onClick={() => setFormValues({ ...formValues, gender: 'Other' })}
                    >
                      {formValues?.gender === 'Other' && (
                        <img src={Check} alt="Check" className="mr-2" />
                      )}
                      Other
                    </div>
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">Insured Phone</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Insured_Phone"
                      placeholder="000-000-0000"
                      value={formValues?.Insured_Phone}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">Insured Date of Birth</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      type="date"
                      className="insurance-input"
                      name="Insured_DOB"
                      placeholder="MM-DD-YYYY"
                      value={formValues?.Insured_DOB}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </>
            )}
            <div className=" my-4">
              <div className="insurance-label">Insured Group Number</div>

              <div className="position-relative  mb-3 pb-1">
                <input
                  className="insurance-input"
                  name="Insured_Group_Number"
                  placeholder="Enter Group Number"
                  value={formValues?.Insured_Group_Number}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className=" my-4">
              <div className="insurance-label">Insured ID Number</div>

              <div className="position-relative  mb-3 pb-1">
                <input
                  className="insurance-input"
                  name="Insured_ID_Number"
                  placeholder="Enter ID Number"
                  value={formValues?.Insured_ID_Number}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="my-4 d-flex align-items-center">
              <input
                type="checkbox"
                checked={formValues?.not_primary_address}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    not_primary_address: e?.target?.checked,
                  })
                }
                className="check mr-2"
              />
              <div className="not-listed">Insured address is different from primary address</div>
            </div>
            {formValues?.not_primary_address && (
              <>
                <div className=" my-4">
                  <div className="insurance-label">Address</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Address_1"
                      placeholder="Enter Address"
                      value={formValues?.Address_1}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">Address 2: Apartment, suite, etc. </div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Address_2"
                      placeholder="Enter Address 2"
                      value={formValues?.Address_2}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">City</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="City"
                      placeholder="Enter City"
                      value={formValues?.City}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">State</div>

                  <div className="position-relative  mb-3 pb-1">
                    {/* <input
                      className="insurance-input"
                      name="State"
                      placeholder="Enter State"
                      value={formValues?.State}
                      onChange={onChange}
                    /> */}
                    <Select
                      placeholder="Enter State"
                      value={formValues?.State?.value}
                      defaultInputValue={formValues?.State}
                      // inputValue={formValues?.State?.label}
                      onChange={(e) => {
                        console.log(e);
                        setFormValues({ ...formValues, State: e?.value });
                        setSelectedState(e);
                        console.log(formValues);
                      }}
                      options={stateList.map((s) => ({
                        value: s.value,
                        label: s.label,
                      }))}
                    />
                  </div>
                </div>

                <div className=" my-4">
                  <div className="insurance-label">Zip</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      className="insurance-input"
                      name="Zip"
                      placeholder="Enter Zip"
                      value={formValues?.Zip}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </>
            )}

            <div className=" my-4">
              <div className="insurance-label">Insurance Card Image is Required</div>
              {files.length == 0 && insuranceData['last-insurance-id-url'] && (
                <img src={insuranceData['last-insurance-id-url']} width="100" height="100" />
              )}

              <div className="my-3 position-relative input-image d-flex align-items-center justify-content-center mb-3 pb-1">
                {files.length == 0 && (
                  <>
                    <img src={aura_icon_camera} alt="aura_icon_camera" />
                    <div className="ml-2">Insurance Card Image</div>
                  </>
                )}
                <input
                  type="file"
                  className="position-absolute z-50 insurance-fileImage"
                  accept="image/png, image/jpeg"
                  style={{ opacity: '0', cursor: 'pointer' }}
                  onChange={(e) => {
                    setFiles(e.target.files);
                  }}
                />
              </div>
              {files && files.length > 0 && (
                <div style={{ display: 'flex', flexDirectio: 'row' }}>
                  <img src={URL.createObjectURL(files?.[0])} alt="insurance-card" />
                  <img
                    src={Replace}
                    alt="replace"
                    className="replacebutton"
                    onClick={() => setFiles([])}
                  />{' '}
                </div>
              )}
            </div>

            <div
              className="InsuranceForm__continue d-flex align-items-center justify-content-center my-5"
              style={{
                opacity: notReadyToSubmit ? '0.4' : '1',
              }}
              onClick={() => {
                if (!notReadyToSubmit) {
                  onSubmitForm();
                }
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state?.user?.userProfile?.[0],
  userInsurance: state?.user?.insurance?.[0],
  userAuthentication: state.authentication,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(ActivationInsurance);
