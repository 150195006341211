import React from 'react';
import './index.css';
import ReportIcon from '../../../assets/image/ReportIcon.png';
import Green_Tick from '../../../assets/image/Green_Tick.png';

export default function Intro({ onCancel, onBegin }) {
  return (
    <>
      <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
        <div className="col-12 col-sm-11 col-xl-7 border d-flex p-2 py-4 py-lg-0 p-lg-5 flex-column align-items-center justify-content-center mt-3 mb-5">
          <div className="mb-3 content-header d-none d-sm-block">
            Here is what you will need to upload the test result.
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="">
              <img src={ReportIcon} alt="ReportIcon" />
            </div>

            <div className="my-3 content-header d-block d-sm-none">
              Here is what you will need to upload the test result.
            </div>

            <div className="ml-1 ml-lg-5 pl-lg-5 flex-grow-1">
              {['Testing Site Information', 'Date & Time', 'Test Result', 'Test Result File'].map(
                (val, i) => (
                  <div key={i} className="list-Box d-flex align-items-center my-3">
                    <img src={Green_Tick} alt="Green_Tick" />
                    <span className="ml-4 ">{val}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5 ml-sm-3 px-1 px-sm-0">
        <div className="col-5 col-md-3 p-0">
          <div className="cancel mr-3" onClick={onCancel}>
            Cancel
          </div>
        </div>
        <div className="col-7 col-sm-6 col-md-4 begin" onClick={onBegin}>
          Begin
        </div>
      </div>
    </>
  );
}
