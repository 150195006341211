import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, StrokeButton } from '../../components';
import css from './VaccineInformation.module.css';
import { processAPI } from '../../api/models/process';
import Syringe from '../../assets/svg/syringe_large.svg';
import CirclePlus from '../../assets/svg/circle-plus.svg';
import { setUser } from '../../store/action/user';
import IsVaccinated from './IsVaccinated';
import VaxUploadCard from './VaxUploadCard';
import SideMenu from './SideMenu';
import IllustrationSuccess from '../../assets/image/illustration_success.png';
import { getUserData, getUserFamilyMembers } from '../../api/models/user';
import OpenTriangle from '../../assets/image/OpenTriangleF.png';
import Shield from '../../assets/image/Sh.png';
import Shield_toast from '../../assets/image/Shield_toast.png';
import ExitModal from '../../components/ExitModal/ExitModal';
import Toaster from '../../components/Toaster/Toaster';
import { Spinner } from 'react-bootstrap';
import { addFamilyMemberStepList } from '../../utils/sidebarSteps';

const VaccineInformation = (props) => {
  const history = useHistory();
  const { state } = useLocation();
  let { id, newUser } = useParams();
  if (!id) {
    id = props?.user?.id;
  }

  const [openModal, setOpenModal] = useState(false);
  const [vaccinations, setVaccinations] = useState([]);
  const [availableVaccines, setAvailableVaccines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(); // for user details
  const { jwt } = props?.authentication;

  const navInsurance = props?.user?.attributes?.['requires-insurance'];
  const navVaccines = props?.user?.attributes?.['nav-vaccines'];
  const stepList = addFamilyMemberStepList(!navInsurance, !navVaccines);

  async function loadUser() {
    const user = await getUserData(props?.authentication?.user_id, jwt);
    props?.setUserDispatch(user?.data);
  }

  function getAvailableVaccines() {
    processAPI({
      endpoint: 'all-vaccines',
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        let vaccines = data?.vaccines;
        let vax = vaccines.map((x) => ({ value: x.id, label: x.name }));
        setAvailableVaccines(vax);
      })
      .catch((error) => console.log(error));
  }

  function loadVax() {
    processAPI({
      endpoint: `vaccinations`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        let filtered_data = data?.filter((val) => val?.attributes['user-id'] == parseInt(id));

        let sorted_data = [];

        vaccinations?.forEach((val) => {
          let result = filtered_data?.find(
            (filtered_data_ele) => filtered_data_ele?.id === val?.id
          );
          if (result) sorted_data.push(result);
        });

        filtered_data?.forEach((val) => {
          let result = vaccinations?.find((vaccinations_ele) => vaccinations_ele?.id === val?.id);
          if (!result) sorted_data.push(val);
        });

        setVaccinations([...sorted_data]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const loadData = async () => {
    let familyMemberList = await getUserFamilyMembers(
      props?.authentication?.user_id,
      props?.authentication?.jwt
    );

    if (familyMemberList?.included) familyMemberList = [...familyMemberList?.included, props?.user];
    else familyMemberList = [props?.user];

    const member = familyMemberList?.find((member) => member?.id == id);

    const unsigned = member?.attributes?.['hipaa-release-details']?.unsigned || [];

    if (unsigned?.length) history.push(`/sign-doc/${id}`);
    else setUser(member);
  };

  useEffect(() => {
    loadUser();
    loadVax();
    getAvailableVaccines();
    !newUser && loadData();
  }, []);

  useEffect(() => {
    const root = document?.getElementById('root');
    root?.scrollTo(0, 0);
    window?.scrollTo(0, 0);
  }, []);

  const [isVaccinatedStep, setIsVaccinatedStep] = useState(1);

  const saveNExit = () => {
    setIsVaccinatedStep(3);
    history.push('/new-user-welcome');
  };

  if (newUser)
    return (
      <>
        {isVaccinatedStep === 1 ? (
          <IsVaccinated
            onSubmit={(val) => {
              if (val) {
                vaccinations?.length === 0 && setVaccinations([{}]);
                setIsVaccinatedStep(2);
              } else history.push('/new-user-welcome');
            }}
          />
        ) : isVaccinatedStep === 2 ? (
          <IsVaccinated goBack={vaccinations?.at(-1)?.id ? null : () => setIsVaccinatedStep(1)}>
            <div className="d-flex justify-content-center align-items-center">
              <div className={`${css.vaccineCardContainer}`}>
                {vaccinations.map((vax, i) => (
                  <div className="my-4" key={vax?.id || i}>
                    <VaxUploadCard
                      loadDoses={loadVax}
                      user_id={id}
                      availableVaccines={availableVaccines}
                      vax={vax}
                      index={i}
                      expanded={i + 1 === vaccinations?.length}
                    />
                  </div>
                ))}

                {vaccinations?.length > 0 && vaccinations?.at(-1)?.id ? (
                  <>
                    <div className={`${css.add_vaccine} my-4`}>Have another vaccine to add?</div>

                    <StrokeButton
                      title="Upload New Dose"
                      icon={CirclePlus}
                      className={css.uploadNewDoseButton}
                      handlePress={() => setVaccinations([...vaccinations, {}])}
                    />

                    <div className={`${css.whiteButton} my-5`} onClick={saveNExit}>
                      Save & Exit
                    </div>
                  </>
                ) : vaccinations?.length > 1 ? (
                  <div
                    className={`${css.whiteButton} my-5`}
                    onClick={() => {
                      let temp_vax = [...vaccinations];
                      temp_vax.pop();
                      setVaccinations([...temp_vax]);
                    }}
                  >
                    Cancel
                  </div>
                ) : null}
              </div>
            </div>
          </IsVaccinated>
        ) : (
          <IsVaccinated>
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center my-5 py-sm-5">
                <img src={IllustrationSuccess} alt="IllustrationSuccess" />
                <div className={`${css.VaccinationCompleted} my-4`}>Vaccination Completed!</div>
              </div>
            </div>
          </IsVaccinated>
        )}
      </>
    );

  if (state?.progressBar)
    return (
      <>
        <ExitModal
          icon={Shield}
          open={openModal}
          onExit={() => {
            history.push('/family');
          }}
          onStay={() => setOpenModal(false)}
          header="Insurance Completed!"
          sub_header="Please remember to return and add vaccine information for your new family member soon."
        />

        {!state?.notInsured && (
          <Toaster
            icon={Shield_toast}
            show
            header="Congratulations!"
            sub_header="Insurance has been assigned"
          />
        )}

        <Layout
          progressBar={state?.progressBar}
          progressBarStepsList={stepList}
          progressBarCurrentStep={4}
          onClickBack={
            vaccinations?.length > 0
              ? null
              : () => {
                  setOpenModal(true);
                }
          }
          headerTitle={
            props?.user?.id !== id ? 'Vaccination Information' : 'My Vaccine Information'
          }
          back={!(vaccinations?.length > 0)}
        >
          <div className="ml-4 mb-5">
            <div className={`${css.vaccineCardContainer2} ml-2`}>
              {user && (
                <>
                  <div className={`${css.name_header} mt-1 pt-1`}>
                    {user?.attributes?.['first-name']} {user?.attributes?.['last-name']}
                  </div>
                  <div className={`${css.form_header} mb-4 pt-1`}>
                    Please fill out all vaccination details.
                  </div>
                </>
              )}

              {(vaccinations?.length === 0 ? [{}] : vaccinations)?.map((vax, i, arr) => (
                <div className="my-4" key={vax?.id || i}>
                  <VaxUploadCard
                    loadDoses={loadVax}
                    user_id={id}
                    availableVaccines={availableVaccines}
                    vax={vax}
                    index={i}
                    expanded={i + 1 === arr?.length}
                  />
                </div>
              ))}

              {vaccinations?.length > 0 && vaccinations?.at(-1)?.id ? (
                <div className="mb-5">
                  <div className={`${css.add_vaccine} my-4`}>Have another vaccine to add?</div>

                  <StrokeButton
                    title="Upload New Dose"
                    icon={CirclePlus}
                    className={css.uploadNewDoseButton}
                    handlePress={() => setVaccinations([...vaccinations, {}])}
                  />

                  <div
                    className={`${css.whiteButton} my-5`}
                    onClick={() => {
                      history?.push('/family');
                    }}
                  >
                    Save & Exit
                  </div>
                </div>
              ) : vaccinations?.length > 1 ? (
                <div
                  className={`${css.whiteButton} my-5`}
                  onClick={() => {
                    let temp_vax = [...vaccinations];
                    temp_vax.pop();
                    setVaccinations([...temp_vax]);
                  }}
                >
                  Cancel
                </div>
              ) : (
                <div
                  className={`${css.whiteButton} my-5`}
                  onClick={() => {
                    history?.push('/family');
                  }}
                >
                  Unvaccinated
                </div>
              )}
            </div>
          </div>
        </Layout>
      </>
    );

  return (
    <Layout
      onClickBack={() => history.push('/family')}
      headerTitle={props?.user?.id !== id ? 'Vaccination Information' : 'My Vaccine Information'}
      tab={
        props?.user?.attributes['family-member-ids']?.find((item) => item === Number(id))
          ? 'family'
          : 'vaccine'
      }
      back
      rightText={
        <div className={css.sideMenu}>
          <SideMenu userDetail={user} />
        </div>
      }
    >
      {loading ? (
        <div className="mt-5 pt-5 d-flex justify-content-center">
          <Spinner animation="grow" variant="warning" />
        </div>
      ) : (
        <div className="ml-4 mb-5">
          {user && props?.user?.id !== user?.id && (
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center">
              <span className={`${css.tabText} ml-2 mr-2 mr-sm-3`}>My Aura</span>

              <img src={OpenTriangle} alt="OpenTriangle" />
              <span className={`${css.tabText} mx-3 `}>My Family</span>

              <img src={OpenTriangle} alt="OpenTriangle" />
              <span className={`${css.tabText} mx-3 `}>
                {user?.attributes?.['first-name']} {user?.attributes?.['last-name']}
              </span>

              <img src={OpenTriangle} alt="OpenTriangle" />
              <span className={`${css.tabText} mx-2 mx-lg-3`} style={{ color: '#FF9900' }}>
                Vaccination Information
              </span>
            </div>
          )}

          {vaccinations?.length > 0 ? (
            <div className={`${css.vaccineCardContainer2} ml-2`}>
              <div className="d-flex justify-content-between align-items-center">
                {user && props?.user?.id !== id && (
                  <div className={`${css.name_header} my-4 pt-1`}>
                    {user?.attributes?.['first-name']}'s Vaccinations
                  </div>
                )}

                <div className={css.sideMenu2}>
                  <SideMenu userDetail={user} />
                </div>
              </div>

              {vaccinations?.map((vax, i) => (
                <div className="my-4" key={vax?.id || i}>
                  <VaxUploadCard
                    loadDoses={loadVax}
                    user_id={id}
                    availableVaccines={availableVaccines}
                    vax={vax}
                    index={i}
                    expanded={i + 1 === vaccinations?.length}
                  />
                </div>
              ))}

              {vaccinations?.length > 0 && vaccinations[vaccinations.length - 1]?.id ? (
                <div className="mb-5">
                  <div className={`${css.add_vaccine} my-4`}>Have another vaccine to add?</div>

                  <StrokeButton
                    title="Upload New Dose"
                    icon={CirclePlus}
                    className={css.uploadNewDoseButton}
                    handlePress={() => setVaccinations([...vaccinations, {}])}
                  />
                </div>
              ) : vaccinations?.length > 1 ? (
                <div
                  className={`${css.whiteButton} my-5`}
                  onClick={() => {
                    let temp_vax = [...vaccinations];
                    temp_vax.pop();
                    setVaccinations([...temp_vax]);
                  }}
                >
                  Cancel
                </div>
              ) : null}
            </div>
          ) : (
            <div className={`${css.vaccineCardContainer2} my-5`}>
              <div className={css.noVaccinationsContainer}>
                <img src={Syringe} alt="syringe" className={css.syringe} />
                <p className={css.noVaxTitle}>I am not vaccinated</p>

                <div className={css.noVaxSubtitle}>
                  <div>You have indicated that you are not</div>
                  <div>currently vaccinated for COVID-19.</div>
                </div>

                <p className={`${css.noVaxSubtitle2} my-4`}>
                  <div>Please return to this page to document your</div>
                  <div> vaccination if your vaccination status changes.</div>
                </p>

                <a
                  className={css.cdcLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cdc.gov/coronavirus/2019-ncov/vaccines/vaccine-benefits.html"
                >
                  Visit the CDC Portal
                </a>

                <p className={`${css.noVaxSubtitle3} mt-5 mb-4`}>
                  Have you received the COVID-19 Vaccine?
                </p>

                <StrokeButton
                  title="Upload Vaccination Info"
                  icon={CirclePlus}
                  className={css.uploadNewDoseButton}
                  handlePress={() => setVaccinations([{}])}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userInsurance: state.user.insurance[0],
  authentication: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VaccineInformation);
