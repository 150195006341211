import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { GreetingLogo } from '../../components';
import BarcodeScannerSvg from '../../assets/svg/barcode_scanner.svg';
import MousePointerClickSvg from '../../assets/svg/mouse_pointer_click.svg';
import CheckSvg from '../../assets/svg/check.svg';
import { isUsability } from '../../api/const';

const config = [
  {
    img: <img src={MousePointerClickSvg} alt="mouse_pointer_click" />,
    title: isUsability ? 'I Do Not Have a Code' : 'I Ordered My Test Online',
    description: isUsability
      ? 'Patients who received their test kit and do not have a Promo Code to register with should use this option.'
      : 'Patients who received their test kit online and do not have a Promo Code. Use this option to properly register their test.',
    buttonTitle: 'I ordered my test online',
  },
  {
    img: <img src={BarcodeScannerSvg} alt="barcode_scanner" />,
    title: 'I Have a Promo Code',
    description:
      'Patients who have a Promo Code from their organization should use this option to properly register their test.',
    buttonTitle: 'I have a Promo Code',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflowX: 'hidden',
    fontFamily: 'SF Pro Display',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
  },
  header: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '22px',
    fontFamily: 'SF Pro Display',
  },
  body: {
    paddingTop: theme.spacing(2),
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    marginBottom: theme.spacing(4),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 24px',
    border: '1.5px solid #D9D9D9',
    boxSizing: 'border-box',
    borderRadius: 20,
    width: 343,
    cursor: 'pointer',
    opacity: 0.5,
  },
  itemSelected: {
    borderColor: '#FF9900',
    opacity: 1,
  },
  itemTitle: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    fontFamily: 'SF Pro Display',
  },
  itemDescription: {
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    color: '#777777',
    marginBottom: theme.spacing(1),
    fontFamily: 'SF Pro Display',
  },
  itemButton: {
    backgroundColor: '#E8E8E8',
    height: 30,
    borderRadius: 100,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
    '&:focus': {
      outline: 0,
    },
  },
  itemButtonSelected: {
    backgroundColor: '#FF9900',
    '&:hover': {
      backgroundColor: '#FF9900',
    },
  },
  activationSubmit: {
    backgroundColor: '#FF9900',
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:hover': {
      backgroundColor: '#FF9900',
    },
    '&:focus': {
      outline: 0,
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  checkImg: {
    marginRight: theme.spacing(1),
    transition: 'all .08s ease-out',
  },
}));

const ActivationItem = ({ img, title, description, buttonTitle, selected, onClick }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.item, selected ? classes.itemSelected : '')}
      onClick={onClick}
    >
      <div>
        {img}
        <Typography component="span" className={classes.itemTitle}>
          {title}
        </Typography>
      </div>
      <Typography className={classes.itemDescription}>{description}</Typography>
      <Button
        className={classNames(classes.itemButton, selected ? classes.itemButtonSelected : '')}
        disabled
        fullWidth
      >
        <img className={classes.checkImg} src={CheckSvg} alt="check" width={selected ? 15 : 0} />
        <span>{buttonTitle}</span>
      </Button>
    </div>
  );
};

const Activation = () => {
  const history = useHistory();
  const classes = useStyles();
  const [activeFlow, setActiveFlow] = useState(-1);

  const handleOnClick = () => {
    if (activeFlow === 0) history.push('/activation-login');
    if (activeFlow === 1) history.push('/promo-code');
  };

  return (
    <div className={classes.root}>
      <GreetingLogo />
      <Typography className={classes.header}>Begin Registering Your Test Kit</Typography>
      <div className={classes.body}>
        <div className={classes.itemContainer}>
          {config.map((item, index) => (
            <ActivationItem
              key={item.title}
              onClick={() => setActiveFlow(activeFlow === index ? -1 : index)}
              selected={activeFlow === index}
              {...item}
            />
          ))}
        </div>
        <Button
          disabled={activeFlow < 0}
          className={classes.activationSubmit}
          fullWidth
          onClick={handleOnClick}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
