import React, { useEffect, useState } from 'react';
import './index.css';
import './Atoms/SelectInput.css';
import FileIcon from '../../../assets/image/Doc.png';
import GreenTick from '../../../assets/image/Green_Tick.png';
import CutIcon from '../../../assets/image/Trash.png';
import { stateList } from '../../../utils/utils';
import { processAPI } from '../../../api/models/process';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useHistory } from 'react-router';
import { uploadFile } from '../../../api/models/forms';
import Exclamationmark from '../../../assets/image/Exclamationmark_R.png';
import Modal from 'react-bootstrap/Modal';

function FinalSubmit({ onBack, onNext, values, userProfile, ...userAuthentication }) {
  //
  const history = useHistory();
  const [finalValues, setFinalValues] = useState(values);

  const [loader, setLoader] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!values?.labName) {
      setFinalValues({
        ...finalValues,
        addressLine1: userProfile['address-line-1'],
        addressLine2: userProfile['address-line-2'],
        city: userProfile?.city,
        state:
          stateList?.find(
            (option) => option?.label === userProfile?.state || option?.value === userProfile?.state
          )?.label || '',
        zip: userProfile?.zip,
        labName: 'At Home',
      });
    }
  }, []);

  async function getUrl() {
    const jwt = userAuthentication?.userAuthentication?.jwt;

    const preSigned = {
      original_filename: finalValues?.files?.[0]?.name,
      content_type: finalValues?.files?.[0]?.type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'results_upload/presigned_post',
    });
    return data;
  }

  const handleSubmit = async () => {
    setLoader(true);
    try {
      const jwt = userAuthentication?.userAuthentication?.jwt;
      let meta;

      if (finalValues?.files?.length > 0) {
        meta = await getUrl();
        await uploadFile(meta?.url, finalValues?.files[0], meta?.fields);
      }

      let data = {};
      data = {
        name: finalValues?.labName,
        address_line_1: finalValues?.addressLine1,
        address_line_2: finalValues?.addressLine2,
        city: finalValues?.city,
        state: finalValues?.state,
        zip_code: finalValues?.zip,
        file_name: meta?.file_name,
        test_date: `${moment(finalValues?.date).format('YYYY-MM-DD')} ${finalValues?.time}`,
        result: finalValues?.testResult,
      };

      const response = await processAPI({
        key: jwt,
        methodType: 'POST',
        endpoint: 'results_upload',
        data,
      });
      setLoader(false);
      history.push('/my-tests');
    } catch (e) {
      setLoader(false);
      e?.test_date && alert(e?.test_date);
    }
  };

  return (
    <>
      <Modal show={openModal} backdrop="static" keyboard={false} dialogClassName="upload_modal">
        <div className="py-4 px-1 p-sm-5 upload_modal_content">
          <div className="row">
            <div className="col-4 p-0 d-flex justify-content-center">
              <img src={Exclamationmark} alt="Exclamationmark" width="75px" height="75px" />
            </div>
            <div className="col-8">
              <div className="UploadFile-header">
                Are you sure would like to cancel your result upload?
              </div>
              <div className="my-2 UploadFile-drag text-left pt-1">
                Progress will not be saved upon cancelation.
              </div>
            </div>
          </div>

          <div className="row my-3">
            <div className="col-5">
              <div className="cancel" onClick={() => setOpenModal(false)}>
                Stay
              </div>
            </div>
            <div className="col-7">
              <div className="cancel" onClick={() => history.push('/my-tests')}>
                Cancel Upload
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
        <div className="col-12 col-sm-10 col-xl-7 mb-5 mt-3 px-3 px-sm-0">
          <div className="mb-3 pb-1 FinalSubmit-main-header">
            Please review and confirm the information provided below.
          </div>

          <div>
            <div className="mb-3 FinalSubmit-header">1. Testing Site Information</div>

            <div className="">
              <div className="FinalSubmit-label">Lab Name</div>

              <div className="position-relative  mb-3 pb-1">
                <input
                  readOnly
                  className="FinalSubmitInput"
                  id="Lab Name"
                  placeholder="Lab Name"
                  value={finalValues?.labName}
                  onChange={(e) =>
                    setFinalValues({
                      ...finalValues,
                      labName: e?.target?.value,
                    })
                  }
                />

                <div className="position-absolute FinalSubmit-edit mb-1">
                  <span
                    onClick={() => {
                      onBack(1);
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>

            {values?.labName && (
              <>
                <div className="">
                  <div className="FinalSubmit-label">Address 1</div>
                  <div className="position-relative mb-3 pb-1">
                    <input
                      readOnly
                      className="FinalSubmitInput"
                      id="Address 1"
                      placeholder="Address 1"
                      value={finalValues?.addressLine1}
                      onChange={(e) =>
                        setFinalValues({
                          ...finalValues,
                          addressLine1: e?.target?.value,
                        })
                      }
                    />

                    <div className="position-absolute FinalSubmit-edit mb-1">
                      <span
                        onClick={() => {
                          onBack(1);
                        }}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="FinalSubmit-label">
                    Address 2{' '}
                    <span className="ml-1 text-muted">Apartment, suite, etc. (optional)</span>
                  </div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      readOnly
                      className="FinalSubmitInput"
                      id="Address 2"
                      // placeholder="Address 2"
                      value={finalValues?.addressLine2}
                      onChange={(e) =>
                        setFinalValues({
                          ...finalValues,
                          addressLine2: e?.target?.value,
                        })
                      }
                    />

                    <div className="position-absolute FinalSubmit-edit mb-1">
                      <span
                        onClick={() => {
                          onBack(1);
                        }}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="FinalSubmit-label">City</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      readOnly
                      className="FinalSubmitInput"
                      id="City"
                      placeholder="City"
                      value={finalValues?.city}
                      onChange={(e) =>
                        setFinalValues({
                          ...finalValues,
                          city: e?.target?.value,
                        })
                      }
                    />

                    <div className="position-absolute FinalSubmit-edit mb-1">
                      <span
                        onClick={() => {
                          onBack(1);
                        }}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="FinalSubmit-label">State</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      readOnly
                      className="FinalSubmitInput"
                      name="state"
                      placeholder="State"
                      value={finalValues?.state}
                    />

                    <div className="position-absolute FinalSubmit-edit mb-1">
                      <span
                        className="bg-white"
                        onClick={() => {
                          onBack(1);
                        }}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="FinalSubmit-label">Zip</div>

                  <div className="position-relative  mb-3 pb-1">
                    <input
                      readOnly
                      className="FinalSubmitInput"
                      id="Zip"
                      placeholder="Zip"
                      value={finalValues?.zip}
                      onChange={(e) => {
                        let value = e?.target?.value;
                        if (isNaN(value) || value.length >= 6) return;

                        setFinalValues({
                          ...finalValues,
                          zip: value,
                        });
                      }}
                    />

                    <div className="position-absolute FinalSubmit-edit mb-1">
                      <span
                        onClick={() => {
                          onBack(1);
                        }}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div>
            <div className="mb-3 FinalSubmit-header">2. Testing Date & Time</div>

            <div className="">
              <div className="FinalSubmit-label">Date</div>

              <div className="position-relative mb-3 pb-1">
                <input
                  className="FinalSubmitInput"
                  id="date"
                  readOnly
                  placeholder="Date"
                  value={
                    finalValues?.date
                      ? finalValues?.date?.toString()?.split(' ')?.slice(0, 4)?.join(' ')
                      : ''
                  }
                />

                <div className="position-absolute FinalSubmit-edit mb-1">
                  <span
                    onClick={() => {
                      onBack(2);
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              <div className="FinalSubmit-label">Time</div>

              <div className="position-relative  mb-3 pb-1">
                <input
                  readOnly
                  type="time"
                  className="FinalSubmitInput a-time"
                  id="Time"
                  placeholder="Time"
                  value={finalValues?.time}
                  onChange={(e) =>
                    setFinalValues({
                      ...finalValues,
                      time: e?.target?.value,
                    })
                  }
                />

                <div className="position-absolute FinalSubmit-edit mb-1">
                  <label
                    htmlFor="Time"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onBack(2);
                    }}
                  >
                    Edit
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-3 FinalSubmit-header">3. Test Result</div>

            <div className="">
              <div className="FinalSubmit-label">Result</div>

              <div className="position-relative  mb-3 pb-1">
                <input
                  readOnly
                  className="FinalSubmitInput"
                  name="testResult"
                  placeholder="Enter Test Result"
                  value={finalValues?.testResult}
                />

                <div className="position-absolute FinalSubmit-edit mb-1">
                  <span
                    className="bg-white"
                    onClick={() => {
                      onBack(3);
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-3 FinalSubmit-header d-flex justify-content-between align-items-center">
              <div>4. Uploaded File</div>
              <div className="FinalSubmit-edit">
                <span
                  onClick={() => {
                    onBack(4);
                  }}
                >
                  Edit
                </span>
              </div>
            </div>

            <div className="">
              <div className="position-relative mb-3 my-4 pt-2 d-flex align-items-center FinalSubmitInput-UploadFile-box">
                <div className="d-flex align-items-center">
                  {finalValues?.files?.map((val, i) => (
                    <div key={i} className="row border rounded p-0 ml-2 ml-sm-3">
                      <div className="col-8 d-flex align-items-center justify-content-center px-1 py-1 px-sm-2">
                        <img src={FileIcon} alt="fileIcon" className="" />
                        <span className="px-1 px-sm-2 fileText">
                          {val?.name?.length > 10 ? `${val?.name?.slice(0, 10)}...` : val?.name}
                        </span>
                        <img
                          src={GreenTick}
                          alt="GreenTick"
                          width="18px"
                          height="18px"
                          className=""
                        />
                      </div>

                      <div className="col-4 p-0 border-left">
                        <div className="d-flex flex-column align-items-center justify-content-center px-2 py-1 px-sm-3">
                          <img src={CutIcon} alt="CutIcon" className="" />
                          <span className="replace-text">Replace</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {finalValues?.files?.length === 0 && (
                  <div className="UploadFile-drag position-absolute w-100">No File Uploaded</div>
                )}
              </div>
            </div>
          </div>

          <div className="row my-4 mt-5">
            <div className="col-12 col-md-6 ">
              <div
                className="cancel"
                onClick={() => {
                  if (loader) return;
                  setOpenModal(true);
                }}
              >
                Cancel Test Result Upload
              </div>
            </div>

            <div className="col-12 col-md-6 mt-4 mt-md-0">
              <div
                className="begin"
                onClick={() => {
                  if (loader) return;
                  return handleSubmit(finalValues);
                }}
              >
                {loader ? <Spinner animation="border" variant="primary" /> : 'Confirm & Submit'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userAuthentication: state.authentication,
  userProfile: state?.user?.userProfile?.[0]?.attributes,
});

export default connect(mapStateToProps)(FinalSubmit);
