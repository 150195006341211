import { updateUserData } from './models/user';
import { GEOLOCATION_KEY } from './const';
import { storeData } from './cache';

let locationTimeout;

async function save(id, key) {
  const now = new Date().toUTCString();
  const info = await getCurrentPosition();
  const { longitude, latitude } = info.coords;
  // console.log('my current location', info);
  storeData(GEOLOCATION_KEY, { longitude, latitude });
  const updatedData = {
    data: {
      id,
      type: 'users',
      attributes: {
        longitude,
        latitude,
        'device-os': 'Web Browser',
        'lonlat-time-taken': now,
      },
    },
  };

  await updateUserData(id, updatedData, key);
}

export function getCurrentPosition() {
  return new Promise(async (res, rej) => {
    navigator.geolocation.getCurrentPosition((position) => {
      res(position);
    });
  });
}

export async function saveCurrentLocation(id, key, interval) {
  // ('location1', id, key, interval)
  save(id, key);
  const val = interval * 60 * 1000;

  locationTimeout = setInterval(async () => {
    save(id);
  }, val); // execute every 15 min
}

export function clearIntervalByKey(key) {
  switch (key) {
    case 'locationTimeout':
      locationTimeout && clearInterval(locationTimeout);
      break;

    default:
      break;
  }
}
