import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomCheckbox = withStyles({
  root: {
    color: '#FF9900',
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'black',
        zIndex: -1,
      },
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CheckboxComponent({ label, selected, handleChange, labelColor }) {
  return (
    <div className="d-flex align-items-center">
      <FormControlLabel
        data-testid="TestkitCheck"
        control={<CustomCheckbox checked={selected} onChange={() => handleChange(selected)} />}
        label={
          <span className="normal-font" style={{ color: labelColor }}>
            {label}
          </span>
        }
      />
    </div>
  );
}

export default CheckboxComponent;
