import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
    color: '#777777',
    fontFamily: 'SF Pro Display',
    width: 'fit-content',
  },
  center: {
    alignItems: 'center',
  },
}));

export default function Logo() {
  const history = useHistory();
  return (
    <img src={LogoImageUrl()} width="200" alt="Logo" onClick={() => history.push('/profile')} />
  );
}

export function SmallLogo() {
  const history = useHistory();
  return (
    <img
      src={LogoImageUrl()}
      width="180"
      height="64"
      alt="Logo"
      className="d-inline-block align-top"
      onClick={() => history.push('/profile')}
    />
  );
}

export function CommonLogo() {
  return <img alt="Logo" src={LogoImageUrl()} width="180" height="64" className="my-4 logo" />;
}

export const GreetingLogo = ({ center = true }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, center ? classes.center : '')}>
      <Typography className={classes.title}>Welcome to</Typography>
      <img alt="Logo" src={LogoImageUrl()} width="200" height="70" className="my-4" />
    </div>
  );
};

export function LogoImageUrl() {
  if (window.location.hostname.endsWith('genetworx.auratracker.org')) {
    return '/genetworx_logo_no_tagline.svg';
  }
  if (window.location.hostname.endsWith('discoverearly.auratracker.org')) {
    return '/discover_early_logo_no_tagline@3x.svg';
  }
  return '/auralogo.svg';
}
