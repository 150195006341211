import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';

import moment from 'moment';
import css from './EditAccountInformation.module.css';
import { processAPI } from '../../api/models/process';
import { setUser, setUserProfile } from '../../store/action/user';
import { stateList, genderList, ethnicList, raceList } from '../../utils/utils';
import { editAccountSchema } from '../../utils/constraints';
import Input from '../../components/Input/Input';
import SelectInput from '../../components/SelectInput/SelectInput';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInput';
import { Layout, Alert, StrokeButton } from '../../components';
import { getUserData, updateUserData } from '../../api/models/user';
import ButtonComponent from '../../components/Button/Button';

function EditAccountInformation(props) {
  const { setUserDispatch, userProfile, setUserProfileDispatch } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const [files, setFiles] = useState([]);

  const { attributes: data, id } = props.user;
  const userProfileId = props.userProfile?.id || '';
  const noData = userProfileId.length === 0;

  function hideAlert() {
    setShowAlert({ show: false });
  }

  async function onSubmit(values) {
    setLoading(true);

    try {
      const { jwt } = props.userAuthentication;
      const userData = {
        data: {
          id,
          type: 'users',
          attributes: {
            email: values.email,
            'first-name': values.firstName,
            'middle-name': values.middleName,
            'last-name': values.lastName,
          },
        },
      };
      setLoading(true);
      const data = await updateUserData(id, userData, jwt);
      await submitUserProfile(values, jwt, data);
      if (data.attributes) {
        setShowAlert({
          show: true,
          message: 'Save successfully',
          type: 'success',
        });
        history.push('/account-information');
      } else {
        setShowAlert({ show: true, message: 'Failed', type: 'error' });
      }
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  async function submitUserProfile(values, jwt, newUserData) {
    const userProfileData = {
      data: {
        type: 'user-profiles',
        attributes: {
          'phone-number': values.phoneNumber,
          'date-of-birth': moment(values.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          gender: values.gender,
          ethnicity: values.ethnicity,
          race: values.race,
        },
        relationships: {
          user: {
            data: { type: 'users', id: parseInt(id) },
          },
        },
      },
    };

    !noData && (userProfileData.data.id = userProfileId);
    const data = await processAPI({
      key: jwt,
      methodType: noData ? 'POST' : 'PATCH',
      data: userProfileData,
      endpoint: noData ? 'user-profiles' : `user-profiles/${userProfileId}`,
    });

    if (data.id) {
      setUserProfileDispatch([{ ...data }]);
    }
    const userData = await getUserData(id, jwt);
    setUserDispatch(userData.data);
  }

  return (
    <Layout
      onClickBack={() => history.goBack()}
      headerTitle="Edit Account Information"
      tab="account"
      back
    >
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div>
        <Formik
          initialValues={{
            firstName: data['first-name'] || '',
            middleName: data['middle-name'] || '',
            lastName: data['last-name'] || '',
            email: data.email || '',
            dateOfBirth:
              moment(userProfile?.attributes['date-of-birth'], 'YYYY-MM-DD').format('MM/DD/YYYY') ||
              '',
            addressLine1: userProfile?.attributes['address-line-1'] || '',
            city: userProfile?.attributes.city || '',
            state: userProfile?.attributes.state || '',
            zip: userProfile?.attributes.zip || '',
            phoneNumber: userProfile?.attributes['phone-number'] || '',
            gender: userProfile?.attributes.gender || '',
            race: userProfile?.attributes.race || '',
            ethnicity: userProfile?.attributes.ethnicity || '',
            mothersMaidenName: userProfile?.attributes['mothers-maiden'] || '',
            // isVaccine: data["nav-vaccines"] || false,
          }}
          validationSchema={editAccountSchema}
          onSubmit={onSubmit}
          className=""
        >
          {(formikProps) => (
            <Form className=" aura-form">
              <div className={css.container}>
                <div className={css.flex1}>
                  <div className={css.subContainer}>
                    <div className={css.header}>
                      <p className={css.name}>
                        {data['first-name']} {data['middle-name']}
                        {data['middle-name'] && ' '}
                        {data['last-name']}
                      </p>
                      <p className={css.primaryAccount}>Primary Account Owner</p>
                    </div>

                    <div className={css.contentContainer}>
                      <Field
                        name="email"
                        label="Email Address *"
                        placeholder="Email Address"
                        component={Input}
                        type="text"
                      />
                      <Field
                        name="dateOfBirth"
                        label="Date of Birth *"
                        component={NumberFormatInput}
                        type="text"
                        placeholder="MM/DD/YYYY"
                        format="##/##/####"
                      />
                      <Field
                        name="phoneNumber"
                        label="Phone Number *"
                        component={NumberFormatInput}
                        type="text"
                        placeholder="(###) ###-####"
                        format="(###) ###-####"
                      />
                      <Field
                        name="gender"
                        label="Gender *"
                        component={SelectInput}
                        classNamePrefix="form-select"
                        placeholder="Gender"
                        options={genderList.map((s) => ({
                          value: s.value,
                          label: s.label,
                        }))}
                      />
                      <Field
                        name="race"
                        label="Race *"
                        component={SelectInput}
                        classNamePrefix="form-select"
                        placeholder="Race"
                        options={raceList.map((s) => ({
                          value: s.value,
                          label: s.label,
                        }))}
                      />
                      <Field
                        name="ethnicity"
                        label="Ethnicity *"
                        component={SelectInput}
                        classNamePrefix="form-select"
                        placeholder="Ethnicity"
                        options={ethnicList.map((s) => ({
                          value: s.value,
                          label: s.label,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.container}>
                <div className={css.flex1}>
                  <div
                    className={css.subContainer}
                    style={{
                      border: 'none',
                      marginTop: '-20px',
                    }}
                  >
                    <div className="row m-0 mt-0 mt-lg-4 p-0">
                      <div className="col-4 pl-0">
                        <StrokeButton
                          title={t('Cancel')}
                          className={css.cancelButton}
                          handlePress={() => history.goBack()}
                          height={50}
                        />
                      </div>
                      <div className="col-8 pr-0">
                        <ButtonComponent
                          text="Save"
                          disabled={!formikProps.isValid}
                          loading={loading}
                          className={`${css.saveButton}`}
                          height={50}
                          onClick={() => {
                            if (loading) return;
                            formikProps?.submitForm();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}

const mapStateTopProps = (state) => ({
  user: state.user.profile,
  userProfile: state.user.userProfile[0],
  userAuthentication: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserProfileDispatch(payload) {
    dispatch(setUserProfile(payload));
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(EditAccountInformation);
