import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Layout } from '../../components';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';
import { setVaxHistory } from '../../store/action/vax';
import { getVaccinations } from '../../api/models/vaccinations';

import cdc from '../../assets/image/cdc2.png';
import dhs from '../../assets/image/dhs2.png';

const RecordCard = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { user, profile, history, vax } = props;
  const [, setLoading] = useState(true);
  const [firstDose, setFirstDose] = useState();
  const [secondDose, setSecondDose] = useState();

  useEffect(() => {
    loadVaccinations();
  }, []);

  useEffect(() => {
    if (vax && vax.length > 0) {
      setFirstDose(vax[0].attributes);
    }
    if (vax && vax.length > 1) {
      setSecondDose(vax[1].attributes);
    }
  }, [vax]);

  async function loadVaccinations() {
    setLoading(true);
    const { authentication } = props;
    const { jwt: key } = authentication;
    const response = await getVaccinations(key);

    if (response?.data.length > 0) {
      props.setVaxHistoryDispatch(response.data);
    }
    setLoading(false);
  }

  return (
    <Layout
      tab={activeTab}
      headerTitle={t('myVaccinationRecord')}
      onClickBack={() => history.goBack()}
      back
    >
      <div className="vaccine">
        <div className={css.cdcFullContainer}>
          <div className={classNames(css.cdcContainer)}>
            <div className={css.cdcRowContainer}>
              <div className={css.cdcColumnContainer}>
                <div>
                  <p className={css.cdcTitle}>COVID-19 Vaccination Record Card</p>
                  <hr className={css.darkline} />
                </div>
                <div className="mr-3">
                  <p className={css.cdcInstructTest}>
                    Please keep this record card, which includes medical information about the
                    vaccines you have received.
                  </p>
                  <p className={css.cdcInstructTest}>
                    Por favor, guarde esta tarjeta de registro, que incluye información médica sobre
                    las vacunas que ha recibido.
                  </p>
                </div>
              </div>
              <div className={css.cdcRowContainer}>
                <img className={css.dhs} src={dhs} alt="dhs" />
                <img className={css.cdc} src={cdc} alt="cdc" />
              </div>
            </div>

            <div className={classNames('pt-5', css.cdcRowContainer)} />

            <div className={css.cdcRowContainer}>
              <span className={css.cdcFields2}>{user && user.attributes['last-name']}</span>
              <span className={css.cdcFields2}>{user && user.attributes['first-name']}</span>
              <span className={classNames(css.cdcFields, 'mr-3')} />
            </div>

            <div className={classNames(css.cdcRowContainer)}>
              <hr className={css.rulerline} />
            </div>

            <div className={css.cdcRowContainer}>
              <span className={css.cdcFields}>Last Name</span>
              <span className={css.cdcFields}>First Name</span>
              <span className={classNames(css.cdcFields, 'mr-3')}>MI</span>
            </div>

            <div className={classNames('pt-5', css.cdcRowContainer)} />

            <div className={css.cdcRowContainer}>
              <span className={css.cdcFields2}>
                {profile?.[0].attributes?.['date-of-birth'] &&
                  moment(profile?.[0].attributes['date-of-birth'], 'YYYY-MM-DD').format(
                    'MM/DD/YYYY'
                  )}
              </span>
              <span className={css.cdcFields2}>{user && user.id}</span>
            </div>

            <div className={classNames(css.cdcRowContainer)}>
              <hr className={css.rulerline} />
            </div>

            <div className={css.cdcRowContainer}>
              <span className={css.cdcFields}>Date of birth</span>
              <span className={css.cdcFields}>
                Patient number{' '}
                <span className={css.cdcFieldsItalic}>(medical record or IIS record number)</span>
              </span>
            </div>

            <div className={classNames(css.cdcRowContainer, css.cdcTable, 'mt-3')}>
              <div className={classNames(css.cdcTableHeader)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcHeaderText}>Vaccine</span>
                </div>
                <div className={classNames(css.cdcTableCell, css.productColumn)}>
                  <div className={css.cdcHeaderText}>Product Name/Manufacturer</div>
                  <hr className={css.dashedline} />
                  <div className={classNames(css.cdcHeaderText)}>Lot Number</div>
                </div>
                <div className={classNames(css.cdcTableCell, css.dateColumn)}>
                  <span className={css.cdcHeaderText}>Date</span>
                  <span className={css.cdcHeaderText}>Fecha</span>
                </div>

                <div className={classNames(css.cdcTableCell, css.siteColumn)}>
                  <span className={css.cdcHeaderText}>Health Professional or Clinic Site</span>
                </div>
              </div>
            </div>

            <div className={css.cdcRowContainer}>
              <div className={classNames(css.cdcTableRow)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcCellText}>1st Dose COVID-19</span>
                </div>
                <div className={classNames(css.cdcTableCell, css.productColumn)}>
                  <div className={css.cdcCellText}>{firstDose?.name}</div>
                  <hr className={css.dashedline} />
                  <div className={classNames(css.cdcCellText)}>{firstDose?.['lot-number']}</div>
                </div>
                <div className={classNames(css.cdcTableCell, css.dateColumn)}>
                  <span className={css.cdcCellText}>
                    {firstDose?.['created-at'] &&
                      moment(firstDose?.['created-at']).format('MM/DD/YY')}
                  </span>
                </div>

                <div className={classNames(css.cdcTableCell, css.siteColumn)}>
                  <span className={css.cdcCellText} />
                </div>
              </div>
            </div>

            <div className={css.cdcRowContainer}>
              <div className={classNames(css.cdcTableRow)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcCellText}>2nd Dose COVID-19</span>
                </div>
                <div className={classNames(css.cdcTableCell, css.productColumn)}>
                  <div className={css.cdcCellText}>{secondDose?.name}</div>
                  <hr className={css.dashedline} />
                  <div className={classNames(css.cdcCellText)}>{secondDose?.['lot-number']}</div>
                </div>
                <div className={classNames(css.cdcTableCell, css.dateColumn)}>
                  <span className={css.cdcCellText}>
                    {secondDose?.['created-at'] &&
                      moment(secondDose?.['created-at']).format('MM/DD/YY')}
                  </span>
                </div>

                <div className={classNames(css.cdcTableCell, css.siteColumn)}>
                  <span className={css.cdcCellText} />
                </div>
              </div>
            </div>

            <div className={css.cdcRowContainer}>
              <div className={classNames(css.cdcTableRow)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcCellText}>Other</span>
                  <span className={css.cdcCellText}>Otra</span>
                </div>
                <div className={classNames(css.cdcTableCell, css.productColumn)}>
                  <div className={css.cdcCellText}> </div>
                  <div className={classNames(css.cdcCellText)}> </div>
                </div>
                <div className={classNames(css.cdcTableCell, css.dateColumn)} />

                <div className={classNames(css.cdcTableCell, css.siteColumn)}>
                  <span className={css.cdcCellText} />
                </div>
              </div>
            </div>

            <div className={css.cdcRowContainer}>
              <div className={classNames(css.cdcTableRow)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcCellText}>Other</span>
                  <span className={css.cdcCellText}>Otra</span>
                </div>
                <div className={classNames(css.cdcTableCell, css.productColumn)}>
                  <div className={css.cdcCellText}> </div>
                  <div className={classNames(css.cdcCellText)}> </div>
                </div>
                <div className={classNames(css.cdcTableCell, css.dateColumn)} />

                <div className={classNames(css.cdcTableCell, css.siteColumn)}>
                  <span className={css.cdcCellText} />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(css.cdcContainer)}>
            <div className={css.cdColumnContainer}>
              <p className={classNames(css.cdcTitle, 'text-center')}>
                Reminder! Return for a second dose!
              </p>
              <p className={classNames(css.cdcTitle, 'text-center')}>
                ¡Recordatorio! ¡Regrese para la segunda dosis!
              </p>
            </div>
            <div className={classNames(css.cdcRowContainer, css.cdcTable, 'mt-3')}>
              <div className={classNames(css.cdcTableHeader)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcHeaderText}>Vaccine</span>
                </div>

                <div className={classNames(css.cdcTableCell, css.fechaColumn)}>
                  <span className={css.cdcHeaderText}>Date / Fecha</span>
                </div>
              </div>
            </div>
            <div className={css.cdcRowContainer}>
              <div className={classNames(css.cdcTableRow)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcCellText}>COVID-19 vaccine</span>
                  <span className={css.cdcCellText}> Vacuna contra el COVID-19</span>
                </div>

                <div className={classNames(css.cdcTableCell, css.fechaColumn)}>
                  <span className={css.CellText}> </span>
                </div>
              </div>
            </div>

            <div className={css.cdcRowContainer}>
              <div className={classNames(css.cdcTableRow)}>
                <div className={classNames(css.cdcTableCell, css.vaxColumn)}>
                  <span className={css.cdcCellText}>Other</span>
                  <span className={css.cdcCellText}>Otra</span>
                </div>

                <div className={classNames(css.cdcTableCell, css.fechaColumn)}>
                  <span className={css.CellText}> </span>
                </div>
              </div>
            </div>

            <div className={classNames(css.cdcInfoContainer, 'my-3')}>
              <div className={classNames(css.cdcColumnContainer, 'mr-1')}>
                <p className={css.cdcInfoText}>
                  Bring this vaccination record to every vaccination or medical visit. Check with
                  your health care provider to make sure you are not missing any doses of routinely
                  recommended vaccines.
                </p>
                <p className={css.cdcInfoText}>
                  For more information about COVID-19 and COVID-19 vaccine, visit{' '}
                  <a
                    target="_blank"
                    href="https://cdc.gov/coronavirus/2019-ncov/index.html"
                    rel="noreferrer"
                  >
                    cdc.gov/coronavirus/2019-ncov/index.html
                  </a>
                  .
                </p>
                <p className={css.cdcInfoText}>
                  You can report possible adverse reactions following COVID-19 vaccination to the
                  Vaccine Adverse Event Reporting System (VAERS) at{' '}
                  <a target="_blank" href="https://vaers.hhs.gov" rel="noreferrer">
                    vaers.hhs.gov
                  </a>
                  .
                </p>
              </div>
              <div className={classNames(css.cdcColumnContainer, 'ml-1')}>
                <p className={css.cdcInfoText}>
                  Lleve este registro de vacunación a cada cita médica o de vacunación. Consulte con
                  su proveedor de atención médica para asegurarse de que no le falte ninguna dosis
                  de las vacunas recomendadas.
                </p>
                <p className={css.cdcInfoText}>
                  Para obtener más información sobre el COVID-19 y la vacuna contra el COVID-19,
                  visite{' '}
                  <a
                    target="_blank"
                    href="https://espanol.cdc.gov/coronavirus/2019-ncov/index.html"
                    rel="noreferrer"
                  >
                    espanol.cdc.gov/coronavirus/2019-ncov/index.html
                  </a>
                  .
                </p>
                <p className={css.cdcInfoText}>
                  Puede notificar las posibles reacciones adversas después de la vacunación contra
                  el COVID-19 al Sistema de Notificación de Reacciones Adversas a las Vacunas
                  (VAERS) en{' '}
                  <a target="_blank" href="https://vaers.hhs.gov" rel="noreferrer">
                    vaers.hhs.gov
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
    profile: state.user.userProfile,
    authentication: state.authentication,
    vax: state.vax.history,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setVaxHistoryDispatch(payload) {
    dispatch(setVaxHistory(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordCard);
