import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import NextIcon from '../../assets/svg/forward.svg';
import BackIcon from '../../assets/svg/backward.svg';

function CalendarComponent({ events, dayChange }) {
  const ref = React.useRef();
  const [value, onChange] = useState(new Date());
  const [start, onStartChange] = useState();
  const today = moment().subtract(1, 'days');
  const lastDay = moment().add(2, 'days');
  const [display, setDisplay] = useState('default');

  useEffect(() => {
    const { current } = ref;
    // console.log('date range' ,current?.childNodes[0]?.childNodes[2]?.innerText);
    setDisplay(current?.childNodes[0]?.childNodes[2]?.innerText);
  }, [start]);

  function tileDisabled({ date, view }) {
    if (view === 'month') {
      let dt = moment(date).format('YYYY-MM-DD').toString();
      let k = events.find((x) => x == dt);
      return !(k && date > today);
    }
  }

  function tileClassName({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (date == today) {
        return 'schedule';
      }
    }
  }

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <div>
          <span>{display}</span>
        </div>
        <div className="nav-container">
          <img
            alt="previous"
            src={BackIcon}
            onClick={() => {
              const { current } = ref;
              current.childNodes[0].childNodes[1].click();
            }}
          />

          <img
            alt="next"
            src={NextIcon}
            onClick={() => {
              const { current } = ref;
              current.childNodes[0].childNodes[3].click();
            }}
          />
        </div>
      </div>
      {/* activeStartDate={new Date(2017, 0, 1)} */}

      <Calendar
        inputRef={ref}
        className=""
        onChange={(e) => {
          dayChange && dayChange(e);
          onChange(e);
        }}
        onActiveStartDateChange={onStartChange}
        calendarType="US"
        locale="en-US"
        showNavigation
        value={value}
        showNeighboringMonth
        onViewChange={(value) => console.log('## value ', value)}
        tileDisabled={tileDisabled}
      />
    </div>
  );
}

export default CalendarComponent;
