import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { List, Map, Layout, SearchInput } from '../../components';

import { processAPI } from '../../api/models/process';
import { saveCurrentLocation, getCurrentPosition } from '../../api/geolocation';
import { getStoredData } from '../../api/cache';
import { GEOLOCATION_KEY } from '../../api/const';
import calculateDistance from '../../api/calculateDistance';
import { setUser } from '../../store/action/user';
import { setSearchResults, setSelectedResults } from '../../store/action/search';

import { useTranslation } from 'react-i18next';

let typingTimer;
const doneTypingInterval = 1000;

const VaccineSiteContainer = (props) => {
  const activeTab = 'vaccine';
  const {
    setSearchDisptach,
    data,
    setSelectedSearchDisptach,
    user,
    selectedLocation,
    userAuthentication,
  } = props;

  const [text, setText] = useState(user?.attributes['default-city']);

  const [loading, setLoading] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({});

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    checkLocation();
    checkLoginUser();
    loadSearch();
    return () => {
      // clearIntervalByKey('locationTimeout'); // to clear setInterval save user location
    };
  }, []);

  async function checkLocation() {
    if ('geolocation' in navigator) {
      const info = await getCurrentPosition();
      const { longitude, latitude } = info.coords;
      setCurrentPosition({ longitude, latitude });
    } else {
      alert('Please enable your location');
    }
  }

  async function checkLoginUser() {
    const { user_id, jwt } = userAuthentication;
    const interval =
      (user && user.attributes && user.attributes['testing-center-location-interval']) || 15;
    saveCurrentLocation(user_id, jwt, interval);
  }

  function loadSearch() {
    const { jwt } = userAuthentication;
    setLoading(true);

    processAPI({
      endpoint: `location-services?filter[service_type]=vaccination`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        sortDataByDistance(data);
      })
      .catch(() => {
        setSearchDisptach([]);
        setLoading(false);
      });
  }

  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.attributes.miles.toUpperCase();
    const bandB = b.attributes.miles.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  async function sortDataByDistance(data) {
    const cacheLocation = await getStoredData(GEOLOCATION_KEY);

    if (cacheLocation && data.length > 0) {
      const { latitude: lat1, longitude: long1 } = cacheLocation;
      const addMiles = data.map((item) => {
        const { latitude: lat2, longitude: long2 } = item.attributes;

        return {
          ...item,
          attributes: {
            miles: calculateDistance(lat1, long1, lat2, long2).toFixed(1),
            ...item.attributes,
          },
        };
      });

      setSearchDisptach(addMiles.sort(compare));
    } else if (data.length > 0) {
      setSearchDisptach(data);
    } else {
      setSearchDisptach([]);
    }
    setLoading(false);
  }

  function onChangeText(value) {
    setText(value);

    if (typingTimer) {
      clearTimeout(typingTimer);
    }
    // execute search after user not inputing for 1 sec
    typingTimer = setTimeout(loadSearch, doneTypingInterval);
  }

  function onSelect(item) {
    const { latitude, longitude } = item.attributes;
    const medtest = item.attributes['medtest-location-id'];
    setSelectedSearchDisptach([parseFloat(latitude), parseFloat(longitude)]);
    history.push(`/vaccineschedule/${item.id}/${medtest}`);
  }

  return (
    <Layout
      headerTitle={t('selectVaccinationSite')}
      tab={activeTab}
      onClickBack={() => history.goBack()}
      back
    >
      <div className="testing-container">
        <div className="flex1">
          <div className="list-container">
            <SearchInput
              onClearText={() => setText('')}
              onChange={(e) => onChangeText(e.target.value)}
              value={text}
            />
            {loading && (
              <div className="text-center">
                <Spinner animation="grow" variant="primary" />
              </div>
            )}
            {!loading && (
              <div className="search-text-container">
                <h2 className="medium-font">
                  {t('searchResults')} ({data.length})
                </h2>
              </div>
            )}
            {data.map((item, index) => {
              const { name } = item.attributes;
              return (
                <List
                  key={`mi-${index}`}
                  bigText={name}
                  // subText={miles && miles + ' Miles'}
                  subText={item.attributes['address-line-1']}
                  onCLick={() => onSelect(item)}
                  arrowIcon
                />
              );
            })}
          </div>
        </div>
        <div className="flex1 testing-map-container">
          <Map
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB10KZdD4gnGsio4k7tvrlEeBh0oemk6I8"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultLocation={user && user.attributes['default-lat-lon']}
            location={selectedLocation}
            currentPosition={currentPosition}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateTopProps = (state) => ({
  user: state.user.profile,
  status: state.authentication.status,
  alertCard: state.alert,
  userAuthentication: state.authentication,
  data: state.search.results,
  selectedLocation: state.search.selectedResults,
});

const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setSearchDisptach(payload) {
    dispatch(setSearchResults(payload));
  },
  setSelectedSearchDisptach(payload) {
    dispatch(setSelectedResults(payload));
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(VaccineSiteContainer);
