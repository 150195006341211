import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, FormControlLabel, Checkbox, Card } from '@material-ui/core';
import { Header, StrokeButton } from '../../components';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { getUserData, getUserFamilyMembers } from '../../api/models/user';
import Exclamation from '../../assets/svg/exclamation.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'SF Pro Display',
    height: '100vh',
  },
  body: {
    position: 'relative',
    flex: '1 1 auto',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    paddingLeft: '15%',
    paddingBottom: theme.spacing(10),
    paddingRight: '15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: 0.87,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      letterSpacing: 0.8,
    },
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
  },
  subTitle2: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.75,
    fontFamily: 'SF Pro Display',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  subTitle3: {
    textAlign: 'center',
    fontFamily: 'SF Pro Display',
    fontWeight: '600',
    fontSize: '15px',
    color: '#777777',
  },
  submitButton: {
    borderRadius: 14,
    fontFamily: 'SF Pro Display',
    '&:disabled': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
      opacity: 0.4,
    },
    '&:focus': {
      outline: 0,
    },
    marginBottom: theme.spacing(3),
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'unset',
    letterSpacing: -0.408,
    background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF',
    },
  },
  exclamation: {
    margin: 'auto',
  },
  bulletPoint: {
    fontFamily: 'SF Pro Display',
    fontSize: 16,
    color: '#777777',
    listStyleType: 'disc',
  },
  qualificationBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const NotEligible = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const ref = useRef(null);
  // console.log(state);
  const [user, setUser] = useState({});
  const [familyMembers, setFamilyMembers] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const userDataCache = await getStoredData(USER_CACHE_NAME);
    setUser(userDataCache);
    let familyMemberList =
      (await getUserFamilyMembers(userDataCache.user_id, userDataCache.jwt)).included || [];
    setFamilyMembers(familyMemberList);
    let userData = await getUserData(userDataCache.user_id, userDataCache.jwt);
    const name =
      familyMemberList.find((member) => {
        console.log(member, state?.selectedMember);
        return member?.id == state?.selectedMember;
      })?.attributes?.['first-name'] || userData?.data?.attributes?.['first-name'];
    console.log(name);
    setName(name);
    console.log(userData);
  };

  return (
    <div className={classes.root}>
      <Header title="No Cost Testing* Ineligible" />
      <div ref={ref} className={classes.body}>
        <p className={classes.subTitle2}>{name} is not eligible for the No Cost Testing* option.</p>
        <img src={Exclamation} alt="exclamation" width="100" className={classes.exclamation} />
        <p className={classes.subTitle3}>
          Based on the responses you submitted, {name} does not meet the requirements for the No
          Cost Testing* option.{' '}
        </p>
        <div className={classes.qualificationBox}>
          <p className={classes.subTitle2}>In order to qualify, a patient must:</p>
          <ul>
            <li className={classes.bulletPoint}>Be symptomatic</li>
            <li className={classes.bulletPoint}>Have been exposed to someone with COVID-19</li>
            <li className={classes.bulletPoint}>Have recently tested positive</li>
          </ul>
        </div>
        <Button
          fullWidth
          // disabled={!testedPositive || !contact || !symptomatic}
          className={classNames(classes.submitButton)}
          onClick={() => {
            history.push('/select-family-member-activation');
          }}
        >
          I understand
        </Button>
      </div>
    </div>
  );
};

export default NotEligible;
