import { RESULTS, SET_SELECTED_RESULTS } from '../types';

const initialState = {
  results: [],
  selectedResults: {},
};

const resultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESULTS:
      return { ...state, results: action.payload };
    case SET_SELECTED_RESULTS:
      return { ...state, selectedResults: action.payload };
    default:
      return state;
  }
};

export default resultsReducer;
