import React, { useState } from 'react';
import './index.css';
import Check from '../../../assets/image/check2.png';
import { useHistory, useLocation, useParams } from 'react-router';

export default function NoCostTesting() {
  const [selectedValue, setSelectedValue] = useState('');
  const { familyMemberId } = useParams();
  const history = useHistory();
  const { state } = useLocation();

  const handleSubmit = () => {
    if (selectedValue == 'yes') {
      const url = `/activation-insurance/${familyMemberId}`;
      console.log(url);
      history.push(url, {
        selectedMember: familyMemberId,
        kit: state?.kit,
      });
    }
    if (selectedValue == 'no') {
      history.push('/ineligible-hrsa');
    }
  };

  return (
    <>
      <div className="text-center noCost-header mt-5 pt-2">No Cost Testing* Program</div>
      <div className="border m-0 my-3" />

      <div className=" mt-5">
        <div className="my-3 py-1 px-1 px-sm-0 text-center  noCost-text ">
          Based on our records, when you ordered this test, you indicated that you did not have
          insurance and would be leveraging public funds for this COVID-19 test.
        </div>

        <div className="my-3 py-1 px-1 px-sm-0 text-center noCost-sub-header ">
          Public funds are no longer available to support COVID-19 testing.
        </div>

        <div className="my-3 py-1 px-1 px-sm-0 text-center noCost-text ">
          In order to participate in our No Cost Testing Program, you will need to provide
          insurance.
        </div>
      </div>

      <div className="pt-5 mt-5">
        <div className="text-center noCost-header p-0 m-0 mt-5 pt-1">
          Do you have Health Insurance?
        </div>
      </div>

      <div className="row m-0 p-0 mt-2 mb-5 pb-5">
        <div className="col-10 offset-1 col-sm-6 offset-sm-3 ">
          <div
            className={`mt-4 d-flex justify-content-center align-items-center w-100 ${
              selectedValue === 'yes' ? 'selected-button' : 'unSelected-button'
            }`}
            onClick={() => setSelectedValue('yes')}
          >
            {selectedValue === 'yes' && <img src={Check} alt="Check" className="mr-2" />} Yes, I
            have Health Insurance
          </div>

          <div
            className={`mt-3 mb-5 d-flex justify-content-center align-items-center w-100 ${
              selectedValue === 'no' ? 'selected-button' : 'unSelected-button'
            }`}
            onClick={() => setSelectedValue('no')}
          >
            {selectedValue === 'no' && <img src={Check} alt="Check" className="mr-2" />} No, I do
            not have Health Insurance
          </div>

          <div
            className="mt-5 d-flex justify-content-center align-items-center continue-button"
            style={selectedValue ? {} : { opacity: '0.4' }}
            onClick={() => {
              if (selectedValue && handleSubmit) handleSubmit(selectedValue);
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </>
  );
}
