import React, { useCallback } from 'react';
import { string, object } from 'prop-types';
import cn from 'classnames';
import './NumberFormatInputSignup.css';

import NumberFormat from 'react-number-format';

const NumberFormatInput = ({
  field,
  label,
  format,
  placeholder,
  prefix,
  onBlurHandler = undefined,
  form: { touched, errors, status, setFieldTouched },
}) => {
  const handleFocus = () => {
    status && (status[field.name] = undefined);
  };

  const handleBlur = useCallback(() => {
    setFieldTouched(field.name);
    onBlurHandler !== undefined && onBlurHandler(field);
  }, [field, setFieldTouched, onBlurHandler]);

  return (
    <div className="auraFieldSignup my-4">
      {label && <label className="auraFieldSignup-label">{label}</label>}
      <div>
        <NumberFormat
          className={cn({
            'with-error':
              (touched[field.name] && status && status[field.name]) ||
              (touched[field.name] && errors && errors[field.name]),
          })}
          format={format}
          placeholder={placeholder}
          prefix={prefix}
          {...field}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {status && status[field.name] && <span>{status[field.name]}</span>}
        {touched[field.name] && errors && errors[field.name] && <span>{errors[field.name]}</span>}
      </div>
    </div>
  );
};

NumberFormatInput.propTypes = {
  field: object.isRequired,
  placeholder: string,
  format: string,
  prefix: string,
};

NumberFormatInput.defaultProps = {
  placeholder: '',
  format: '',
  prefix: '',
};

export default NumberFormatInput;
