import React from 'react';
import { Layout } from '../../components';
import { connect } from 'react-redux';
import Markdown from 'react-markdown';

import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

const Docs = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { history } = props;

  return (
    <Layout
      tab={activeTab}
      headerTitle={t('consentForms')}
      onClickBack={() => history?.goBack()}
      back
    >
      <div className="vaccine">
        <div className={css.subContainer}>
          <Markdown />
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

export default connect(mapStateToProps)(Docs);
