import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import HomeIcon from '../../assets/svg/home.svg';
import HomeSelectedIcon from '../../assets/svg/home_selected.svg';
import ScanIcon from '../../assets/svg/scan.svg';
import ScanSelectedIcon from '../../assets/svg/scan_selected.svg';
import ResultsIcon from '../../assets/svg/results.svg';
import ResultsSelectedIcon from '../../assets/svg/results_selected.svg';
import GlobeIcon from '../../assets/svg/globe.svg';
import GlobeSelectedIcon from '../../assets/svg/globe_selected.png';
import UserIcon from '../../assets/svg/user.svg';
import UserSelectedIcon from '../../assets/svg/user_selected.svg';
import DownCaret from '../../assets/svg/keyboard-arrow-top.svg';

import css from './Sidebar.module.css';
import { useTranslation } from 'react-i18next';

function Sidebar(props) {
  const { activeTab, heatmap, user } = props;
  const { t } = useTranslation();

  const navTesting = user && user.attributes && user.attributes['nav-testing'];
  const navScan = user && user.attributes && user.attributes['nav-scan'];
  const navDocuments = user && user.attributes && user.attributes['nav-documents'];
  const navMyAura = user && user.attributes && user.attributes['nav-my-aura'];
  const navFamily = user && user.attributes && user.attributes['nav-family-groups'];
  const navVaccine =
    user &&
    user.attributes &&
    (user.attributes['nav-vaccines'] || user.attributes['manual-vaccine-upload']);
  const navInsurance = user && user.attributes && user.attributes['requires-insurance'];
  const requireHealthReport = user && user.attributes && user.attributes['health-report'];
  const isUnder13 = user && user.attributes && user.attributes['under-13'] === true;
  const showModalUnder13 =
    user && user.attributes && user.attributes['under-13-signed'] !== true && isUnder13;

  const [myFamilyExpanded, setMyFamilyExpanded] = useState(
    activeTab === 'family' ||
      activeTab === 'account' ||
      activeTab === 'insurance' ||
      activeTab === 'vaccine' ||
      activeTab === 'techSupport' ||
      activeTab === 'dailySymptomChecker' ||
      activeTab === 'documents'
  );
  return (
    <div>
      {!showModalUnder13 && (
        <div>
          {navTesting && (
            <Link
              to="/testing"
              data-testid="Nav-Testing"
              className={classNames(css.buttonContainer, activeTab === 'testing' && css.selected)}
            >
              <img
                alt="Home Icon"
                src={activeTab === 'testing' ? HomeSelectedIcon : HomeIcon}
                width={20}
              />
              <span>{t('schedule')}</span>
            </Link>
          )}
          {navScan && (
            <Link
              to="/scan"
              data-testid="Nav-Scan"
              className={classNames(css.buttonContainer, activeTab === 'scan' && css.selected)}
            >
              <img
                alt="Scan Icon"
                src={activeTab === 'scan' ? ScanSelectedIcon : ScanIcon}
                width={20}
              />
              <span>{t('scan')}</span>
            </Link>
          )}

          <Link
            to="/my-tests"
            data-testid="Nav-Result"
            className={classNames(css.buttonContainer, activeTab === 'myTests' && css.selected)}
          >
            <img
              alt="Results Icon"
              src={activeTab === 'results' ? ResultsSelectedIcon : ResultsIcon}
              width={20}
            />
            <span>My Tests</span>
          </Link>
          {heatmap && (
            <Link
              to="/map"
              data-testid="Heat-Map"
              className={classNames(css.buttonContainer, activeTab === 'map' && css.selected)}
            >
              <img
                alt="Globe Icon"
                src={activeTab === 'map' ? GlobeSelectedIcon : GlobeIcon}
                width={20}
              />
              <span>{t('heatMap')}</span>
            </Link>
          )}
          <div
            className={classNames(
              css.buttonContainer,
              (activeTab === 'profile' ||
                activeTab === 'family' ||
                activeTab === 'account' ||
                activeTab === 'insurance' ||
                activeTab === 'vaccine' ||
                activeTab === 'techSupport' ||
                activeTab === 'dailySymptomChecker' ||
                activeTab === 'documents') &&
                css.selected
            )}
          >
            <Link
              to="/profile"
              data-testid="Nav-Aura"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                alt="User Icon"
                src={activeTab === 'profile' ? UserSelectedIcon : UserIcon}
                width={20}
                onClick={() => {
                  setMyFamilyExpanded(false);
                }}
              />
              <span
                style={{ marginRight: '60px' }}
                onClick={() => {
                  setMyFamilyExpanded(false);
                }}
              >
                {t('myAura')}
              </span>
            </Link>
            <img
              src={DownCaret}
              alt="Expand Icon"
              onClick={() => {
                setMyFamilyExpanded(!myFamilyExpanded);
              }}
            />
          </div>
          {myFamilyExpanded && navMyAura && (
            <div className={css.myFamilyLinkList}>
              {[
                requireHealthReport && {
                  path: '/daily-symptom-checker',
                  text: t('dailySymptomChecker'),
                  dotColor: '#70CC6E',
                  tabName: 'dailySymptomChecker',
                },
                navFamily && {
                  path: '/family',
                  text: t('myFamily'),
                  dotColor: '#FF77C0',
                  tabName: 'family',
                },
                navInsurance && {
                  path: '/insurance-information',
                  text: t('myInsurance'),
                  dotColor: '#F8C820',
                  tabName: 'insurance',
                },
                {
                  path: '/account-information',
                  text: t('myAccountInfo'),
                  dotColor: '#BFBFBF',
                  tabName: 'account',
                },
                navVaccine && {
                  path: '/vaccine-information',
                  text: t('myVaccine'),
                  dotColor: '#9DD4F2',
                  tabName: 'vaccine',
                },
                {
                  path: '/tech-support',
                  text: t('techSupport'),
                  dotColor: '#BABABA',
                  tabName: 'techSupport',
                },
                navDocuments && {
                  path: '/documents',
                  text: t('myDocuments'),
                  dotColor: '#F5A860',
                  tabName: 'documents',
                },
              ].map(
                (item, index) =>
                  item.text && (
                    <Link
                      key={index}
                      to={item.path}
                      className={classNames(
                        css.subButtonContainer,
                        activeTab === item.tabName && css.selected
                      )}
                    >
                      <p
                        style={{
                          color: item.dotColor,
                          marginBottom: 0,
                          fontSize: '35px',
                        }}
                      >
                        &bull;
                      </p>
                      <span>{item.text}</span>
                    </Link>
                  )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    heatmap: state.user.profile?.attributes?.heatmap,
    user: state.user.profile,
  };
}

export default connect(mapStateToProps)(Sidebar);
