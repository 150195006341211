import React, { useState } from 'react';
import classNames from 'classnames';
import { Layout, Checkbox, Button, Divider } from '../../components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

import noticeLogo from '../../assets/svg/caution.svg';

const Notice = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { history } = props;
  const [loading, setLoading] = useState(false);

  const [agree, setAgree] = useState(false);

  async function postData() {
    setLoading(false);
    history.push('/vaccinesite');
  }

  return (
    <Layout tab={activeTab} headerTitle={t('pleaseNote')} onClickBack={() => history.goBack()} back>
      <div className="vaccine">
        <div className={css.subContainer}>
          <img src={noticeLogo} className={css.caution} alt="Logo" />
          <Divider />
          <div className={css.noticeContainer}>
            <p>{t('notice')}</p>
            <ol>
              <li>{t('youWillNot')}</li>
              <li>{t('theVaccineType')}</li>
            </ol>
            <Checkbox
              selected={agree}
              handleChange={() => setAgree(!agree)}
              label={t('iAgreeTo')}
              labelColor={!agree && '#777777'}
            />

            <Button
              disabled={!agree}
              className={classNames('mt-4 mb-2')}
              text={t('proceedToScheduling')}
              loading={loading}
              onClick={postData}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
