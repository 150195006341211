import React, { useState } from 'react';
import './index.css';
import IsInsured from './IsInsured';
import Arrow from '../../../assets/image/Arrow.png';
import AddNewUserInsurance from './AddNewUserInsurance';
import AdditionalInfo from './AdditionalInfo';
import InsuranceCompleted from './InsuranceCompleted';

const Index = () => {
  const [insuranceFormView, setInsuranceFormView] = useState(false);
  const [additionalInfoView, setAdditionalInfoView] = useState(false);
  const [insuranceDone, setInsuranceDone] = useState(false);

  const goBack = () => {
    insuranceFormView && setInsuranceFormView(false);
    additionalInfoView && setAdditionalInfoView(false);
  };

  const onSubmit = (insuredVal) => {
    insuredVal ? setInsuranceFormView(true) : setAdditionalInfoView(true);
  };

  return (
    <>
      <div className=" d-flex align-items-center m-3 px-0 px-sm-4 m-sm-4">
        {(insuranceFormView || additionalInfoView) && (
          <div className="back-button-logo">
            <img
              src={Arrow}
              alt="Arrow"
              style={{ cursor: 'pointer' }}
              className="mr-3"
              onClick={goBack}
            />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <div
            className={
              insuranceFormView || additionalInfoView
                ? 'mx-sm-5 mx-0 header-text px-5'
                : 'mx-sm-5 mx-0 justify-content-center px-5 header-text'
            }
          >
            <span className="IsInsured_header">Insurance Information</span>
          </div>
        </div>
      </div>

      <div className="border mt-4" />

      {insuranceFormView ? (
        <AddNewUserInsurance
          setInsuranceDone={setInsuranceDone}
          setInsuranceFormView={setInsuranceFormView}
        />
      ) : additionalInfoView ? (
        <AdditionalInfo
          setInsuranceDone={setInsuranceDone}
          setAdditionalInfoView={setAdditionalInfoView}
        />
      ) : insuranceDone ? (
        <InsuranceCompleted />
      ) : (
        <div className="mt-2 mt-sm-5 pt-5">
          <IsInsured
            onSubmit={(val) => {
              onSubmit(val);
            }}
          />
        </div>
      )}
    </>
  );
};
export default Index;
