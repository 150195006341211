import React from 'react';
import classNames from 'classnames';
import NextIcon from '../../assets/svg/next.svg';
import { Divider, TextField } from '..';
import { Button } from 'react-bootstrap';

import css from './List.module.css';
import GreenPositiveIcon from '../../assets/svg/green_positive.svg';
import GreenNegativeIcon from '../../assets/svg/green_negative.svg';
import RedNegativeIcon from '../../assets/svg/red_negative.svg';
import RedPositiveIcon from '../../assets/svg/red_positive.svg';
import OrangePendingIcon from '../../assets/svg/orange_pending.svg';
import CertificationIcon from '../../assets/image/certification_big.png';
import moment from 'moment';

export function CertificationList({ successColor, text1, text2 }) {
  return (
    <div>
      <div className="d-flex mt-3 mb-3">
        <img alt="icon" src={CertificationIcon} className={css.certificationImg} />
        <div className="d-flex flex-column justify-content-between w-100">
          <p className="normal-font">Back to Work Certified</p>
          <div className="d-flex justify-content-between ">
            <p className="small-sub-font" style={{ color: '#777777' }}>
              {text1}
            </p>
            <p className="small-sub-font" style={{ color: successColor ? '#78DA76' : '#E74747' }}>
              {text2}
            </p>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
}

export function ListForm(props) {
  const { title, error } = props;

  return (
    <div className={css.listSimpleContainer}>
      <TextField
        label={title}
        className="pb-0 w-100"
        classnameinput={css.listFormFont}
        helperText={error && props.errorMessage}
        {...props}
      >
        {props.children}
      </TextField>
    </div>
  );
}

export function DataList({ title, description, descriptionClass }) {
  return (
    <div className={css.listSimpleContainer}>
      <p className={classNames('small-list-font', css.mediumSubFont)}>{title}</p>
      <h3
        className={classNames('normal-font', css.normalFont, descriptionClass)}
        data-testid="DATAVAL"
      >
        {description}
      </h3>
      <Divider />
    </div>
  );
}

export function ListSimple({ title, description, divider }) {
  return (
    <div className={css.listSimpleContainer}>
      <p className={classNames('medium-sub-font', css.mediumSubFont)}>{title}</p>
      <h3 className={classNames('normal-font', css.normalFont)}>{description}</h3>
      {divider && <Divider />}
    </div>
  );
}

export function ListFamilyMember({
  id,
  firstName,
  lastName,
  email,
  onConsentClick,
  showConsentButton,
  signedOnDate,
  onQrClick,
  showSymptoms,
  onSymptomClick,
  symptomsDisabled,
  symptomsDate,
}) {
  const fmDate = moment(signedOnDate).format('MM/DD/YYYY');

  return (
    <>
      <div className={classNames('mt-3 d-flex justify-content-between', css.signContainer)}>
        <div className={css.nameColumn}>
          <h4 className={classNames('small-font', css.normalFont)}>
            {firstName} {lastName}
          </h4>
        </div>
        <div className={css.qrColumn}>
          <Button
            data-testid="QRCLICK"
            className="d-flex justify-content-center mb-2"
            variant="primary"
            size="sm"
            onClick={onQrClick}
          >
            QR Code
          </Button>
        </div>
        {showSymptoms && (
          <div className={css.signColumn}>
            {!symptomsDisabled && (
              <Button
                data-testid="SYMPTOM"
                className="d-flex justify-content-center mb-2"
                variant="primary"
                size="sm"
                onClick={onSymptomClick}
              >
                Symptoms
              </Button>
            )}
            {symptomsDisabled && (
              <h4 className={classNames('small-font', css.normalFont)}>Checked {symptomsDate}</h4>
            )}
          </div>
        )}
        <div className={css.signColumn}>
          {showConsentButton && (
            <Button
              size="sm"
              className="d-flex justify-content-end mb-2"
              variant="primary"
              onClick={onConsentClick}
            >
              Sign Doc
            </Button>
          )}
          {!showConsentButton && signedOnDate && (
            <h4 className={classNames('small-font', css.normalFont)}>Signed {fmDate}</h4>
          )}
        </div>
      </div>
      <Divider />
    </>
  );
}

export function ListResults({
  selected,
  result,
  type,
  date,
  icon,
  updatedTime,
  name,
  onCLick,
  full,
}) {
  let color = OrangePendingIcon;
  switch (icon) {
    case 'greenNegative':
      color = GreenNegativeIcon;
      break;
    case 'greenPositive':
      color = GreenPositiveIcon;
      break;
    case 'redPositive':
      color = RedPositiveIcon;
      break;
    case 'redNegative':
      color = RedNegativeIcon;
      break;
    default:
      color = OrangePendingIcon;
      break;
  }

  if (full) {
    return (
      <div>
        <div className={css.containerFull} onClick={onCLick}>
          <div className={css.flex}>
            <div>
              <h4 className="normal-font">{type}</h4>
              <p className="sub-font">{date}</p>
            </div>
          </div>
          <div className={css.flexAlignCenter}>
            <div className={css.text1Container}>
              <p className="small-font">{result}</p>
            </div>
            <img className={css.icon} alt="positive" src={color} width={26} />
          </div>
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <div>
      <div className={classNames(css.container1, selected && css.selected)} onClick={onCLick}>
        <div className={css.flex}>
          {/* <div className={css.circleContainer}>
            <div className={classNames(css.circle, selected && css.orange)}></div>
          </div> */}
          <div>
            <h4 className="normal-font">{type}</h4>
            <p className="sub-font">
              {date} - {name}
            </p>
          </div>
        </div>
        <div className={css.flexAlignCenter}>
          <div className={css.text1Container}>
            <p className="small-font">{result}</p>
          </div>
          <img className={css.icon} alt="positive" src={color} width={26} />
        </div>
      </div>
      <Divider className={css.divider} />
    </div>
  );
}

function ListComponent({ bigText, subText, arrowIcon, subTextClassName, onCLick }) {
  return (
    <div>
      <div className={css.container} onClick={onCLick}>
        <div className={css.left}>
          <h4 className="normal-font">{bigText}</h4>
          <p className={classNames('sub-font', subTextClassName)}>{subText}</p>
        </div>
        {arrowIcon && (
          <div className={css.right}>
            <img alt="next" src={NextIcon} />
          </div>
        )}
      </div>
      <Divider />
    </div>
  );
}

export default ListComponent;
