import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spinner, Button as Button2, Alert, Form, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import MaskedInput from 'react-text-mask';
import { animateScroll } from 'react-scroll';
import { symptoms } from '../../api/models/symptoms';
import { getQrByUuid } from '../../api/models/qr';
import { userSymptoms, getUserData, getUserFamilyMembers } from '../../api/models/user';
import { capitalize } from '../../api';
import CTACirclePlus from '../../assets/svg/CTACirclePlus.svg';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Layout,
  ListForm,
  ListFamilyMember,
  StrokeButton,
  Modal,
  Divider,
  CommonLogo,
} from '../../components';
import css from './Family.module.css';
import { setFamilyMembers, setQRCode, setSelectedFamilyMember } from '../../store/action/family';
import { setUser } from '../../store/action/user';
import { authentication } from '../../store/action/auth';
import { getStoredData, storeData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { validateFamilyMemberInformation } from '../../api/validation';
import { processAPI } from '../../api/models/process';
import { signUp } from '../../api/auth';
import { stateList, genderList, ethnicList, raceList } from '../../utils/utils';
import { resultsMedTest } from '../../api/models/medTest';
import CirclePlus from '../../assets/svg/circle-plus.svg';
import { MenuItem } from '@material-ui/core';
import { addFamilyMemberStepList } from '../../utils/sidebarSteps';
import FamilyMemberCard from './FamilyMemberCard';

function TextMaskDOB(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

const FamilyContainer = (props) => {
  const { t, i18n } = useTranslation();
  const { pathname } = props.history.location;
  const { profile } = props;
  const activeTab = pathname.split('/')[1];
  const {
    familyMembers,
    setFamilyMembersDispatch,
    setSelectedFamilyMemberDispatch,
    setQrCodeDispatch,
    status,
    setAuthenticationDispatch,
  } = props;
  const history = useHistory();
  console.log('familyMembers', familyMembers);

  const [loading, setLoading] = useState(!(familyMembers.length > 0));
  const [user, setUser] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [addFamilyMember, setAddFamilyMember] = useState(false);
  const [svgCode, setSvgCode] = useState('');
  const buff = new Buffer(svgCode);
  const base64data = buff.toString('base64');

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();

  const [birthday, setBirthday] = useState();
  const [gender, setGender] = useState();
  const [ethnic, setEthnic] = useState();
  const [race, setRace] = useState();

  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;

  const [unsigned, setUnsigned] = useState();
  const [unsignedIndex, setUnsignedIndex] = useState(0);
  const [isQrVisible, setQrVisible] = useState(false);
  const [isConsentVisible, setConsentVisible] = useState(false);
  const [lastUnsigned, setLastUnsigned] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [agree, setAgree] = useState(false);

  const [familyMemberId, setFamilyMemberId] = useState();
  const [familyMemberName, setFamilyMemberName] = useState();

  const [showModal, setShowModal] = useState('');
  const [updatedList, setUpdatedList] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [userSymptomsData, setUserSymptomsdata] = useState([]);
  const lastUpdated = userSymptomsData && userSymptomsData.length > 0 && moment().format('L');
  const [loadingList, setLoadingList] = useState(true);
  const [familySymptoms, setFamilySymptoms] = useState([]);
  const [currentFamilyId, setCurrentFamilyId] = useState('');
  const [currentFamilySymptoms, setCurrentFamilySymptoms] = useState([]);
  const [symptomsSubmittedIds, setSymptomsSubmittedIds] = useState([]);
  // const familySymptomsChecker = props?.user?.profile?.attributes['family-symptoms-checker'];

  const [familyMemberResults, setFamilyMemberResults] = useState({});

  const [family, setFamily] = useState([]);

  const { setUserDispatch } = props;

  const [navVaccines, setNavVaccines] = useState(false);
  const [navInsurance, setNavInsurance] = useState(false);

  const stepList = addFamilyMemberStepList(!navInsurance, !navVaccines);

  function hideModal() {
    setShowModal('');
  }

  function openModal(modalName) {
    setShowModal(modalName);
  }

  useEffect(() => {
    setLoading(true);
    loadData();
  }, []);

  useEffect(() => {
    unsigned && setLastUnsigned(unsignedIndex === unsigned.length - 1);
  }, [unsignedIndex]);

  const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x.attributes[key]] = rv[x.attributes[key]] || []).push(x);
      return rv;
    }, {});
  };

  const handleBirthday = (input) => {
    let v = input;
    if (input.length <= 10) {
      if (v.match(/^\d{2}$/) !== null) {
        input = `${v}/`;
      } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
        input = `${v}/`;
      }
      setBirthday(input);
    }
  };

  async function loadData() {
    setLoading(!(familyMembers.length > 0));
    const userData = await getStoredData(USER_CACHE_NAME);
    setUser(userData);
    if (userData && userData.status === 'login') {
      let familyMemberList =
        (await getUserFamilyMembers(userData.user_id, userData.jwt)).included || [];
      setFamily(familyMemberList);
      setFamilyMembersDispatch(familyMemberList);
      let results = (await resultsMedTest(userData.jwt)) || [];
      let groupedResults = groupBy(results, 'user-id');
      setFamilyMemberResults(groupedResults);
      const user = await loadUser();
      familyMemberList.unshift(user);
      setNavVaccines(user?.attributes['nav-vaccines']);
      setNavInsurance(user?.attributes['requires-insurance']);

      setLoading(false);
    } else {
      setShowDialog(true);
      setLoading(false);
    }
  }

  function filterSelectedSymptoms(symptoms, userSymptoms) {
    if (!userSymptoms) return [];
    return symptoms.map((item) => {
      const index = userSymptoms?.findIndex((item1) => item1.symptom_id == item.id);

      if (index > -1) {
        return { ...item, selected: true, symptomId: userSymptoms[index].id };
      }
      return item;
    });
  }

  async function loadUser() {
    const us = await getUserData(props.authentication.user_id, props.authentication.jwt);
    props.setUserDispatch(us.data);
    return us.data;
  }

  async function loadFamilyMemberConsentForms(famMemberId) {
    if (familyMembers && familyMembers.length > 0) {
      let familyMember = familyMembers && familyMembers.find((x) => x.id === famMemberId);
      console.log(familyMember);
      const us =
        familyMember &&
        familyMember.attributes &&
        familyMember.attributes['hipaa-release-details'] &&
        familyMember.attributes['hipaa-release-details'].unsigned;
      setUnsigned(us);
      setFamilyMemberId(famMemberId);
      if (us) {
        setConsentVisible(true);
        setLastUnsigned(unsignedIndex === us.length - 1);
      }
    }
  }

  function checkUnsigned(famMemberId) {
    if (familyMembers && familyMembers.length > 0) {
      const familyMember = familyMembers && familyMembers.find((x) => x.id === famMemberId);
      console.log(familyMember);
      const us =
        familyMember &&
        familyMember.attributes &&
        familyMember.attributes['hipaa-release-details'] &&
        familyMember.attributes['hipaa-release-details'].unsigned;
      if (us && us.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  function getSignedDate(famMemberId) {
    if (familyMembers && familyMembers.length > 0) {
      const familyMember = familyMembers && familyMembers.find((x) => x.id === famMemberId);
      const s =
        familyMember &&
        familyMember.attributes &&
        familyMember.attributes['hipaa-release-details'] &&
        familyMember.attributes['hipaa-release-details'].signed;
      if (s && s.length > 0) {
        return s[0]?.signed_on;
      }
    }
    return null;
  }

  function getSymptomsDate() {
    return moment().format('MM/DD/YYYY');
  }

  function bottomScroll() {
    animateScroll.scrollToBottom({
      containerId: 'scroll-div',
    });
  }

  async function hipaaApproved() {
    const { id } = props.user.profile;
    const { jwt } = props.authentication;
    setLoadingModal(true);
    try {
      await Promise.all(
        unsigned.map(async (item) => {
          const updatedData = {
            data: {
              type: 'user-hipaa-releases',
              attributes: {
                'family-member-id': familyMemberId,
                'hipaa-release-id': item.id,
                'user-id': +id,
              },
            },
          };

          await processAPI({
            key: jwt,
            endpoint: 'user-hipaa-releases',
            methodType: 'POST',
            data: updatedData,
          });
        })
      );
      await loadUser();
      setUnsigned([]);
      setUnsignedIndex(0);
      setAgree(false);
      loadData();
    } catch (error) {
      console.log('...error ', error);
    }

    setLoadingModal(false);
  }

  function nextUnsigned() {
    setAgree(false);
    setUnsignedIndex(unsignedIndex + 1);
  }

  function checkFamilySymptoms(id) {
    const currentFamilySymptoms = familySymptoms.find((symptom) => symptom.id === id);
    setCurrentFamilySymptoms(currentFamilySymptoms?.symptoms || []);
    setCurrentFamilyId(id);
    openModal('report');
  }

  function loadFamilyMemberQrCode(famMemberId) {
    console.log('famMemberId', famMemberId);
    if (famMemberId === profile.id) {
      const { uuid } = profile.attributes;
      console.log(uuid);

      if (uuid) {
        setFamilyMemberName(
          `${profile.attributes['first-name']} ${profile.attributes['last-name']}`
        );
        loadQrCodeByUuid(uuid, `${profile.attributes['first-name']}`);
      }
    } else if (family && family.length > 0) {
      console.log('familyMembers', family);
      const familyMember = family && family.find((x) => x.id === famMemberId);
      console.log('familyMember', familyMember);
      const uuid = familyMember && familyMember.attributes && familyMember.attributes.uuid;
      console.log(uuid);
      setFamilyMemberId(famMemberId);

      if (uuid) {
        setFamilyMemberName(
          `${familyMember.attributes['first-name']} ${familyMember.attributes['last-name']}`
        );
        loadQrCodeByUuid(uuid, `${familyMember.attributes['first-name']} `);
      }
    }
  }

  async function loadQrCodeByUuid(uuid, name) {
    // setQrVisible(true);
    const { authentication } = props;
    const { jwt } = authentication;
    const data = await getQrByUuid(jwt, uuid);
    setSvgCode(`${data.svgCode}`);
    setQrCodeDispatch(data.svgCode);
    console.log('family member name', name);
    history.push({
      pathname: '/qr-code',
      state: { name },
    });
  }

  function closeQrModal() {
    setQrVisible(false);
    setSvgCode('');
  }

  async function multiplePost() {
    const { jwt } = props.authentication;
    try {
      const postData = updatedList.filter((item) => item.selected);

      setLoadingUpdate(true);
      await Promise.all(
        postData.map(async (item) => {
          await postSymptoms(jwt, currentFamilyId, item.id);
        })
      );

      setLoadingUpdate(false);
      setUpdatedList([]);
      hideModal();
      setShowAlert({
        show: true,
        message: 'Save successfully',
        type: 'success',
      });

      setUserSymptomsdata(postData); // data doesnt matter just for rendering
      setSymptomsSubmittedIds((symptomsSubmittedIds) => [...symptomsSubmittedIds, currentFamilyId]);
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }
  }

  async function postSymptoms(key, userId, symptomId) {
    const data = {
      data: {
        type: 'user-symptoms',
        attributes: {
          'user-id': userId,
          'symptom-id': symptomId,
        },
      },
    };

    await userSymptoms({ data, key, methodType: 'POST' });
  }

  function updateList(id, selected, symptomId) {
    const newList = [...currentFamilySymptoms];
    const index = newList.findIndex((item) => item.id === id);

    newList[index] = { ...newList[index], selected };

    let data = updatedList;
    const indexUpdatedList = data.findIndex((item) => item.id == id);

    if (indexUpdatedList > -1) {
      data.splice(indexUpdatedList, 1);
    } else {
      data.push({ id, symptomId, selected });
    }

    setUpdatedList(data);
  }

  async function onSubmit(e) {
    e.preventDefault();

    const { invalid, message } = validateFamilyMemberInformation({
      firstName,
      lastName,
      birthday,
      address,
      zip,
      gender,
      race,
      ethnic,
      city,
      state,
    });
    setValidation({ invalid, message });

    if (!invalid) {
      try {
        setLoading(true);
        const managed = !!(user.phone_number && !user.email);
        const data = {
          'primary-user-id': user.user_id,
          email: user.email,
          'phone-number': user.phone_number,
          'first-name': firstName,
          'last-name': lastName,
          'address-line-1': address,
          'address-line-2': address2,
          state,
          city,
          zip,
          'date-of-birth': moment(birthday, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          gender,
          ethnicity: ethnic,
          race,
          'managed-account': managed,
        };

        const response = await signUp(data);
        console.log('response', response);

        if (response.errors) {
          const detail = response.errors[0];
          setValidation({ invalid: true, message: detail });
          console.log('response.errors', response.errors);
          alert(detail);
        } else {
          let { id } = response.user;
          loadData();
          setAddFamilyMember(false);
          history.push(`/sign-doc/${id}`, { next: false });
        }
      } catch (error) {
        console.log('error', error);
      }
      setLoading(false);
    }
  }

  return (
    <Layout
      headerTitle="My Family"
      tab={activeTab}
      disablePrimaryUserHipaaPopup
      back
      onClickBack={() => {
        addFamilyMember ? setAddFamilyMember(false) : history.push('/profile');
      }}
      progressBar={addFamilyMember}
      progressBarStepsList={stepList}
      progressBarCurrentStep={0}
      rightText={
        !addFamilyMember && (
          <StrokeButton
            title="Add Family Member"
            className={css.addFamilyMemberButton}
            icon={CirclePlus}
            handlePress={() => setAddFamilyMember(true)}
          />
        )
      }
    >
      {addFamilyMember ? (
        <>
          <Form onSubmit={onSubmit} className={css.form} noValidate>
            <h3 className={css.addFamilyHeaderText}>Add a Family Member</h3>
            <ListForm
              required
              id="first-name"
              label={t('firstName')}
              placeholder="enter your first name"
              name="first-name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              error={invalid === 'firstName'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'fName' }}
            />
            <ListForm
              required
              id="last-name"
              label={t('lastName')}
              placeholder="enter your last name"
              name="last-name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              error={invalid === 'lastName'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'lName' }}
            />
            <ListForm
              required
              id="date-of-birth"
              label={`${t('dateOfBirth')} (mm/dd/yyyy)`}
              inputComponent={TextMaskDOB}
              name="birthday"
              onChange={(e) => handleBirthday(e.target.value)}
              value={birthday}
              error={invalid === 'birthday'}
              errorMessage={errorMessage}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              inputProps={{
                'data-testid': 'dob',
                mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
              }}
            />
            <ListForm
              required
              id="address"
              label={t('address')}
              placeholder="enter your address"
              name="address"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              error={invalid === 'address'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'address' }}
            />
            <ListForm
              id="address2"
              label={t('address2')}
              placeholder="enter your address 2"
              name="address2"
              onChange={(e) => setAddress2(e.target.value)}
              value={address2}
              error={invalid === 'address 2'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'address2' }}
            />
            <ListForm
              required
              id="city"
              label={t('city')}
              placeholder="enter your city"
              name="city"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              error={invalid === 'city'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'city' }}
            />
            <ListForm
              required
              title={t('state')}
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              error={invalid === 'state'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'state' }}
              select
            >
              {stateList.map((item) => (
                <MenuItem className="normal-font" value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </ListForm>
            <ListForm
              required
              id="zip"
              label={t('zipCode')}
              placeholder="enter your zip code"
              name="zip"
              onChange={(e) => setZip(e.target.value)}
              value={zip}
              error={invalid === 'zip'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'zipCode' }}
            />
            <ListForm
              title={t('gender')}
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              error={invalid === 'gender'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'gender' }}
              select
            >
              {genderList.map((item) => (
                <MenuItem className="normal-font" value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </ListForm>
            <ListForm
              title={t('race')}
              id="race"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              error={invalid === 'race'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'race' }}
              select
            >
              {raceList.map((item) => (
                <MenuItem className="normal-font" value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </ListForm>

            <ListForm
              title={t('ethnicity')}
              id="ethnicity"
              value={ethnic}
              onChange={(e) => setEthnic(e.target.value)}
              error={invalid === 'ethnic'}
              errorMessage={errorMessage}
              inputProps={{ 'data-testid': 'ethnicity' }}
              select
            >
              {ethnicList.map((item) => (
                <MenuItem className="normal-font" value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </ListForm>

            {invalid === true && (
              <Alert severity="error" className="mb-2">
                {errorMessage}
              </Alert>
            )}

            <div className="form-group justify-content-between flex-row pt-2 mt-2">
              <Button
                disabled={
                  loading || !firstName || !lastName || !birthday || !state || !city || !address
                }
                type="submit"
                className={css.addFamilySubmit}
                data-testid="btnSave"
              >
                {!loading && (
                  <img src={CTACirclePlus} alt="plus" style={{ marginRight: '0.5rem' }} />
                )}
                {loading ? <Spinner animation="border" variant="warning" /> : 'Add Family Member'}
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <div className={css.container}>
          <h3 className={css.title}>{t('familyHeader')}</h3>
          <div className={css.familyGrid}>
            {family.map(({ attributes, id }, index) => {
              const selectedResults = familyMemberResults[id];
              const numResults = selectedResults && selectedResults.length;
              const unsignedDocs = attributes?.['hipaa-release-details']?.unsigned || [];
              const mostRecentResult = selectedResults && selectedResults[numResults - 1];

              return (
                <FamilyMemberCard
                  key={id}
                  id={id}
                  index={index}
                  css={css}
                  unsignedDocs={unsignedDocs}
                  attributes={attributes}
                  numResults={numResults}
                  mostRecentResult={mostRecentResult}
                  activeTab={activeTab}
                  loadUser={loadUser}
                  loadFamilyMemberQrCode={loadFamilyMemberQrCode}
                />
              );
            })}
          </div>
        </div>
      )}

      <Modal
        show={isQrVisible}
        closeButton
        handleClose={() => closeQrModal()}
        title={familyMemberName}
        hideSubmit
      >
        <div className="d-flex flex-column align-items-md-center">
          <img src={`data:image/svg+xml;base64,${base64data}`} alt="" />
        </div>
      </Modal>
      <Modal
        title={t('healthReport')}
        show={showModal === 'report'}
        handleSubmit={multiplePost}
        loading={loadingUpdate}
        handleClose={hideModal}
      >
        <div className="p-4">
          <div className="d-flex justify-content-between">
            <p className="normal-font">Date: {moment().format('L')}</p>
            {lastUpdated && (
              <p className="x-small-sub-font">
                {t('lastUpdated')} {lastUpdated}
              </p>
            )}
          </div>
          <div className="mt-4 mb-4">
            <p className="normal-font text-center">{t('pleaseCheckAny')}</p>
          </div>
          {loadingList && (
            <div className="d-flex justify-content-center mt-4 mb-4">
              <Spinner animation="grow" variant="primary" />
            </div>
          )}
          <div>
            {currentFamilySymptoms.map((item, index) => (
              <div key={`cb-${index}`}>
                <Divider />
                <div className="mt-2 mb-2">
                  <Checkbox
                    label={capitalize(item.attributes.name)}
                    selected={item.selected}
                    handleChange={() => updateList(item.id, !item.selected, item.symptomId)}
                    labelColor={!item.selected && '#777777'}
                  />
                </div>
              </div>
            ))}
            {currentFamilySymptoms.length > 0 && <Divider />}
          </div>
        </div>
      </Modal>

      {unsigned && unsigned.length > 0 && unsigned[unsignedIndex].title && (
        <Modal
          show={isConsentVisible}
          handleClose={() => setConsentVisible(false)}
          title={unsigned[unsignedIndex].title}
          footerComponent={
            <div className="d-flex flex-column flex-sm-row align-items-md-center justify-content-between w-100">
              <Checkbox
                selected={agree}
                handleChange={() => setAgree(!agree)}
                label={t('clickToAgree')}
                labelColor={!agree && '#777777'}
              />
              <div className="mt-3">
                <Button2 variant="outline-primary w-100" onClick={bottomScroll}>
                  Scroll To Bottom
                </Button2>
              </div>
              <div className="mt-3">
                {loadingModal ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  <Button2
                    variant="primary w-100"
                    disabled={!agree}
                    onClick={lastUnsigned ? hipaaApproved : nextUnsigned}
                  >
                    {lastUnsigned ? 'OK' : 'NEXT'}
                  </Button2>
                )}
              </div>
            </div>
          }
        >
          <div id="scroll-div" className="hippa-container">
            <p className="norma-font">
              <Markdown
                source={unsigned[unsignedIndex].release_text.replace(
                  /my employer/g,
                  user?.profile?.attributes?.['company-name']
                )}
              />
            </p>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  profile: state.user.profile,
  familyMembers: state.family.familyMembers,
  qrCode: state.family.qrCode,
  authentication: state.authentication,
  status: state.authentication.status,
});

const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setFamilyMembersDispatch(payload) {
    dispatch(setFamilyMembers(payload));
  },
  setAuthenticationDispatch(payload) {
    dispatch(authentication(payload));
  },
  setSelectedFamilyMemberDispatch(payload) {
    dispatch(setSelectedFamilyMember(payload));
  },
  setQrCodeDispatch(payload) {
    console.log('setQrCodeDispatch', payload);
    dispatch(setQRCode(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyContainer);
