import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, StrokeButton, Alert, Checkbox } from '../../components';
import Select from 'react-select';
import css from './InsuranceInformation.module.css';
import {
  getUserDataProfile,
  getUserFamilyMembers,
  getUserFamilyMembersFullData,
  getUserData,
} from '../../api/models/user';
import Arrow from '../../assets/svg/Arrow2.svg';
import CaretUp from '../../assets/svg/caret_up.svg';
import Check from '../../assets/svg/check.svg';
import InsuranceShield from '../../assets/svg/insurance-shield-outline.svg';
import CirclePlus from '../../assets/svg/circle-plus.svg';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import Input from '../../components/Input/Input';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import { stateList } from '../../utils/utils';
import { Spinner } from 'react-bootstrap';
import { editInsuranceSchema } from '../../utils/constraints';
import { processAPI } from '../../api/models/process';
import { uploadFile } from '../../api/models/forms';
import Camera from '../../assets/svg/camera.svg';
import Replace from '../../assets/svg/Replace.svg';
import OpenTriangle from '../../assets/image/OpenTriangleF.png';
import { setUser } from '../../store/action/user';
import SideMenu from '../VaccineInformation/SideMenu';

const InsuranceInformation = (props) => {
  let { id } = useParams();
  if (!id) {
    id = props.user.id;
  }
  let key = props.authentication.jwt;

  const history = useHistory();
  const userResource = props?.user?.attributes || {};
  const [insuranceData, setInsuranceData] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [showAlert, setShowAlert] = useState({ show: false });
  const [user, setUser] = useState();
  const [familyMemberDetails, setFamilyMemberDetails] = useState([]);
  const [accountUserDetails, setAccountUserDetails] = useState(null);
  const { jwt } = props?.authentication;

  async function loadUser() {
    const user = await getUserData(props?.authentication?.user_id, jwt);
    props?.setUserDispatch(user?.data);
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  const loadData = async () => {
    const data = await getUserDataProfile(props.user.id, props.authentication.jwt);
    setInsuranceData(data);
  };

  async function getFamily() {
    const family = (await getUserFamilyMembers(props.user.id, props.authentication.jwt)).included;
    const member = family?.find((member) => member?.id == id);
    setUser(member);
    setFamilyMembers(family);
  }

  async function getFamilyAndUser() {
    const familyMemberData = (
      await getUserFamilyMembersFullData(props.user.id, props.authentication.jwt)
    ).included;
    if (id == props?.user?.id) {
      setAccountUserDetails(
        familyMemberData?.find((item) => item?.type == 'user-profiles')?.attributes
      );
    } else {
      setFamilyMemberDetails(familyMemberData?.find((item) => item.id == id)?.attributes);
    }
  }

  useEffect(() => {
    getFamilyAndUser();
    loadUser();
    loadData();
    getFamily();
    searchPlan('');
    getFamilyMemberInsurance();
  }, []);

  const getFamilyMemberInsurance = async () => {
    const familyData = await processAPI({
      key: props.authentication.jwt,
      methodType: 'GET',
      endpoint: `insurance-policy-members`,
    });
  };

  async function searchPlan(planLabel) {
    const endpoint = `insurance-plans?filter[plan-name]=${planLabel}`;

    const data = await processAPI({ endpoint, key, methodType: 'GET' });
    const plans = data.map((item) => {
      const { attributes } = item;
      const label =
        attributes['company-name'] === attributes['plan-name']
          ? attributes['plan-name']
          : `${attributes['plan-name']}-${attributes['company-name']}`;
      return { value: item.id, label };
    });

    setPlanList(plans);
    return plans;
  }

  const InsurancePolicyCard = (props) => {
    const { planList } = props;
    const [expanded, setExpanded] = useState(false);

    const [insuranceNotListed, setInsuranceNotListed] = useState(
      !!props?.item?.attributes['free-form-name']
    );
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
      (props?.item?.attributes['insurance-company-name'] && [
        {
          value: props?.item?.attributes['insurance-plan-id'],
          label: props?.item?.attributes['insurance-company-name'],
        },
      ]) || [planList[0]] ||
        []
    );

    const [files, setFiles] = useState([]);

    async function getUrl() {
      const preSigned = {
        filename: files[0].name,
        image_type: files[0].type,
      };
      const data = await processAPI({
        key,
        methodType: 'POST',
        data: preSigned,
        endpoint: 'pre-signed-post',
      });
      return data;
    }

    async function saveFileName(fileName, insurancePlanId) {
      const data = await processAPI({
        key,
        methodType: 'PATCH',
        data: {
          filename: fileName,
          imageable_id: insurancePlanId,
          imageable_type: 'InsurancePolicy',
        },
        endpoint: 'image',
      });
      return data;
    }

    async function insuranceSubmit(values) {
      const { attributes } = props?.user;
      const { accountUserDetails, familyMemberDetails } = props;

      if (values?.patientRelationship === 'SEL') {
        if (props?.user?.id == id) {
          values.firstName = attributes['first-name'];
          values.lastName = attributes['last-name'];
          values.gender = accountUserDetails.gender;
          values.phoneNumber = accountUserDetails['phone-number'];
          values.dateOfBirth = accountUserDetails['date-of-birth'];
        } else {
          values.firstName = familyMemberDetails['first-name'] || '';
          values.lastName = familyMemberDetails['last-name'] || '';
          values.gender = familyMemberDetails.gender || '';
          values.phoneNumber = familyMemberDetails['phone-number'] || '';
          values.dateOfBirth = familyMemberDetails['date-of-birth'] || '';
        }
      }

      setLoading(true);
      let meta;
      try {
        if (files?.length > 0) {
          meta = await getUrl();
          await uploadFile(meta.url, files[0], meta.fields);
        }

        const myData = {
          data: {
            type: 'insurance-policies',
            id: props.item.id,
            attributes: {
              'policy-number': values?.insuredPolicyNumber,
              'group-number': values?.insuredGroupNumber,
              'first-name-of-insured': values?.firstName,
              'last-name-of-insured': values?.lastName,
              'insureds-address': `${values?.addressLine1} ${values?.addressLine2} ${values?.city} ${values?.state} ${values?.zip}`,
              'insureds-address-1': values?.addressLine1,
              'insureds-address-2': values?.addressLine2 && values?.addressLine2,
              'insureds-city': values?.city,
              'insureds-state': values?.state,
              'insureds-zip-code': values?.zip,
              'insureds-gender': values?.gender,
              'insureds-phone-number': values?.phoneNumber,
              'insureds-relationship-to-patient': values?.patientRelationship,
              'insureds-date-of-birth': values?.dateOfBirth,
              'insurance-plan-id':
                (selectedOption && !insuranceNotListed && parseInt(values?.insuranceName)) || null,
              'free-form-name': insuranceNotListed ? values?.insuranceFreeformName : null,

              'user-id': parseInt(id),
            },
          },
        };

        const data = await processAPI({
          key,
          methodType: 'PATCH',
          data: myData,
          endpoint: `insurance-policies/${props.item.id}`,
        });

        if (data.id) {
          setShowAlert({
            show: true,
            message: 'Save successfully',
            type: 'success',
          });
          if (files?.length) {
            await saveFileName(meta?.fields?.key, data.id);
          }
          loadData();
        } else {
          setShowAlert({ show: true, message: 'Failed', type: 'error' });
        }
      } catch (error) {
        setShowAlert({ show: true, message: 'Failed', type: 'error' });
        console.log(error);
      }

      setLoading(false);
    }

    return props.item.attributes['insurance-company-name'] ||
      props.item.attributes['free-form-name'] ? (
      <div className={expanded ? css.policyCardExpanded : css.policyCard} key={props.key}>
        <div className={css.policyCardTop}>
          <div className={css.policyCardTopLeft}>
            <h4 className={css.policyNameText}>
              {props.item.attributes['insurance-company-name'] ||
                props.item.attributes['free-form-name']}
            </h4>
            <h5 className={css.policyHolderText}>Policy Holder: {props.item.attributes.name}</h5>
          </div>
          <img
            src={expanded ? CaretUp : Arrow}
            style={{ cursor: 'pointer' }}
            alt="arrow"
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </div>
        {expanded ? (
          <div className={css.policyCardInfo}>
            <Formik
              initialValues={{
                insuranceNotListed: !!props?.item?.attributes['free-form-name'],
                insuranceName:
                  props.item.attributes['insurance-plan-id'] || planList[0]?.value || '',
                insuranceFreeformName: props.item.attributes['free-form-name'] || '',
                patientRelationship:
                  props.item.attributes['insureds-relationship-to-patient'] || '',
                firstName: props.item.attributes['first-name-of-insured'] || '',
                lastName: props.item.attributes['last-name-of-insured'] || '',
                gender: props.item.attributes['insureds-gender'] || '',
                phoneNumber: props.item.attributes['insureds-phone-number'] || '',
                dateOfBirth:
                  (props.item.attributes['insureds-date-of-birth'] &&
                    moment(props.item.attributes['insureds-date-of-birth']).format('MM-DD-YYYY')) ||
                  '',
                insuredGroupNumber: props.item.attributes['group-number'] || '',
                insuredPolicyNumber: props.item.attributes['policy-number'] || '',

                addressLine1: props.item.attributes['insureds-address-1'] || '',
                addressLine2: props.item.attributes['insureds-address-2'] || '',
                city: props.item.attributes['insureds-city'] || '',
                state: props.item.attributes['insureds-state'] || '',
                zip: props.item.attributes['insureds-zip-code'] || '',
              }}
              validationSchema={editInsuranceSchema}
              onSubmit={insuranceSubmit}
            >
              {(formikProps) => (
                <Form className={css.form}>
                  <div className={css.formContainer}>
                    {!insuranceNotListed && (
                      <div className="position-relative  mb-3 pb-1">
                        <Select
                          classNamePrefix="form-select"
                          name="insuranceName"
                          placeholder="Please type in the insurance name"
                          value={selectedOption}
                          options={planList}
                          onChange={(e) => {
                            formikProps?.setFieldValue('insuranceName', e?.value);
                            setSelectedOption(e);
                          }}
                        />
                      </div>
                    )}

                    <div className="my-2 d-flex align-items-center">
                      <Checkbox
                        selected={insuranceNotListed}
                        name="insuranceNotListed"
                        handleChange={(e) => {
                          setInsuranceNotListed(!insuranceNotListed);
                          formikProps?.setFieldValue(
                            'insuranceNotListed',
                            !formikProps?.values?.insuranceNotListed
                          );
                        }}
                        label="Insurance not listed?"
                      />
                    </div>

                    {insuranceNotListed && (
                      <div className="mb-3 pb-1">
                        <input
                          className="insurance-input"
                          name="insuranceName"
                          placeholder="Please type in the insurance name"
                          value={formikProps?.values?.insuranceFreeformName}
                          onChange={(e) => {
                            formikProps?.setFieldValue('insuranceFreeformName', e?.target.value);
                          }}
                        />
                      </div>
                    )}

                    <div className="my-4">
                      <div className="insurance-label">Relationship to the Insured*</div>

                      <div
                        className="d-flex flex-wrap flex-sm-nowrap align-items-center"
                        name="patientRelationship"
                      >
                        <div
                          className={`mt-3 ml-3 ml-sm-0 d-flex justify-content-center align-items-center ${
                            formikProps?.values?.patientRelationship === 'SEL'
                              ? 'selected-button'
                              : 'unSelected-button'
                          }`}
                          onClick={() => {
                            formikProps?.setFieldValue('patientRelationship', 'SEL');
                            formikProps?.setFieldValue(
                              'firstName',
                              props.item.attributes['first-name-of-insured'] || ''
                            );
                            formikProps?.setFieldValue(
                              'lastName',
                              props.item.attributes['last-name-of-insured'] || ''
                            );
                            formikProps?.setFieldValue(
                              'gender',
                              props.item.attributes['insureds-gender'] || ''
                            );
                            formikProps?.setFieldValue(
                              'phoneNumber',
                              props.item.attributes['insureds-phone-number'] || ''
                            );
                            formikProps?.setFieldValue(
                              'dateOfBirth',
                              (props.item.attributes['insureds-date-of-birth'] &&
                                moment(props.item.attributes['insureds-date-of-birth']).format(
                                  'MM-DD-YYYY'
                                )) ||
                                ''
                            );
                          }}
                        >
                          {formikProps?.values?.patientRelationship === 'SEL' && (
                            <img src={Check} alt="Check" className="mr-2" />
                          )}
                          Self
                        </div>

                        <div
                          className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                            formikProps?.values?.patientRelationship === 'SPO'
                              ? 'selected-button'
                              : 'unSelected-button'
                          }`}
                          onClick={() => formikProps?.setFieldValue('patientRelationship', 'SPO')}
                        >
                          {formikProps?.values?.patientRelationship === 'SPO' && (
                            <img src={Check} alt="Check" className="mr-2" />
                          )}
                          Spouse
                        </div>

                        <div
                          className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                            formikProps?.values?.patientRelationship === 'CHD'
                              ? 'selected-button'
                              : 'unSelected-button'
                          }`}
                          onClick={() => formikProps?.setFieldValue('patientRelationship', 'CHD')}
                        >
                          {formikProps?.values?.patientRelationship === 'CHD' && (
                            <img src={Check} alt="Check" className="mr-2" />
                          )}
                          Child
                        </div>

                        <div
                          className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                            formikProps?.values?.patientRelationship === 'OTH'
                              ? 'selected-button'
                              : 'unSelected-button'
                          }`}
                          onClick={() => formikProps?.setFieldValue('patientRelationship', 'OTH')}
                        >
                          {formikProps?.values?.patientRelationship === 'OTH' && (
                            <img src={Check} alt="Check" className="mr-2" />
                          )}
                          Other
                        </div>

                        <div
                          className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                            formikProps?.values?.patientRelationship === 'UNK'
                              ? 'selected-button'
                              : 'unSelected-button'
                          }`}
                          onClick={() => formikProps?.setFieldValue('patientRelationship', 'UNK')}
                        >
                          {formikProps?.values?.patientRelationship === 'UNK' && (
                            <img src={Check} alt="Check" className="mr-2" />
                          )}
                          Unknown
                        </div>
                      </div>
                    </div>

                    {formikProps?.values?.patientRelationship !== 'SEL' && (
                      <>
                        <div className={css.inputItem}>
                          <Field
                            name="firstName"
                            label="Insured First Name*"
                            placeholder="First Name"
                            component={Input}
                            type="text"
                          />
                        </div>
                        <Field
                          name="lastName"
                          label="Insured Last Name*"
                          placeholder="Last Name"
                          component={Input}
                          type="text"
                        />

                        <div className="my-4">
                          <div className="insurance-label">Insured Gender*</div>

                          <div className="d-flex flex-wrap flex-sm-nowrap  align-items-center">
                            <div
                              className={`mt-3 ml-3 ml-sm-0 d-flex justify-content-center align-items-center ${
                                formikProps?.values?.gender === ('Male' || 'M')
                                  ? 'selected-button'
                                  : 'unSelected-button'
                              }`}
                              onClick={() => formikProps?.setFieldValue('gender', 'Male')}
                            >
                              {formikProps?.values?.gender === 'Male' && (
                                <img src={Check} alt="Check" className="mr-2" />
                              )}
                              Male
                            </div>

                            <div
                              className={`mt-3 ml-3 d-flex justify-content-center align-items-center ${
                                formikProps?.values?.gender === ('Female' || 'F')
                                  ? 'selected-button'
                                  : 'unSelected-button'
                              }`}
                              onClick={() => formikProps?.setFieldValue('gender', 'Female')}
                            >
                              {formikProps?.values?.gender === 'Female' && (
                                <img src={Check} alt="Check" className="mr-2" />
                              )}
                              Female
                            </div>

                            <div
                              className={`mt-3 ml-3  d-flex justify-content-center align-items-center ${
                                formikProps?.values?.gender === 'Other'
                                  ? 'selected-button'
                                  : 'unSelected-button'
                              }`}
                              onClick={() => formikProps?.setFieldValue('gender', 'Other')}
                            >
                              {formikProps?.values?.gender === 'Other' && (
                                <img src={Check} alt="Check" className="mr-2" />
                              )}
                              Other
                            </div>
                          </div>
                        </div>

                        <Field
                          name="phoneNumber"
                          label="Insured Phone*"
                          component={NumberFormatInput}
                          type="text"
                          placeholder="(###) ###-####"
                          format="(###) ###-####"
                        />

                        <div className={css.inputItem}>
                          <Field
                            name="dateOfBirth"
                            label="Insured Date of Birth*"
                            component={NumberFormatInput}
                            type="text"
                            placeholder="MM-DD-YYYY"
                            format="##-##-####"
                          />
                        </div>
                      </>
                    )}

                    <Field
                      name="insuredGroupNumber"
                      label="Insured Group Number"
                      placeholder="Insured Group Number"
                      component={Input}
                      type="text"
                    />
                    <Field
                      name="insuredPolicyNumber"
                      label="Insured ID Number*"
                      placeholder="Insured ID Number"
                      component={Input}
                      type="text"
                    />

                    <Field
                      name="addressLine1"
                      label="Address *"
                      placeholder="Address Line 1"
                      component={Input}
                      type="text"
                    />
                    <Field
                      name="addressLine2"
                      label="Address 2"
                      placeholder="Address Line 2"
                      component={Input}
                      type="text"
                    />
                    <div className={css.inputItem}>
                      <Field
                        name="city"
                        label="City *"
                        placeholder="City"
                        component={Input}
                        type="text"
                      />
                    </div>
                    <div className={css.inputItem}>
                      <Field
                        name="state"
                        label="State *"
                        component={SelectInput}
                        classNamePrefix="form-select"
                        placeholder="State"
                        options={stateList.map((s) => ({
                          value: s.value,
                          label: s.label,
                        }))}
                      />
                    </div>
                    <div className={css.inputItem}>
                      <Field
                        name="zip"
                        label="Zip *"
                        placeholder="Zip"
                        component={Input}
                        type="text"
                      />
                    </div>

                    <div>
                      {!props.item.attributes['last-insurance-id-url'] && (
                        <div className="pb-2">Insurance Card Image is Required*</div>
                      )}
                      {!files.length && !props.item.attributes['last-insurance-id-url'] ? (
                        <div>
                          <label htmlFor="cardImage" style={{ cursor: 'pointer', width: '100%' }}>
                            <StrokeButton
                              className={css.uploadPhotoButton}
                              icon={Camera}
                              title="Upload a Photo ID"
                            />
                          </label>
                          <input
                            id="cardImage"
                            accept="image/*"
                            title="&nbsp;"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                            type="file"
                            multiple={false}
                            name="cardImage"
                            className={css.inputfile}
                            onChange={(e) => {
                              setFiles(e.target.files);
                            }}
                          />
                        </div>
                      ) : (
                        <div className={css.imagePreview}>
                          <img
                            src={
                              props.item.attributes['last-insurance-id-url']
                                ? props.item.attributes['last-insurance-id-url']
                                : URL.createObjectURL(files[0])
                            }
                            alt="insurance-card"
                            style={
                              window.innerWidth > '480'
                                ? { width: '320px', height: 'auto' }
                                : { width: '100px', height: 'auto' }
                            }
                          />
                          <img
                            src={Replace}
                            alt="replace"
                            className={css.replaceButton}
                            onClick={() => {
                              setFiles([]);
                              props.item.attributes['last-insurance-id-url'] = '';
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {files?.length ? (formikProps.dirty = true) : null}
                  <button
                    disabled={
                      !(formikProps.isValid && formikProps.dirty) ||
                      loading ||
                      (!files?.length && !props?.item?.attributes['last-insurance-id-url'])
                    }
                    type="submit"
                    className={
                      !(formikProps.isValid && formikProps.dirty) ||
                      loading ||
                      (!files?.length && !props?.item?.attributes['last-insurance-id-url'])
                        ? css.saveButtonDisabled
                        : css.saveButton
                    }
                    text="Save Insurance Info"
                  >
                    {loading ? (
                      <Spinner animation="border" variant="secondary" />
                    ) : (
                      'Save Insurance Info'
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className={css.policyCardBottom}>
            {insuranceData
              .filter(
                (x) =>
                  // eslint-disable-next-line eqeqeq
                  x.attributes['policy-number'] == props.item.attributes['policy-number']
              )
              .filter(
                (v, i, a) =>
                  a.findIndex((t) => t.attributes['user-id'] === v.attributes['user-id']) === i
              )
              .map((plan) => {
                let familyMember = familyMembers?.find((i) => i.id == plan.attributes['user-id']);
                return (
                  familyMember && (
                    <div className={css.insuranceChip}>
                      <img src={Check} alt="check" />
                      <p className={css.insuranceChipText}>
                        {familyMember.attributes['first-name']}{' '}
                        {familyMember.attributes['last-name']}
                      </p>
                    </div>
                  )
                );
              })}
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <Layout
      onClickBack={() => history.goBack()}
      headerTitle="Insurance Information"
      tab={familyMembers?.find((item) => item?.id === id) ? 'family' : 'insurance'}
      back
      rightText={
        <div className={css.sideMenu}>
          <SideMenu userDetail={user} />
        </div>
      }
    >
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <div className={css.flex1}>
          {user && props?.user?.id !== user?.id && (
            <div
              className={`${css.breadContainer} d-flex flex-wrap flex-lg-nowrap align-items-center mb-4`}
            >
              <span className={`${css.tabText} mr-2 mr-sm-3`}>My Aura</span>

              <img src={OpenTriangle} alt="OpenTriangle" />
              <span className={`${css.tabText} mx-3 `}>My Family</span>

              <img src={OpenTriangle} alt="OpenTriangle" />
              <span className={`${css.tabText} mx-3 `}>
                {user?.attributes?.['first-name']} {user?.attributes?.['last-name']}
              </span>

              <img src={OpenTriangle} alt="OpenTriangle" />
              <span className={`${css.tabText} mx-2 mx-lg-3`} style={{ color: '#FF9900' }}>
                Insurance Information
              </span>
            </div>
          )}
          <div className={css.subContainer}>
            <div className={css.header}>
              <h4 className="medium-font">
                {familyMembers?.find((item) => item?.id == id)?.attributes?.['first-name'] ||
                  userResource?.['first-name']}
                's Insurance Carriers
              </h4>
              <br />
              <div className={css.sideMenu2}>
                <SideMenu userDetail={user} />
              </div>
            </div>
            <div className={css.insurancePlansContainer}>
              {props.user.id == id
                ? insuranceData
                    ?.filter(
                      (v, i, a) =>
                        a.findIndex(
                          (t) => t.attributes['policy-number'] === v.attributes['policy-number']
                        ) === i
                    )
                    .map(
                      (item) =>
                        item && (
                          <InsurancePolicyCard
                            item={item}
                            key={item.id}
                            planList={planList}
                            user={props.user}
                            accountUserDetails={accountUserDetails}
                            familyMemberDetails={familyMemberDetails}
                          />
                        )
                    )
                : insuranceData
                    ?.filter(
                      (plan) =>
                        // eslint-disable-next-line eqeqeq
                        id == plan.attributes['user-id']
                    )
                    ?.filter(
                      (v, i, a) =>
                        a.findIndex(
                          (t) => t.attributes['policy-number'] === v.attributes['policy-number']
                        ) === i
                    )
                    .map(
                      (item) =>
                        item && (
                          <InsurancePolicyCard
                            item={item}
                            key={item.id}
                            planList={planList}
                            user={props.user}
                            accountUserDetails={accountUserDetails}
                            familyMemberDetails={familyMemberDetails}
                          />
                        )
                    )}
            </div>
            <div className={css.addInsuranceContainer}>
              <img src={InsuranceShield} alt="insurance shield" />
              <div className={css.addInsuranceContainerRight}>
                <h4 className={css.addInsuranceText}>Have another insurance to add?</h4>
                <StrokeButton
                  title="Add an Insurance"
                  icon={CirclePlus}
                  className={css.addInsuranceButton}
                  handlePress={() => {
                    history.push(`/add-insurance/${id}`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userInsurance: state.user.insurance[0],
});
const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceInformation);
