import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { Layout, Checkbox, Button, Alert, Divider, Modal, Calendar } from '../../components';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import css from './Vaccination.module.css';

import { processAPI } from '../../api/models/process';
import { setUserProfile, setUser } from '../../store/action/user';
import { getUserData, getUserDataProfile } from '../../api/models/user';

const Schedule = (props) => {
  const activeTab = 'vaccine';
  const { t } = useTranslation();
  const { history, setUserDispatch, setUserProfileDispatch } = props;

  const [selectedButton, setSelectedButton] = useState(0);
  const [loading, setLoading] = useState(true);
  const { service, medtest } = useParams();
  const [confirmedLocationName, setConfirmedLocationName] = useState('');
  const [events, setEvents] = useState([]);
  const [times, setTimes] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setSelectedTime] = useState();

  const [showAlert, setShowAlert] = useState({ show: false });
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (service) {
      loadData();
    } else {
      // loadUserLocation(theDate, 0, profile.attributes);
    }
  }, []);

  useEffect(() => {
    availableTimes();
  }, [selectedDay]);

  async function loadData() {
    await availableDays();
  }

  async function availableDays() {
    const { jwt } = props.userAuthentication;
    setLoading(true);
    const today = moment().format('YYYY-MM-DD');
    processAPI({
      endpoint: `location-services/${service}/available-days?date=${today}`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        if (!selectedDay && data && data.length > 0) {
          setSelectedDay(data[0]);
        }
        setEvents(data);
      })
      .catch(() => {});
  }

  async function availableTimes() {
    const { jwt } = props.userAuthentication;
    setLoading(true);
    processAPI({
      endpoint: `location-services/${service}/available-times?date=${selectedDay}`,
      methodType: 'GET',
      key: jwt,
    })
      .then((data) => {
        setTimes(data);
      })
      .catch(() => {});
  }

  async function dayChange(e) {
    setSelectedDay(moment(e).format('YYYY-MM-DD'));
    setSelectedTime(undefined);
  }

  async function postData() {
    setLoading(true);

    if (!selectedTime) {
      setShowAlert({
        show: true,
        message: t('mustSelectApptTime'),
        type: 'error',
      });
      return;
    }

    const { jwt, user_id: userId } = props.userAuthentication;
    const endpoint = 'medtest-location-appointments';
    const appointmentTime = `${selectedDay} ${selectedTime}`;
    const uid = userId;

    try {
      const myData = {
        data: {
          type: endpoint,
          attributes: {
            'user-id': uid,
            'medtest-location-id': medtest,
            'appointment-time': appointmentTime,
            'location-service-id': service,
          },
        },
      };

      const appointmentResponse = await processAPI({
        endpoint,
        methodType: 'POST',
        key: jwt,
        data: myData,
      });
      setConfirmedLocationName(appointmentResponse.attributes['medtest-location-name']);
      const userData = await getUserData(uid, jwt);
      setUserDispatch(userData.data);
      const userProfile = await getUserDataProfile(uid, jwt);
      setUserProfileDispatch(userProfile);
    } catch (error) {}

    setLoading(false);

    setShowAlert({
      show: true,
      message: t('successBookedAppointment'),
      type: 'success',
    });
    setVisible(true);
  }

  function updateList(item, selected) {
    setSelectedTime(selected ? undefined : item);
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  function handleButtonPress(index) {
    setSelectedButton(index);
    setSelectedDay(undefined);
  }

  return (
    <Layout
      tab={activeTab}
      headerTitle={t('bookAppointment')}
      onClickBack={() => history.goBack()}
      back
    >
      <div className="vaccine">
        <div className={css.subContainer}>
          <Alert
            open={showAlert.show}
            handleClose={hideAlert}
            message={showAlert.message}
            type={showAlert.type}
          />

          <Calendar events={events} dayChange={dayChange} />
          <div className="my-4">
            <p className={classNames(css['normal-font'], 'text-center')}>
              {t('selectAvailableAppointment')}
            </p>
          </div>

          {times && times.length === 0 && (
            <>
              <div className="my-4 dflex justify-content-center">
                <p className={classNames(css.weAreSorry, 'text-center')}>{t('weAreSorry')}</p>
              </div>
            </>
          )}

          {times &&
            times.map((item) => (
              <>
                <div className="my-2">
                  <Checkbox
                    className="my-2"
                    label={moment(item, 'hh:mm').format('LT')}
                    selected={item === selectedTime}
                    handleChange={(value) => updateList(item, value)}
                    labelColor={!item.selected && '#777777'}
                  />
                </div>

                <Divider />
              </>
            ))}

          {times && times.length > 0 && (
            <div className={css.sticky}>
              <Button
                disabled={!selectedDay || !selectedTime}
                className={classNames('mt-4 mb-2')}
                text={t('bookAppointment')}
                onClick={postData}
              />
            </div>
          )}
        </div>

        <Modal
          show={isVisible}
          closeButton
          handleClose={() => {
            setVisible(false);
            history.push('/vaccine');
          }}
          title="Your Appointment was Scheduled"
          hideSubmit
        >
          <p>{`Please go to ${confirmedLocationName}`}</p>
          <p>
            on {moment(selectedDay).format('MM/DD/YYYY')} at{' '}
            {moment(selectedTime, 'hh:mm').format('LT')}
          </p>
        </Modal>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    userAuthentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserProfileDispatch(payload) {
    dispatch(setUserProfile(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
