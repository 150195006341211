import React from 'react';
import { useTranslation } from 'react-i18next';

const Exposure = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row ml-4 mr-4">
        <div className="col-sm pt-5">
          <h5>{t('screenerInstructions4')}</h5>
          <p>{t('screenerInstructions5')}</p>
        </div>
      </div>

      <div className="row pt-4 mx-2">
        <div className="col-md-3">
          <label className="h-100 w-100">
            <input
              type="radio"
              name="pwn_health_order[symptom_severity]"
              className="card-input-element d-none radio"
              id="mild"
              value="severe"
            />
            <div className="card card-input h-100 w-100">
              <div className="card-header">
                <div className="row">
                  <p>Exposed or Sick Contact</p>
                  <div id="mild-visual" className="col text-right">
                    <img src="/circle.svg" alt="" width="15" height="15" />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="disc pl-4">
                  <li>{t('screenerExposure1')}</li>
                </ul>
                {t('screenerExposure2')}
              </div>
            </div>
          </label>
        </div>

        <div className="col-md-3 pb-4">
          <label className="h-100 w-100">
            <input
              type="radio"
              name="pwn_health_order[symptom_severity]"
              className="card-input-element d-none radio"
              id="none"
              value="testing"
            />

            <div className="card card-input h-100 w-100">
              <div className="card-header">
                <div className="row">
                  <p>Testing recommended</p>
                  <div id="none-visual" className="col text-right">
                    <img src="/circle.svg" alt="" width="15" height="15" />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="disc pl-4">
                  <li>{t('screenerExposureTesting')}</li>
                </ul>
              </div>
            </div>
          </label>
        </div>
        <div className="col-md-3 pb-4">
          <label className="h-100 w-100">
            <input
              type="radio"
              name="pwn_health_order[symptom_severity]"
              className="card-input-element d-none radio"
              id="none"
              value="testing"
            />

            <div className="card card-input h-100 w-100">
              <div className="card-header">
                <div className="row">
                  <p>Congregate Setting</p>
                  <div id="none-visual" className="col text-right">
                    <img src="/circle.svg" alt="" width="15" height="15" />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="disc pl-4">
                  <li>{t('screenerExposureCongregate1')}</li>
                </ul>
                {t('screenerExposureCongregate2')}
              </div>
            </div>
          </label>
        </div>
        <div className="col-md-3 pb-4">
          <label className="h-100 w-100">
            <input
              type="radio"
              name="pwn_health_order[symptom_severity]"
              className="card-input-element d-none radio"
              id="none"
              value="testing"
            />
            <div className="card card-input h-100 w-100">
              <div className="card-header">
                <div className="row">
                  <p>Unexposed</p>
                  <div id="none-visual" className="col text-right">
                    <img src="/circle.svg" alt="" width="15" height="15" />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ul className="disc pl-4">
                  <li>{t('screenerExposureNone')}</li>
                </ul>
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};
export default Exposure;
