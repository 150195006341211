import React, { useState } from 'react';
import CalendarComponent from './Atoms/Calendar';
import './index.css';
import OpenTriangle from '../../../assets/image/OpenTriangle.png';
import moment from 'moment';

export default function DateTime({ onBack, onNext, dateTime }) {
  const [date, onSelectDate] = useState(dateTime?.date);
  const [time, onSelectTime] = useState(dateTime?.time);
  const [openCalendar, setOpenCalendar] = useState(false);
  const today = new Date();
  const checkToday =
    `${moment(date).format('YYYY-MM-DD')}` === `${moment(today).format('YYYY-MM-DD')}`;
  const checkTime =
    `${time.split(':')[0]}` > `${today.getHours() < 10 ? '0' : ''}${today.getHours()}`
      ? true
      : !!(
          `${time.split(':')[0]}` === `${today.getHours() < 10 ? '0' : ''}${today.getHours()}` &&
          `${time.split(':')[1]}` >= `${today.getMinutes() < 10 ? '0' : ''}${today.getMinutes()}`
        );

  return (
    <>
      <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
        <div className="col-12 col-sm-10 col-xl-7 mb-5 mt-3 px-3 px-sm-0">
          <div className="mb-3 dnt-header">Date & Time of Test Result</div>

          <div className="">
            <div className="date-text">Date</div>

            <div className="position-relative">
              <input
                className="dateInput"
                id="date"
                readOnly
                label="Date"
                placeholder="Select"
                value={date ? date?.toString()?.split(' ')?.slice(0, 4)?.join(' ') : ''}
                onFocus={() => setOpenCalendar(true)}
                // onBlur={() => setOpenCalendar(false)}
              />

              <div className="position-absolute dropIcon">
                <img alt="" src={OpenTriangle} className="" />
              </div>
            </div>
          </div>
          {openCalendar && (
            <div className="pos">
              <CalendarComponent
                onSelect={(e) => {
                  onSelectDate(e);
                  setOpenCalendar(false);
                }}
              />
            </div>
          )}
        </div>

        <div className="col-12 col-sm-10 col-xl-7 mb-2 mt-3 px-3 px-sm-0">
          <div className="">
            <div className="date-text">Time</div>

            <div className="position-relative">
              <input
                className="dateInput a-time"
                id="time"
                placeholder="Select"
                type="time"
                value={time || ''}
                onChange={(e) => onSelectTime(e?.target?.value)}
              />

              <div className="position-absolute dropIcon">
                <img alt="" src={OpenTriangle} className="" />
              </div>
            </div>
          </div>
        </div>
        {checkToday && checkTime && (
          <div
            className="col-12 col-sm-10 col-xl-7 px-3 px-sm-0"
            style={{ color: 'red', fontWeight: 'bold' }}
          >
            For current date, Time is invalid
          </div>
        )}
      </div>

      <div className="row my-5 ml-sm-3 px-1 px-sm-0">
        <div className="col-5 col-sm-5 col-xl-3 p-0 pl-3 pl-sm-0">
          <div className="cancel " onClick={onBack}>
            Back
          </div>
        </div>

        <div className="col-7 col-sm-5 col-xl-4 pr-3 pr-sm-0">
          <div
            className="begin"
            style={
              checkToday && checkTime ? { opacity: '0.4' } : time && date ? {} : { opacity: '0.4' }
            }
            onClick={() => {
              if (checkToday && checkTime) return;
              if (date && time) onNext({ date, time });
            }}
          >
            Next
          </div>
        </div>
      </div>
    </>
  );
}
