import React from 'react';
import classNames from 'classnames';

import { Divider } from '..';
import ArrowRight from '../../assets/svg/arrow_right.svg';
import css from './Table.module.css';

function Row({ index, name, relation, handleClick }) {
  return (
    <div
      key={index + name}
      onClick={handleClick}
      className={classNames('d-flex justify-content-between align-items-center', css.rowContainer)}
    >
      <p className="small-list-font-bold w-100">{name}</p>
      <div className="d-flex justify-content-between align-items-center w-100">
        <p className="small-list-font">{relation}</p>
        <img alt="right" src={ArrowRight} />
      </div>
    </div>
  );
}

function TableComponent({ headerTitle, data, handleClick }) {
  return (
    <div>
      <h1 className="p-3 normal-font">{headerTitle}</h1>
      <div className="mb-2">
        {data &&
          data.map((item, index) => (
            <Row
              index={index + item.name}
              name={item.name}
              relation={item.relation}
              handleClick={() => handleClick(item)}
            />
          ))}
      </div>
      <Divider />
      <div className={classNames('d-flex mt-2 mb-2', css.tableFooter)}>
        <div className="w-100">
          <p className="small-list-font">NAME</p>
        </div>
        <di className="w-100">
          <p className="small-list-font">RELATION</p>
        </di>
      </div>
    </div>
  );
}

export default TableComponent;
