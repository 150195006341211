import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import React, { useState } from 'react';
import { Modal } from '../../../components';
import css from './Screener.css';

const SevereSymptomsModal = ({ isVisible, setVisible }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Modal
      show={isVisible}
      handleSubmit={() => setVisible(false)}
      closeText={t('symptomWarningSevereYes')}
      submitText={t('symptomWarningSevereNo')}
      handleClose={() => {
        setVisible(false);
        history.push('/testkits/ineligible');
      }}
      title={t('symptomWarningHeader')}
      primaryVariant="primary"
      secondaryVariant="danger"
      closeButton
    >
      <div>
        <b>{t('symptomWarningInstructions')}</b>
      </div>
      <ul>
        <li>{t('symptomWarningCheck1')}</li>
        <li>{t('symptomWarningCheck2')}</li>
        <li>{t('symptomWarningCheck3')}</li>
        <li>{t('symptomWarningCheck4')}</li>
        <li>{t('symptomWarningCheck5')}</li>
        <li>{t('symptomWarningCheck6')}</li>
        <li>{t('symptomWarningCheck7')}</li>
        <li>{t('symptomWarningCheck8')}</li>
      </ul>
    </Modal>
  );
};

export default SevereSymptomsModal;
