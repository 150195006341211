import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

// const defaultLocation = [39.979793,-75.1696627];

const MapComponent = withScriptjs(
  withGoogleMap((props) => {
    let defaultLocation = props.defaultLocation || {
      latitude: 39.979793,
      longitude: -75.1696627,
    };

    return (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: +defaultLocation.latitude, lng: +defaultLocation.longitude }}
      >
        {props.isMarkerShown && (
          <Marker position={{ lat: +defaultLocation.latitude, lng: +defaultLocation.longitude }} />
        )}
      </GoogleMap>
    );
  })
);

export default MapComponent;
