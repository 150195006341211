import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, StrokeButton, ListSimple, Divider } from '../../components';
import Results from '../Results/Results';
import ShopTestKits from '../TestKits/ShopTestKits';
import TestKits from '../TestKits/TestKits';
import CirclePlus from '../../assets/svg/circle-plus.svg';
import css from './MyTests.module.css';
import Close from '../../assets/svg/close.svg';
import GreenPositiveIcon from '../../assets/svg/green_positive.svg';
import GreenNegativeIcon from '../../assets/svg/green_negative.svg';
import RedNegativeIcon from '../../assets/svg/red_negative.svg';
import RedPositiveIcon from '../../assets/svg/red_positive.svg';
import OrangePendingIcon from '../../assets/svg/orange_pending.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isUsability } from '../../api/const';
import { getTimeSinceTest } from '../../utils/utils';

const MyTests = (props) => {
  const { t, i18n } = useTranslation();

  const [selectedTab, setSelectedTab] = React.useState(props?.location?.state?.tab || 0);

  const iconSrc = {
    greenNegative: GreenNegativeIcon,
    greenPositive: GreenPositiveIcon,
    redPositive: RedPositiveIcon,
    redNegative: RedNegativeIcon,
    pending: OrangePendingIcon,
  };
  const history = useHistory();
  const [selectedData, setSelectedData] = useState();
  const [fullHistory, setFullHistory] = React.useState(false);

  const navShopTestKits = !props?.user?.attributes?.['nav-hide-shop-test-kits'];

  const navResults = props?.user?.attributes?.['nav-results'];
  const navUploadTestResult = props?.user?.attributes?.['enable-upload-test-results'];

  useEffect(() => {
    // hack to prevent showing results tab if its disabled
    if (!props?.location?.state?.tab && !navResults) {
      setSelectedTab(1);
    }
  }, []);

  useEffect(() => {
    // hack to prevent keep showing "Results Modal" on side if switched to another tab from result.
    if (selectedTab !== 0) {
      setSelectedData(null);
    }
  }, [selectedTab]);

  const components = [
    navResults && (
      <Results
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        fullHistory={fullHistory}
        setFullHistory={setFullHistory}
      />
    ),
    <TestKits />,
    navShopTestKits && <ShopTestKits />,
  ];

  const UploadTestResultButton = (
    <div className="d-flex pl-2 pl-lg-0 flex-column flex-lg-row align-items-center">
      <div className={`${css.antigenText} pr-0 pr-lg-3 pb-2 pb-lg-0`}>Have an Antigen Test?</div>

      <div className={`${css.up_button}`} onClick={() => history.push('/my-tests/upload-test')}>
        <img alt="icon" src={CirclePlus} />
        <div className="pl-3">Upload Test Result</div>
      </div>
    </div>
  );
  const otherTestKitButton = (
    <div className="d-flex pl-2 pl-lg-0 flex-column flex-lg-row align-items-center">
      <div className={`${css.antigenText} pr-0 pr-lg-3 pb-2 pb-lg-0`}>
        Test Kit Not Shown Below?
      </div>

      <div
        className={`${css.up_button}`}
        onClick={() => history.push('/select-family-member-activation')}
      >
        <img alt="icon" src={CirclePlus} />
        <div className="pl-3">Register Other Test Kit</div>
      </div>
    </div>
  );
  let rightHeaderButton = null;
  if (selectedTab === 0 && !fullHistory && navUploadTestResult) {
    rightHeaderButton = UploadTestResultButton;
  } else if (selectedTab === 1) {
    rightHeaderButton = otherTestKitButton;
  }

  return (
    <div className={`${fullHistory && selectedData ? 'd-flex' : ''}`}>
      <Layout
        onClickBack={() => setFullHistory(false)}
        headerTitle="My Tests"
        tab="myTests"
        rightText={rightHeaderButton}
        back={!!(selectedTab === 0 && fullHistory)}
      >
        <div className={css.container}>
          <div className={css.tabContainer}>
            {[
              navResults && { name: 'Results' },
              { name: 'My Test Kits' },
              navShopTestKits && { name: 'Shop Test Kits' },
            ].map((tab, index) => (
              <div
                onClick={() => setSelectedTab(index)}
                className={tab && selectedTab === index ? css.tabItemSelected : css.tabItem}
                key={index}
              >
                {tab?.name}
              </div>
            ))}
          </div>
          <Divider />
          <div className={css.flex1}>{components[selectedTab]}</div>
        </div>
      </Layout>

      {fullHistory && selectedData && (
        <div className={css.pageRight}>
          <div className={css.headerSection}>
            <img
              src={Close}
              className={css.closeButton}
              alt="close"
              onClick={() => {
                setSelectedData(null);
              }}
            />
            <div className={css.titleContainer}>
              <h2 className="large-font">{selectedData['medtest-type']}</h2>
            </div>
          </div>
          <Divider />
          <div className={css.sideContentContainer}>
            <div className={css.sideContentData}>
              <div>
                <h3 className="normal-font">
                  Results: {selectedData['test-result']}
                  <img alt="icon" className={css.icon} src={iconSrc[selectedData.iconColor]} />
                </h3>
                <p className="small-sub-font">
                  Last Updated {moment(selectedData['updated-at']).calendar()}
                </p>
              </div>
              <ListSimple
                title="Date of Test"
                description={moment(selectedData['test-date']).format('dddd, MMM Do, YYYY')}
                divider
              />
              <ListSimple
                title="Time since Test"
                description={getTimeSinceTest(selectedData?.['test-date'])}
                divider
              />
              <ListSimple
                title="Location of Testing"
                description={selectedData['test-location-name']}
              />
            </div>
            {selectedData['test-result-url'] != null ? (
              <>
                <StrokeButton
                  className={css.strokeButtonPDF}
                  title={t('viewPdfResult')}
                  handlePress={() => {
                    window.open(selectedData['test-result-url']);
                  }}
                />
              </>
            ) : (
              <div className={css.notAvailableText}>Result Details Not Available</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  user: state.user.profile,
  userProfile: state.user.userProfile[0],
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTests);
