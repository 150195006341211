import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ArrowLeft from '../../assets/svg/arrow_left_big.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '0 1 auto',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 34,
    letterSpacing: 0.374,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.75,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  backContainer: {
    position: 'absolute',
    left: 20,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  backImg: {
    marginRight: theme.spacing(2),
  },
  backTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#8b8b8b',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const ProgressBar = styled('div')((props) => ({
  position: 'relative',
  height: 8,
  background: '#EEEEEE',
  '&:after': {
    borderRadius: '8px',
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    background: '#FF9900',
    width: props.width,
  },
}));

const Header = ({ title, progress = 0, onBack }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div
          className={classes.content}
          style={progress ? {} : { borderBottom: '3px solid #EEEEEE' }}
        >
          {onBack && (
            <div className={classes.backContainer} onClick={onBack}>
              <img className={classes.backImg} src={ArrowLeft} alt="left" />
              <Typography className={classes.backTitle}>Back</Typography>
            </div>
          )}
          <Typography className={classes.title}>{title}</Typography>
        </div>
        {progress > 0 && <ProgressBar width={`${progress}%`} />}
      </div>
    </>
  );
};

export default Header;
