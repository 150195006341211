import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Markdown from 'react-markdown';
import { Button, Checkbox, Layout, Alert } from '../../components';
import SmoothScroll from '../../assets/svg/smoothscroll.svg';
import { useParams, useHistory } from 'react-router';
import { getUserFamilyMembers, getUserData } from '../../api/models/user';
import { processAPI } from '../../api/models/process';
import './SignDoc.css';
import Arrow from '../../assets/image/Arrow.png';
import { Spinner } from 'react-bootstrap';
import Exclamationmark from '../../assets/image/Exclamationmark_Y.png';
import Modal from 'react-bootstrap/Modal';
import { addFamilyMemberStepList } from '../../utils/sidebarSteps';
import { setUser } from '../../store/action/user';
import { useLocation } from 'react-router-dom';

const SignDoc = (props) => {
  const history = useHistory();
  const { state } = useLocation();
  let { id } = useParams();
  if (!id) {
    id = props?.user?.id;
  }
  const { state: prodState } = useLocation();

  const [loading, setLoading] = useState(true);
  const [loadingAPI, setLoadingAPI] = useState(false);
  const [list, setList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(list[0]);
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const navInsurance = props?.user?.attributes?.['requires-insurance'];
  const navVaccines = props?.user?.attributes?.['nav-vaccines'];
  const stepList = addFamilyMemberStepList(!navInsurance, !navVaccines);
  const [showAlert, setShowAlert] = useState({ show: false });

  const loadData = async () => {
    let docs = [];

    if (id != props?.user?.id) {
      let familyMemberList = await getUserFamilyMembers(
        props?.authentication?.user_id,
        props?.authentication?.jwt
      );
      docs =
        familyMemberList?.included?.find((member) => member?.id == id)?.attributes?.[
          'hipaa-release-details'
        ]?.unsigned || [];

      if (state?.declined) {
        let declinedDocs =
          familyMemberList?.included?.find((member) => member?.id == id)?.attributes?.[
            'hipaa-release-details'
          ]?.declined || [];

        declinedDocs = declinedDocs?.filter?.((item) => item?.id == state?.declined);

        docs = [...declinedDocs];
      }
    } else {
      docs = props?.user?.attributes?.['hipaa-release-details']?.unsigned || [];

      if (state?.declined) {
        let declinedDocs = props?.user?.attributes?.['hipaa-release-details']?.declined || [];

        declinedDocs = declinedDocs?.filter?.((item) => item?.id == state?.declined);

        docs = [...declinedDocs];
      }
    }

    if (docs?.length) {
      setList(docs);
      setSelectedDoc(docs[0]);
      setLoading(false);
    } else {
      history.goBack();
    }
  };

  const onSubmit = async () => {
    setShowAlert({
      show: false,
    });
    setLoadingAPI(true);
    const { jwt } = props?.authentication;

    try {
      const updatedData = {
        data: {
          type: 'user-hipaa-releases',
          attributes: {
            'hipaa-release-id': selectedDoc?.id,
            'user-id': props?.user?.id,
            declined,
          },
        },
      };

      if (id != props?.user?.id) updatedData.data.attributes['family-member-id'] = id;

      const isUpdating = selectedDoc?.declined_on || selectedDoc?.signed_on;

      await processAPI({
        key: jwt,
        endpoint: isUpdating
          ? `user-hipaa-releases/${selectedDoc?.user_hipaa_release_id}`
          : 'user-hipaa-releases',
        methodType: isUpdating ? 'PATCH' : 'POST',
        data: updatedData,
      });

      if (selectedDocIndex === list.length - 1) {
        const user = await getUserData(props?.authentication?.user_id, jwt);
        props?.setUserDispatch(user?.data);

        if (state?.declined) {
          history.goBack();
          return;
        }

        if (navInsurance) {
          id == props?.user?.id
            ? history.push('/new-user-insurance')
            : history.push(`/assign-insurance/${id}`);
        } else if (state.next && props?.userProfile?.attributes['sign-in-count'] !== 1) {
          history.push('/profile');
        } else if (state.nextRoute && id != props?.user?.id) {
          history.push(state.nextRoute);
        } else if (navVaccines) {
          if (id != props?.user?.id)
            history.push(`/vaccine-information/${id}`, {
              progressBar: true,
              notInsured: true,
            });
          else history.push(`/vaccine-information/${id}/newUser`);
        } else history.push('/new-user-welcome');
      } else {
        window.scrollTo({
          top: '0',
        });
        setSelectedDoc(list[selectedDocIndex + 1]);
        setSelectedDocIndex(selectedDocIndex + 1);
        setLoadingAPI(false);
        setChecked(false);
        setDeclined(false);
      }
    } catch (error) {
      setShowAlert({
        show: true,
        message: 'Something went wrong, Please try again.',
        type: 'error',
      });
      setLoadingAPI(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const root = document?.getElementById('root');
    root?.scrollTo(0, 0);
    window?.scrollTo(0, 0);
  }, []);

  if (loading)
    return (
      <div className="mt-5 pt-5 d-flex justify-content-center">
        <div className="mt-5 pt-5">
          <Spinner animation="grow" variant="warning" />
        </div>
      </div>
    );

  if (id == props?.user?.id || state?.declined)
    return (
      <>
        <Alert
          open={showAlert?.show}
          handleClose={() => {
            setShowAlert({
              show: false,
            });
          }}
          message={showAlert?.message}
          type={showAlert?.type}
        />

        <div className="d-flex justify-content-center">
          <div className="px-3 px-sm-5 mb-5" style={{ width: '85%' }}>
            <div className="d-flex justify-content-center mt-4 mb-5">
              <div className="myDoc">Consent Form</div>
            </div>

            <div className="myDoc-header mb-4">{selectedDoc?.title}</div>

            <Markdown className="markdown" source={selectedDoc?.release_text} />

            <Checkbox
              label="Check to Agree"
              selected={checked}
              handleChange={() => {
                setChecked(!checked);
                setDeclined(false);
              }}
            />

            {selectedDoc?.declinable && (
              <Checkbox
                label="Decline"
                selected={declined}
                handleChange={() => {
                  setDeclined(!declined);
                  setChecked(false);
                }}
              />
            )}

            <Button
              text={declined ? 'Decline and Submit Document' : 'Sign and Submit Document'}
              disabled={!checked && !declined}
              loading={loadingAPI}
              onClick={() => {
                if (loadingAPI) return;
                onSubmit();
              }}
            />

            <img
              src={SmoothScroll}
              className="smoothScrollBtn"
              alt="smoothscroll"
              onClick={() => {
                window.scrollTo({
                  top: document?.body?.scrollHeight,
                });
              }}
            />
          </div>
        </div>
      </>
    );

  return (
    <Layout
      progressBar={!prodState?.noProgressBar}
      progressBarStepsList={stepList}
      progressBarCurrentStep={1}
      tab={prodState?.activeTab}
    >
      <>
        <Alert
          open={showAlert?.show}
          handleClose={() => {
            setShowAlert({
              show: false,
            });
          }}
          message={showAlert?.message}
          type={showAlert?.type}
        />

        <Modal show={openModal} backdrop="static" keyboard={false} dialogClassName="myDoc-modal">
          <div className="py-4 px-3 px-sm-4 myDoc-modal_content">
            <div className="row">
              <div className="col-4 p-0 d-flex justify-content-center">
                <img src={Exclamationmark} alt="Exclamationmark" width="75px" height="75px" />
              </div>
              <div className="col-8">
                <div className="myDoc-modal-header">Are you sure you would like to exit?</div>
                <div className="my-2 myDoc-drag text-left pt-1">
                  Going back will not save your information!
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-5">
                <div className="myDoc-cancel" onClick={() => setOpenModal(false)}>
                  Stay
                </div>
              </div>
              <div className="col-7">
                <div
                  className="myDoc-cancel"
                  onClick={() => {
                    history?.push('/family');
                  }}
                >
                  Exit this Page
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <div className="px-3 px-sm-5 mb-5 myDoc-container">
          <div className="d-flex align-items-center mt-3 mb-5">
            <div className="mb-1 back-button" onClick={() => setOpenModal(true)}>
              <img src={Arrow} alt="Arrow" width={14} height={25} />
            </div>

            <div className="myDoc mx-2 mx-sm-3">My Documents</div>
          </div>

          <div className="myDoc-header mb-4">{selectedDoc?.title}</div>

          <Markdown className="markdown" source={selectedDoc?.release_text} />

          <Checkbox
            label="Check to Agree"
            selected={checked}
            handleChange={() => {
              setChecked(!checked);
              setDeclined(false);
            }}
          />

          {selectedDoc?.declinable && (
            <Checkbox
              label="Decline"
              selected={declined}
              handleChange={() => {
                setDeclined(!declined);
                setChecked(false);
              }}
            />
          )}

          <Button
            text={declined ? 'Decline and Submit Document' : 'Sign and Submit Document'}
            disabled={!checked && !declined}
            loading={loadingAPI}
            onClick={() => {
              if (loadingAPI) return;
              onSubmit();
            }}
          />

          <img
            src={SmoothScroll}
            className="smoothScrollBtn"
            alt="smoothscroll"
            onClick={() => {
              window.scrollTo({
                top: document?.body?.scrollHeight,
              });
            }}
          />
        </div>
      </>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  userProfile: state.user.userPorfile,
  authentication: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignDoc);
