import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Markdown from 'react-markdown';
import moment from 'moment';
import { Layout } from '../../components';
import css from './Documents.module.css';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import Close from '../../assets/svg/close.svg';
import FullScreen from '../../assets/svg/fullscreen.svg';
import Collapse from '../../assets/svg/collapse.svg';
import SmoothScroll from '../../assets/svg/smoothscroll.svg';
import Arrow from '../../assets/svg/Arrow2.svg';
import BackArrow from '../../assets/svg/back.svg';
import { useParams, useHistory } from 'react-router';
import { getUserFamilyMembers } from '../../api/models/user';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import './Doc.css';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

const Documents = (props) => {
  const path = useHistory();
  const { user, history } = props;
  let { id } = useParams();
  if (!id) {
    id = props?.user?.id;
  }
  const [list, setList] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);

  const loadData = async () => {
    let docs = [];

    if (id != props?.user?.id) {
      const userData = await getStoredData(USER_CACHE_NAME);
      let familyMemberList =
        (await getUserFamilyMembers(userData?.user_id, userData?.jwt))?.included || [];

      let memberData = familyMemberList?.find((member) => member?.id == id);
      let signed = memberData?.attributes?.['hipaa-release-details']?.signed || [];
      let declined = memberData?.attributes?.['hipaa-release-details']?.declined || [];
      docs = [...signed, ...declined];
      setFamilyMembers(familyMemberList);
      setList(docs);
    } else {
      let signed = user?.attributes?.['hipaa-release-details']?.signed || [];
      let declined = user?.attributes?.['hipaa-release-details']?.declined || [];
      docs = [...signed, ...declined];
      setList(docs);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const signDoc = (docId) => {
    path.push(`/sign-doc/${id}`, {
      declined: docId,
    });
  };

  const [selectedDoc, setSelectedDoc] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const familyMemberName = familyMembers?.find((item) => item?.id == id)?.attributes?.[
    'first-name'
  ];

  return (
    <div className={css.page}>
      <Layout
        onClickBack={() => history?.goBack()}
        headerTitle={familyMemberName ? `${familyMemberName}'s Documents` : 'Documents'}
        tab={
          user.attributes['family-member-ids']?.find((item) => item === Number(id))
            ? 'family'
            : 'documents'
        }
        back
      >
        <PrintProvider>
          <NoPrint>
            <div className={css.container}>
              <div className={css.flex1}>
                <div className={css.listContainer}>
                  {!expanded ? (
                    <div className={css.container1}>
                      <Print>
                        {list &&
                          list.map((item, index) => {
                            const { title, signed_on, declined_on } = item;
                            return (
                              <div
                                key={`doc-${index}`}
                                onClick={() => {
                                  if (window?.innerWidth <= 1024 && !selectedDoc) setExpanded(true);
                                  setSelectedDoc(list[index]);
                                }}
                              >
                                <div className={css.docEntry}>
                                  <div className="w-100">
                                    <div className={css.docTitle}>{title}</div>
                                    <div className={css.docSubtitle}>
                                      {signed_on ? (
                                        <>Signed on {moment(signed_on).format('MMMM Do, YYYY')}</>
                                      ) : (
                                        <>
                                          Declined on {moment(declined_on).format('MMMM Do, YYYY')}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <img
                                    style={{ width: '13px', height: '21px' }}
                                    src={selectedDoc === list[index] ? BackArrow : Arrow}
                                    alt="arrow"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </Print>
                    </div>
                  ) : (
                    <div>
                      {selectedDoc && (
                        <div className={css.docContainer}>
                          <div className={css.pageRightButtonContainer}>
                            <img
                              src={Close}
                              className={css.closeButton}
                              alt="close"
                              onClick={() => {
                                setExpanded(false);
                                setSelectedDoc(null);
                              }}
                            />
                            <img
                              src={Collapse}
                              className={`${css.closeButton} ${
                                window?.innerWidth <= 1024 ? 'd-none' : ''
                              }`}
                              alt="collapse"
                              onClick={() => setExpanded(false)}
                            />
                          </div>
                          <div className={css.pageRightTitle}>{selectedDoc?.title}</div>
                          <div className={css.pageRightSubTitle}>
                            {selectedDoc?.signed_on ? (
                              <>
                                Signed on {moment(selectedDoc?.signed_on).format('MMMM Do, YYYY')}
                              </>
                            ) : (
                              <>
                                Declined on{' '}
                                {moment(selectedDoc?.declined_on).format('MMMM Do, YYYY')}
                                <span
                                  className={`${css.doc_sign} ml-2`}
                                  onClick={() => {
                                    signDoc(selectedDoc?.id);
                                  }}
                                >
                                  Want to Sign?
                                </span>
                              </>
                            )}
                          </div>
                          <Markdown className="markdown" source={selectedDoc?.release_text} />
                          <img
                            src={SmoothScroll}
                            className={css.smoothScroll}
                            alt="smoothscroll"
                            onClick={() => {
                              window.scrollTo(0, document.body.scrollHeight, {
                                behavior: 'smooth',
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </NoPrint>
        </PrintProvider>
      </Layout>
      <AnimatePresence>
        {!expanded && selectedDoc && (
          <motion.div
            className={css.pageRight}
            initial={{ marginRight: -450 }}
            animate={{
              marginRight: 0,
            }}
            exit={{ marginRight: -450 }}
            transition={{ duration: 0.5 }}
          >
            <div className={css.docContainer}>
              <div className={css.pageRightButtonContainer}>
                <img
                  src={Close}
                  className={css.closeButton}
                  alt="close"
                  onClick={() => {
                    setExpanded(false);
                    setSelectedDoc(null);
                  }}
                />
                <img
                  src={FullScreen}
                  className={css.closeButton}
                  alt="fullscreen"
                  onClick={() => setExpanded(true)}
                />
              </div>
              <div className={css.pageRightTitle}>{selectedDoc?.title}</div>
              <div className={css.pageRightSubTitle}>
                {selectedDoc?.signed_on ? (
                  <>Signed on {moment(selectedDoc?.signed_on).format('MMMM Do, YYYY')}</>
                ) : (
                  <>
                    Declined on {moment(selectedDoc?.declined_on).format('MMMM Do, YYYY')}
                    <span
                      className={`${css.doc_sign} ml-2`}
                      onClick={() => {
                        signDoc(selectedDoc?.id);
                      }}
                    >
                      Want to Sign?
                    </span>
                  </>
                )}
              </div>
              <Markdown className="markdown" source={selectedDoc?.release_text} />
              <img
                src={SmoothScroll}
                className={css.smoothScroll}
                alt="smoothscroll"
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight, {
                    behavior: 'smooth',
                  });
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  authentication: state.authentication,
});

export default connect(mapStateToProps)(Documents);
