import React from 'react';
import { object } from 'prop-types';
import Select from 'react-select';
import cn from 'classnames';
import './SelectInput.css';

const SelectInput = ({
  field,
  label,
  placeholder,
  disabled,
  options,
  classNamePrefix,
  handleChange,
  isSearchable,
  form: { touched, errors, status, setFieldValue, setFieldTouched },
}) => (
  <div className="auraFieldSelectInput my-4">
    {label && <label className="auraFieldSelectInput-label">{label}</label>}
    <div>
      <Select
        {...field}
        name={field.name}
        options={options}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
        className={cn({
          'with-error':
            (touched[field.name] && status && status[field.name]) ||
            (touched[field.name] && errors && errors[field.name]),
        })}
        isDisabled={disabled}
        value={options ? options.find((option) => option.label === field.value) : ''}
        onChange={(option) => {
          setFieldValue(field.name, option.label);
          handleChange && handleChange(option);
        }}
        onBlur={() => setFieldTouched(field.name, true)}
        isSearchable={isSearchable}
      />
      {touched[field.name] && status && status[field.name] && <span>{status[field.name]}</span>}
      {touched[field.name] && errors && errors[field.name] && <span>{errors[field.name]}</span>}
    </div>
  </div>
);

SelectInput.propTypes = {
  field: object.isRequired,
};

export default SelectInput;
