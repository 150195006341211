import React from 'react';
import { object } from 'prop-types';
import css from './CheckboxTwo.css';

const CheckboxTwo = ({ field, children, disabled, form: { touched, errors, status } }) => (
  <div className="aura-checkbox-container">
    <div className="aura-checkbox">
      <label htmlFor={field.id}>
        <input {...field} type="checkbox" disabled={disabled} checked={field.value} />
        {children}
      </label>
    </div>
    {touched[field.name] && status && status[field.name] && (
      <span className="error">{status[field.name]}</span>
    )}
    {touched[field.name] && errors && errors[field.name] && (
      <span className="error">{errors[field.name]}</span>
    )}
  </div>
);

CheckboxTwo.propTypes = {
  field: object.isRequired,
};

export default CheckboxTwo;
