import React from 'react';
import classNames from 'classnames';

import ReactPlayer from 'react-player';
import css from './TestKits.module.css';
import { useTranslation } from 'react-i18next';

function InstructionVideo() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h4>{t('viewVideoInstructions')}</h4>
      <h4 className="small-font mb-4 pt-4">{t('inAdditionTo')}</h4>
      <ReactPlayer
        width="100%"
        controls
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 640,
          backgroundColor: 'black',
          border: '1px solid #EBEDEF',
        }}
        url="https://www.youtube.com/watch?v=ZBCIQVpobGI&feature=youtu.be"
      />
    </>
  );
}

export default InstructionVideo;
