import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import css from './TestKits.module.css';
import { Modal } from '../../components';
import { connect } from 'react-redux';
import { getUserTestKits } from '../../api/models/user';
import { setUserTestKits } from '../../store/action/user';
import { useTranslation } from 'react-i18next';
import ShopButton from '../../assets/svg/shoptestkits.svg';
import ButtonComponent from '../../components/Button/Button';
import TestKitCard from './TestKitCard';

const TestKitsContainer = (props) => {
  const [loading, setLoading] = useState(true);
  const pathname = props?.history?.location?.pathname;
  const activeTab = pathname?.split('/')[1];
  const [isVisible, setVisible] = useState(false);
  const [selectedKit, setKit] = useState();
  const [newTestKit, setNewTestKit] = useState([]);
  const [unassisted, setUnassisted] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const navShopTestKits = !props?.user?.profile?.attributes?.['nav-hide-shop-test-kits'];

  useEffect(() => {
    loadTestKits();
  }, []);

  async function loadTestKits() {
    setLoading(true);
    const { authentication } = props;
    const { jwt: key, user_id } = authentication;
    const response = await getUserTestKits(user_id, key, 'medtest-kits,family-medtest-kits');

    if (response && response.included && response.included[0]) {
      // setNewTestKit(response.included.filter(x => x.attributes['specimen-code'] === null));
      setNewTestKit(response.included);
      props.setUserTestKitsDispatch(response.included);
    }
    setLoading(false);
  }

  const showSelectText = useMemo(() => {
    if (
      !loading &&
      newTestKit &&
      newTestKit?.filter((x) => x?.attributes?.status === 'ordered')?.length > 0
    )
      return true;
    return false;
  }, [loading, newTestKit]);

  return (
    <div className={css.container}>
      <div className={css.flex1}>
        {showSelectText && <h3 className={css.selectTestKitText}>Select a Test Kit</h3>}

        <div className={css.testKitsGridContainer}>
          {!loading && newTestKit ? (
            <>
              {newTestKit?.map((kit) => (
                <TestKitCard kit={kit} />
              ))}
            </>
          ) : (
            <div className={css.noTestKitsContatiner}>
              Currently, You Do Not Have Any Test Kits Assigned To Your Account
            </div>
          )}
          {loading && (
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" variant="primary" />
            </div>
          )}
        </div>
        {!loading &&
        newTestKit &&
        newTestKit.filter((x) => x.attributes.status === 'ordered').length > 0 ? (
          <ButtonComponent
            text="Continue"
            disabled={!selectedKit}
            className={css.testKitContinueBtn}
            onClick={() => {
              history.push('/select-family-member-activation', {
                kit: selectedKit,
              });
            }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              marginTop: '20px',
            }}
          >
            {navShopTestKits && (
              <a href="https://testnowandgo.com/order-at-home-tests/">
                <img
                  src={ShopButton}
                  className={css.shopLogo}
                  // style={
                  //   window.innerWidth < 480
                  //     ? { width: 240, height: "auto" }
                  //     : {}
                  // }
                  alt="shop button"
                />
              </a>
            )}
          </div>
        )}
        <Modal
          show={isVisible}
          handleClose={() => setVisible(false)}
          submitText="Activate Now"
          submitDisable={loading}
          handleSubmit={() => {
            if (unassisted) {
              history.push('/activate-unassisted');
            } else {
              history.push(`/activate/${selectedKit}`);
            }
          }}
          loading={loading}
          title="Collecting Sample Now?"
        >
          <div>
            <b>IMPORTANT! READ ALL DIRECTIONS THOROUGHLY & CAREFULLY BEFORE YOU START</b>
          </div>
          <ul>
            <br />
            <li>
              Please postpone registering a test and collecting a sample until you can follow the
              guidelines below:
            </li>
            <br />
            <li>&bull; Test kits must be stored at room temperature 41-77°F (5-25°C)</li>
            <br />
            <li>&bull; Test kits must be returned within 24 hours of sample collection</li>
            <br />
            <li>
              &bull; Test kits cannot be shipped late Friday evening, or over the weekend. Visit{' '}
              <a href="http://bit.ly/fedexdropbox">bit.ly/fedexdropbox</a> to view FedEx drop box
              locations and pickup schedules.
            </li>
          </ul>
        </Modal>
      </div>
      <Modal
        show={isVisible}
        handleClose={() => setVisible(false)}
        submitText="Activate Now"
        submitDisable={loading}
        handleSubmit={() => {
          if (unassisted) {
            history.push('/activate-unassisted');
          } else {
            history.push(`/activate/${selectedKit}`);
          }
        }}
        loading={loading}
        title="Collecting Sample Now?"
      >
        <div>
          <b>{t('pleaseReadInstructions')}</b>
        </div>
        <ul>
          <li>{t('testKitsMay')}</li>
          <li>{t('pleaseCollect')}</li>
          <li>{t('doNotShip')}</li>
          <li>{t('pleaseDropPackage')}</li>
          <li>{t('preAddressedFedEx')}</li>
          <li>
            {t('visit')}{' '}
            <a href="https://bit.ly/fedexdropbox" target="_blank" rel="noreferrer">
              bit.ly/fedexdropbox
            </a>{' '}
            {t('onlineToView')}
          </li>
        </ul>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TestKitsContainer);
