import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Layout } from '../../components';
import css from './DailySymptomCheckerHistory.module.css';
import { useParams } from 'react-router';
import { getUserData, getUserFamilyMembers, userSymptoms } from '../../api/models/user';
import { getStoredData } from '../../api/cache';
import { USER_CACHE_NAME } from '../../api/const';
import { symptoms } from '../../api/models/symptoms';
import { authentication } from '../../store/action/auth';
import { useTranslation } from 'react-i18next';
import { resultsMedTest } from '../../api/models/medTest';
import Search from '../../assets/svg/magnifyingglass.svg';
import DownArrow from '../../assets/svg/downarrow.svg';
import RightArrow from '../../assets/svg/Arrow2.svg';
import Close from '../../assets/svg/close.svg';
import CheckedCheckbox from '../../assets/svg/checked-checkbox.svg';
import { capitalize } from '../../api';

const DailySymptomCheckerHistory = (props) => {
  const { t, i18n } = useTranslation();
  const { user, history } = props;
  let { id } = useParams();
  if (!id) {
    id = props.user.id;
  }
  const activeTab = 'dailySymptomChecker';
  const [name, setName] = useState('');
  const [ascending, setAscending] = useState(false);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState('');

  async function loadUser() {
    const us = await getUserData(props.authentication.user_id, props.authentication.jwt);
    return us.data;
  }

  const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x.attributes[key]] = rv[x.attributes[key]] || []).push(x);
      return rv;
    }, {});
  };

  const loadData = async () => {
    const symptomsResults = await symptoms(props.authentication.jwt);
    const userData = await getStoredData(USER_CACHE_NAME);
    console.log('symptomsResults', symptomsResults);
    let familyMemberList =
      (await getUserFamilyMembers(userData.user_id, userData.jwt)).included || [];
    const user = await loadUser();
    familyMemberList.unshift(user);
    let name = familyMemberList.find((f) => f.id === id).attributes?.['first-name'];
    setName(name);
    let results =
      (await userSymptoms({
        key: props.authentication.jwt,
        methodType: 'GET',
      })) || [];
    let groupedResults = groupBy(results, 'user-id');
    console.log('results', groupedResults);
    let groupedGroupedResults = groupBy(groupedResults[id], 'date-reported');
    console.log('groupedGroupedResults', groupedGroupedResults);
    setList(groupedGroupedResults);
    setFilteredList(groupedGroupedResults);
  };

  const handleSearch = (text) => {
    let filteredtext = text.split('/').join('-');
    setSearchText(filteredtext);
    let filteredData = [];
    if (text.length > 0) {
      filteredData = Object.keys(list).filter((item) => item.includes(filteredtext.toLowerCase()));
      let newData = Object.fromEntries(
        Object.entries(list).filter(([key]) => filteredData.includes(key))
      );
      setFilteredList(newData);
    } else {
      setFilteredList(list);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={css.page}>
      <Layout
        back
        onClickBack={() => history.goBack()}
        headerTitle={
          name ? `${name}'s Daily Symptoms Checker History` : 'Daily Symptoms Checker History'
        }
        tab={activeTab}
      >
        <div className={css.container}>
          <div className={css.header}>
            <div className={css.search}>
              <img src={Search} alt="search" />
              <input
                type="text"
                placeholder="Search"
                className={css.searchInput}
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className={css.sorter} onClick={() => setAscending(!ascending)}>
              <p className={css.sorterText}>Date</p>
              <img
                className={ascending ? css.sorterArrowUp : css.sorterArrow}
                src={DownArrow}
                alt="down arrow"
              />
            </div>
          </div>
          <div className={css.list}>
            {Object.keys(filteredList)
              .sort((a, b) => {
                if (!ascending) {
                  return new Date(b) - new Date(a);
                }
                return new Date(a) - new Date(b);
              })
              .map((item) => {
                let color;
                switch (list[item].length) {
                  case 0:
                    color = '#E3E3E3';
                    break;
                  case 1:
                    color = '#FFE7E7';
                    break;
                  case 2:
                    color = '#FFB9B9';
                    break;
                  case 3:
                    color = '#FA6F6F';
                    break;
                  default:
                    color = '#FA6F6F';
                    break;
                }
                return (
                  <div
                    className={css.item}
                    key={item.id}
                    onClick={() => setSelectedItem({ date: item, data: list[item] })}
                  >
                    <div className={css.itemHeaderLeft}>
                      <p className={css.date}>{moment(item).format('MM/DD/YY')}</p>
                    </div>
                    <div className={css.itemHeaderRight}>
                      <div className={css.chip} style={{ backgroundColor: color }}>
                        <p className={css.chipText}>{list[item].length} Symptoms</p>
                      </div>
                      <img src={RightArrow} alt="right arrow" />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Layout>
      {selectedItem && (
        <div className={css.pageRight}>
          <img
            src={Close}
            className={css.closeButton}
            alt="close"
            onClick={() => {
              setSelectedItem(null);
            }}
          />
          <h4 className={css.selectedDate}>Date: {moment(selectedItem.date).format('MM/DD/YY')}</h4>
          <h4 className={css.selectionText}>You recorded these symptoms</h4>
          {selectedItem.data.map((item) => {
            console.log('item', item);
            return (
              <div className={css.symptomItem}>
                <img src={CheckedCheckbox} alt="checked checkbox" />
                <p className={css.symptom}>{capitalize(item.attributes['symptom-name'])}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  authentication: state.authentication,
});

export default connect(mapStateToProps)(DailySymptomCheckerHistory);
