import React from 'react';
import { connect } from 'react-redux';
import { Layout } from '../../components';
import { useHistory } from 'react-router-dom';
import css from './QRCode.module.css';
import AuraLogo from '../../assets/svg/Aura_Logo.svg';

const QRCode = (props) => {
  console.log(props);
  const history = useHistory();
  if (!props.qrCode) {
    history.push('/family');
  }
  const svgCode = props.qrCode;
  const { name } = props.location.state;
  const buff = new Buffer(svgCode);
  const base64data = buff.toString('base64');

  return (
    <Layout headerTitle="My Family" tab="family" back onClickBack={() => history.push('/family')}>
      <h4 className={css.breadCrumbs}>
        {'My Aura     '}
        {'  '}&#x25B8;{'  My Family  '}
        {'  '}&#x25B8;{'  '}
        {name}
        {'  '}&#x25B8;{'  '}
        <h4 className={css.orange}>QR Code</h4>
      </h4>
      <div className={css.container}>
        <h5 className={css.headerText}>{name}'s QR Code</h5>
        <div className={css.qrCodeContainer}>
          <h4 className={css.instructionsText}>Scan to see certificate</h4>
          <img src={`data:image/svg+xml;base64,${base64data}`} alt="" className={css.qrCode} />
          <div className={css.qrFooter}>
            <img src={AuraLogo} alt="Aura Logo" className={css.auraLogo} />
            <h5 className={css.footerText}>Certification Code</h5>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  qrCode: state.family.qrCode,
});

export default connect(mapStateToProps)(QRCode);
