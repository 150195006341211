import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { Layout, SearchInput, CertificationList } from '../../components';
import css from './Certification.module.css';
import ArrowDownIcon from '../../assets/svg/arrow_down.svg';
import ArrowUpIcon from '../../assets/svg/arrow_up.svg';
import { certification } from '../../api/models/certification';

import { useTranslation } from 'react-i18next';

const Certification = (props) => {
  const history = useHistory();
  const { authentication } = props;

  const [ascending, setAscending] = useState(true);
  const [isVisible, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    loadCertification();
  }, []);

  async function loadCertification() {
    const { jwt } = authentication;
    const result = await certification(jwt);
    setData(result);
    setLoading(false);
  }

  function selectData(item) {
    setSelectedData(item);
    showModal();
  }

  function showModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function checkedExpired(date) {
    const dateToday = moment().format('l');
    const dateExpire = moment(date).format('l');
    return dateToday < dateExpire;
  }

  function formatDate(date) {
    return moment(date).format('dddd, MMMM Do, YYYY');
  }

  function compare(a, b) {
    const testA = a.attributes['date-certified'];
    const testB = b.attributes['date-certified'];

    let comparison = 0;
    if (testA > testB) {
      comparison = 1;
    } else if (testA < testB) {
      comparison = -1;
    }

    return ascending ? comparison * -1 : comparison;
  }

  function sortData() {
    setAscending(!ascending);
  }

  return (
    <Layout headerTitle="Certifications" onClickBack={() => history.goBack()} back>
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.listContainer}>
            <div className={css.container1}>
              {/* <SearchInput /> */}
              {!loading && data.length === 0 ? (
                <p className="medium-font-bold text-center">{t('noCertification')}</p>
              ) : (
                <div className={css.dateContainer} onClick={sortData}>
                  <p className="medium-font-bold">Date</p>
                  <img alt="arrow" src={ascending ? ArrowDownIcon : ArrowUpIcon} />
                </div>
              )}
              {loading && (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="warning" />
                </div>
              )}
              <div>
                {data.sort(compare).map((i) => {
                  const item = i.attributes;
                  const expire = checkedExpired(item['valid-through']);
                  return (
                    <CertificationList
                      text1={`Issued: ${item['date-certified']}`}
                      text2={`Expires: ${item['valid-through']}`}
                      successColor={expire}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className={css.flex1}></div> */}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps)(Certification);
