import { Accordion, Button, Card, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { processAPI } from '../../../api/models/process';
import { getUserTestKits } from '../../../api/models/user';
import { Layout, StrokeButton, SmallButton, Modal } from '../../../components';
import { Link } from 'react-router-dom';
import { setUserTestKits } from '../../../store/action/user';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import CaretDownIcon from '../../../assets/svg/caret_down.svg';
import classNames from 'classnames';
import css from '../TestKits.module.css';
import gxlogo from '../../../assets/image/GenetworxLogo.jpg';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ScreenerExposure from './Exposure';
import ScreenerRisk from './Risk';
import ScreenerSymptoms from './Symptoms';
import SevereSymptomsModal from './SevereSymptomsModal';

const Screener = (props) => {
  const [loading, setLoading] = useState(true);
  const [isVisible, setVisible] = useState(true);
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    // loadTestKits();
  }, []);

  async function loadTestKits() {
    // setLoading(true);
    console.log('loading');

    const { authentication } = props;
    const { jwt: key, user_id } = authentication;
    const response = await getUserTestKits(user_id, key);

    if (response && response.included && response.included[0]) {
      // setNewTestKit(response.included.filter(x => x.attributes['specimen-code'] === null));
      // setNewTestKit(response.included);
      // props.setUserTestKitsDispatch(response.included);
    }
    setLoading(false);
  }

  return (
    <div className={css.container}>
      <div className={css.flex1}>
        <ScreenerSymptoms />
        <ScreenerExposure />
        <ScreenerRisk />

        <SevereSymptomsModal isVisible={isVisible} setVisible={setVisible} />
        <div className={css.wizardButtons}>
          <Button variant="primary" size="sm" onClick={() => history.push('/testkits/account')}>
            {t('Next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Screener);
