import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import NextIcon from '../../../../assets/svg/forward.svg';
import BackIcon from '../../../../assets/svg/backward.svg';

function CalendarComponent({ onSelect }) {
  const ref = React.useRef();
  const [value, onChange] = useState(new Date());
  const [start, onStartChange] = useState();
  const [display, setDisplay] = useState('default');

  useEffect(() => {
    const { current } = ref;
    setDisplay(current?.childNodes[0]?.childNodes[2]?.innerText);
  }, [start]);

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <div>
          <span>{display}</span>
        </div>
        <div className="nav-container">
          <img
            alt="previous"
            src={BackIcon}
            onClick={() => {
              const { current } = ref;
              current.childNodes[0].childNodes[1].click();
            }}
          />

          <img
            alt="next"
            src={NextIcon}
            onClick={() => {
              const { current } = ref;
              current.childNodes[0].childNodes[3].click();
            }}
          />
        </div>
      </div>

      <Calendar
        inputRef={ref}
        className=""
        onChange={(e) => {
          onChange(e);
          onSelect(e);
        }}
        onActiveStartDateChange={onStartChange}
        calendarType="US"
        locale="en-US"
        showNavigation
        value={value}
        showNeighboringMonth={false}
        maxDate={new Date()}
      />
    </div>
  );
}

export default CalendarComponent;
