import React, { useEffect, useMemo, useState } from 'react';
import css from './ResultsCards.module.css';
import moment from 'moment';
import Slider from 'react-slick';
import { getTimeSinceTest } from '../../utils/utils';
// import "./index.css";

const ResultsCards = ({ setFullHistory, results: propsResults }) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    let tempResults = propsResults;
    if (propsResults?.length > 10) tempResults = propsResults?.slice(0, 10);

    setResults(tempResults);
  }, [propsResults]);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: props.onClick === null ? 'none' : 'block',
          background: 'gray',
          borderRadius: '100%',
        }}
        onClick={onClick}
      />
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: props.onClick === null ? 'none' : 'block',
          background: 'gray',
          borderRadius: '100%',
        }}
        onClick={onClick}
      />
    );
  }

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: results?.length > 1 ? 3 : 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: results?.length > 1 ? 2 : 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
      ],
    }),
    [results]
  );

  const settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    vertical: true,
    verticalSwiping: true,

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  if (results?.length === 0 || !results) {
    return (
      <div className={css.noResultsContainer}>
        <span className={css.noResultsYet}>No Test Results yet</span>
      </div>
    );
  }

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.headerText}>Recent({results?.length})</div>
        <span className={css.historyLink} onClick={() => setFullHistory(true)}>
          See full history
        </span>
      </div>

      <div className="d-block d-md-none">
        <Slider {...settings2}>
          {results?.map(({ attributes, id }, index) => (
            <div className={css.cardsContainer} key={id}>
              <div className={css.resultCard}>
                <div className={css.cardHeader}>
                  <span className={css.headerText}>{attributes['medtest-type']}</span>
                  <span className={css.cardDate}>
                    {moment(attributes['test-date']).format('ddd MMM Do, YYYY')}
                  </span>
                </div>
                <div
                  className={
                    attributes['test-result'] === 'Positive'
                      ? css.resultContainerRed
                      : attributes['test-result'] === 'Negative'
                      ? css.resultContainerGreen
                      : css.resultContainerYellow
                  }
                >
                  <span
                    className={
                      attributes['test-result'] === 'Positive'
                        ? css.resultsTextRed
                        : css.resultsText
                    }
                  >
                    Results: {attributes['test-result']}
                  </span>
                  {/* {attributes["test-result"] === "Positive" && index === 0 ? (
                <>
                  <span className={css.resultsSubText}>
                    We recommend purchasing a test immediately
                  </span>
                  <span className={css.resultsButton}>
                    Purchase Home Test Kit Now
                  </span>
                </>
              ) : null} */}
                </div>
                <div className={css.detailsContainer}>
                  <span>Name</span>
                  <span className={css.detailsText}>
                    {`${attributes['first-name']} ${attributes['last-name']}`}
                  </span>
                </div>
                <hr className={css.horizontalLine} />
                <div className={css.detailsContainer}>
                  <span>Time since Test</span>
                  <span className={css.detailsText}>
                    {getTimeSinceTest(attributes?.['test-date'])}
                  </span>
                </div>
                <hr className={css.horizontalLine} />
                <div className={css.detailsContainer}>
                  <span>Location of Testing</span>
                  <span className={css.detailsText}>
                    {attributes['test-location-name'] || 'Not Available'}
                  </span>
                </div>
                <hr className={css.horizontalLine} />
                <div className={css.detailsContainer}>
                  <span>Results shared with:</span>
                  <span className={css.sharedWith}>&#10003; You</span>
                </div>

                {attributes['test-result-url'] ? (
                  <div
                    className={css.resultDetailsButtonContainer}
                    onClick={() => window.open(attributes['test-result-url'])}
                  >
                    <span className={css.resultDetailsButton}>View Result Details</span>
                  </div>
                ) : (
                  <div className={css.resultDetailsButtonContainer}>
                    <span className={css.noResultDetails}>Result Details Not Available</span>
                  </div>
                )}
                {/* {attributes["test-result"] === "Positive" && index === 0 ? (
              <div className={css.linkContainer}>
                <span className={css.alertContactsLink}>
                  Alert Recent Contacts
                </span>
              </div>
            ) : null} */}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="d-none d-md-block">
        <Slider {...settings}>
          {results?.map(({ attributes, id }, index) => (
            <div className={css.cardsContainer} key={id}>
              <div className={css.resultCard}>
                <div className={css.cardHeader}>
                  <span className={css.headerText}>{attributes['medtest-type']}</span>
                  <span className={css.cardDate}>
                    {moment(attributes['test-date']).format('ddd MMM Do, YYYY')}
                  </span>
                </div>
                <div
                  className={
                    attributes['test-result'] === 'Positive'
                      ? css.resultContainerRed
                      : attributes['test-result'] === 'Negative'
                      ? css.resultContainerGreen
                      : css.resultContainerYellow
                  }
                >
                  <span
                    className={
                      attributes['test-result'] === 'Positive'
                        ? css.resultsTextRed
                        : css.resultsText
                    }
                  >
                    Results: {attributes['test-result']}
                  </span>
                  {/* {attributes["test-result"] === "Positive" && index === 0 ? (
                <>
                  <span className={css.resultsSubText}>
                    We recommend purchasing a test immediately
                  </span>
                  <span className={css.resultsButton}>
                    Purchase Home Test Kit Now
                  </span>
                </>
              ) : null} */}
                </div>
                <div className={css.detailsContainer}>
                  <span>Name</span>
                  <span className={css.detailsText}>
                    {`${attributes['first-name']} ${attributes['last-name']}`}
                  </span>
                </div>
                <hr className={css.horizontalLine} />
                <div className={css.detailsContainer}>
                  <span>Time since Test</span>
                  <span className={css.detailsText}>
                    {getTimeSinceTest(attributes?.['test-date'])}
                  </span>
                </div>
                <hr className={css.horizontalLine} />
                <div className={css.detailsContainer}>
                  <span>Location of Testing</span>
                  <span className={css.detailsText}>
                    {attributes['test-location-name'] || 'Not Available'}
                  </span>
                </div>
                <hr className={css.horizontalLine} />
                <div className={css.detailsContainer}>
                  <span>Results shared with:</span>
                  <span className={css.sharedWith}>&#10003; You</span>
                </div>

                {attributes['test-result-url'] ? (
                  <div
                    className={css.resultDetailsButtonContainer}
                    onClick={() => window.open(attributes['test-result-url'])}
                  >
                    <span className={css.resultDetailsButton}>View Result Details</span>
                  </div>
                ) : (
                  <div className={css.resultDetailsButtonContainer}>
                    <span className={css.noResultDetails}>Result Details Not Available</span>
                  </div>
                )}
                {/* {attributes["test-result"] === "Positive" && index === 0 ? (
              <div className={css.linkContainer}>
                <span className={css.alertContactsLink}>
                  Alert Recent Contacts
                </span>
              </div>
            ) : null} */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default ResultsCards;
