import React, { useCallback, useState } from 'react';
import { string, object } from 'prop-types';
import cn from 'classnames';
import './Input.css';

const Input = ({
  field,
  label,
  type,
  placeholder,
  disabled,
  onBlurHandler = undefined,
  form: { touched, errors, status, setFieldTouched },
}) => {
  const handleBlur = useCallback(() => {
    setFieldTouched(field.name);
    onBlurHandler !== undefined && onBlurHandler(field);
  }, [field, setFieldTouched, onBlurHandler]);

  const [isOpen, setOpen] = useState(false);

  return (
    <div className="auraFielInput my-4">
      {label && <label className="auraFielInput-label">{label}</label>}
      <div className="position-relative">
        <input
          className={cn({
            withError:
              (touched[field.name] && status && status[field.name]) ||
              (touched[field.name] && errors && errors[field.name]),
          })}
          disabled={disabled}
          onBlurCapture={handleBlur}
          {...field}
          {...{ placeholder }}
          type={type === 'password' && isOpen ? 'text' : type}
        />

        {type === 'password' && (
          <div className="position-absolute eyeIcon">
            <img
              alt=""
              src={isOpen ? 'hiddenIcon' : 'eyeIcon'}
              width="20"
              height="20"
              className=""
              onClick={() => setOpen((prev) => !prev)}
            />
          </div>
        )}

        {touched[field.name] && status && status[field.name] && <span>{status[field.name]}</span>}
        {touched[field.name] && errors && errors[field.name] && <span>{errors[field.name]}</span>}
      </div>
    </div>
  );
};

Input.propTypes = {
  field: object.isRequired,
  type: string.isRequired,
};

export default Input;
