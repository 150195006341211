import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Alert } from '../../components';
import css from './VaccineInformation.module.css';
import { processAPI } from '../../api/models/process';
import CircleCheck from '../../assets/svg/circle-check.svg';
import CaretUp from '../../assets/svg/filled_caret_up.svg';
import CaretDown from '../../assets/svg/filled_caret_down.svg';
import EditPencil from '../../assets/svg/edit_pencil.svg';
import { Field, Form, Formik } from 'formik';
import SelectInput from '../../components/SelectInput/SelectInput';
import Input from '../../components/Input/Input';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInput';
import UploadPhotoButton from '../../assets/svg/uploadPhotoButton.svg';
import Replace from '../../assets/svg/Replace.svg';
import { Spinner } from 'react-bootstrap';
import { uploadFile } from '../../api/models/forms';
import * as Yup from 'yup';
import './VaxUploadCard.css';

const numbersToWords = {
  0: 'First',
  1: 'Second',
  2: 'Third',
  3: 'Fourth',
  4: 'Fifth',
  5: 'Sixth',
  6: 'Seventh',
  7: 'Eighth',
  8: 'Ninth',
  9: 'Tenth',
  10: 'Eleventh', // lets pray we don't have to get an 11th shot let alone more...
  11: 'Twelfth',
  12: 'Thirteenth',
  13: 'Fourteenth',
  14: 'Fifteenth',
  15: 'Sixteenth',
  16: 'Seventeenth',
  17: 'Eighteenth',
  18: 'Nineteenth',
  19: 'Twentieth',
};

const vaccineSchema = Yup.object({
  vaccineType: Yup.string().required('Vaccine name is required'),
  doseDate: Yup.string()
    .required('Dose date is required')
    .test('isValid', 'Invalid Date', (value) => {
      const date = moment(value, 'MM-DD-YYYY');
      return date.isValid() && moment().diff(date) > 0 && date.year() > 1850;
    }),
  lotNumber: Yup.string().required('Dose lot number is required'),
});

const VaxUploadCard = (props) => {
  const { jwt } = props.authentication;
  const [editing, setEditing] = useState(!props?.vax?.id);
  const [expanded, setExpanded] = useState(props?.expanded);
  const [photoUrl, setPhotoUrl] = useState('');
  const [files, setFiles] = useState([]);
  const [isImageError, setIsImageError] = useState(false);

  const [showAlert, setShowAlert] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [replaceImage, setReplaceImage] = useState(false);

  useEffect(() => {
    if (props?.vax?.id) getPhoto(props?.vax?.id);
  }, []);

  useEffect(() => {
    setExpanded(props?.expanded);
    if (editing && !props?.expanded) setEditing(false);
  }, [props?.expanded]);

  async function getFileNameURL() {
    const preSigned = {
      filename: files[0].name,
      image_type: files[0].type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'pre-signed-post',
    });
    return data;
  }

  async function saveFileName(fileName, vaccinationId) {
    const data = await processAPI({
      key: jwt,
      methodType: 'PATCH',
      data: {
        filename: fileName,
        imageable_id: vaccinationId,
        imageable_type: 'Vaccination',
      },
      endpoint: 'image',
    });

    return data;
  }

  async function createOrUpdateVaccination(vaccinationDate, lotNumber, vaccineId, vaccinationId) {
    const myData = {
      data: {
        type: 'vaccinations',
        attributes: {
          'administered-at': moment(vaccinationDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          'lot-number': lotNumber,
          'manual-upload': true,
          'user-id': parseInt(props?.user_id),
        },
        relationships: {
          user: {
            data: { type: 'users', id: parseInt([props?.user_id]) },
          },
          vaccine: {
            data: { type: 'vaccines', id: parseInt(vaccineId) },
          },
        },
      },
    };

    const shouldCreate = !vaccinationId;
    if (!shouldCreate) {
      myData.data.id = vaccinationId;
    }
    const data = await processAPI({
      key: jwt,
      methodType: shouldCreate ? 'POST' : 'PATCH',
      data: myData,
      endpoint: shouldCreate ? 'vaccinations' : `vaccinations/${vaccinationId}`,
    });
    return data;
  }

  async function getVaccinationById(id) {
    const data = await processAPI({
      endpoint: `vaccinations/${id}`,
      methodType: 'GET',
      key: jwt,
    });
    return data;
  }

  async function autoDeleteVaccination(id) {
    processAPI({
      endpoint: `vaccinations/${id}`,
      methodType: 'DELETE',
      key: jwt,
    });
  }

  async function vaccinationSubmit(values) {
    setLoading(true);
    let meta;
    const isSameImage = photoUrl && !replaceImage;
    try {
      if (files?.length > 0 || isSameImage) {
        setIsImageError(false);

        if (!isSameImage) {
          meta = await getFileNameURL();
          await uploadFile(meta?.url, files[0], meta?.fields);
        }

        const VaccineResponse = await createOrUpdateVaccination(
          values?.doseDate,
          values?.lotNumber,
          values?.vaccineType,
          props?.vax?.id
        );
        if (VaccineResponse?.id) {
          if (!isSameImage) {
            await saveFileName(meta?.fields?.key, VaccineResponse?.id);
          }

          let curVaxData = await getVaccinationById(VaccineResponse?.id);
          if (curVaxData?.attributes['valid-vax-card']) {
            props?.loadDoses && props?.loadDoses();
            await getPhoto(VaccineResponse?.id);
            setFiles([]);
            setEditing(false);
            setShowAlert({
              show: true,
              message: 'Saved successfully',
              type: 'success',
            });
          } else {
            autoDeleteVaccination(curVaxData?.id);
            setFiles([]);
            setShowAlert({
              show: true,
              message:
                'Your image could not be read. Please upload a clear image of your vaccination card.',
              type: 'error',
            });
          }
        } else {
          setShowAlert({ show: true, message: 'Failed', type: 'error' });
        }
      } else {
        setShowAlert({
          show: true,
          message: 'Please upload a image of your vaccination card.',
          type: 'error',
        });
        setIsImageError(true);
      }
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
      console.log(error);
    }

    setLoading(false);
  }

  async function getPhoto(ImageId) {
    return new Promise((resolve, reject) => {
      processAPI({
        endpoint: `vaccinations/${ImageId}/vaccine-cards`,
        methodType: 'GET',
        key: jwt,
      })
        .then((data) => {
          setPhotoUrl(data[data?.length - 1]?.attributes?.url);
          resolve();
        })
        .catch((error) => console.log(error));
    });
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  return (
    <>
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />

      <div
        className={css.vaxCard}
        key={props?.vax?.id || props?.index}
        style={loading ? { pointerEvents: 'none' } : {}}
      >
        <div className={css.vaxCardHeader}>
          <div className={css.vaxCardHeaderLeft}>
            {props?.vax?.id && !editing && (
              <img src={CircleCheck} className={css.vaxCardIcon} alt="circle-check" />
            )}
            <p className={css.vaxCardHeaderText}>{numbersToWords[props?.index]} Dose</p>
          </div>
          {props?.vax?.id && !editing && (
            <img
              src={expanded ? CaretUp : CaretDown}
              alt="caret"
              onClick={() => setExpanded(!expanded)}
            />
          )}
        </div>

        {expanded && (
          <Formik
            initialValues={{
              vaccineType: props?.vax?.attributes?.['vaccine-id'],
              doseDate: moment(props?.vax?.attributes?.['administered-at']).format('MM-DD-YYYY'),
              lotNumber: props?.vax?.attributes?.['lot-number'],
              vaccineName: props?.availableVaccines?.find(
                (option) => option?.value === props?.vax?.attributes?.['vaccine-id']
              )?.label,
            }}
            validationSchema={vaccineSchema}
            onSubmit={vaccinationSubmit}
            enableReinitialize
          >
            {(formikProps) => (
              <Form>
                <div className={`${css.vaxCardBody} `}>
                  <div className={css.vaxCardBodyLeft}>
                    {props?.vax?.id && (
                      <img
                        src={EditPencil}
                        alt="edit"
                        className={css.editButton}
                        onClick={() => {
                          setEditing(!editing);
                          formikProps?.resetForm();
                          setFiles([]);
                          setReplaceImage(false);
                        }}
                        style={
                          editing
                            ? {
                                borderRadius: '100%',
                                border: '2px solid #FF9900',
                              }
                            : {}
                        }
                      />
                    )}

                    <div className={css.form}>
                      <div className={css.formInner}>
                        <div className="label_Css">Vaccine Name *</div>
                        {editing ? (
                          <Field
                            name="vaccineType"
                            isSearchable={false}
                            component={SelectInput}
                            classNamePrefix="form-select"
                            placeholder="ex. Pfizer, Moderna"
                            options={props?.availableVaccines.map((s) => ({
                              value: s.value,
                              label: s.label,
                            }))}
                            disabled={!editing}
                          />
                        ) : (
                          <Field
                            name="vaccineName"
                            component={Input}
                            type="text"
                            placeholder="MM-DD-YYYY"
                            format="##-##-####"
                            disabled={!editing}
                          />
                        )}

                        <div className="label_Css">Dose Date *</div>
                        <Field
                          name="doseDate"
                          // label="Dose Date *"
                          component={editing ? NumberFormatInput : Input}
                          type="text"
                          placeholder="MM-DD-YYYY"
                          format="##-##-####"
                          disabled={!editing}
                        />

                        <div className="label_Css">Dose Lot Number *</div>
                        <Field
                          name="lotNumber"
                          // label="Dose Lot Number *"
                          placeholder="Lot Number"
                          component={Input}
                          type="text"
                          disabled={!editing}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={css.vaxCardBodyRight}>
                    <div className={css.photoContainer}>
                      {photoUrl && !editing && (
                        <img src={photoUrl} width={200} height={200} alt="vaccine-card" />
                      )}

                      {photoUrl && editing && !replaceImage && (
                        <div className={css.imagePreview}>
                          <img src={photoUrl} width={200} height={200} alt="vaccine-card" />

                          <img
                            src={Replace}
                            alt="replace"
                            className={css.replaceButton}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setReplaceImage(true)}
                          />
                        </div>
                      )}

                      {editing && files?.length === 0 && (replaceImage || !photoUrl) && (
                        <div>
                          <label className={css.uploadPhotoButton} htmlFor="cardImage">
                            <img src={UploadPhotoButton} alt="Upload" />
                          </label>

                          <input
                            id="cardImage"
                            accept="image/*"
                            title="&nbsp;"
                            type="file"
                            multiple
                            name="cardImage"
                            className={isImageError ? css.inputfileerror : css.inputfile}
                            onChange={(e) => {
                              setFiles(e?.target?.files);
                            }}
                          />
                        </div>
                      )}

                      {editing && files[0] && (
                        <div className={css.imagePreview}>
                          <img
                            src={files[0] && URL.createObjectURL(files[0])}
                            alt="insurance-card"
                            width={200}
                            height={200}
                          />
                          <img
                            src={Replace}
                            alt="replace"
                            className={css.replaceButton}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setFiles([])}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {editing && (
                  <div className="mt-4">
                    <div
                      onClick={() => {
                        if (loading) return;

                        if (
                          !formikProps.isValid ||
                          (!formikProps.dirty && !replaceImage) ||
                          (files?.length === 0 && (replaceImage || !photoUrl))
                        )
                          return;
                        return formikProps?.submitForm();
                      }}
                      className={
                        !formikProps.isValid ||
                        (!formikProps.dirty && !replaceImage) ||
                        (files?.length === 0 && (replaceImage || !photoUrl))
                          ? css.saveButtonDisabled
                          : css.saveButton
                      }
                      text="Add Vaccine"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner animation="border" variant="secondary" />
                      ) : props?.vax?.id ? (
                        'Update Vaccine'
                      ) : (
                        'Add Vaccine'
                      )}
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authentication: state?.authentication,
});

export default React.memo(connect(mapStateToProps)(VaxUploadCard));
