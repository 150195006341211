import React, { useState, useEffect, useRef } from 'react';
import {
  Header,
  Alert,
  Checkbox,
  Layout,
  TextField,
  DashedButton,
  ListForm,
  Modal,
} from '../../components';
import BackIcon from '../../assets/svg/back.svg';
import RCard from '../../assets/image/R_Card.png';
import Tube from '../../assets/image/Tube.png';
import Camera from '../../assets/image/camera.png';
import './ActivationFlowActivateKit.css';
import classNames from 'classnames';
import { Button, Spinner, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';

import MaskedInput from 'react-text-mask';
import css from './TestKits.module.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { processAPI } from '../../api/models/process';
import { getUserTestKits, createUserTestKits } from '../../api/models/user';
import { validateKitInformation } from '../../api/validation';
import ScanIcon from '../../assets/svg/scan_icon.svg';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { animateScroll } from 'react-scroll';
import { setUserTestKits } from '../../store/action/user';
import SampleTestKit from '../../assets/image/sampletestkit.png';
import SampleTrackingNumber from '../../assets/image/sampletrackingnumber.png';
import confirmationForm from '../../assets/image/confirmationform.png';
import ExclamationPoint from '../../assets/svg/exclamationpoint.svg';
import tubeLabel from '../../assets/image/tubelabel.png';
import specimen from '../../assets/image/specimen.png';

function TextMaskActivationCode(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/G/i, /X/i, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskCollectionDate(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /[0-2]/,
        /\d/,
        ':',
        /[0-5]/,
        /[0-9]/,
      ]}
      // mask={[/2/, /0/, /[2]/, /\d/, '-', /[01]/, /[\d]/, '-', /[0-3]/, /\d/, " ", /[0-2]/, /\d/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

function TextMaskTrackingNumber(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onFocus={() =>
        setTimeout(() => {
          inputRef.setSelectionRange(0, 0);
        }, 0)
      }
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

const UsabilityKitRegistration = (props) => {
  const [scannerOpened, setScannerOpened] = useState(false);
  const cameraTimer = useRef(null);
  const { t, i18n } = useTranslation();
  const { familyMemberId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const { pathname } = props.history.location;
  const activeTab = pathname.split('/')[1];
  const [specimenCode, setSpecimenCode] = useState('');
  const [collectionDate, setCollectionDate] = useState(
    moment(new Date()).format('MM/DD/YYYY HH:mm')
  );
  const [returnTrackingNumber, setReturnTrackingNumber] = useState('');
  const [newTestKit, setNewTestKit] = useState([]);
  const [registeredTestKit, setRegisteredTestKit] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  //   const { testKitId } = useParams();
  const [validation, setValidation] = useState({ invalid: '', message: '' });
  const { invalid, message: errorMessage } = validation;
  const history = useHistory();
  const { state } = useLocation();

  const kitId = state?.kit;

  const { testkits } = props;
  const [radioValue, setRadioValue] = useState('1');

  const [page, setPage] = useState(1);

  const [promoCode, setPromoCode] = useState('');

  useEffect(() => {
    const kits = props.user.testkits;
    const promoCode = localStorage.getItem('promo-code');
    promoCode && setPromoCode(promoCode);
  }, [props.user]);

  async function createKit() {
    const { authentication } = props;
    const { jwt: key, user_id } = authentication;
    let result = await createUserTestKits(familyMemberId, key);
    let id = result?.data?.id;
    return id;
  }

  async function onSubmit() {
    history.push(`/kit-tracking/${familyMemberId}`, {
      kit: state?.kit,
      specimenCode,
    });
  }

  function hideAlert() {
    clearTimeout(cameraTimer);
    setShowAlert({ ...showAlert, ...{ show: false } });
  }

  const [focusedInput, setFocusedInput] = useState(false);
  useEffect(() => {
    if (showScanner) {
      cameraTimer.current = setTimeout(() => {
        setShowScanner(false);
        setScannerOpened(true);
        setShowAlert({ show: true, type: 'error', message: 'Scan Unsuccessful.' });
      }, 10000);
    }
    return () => clearTimeout(cameraTimer.current);
  }, [showScanner, setShowScanner, setShowAlert, setScannerOpened]);

  const UsabilityActivationFlow = (
    <>
      <>
        <Header title="Kit Registration" progress={50} onBack={() => history.goBack()} />
        <div className="mt-4 pt-4 d-flex flex-column align-items-center mx-3 mx-sm-0 px-md-3 px-lg-0">
          <div>
            <div className="content_header">Enter the Registration Code</div>
            <div className="content_text mt-2">
              Manually enter your registration code below. Alternatively, you can scan the barcode
              located on your test tube and/or registration card.
            </div>

            <div className="mt-2">
              <ListForm
                focused={focusedInput}
                required
                label="Registration Code"
                inputcomponent={TextMaskActivationCode}
                name="activationCode"
                onChange={(e) => {
                  const kits = props?.user?.testkits;
                  const thekit = kits?.find(
                    (x) => x?.attributes['specimen-code'] === e?.target?.value?.toUpperCase()
                  );
                  if (thekit) {
                    setValidation({
                      invalid: 'specimenCode',
                      message: t('registrationFailedActivation'),
                    });
                    return;
                  }
                  if (!e?.target?.value.match(/GX81\d{6}$/)) {
                    setValidation({
                      invalid: 'specimenCode',
                      message: t('registrationFailedValidation'),
                    });
                  } else {
                    setValidation({ invalid: '', message: '' });
                  }
                  if (specimenCode.length === 10) {
                    if (e?.target?.value.length < 10) {
                      setSpecimenCode(`${e?.target?.value}`);
                    } else {
                      setSpecimenCode(`${specimenCode}`);
                    }
                  } else {
                    setSpecimenCode(`${e?.target?.value}`);
                  }
                }}
                value={specimenCode}
                error={invalid === 'specimenCode'}
                errorMessage={errorMessage}
                inputProps={{ 'data-testid': 'ACTKIT' }}
              />
            </div>

            <div className="d-flex align-items-center justify-content-center my-4">
              <div className="border-bottom flex-grow-1" />
              <div className="mx-2">OR</div>
              <div className="border-bottom flex-grow-1" />
            </div>

            <div className="mb-5 ">
              <div className="content_box py-4 px-1 px-sm-4 px-md-0 px-lg-4">
                <div className="d-flex flex-column align-items-center mt-2">
                  <div className="content_box_header">Scan from Collection Tube</div>
                  <div className="content_box_header">OR Registration Card</div>
                </div>

                {showScanner ? (
                  <div
                    id="scanner"
                    className="d-flex mt-4 justify-content-center align-items-center"
                  >
                    <BarcodeScannerComponent
                      width={380}
                      onUpdate={(err, result) => {
                        if (!scannerOpened) {
                          setScannerOpened(true);
                        }
                        if (result) {
                          clearTimeout(cameraTimer.current);
                          setShowScanner(false);
                          if (!result?.text.match(/GX81\d{6}$/)) {
                            setValidation({
                              invalid: 'specimenCode',
                              message: t('registrationFailedValidation'),
                            });
                            setSpecimenCode(result?.text.slice(0, 10));
                            setShowAlert({
                              show: true,
                              type: 'error',
                              message: 'Scan unsuccessful.',
                            });
                            return;
                          }
                          setSpecimenCode(result?.text);
                          setShowAlert({
                            show: true,
                            type: 'success',
                            message: 'Scan Successful!',
                          });
                          return;
                        }
                        if (err) console.log(err);
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-4 pt-2">
                    <img src={Tube} alt="Tube" />
                    <img src={RCard} alt="RCard" className="mt-3 mt-sm-0" />
                  </div>
                )}

                <div className="up_Button mt-5" onClick={() => setShowScanner(!showScanner)}>
                  <img alt="icon" src={Camera} />
                  <div className="pl-3">{scannerOpened ? 'Scan Again' : 'Scan Code'}</div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-4 pt-2">
                  <div className="content_box_des">
                    Barcodes can be scanned horizontally and vertically
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div
                className="Continue_Button my-5"
                style={
                  loading || specimenCode?.length !== 10 || !specimenCode?.match(/GX81\d{6}$/)
                    ? { opacity: '0.6', cursor: 'default' }
                    : {}
                }
                onClick={() => {
                  if (loading || specimenCode?.length !== 10) return setFocusedInput(true);
                  onSubmit();
                }}
              >
                {loading ? <Spinner animation="border" variant="primary" /> : 'Continue'}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );

  return (
    <Layout tab="testkits" headerTitle="" onClickBack="" disablePrimaryUserHipaaPopup back={false}>
      <Alert
        className="mb-"
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />

      <div>
        <div className="d-none d-md-block" style={{ marginTop: '-100px' }}>
          {UsabilityActivationFlow}
        </div>
        <div className="d-block d-md-none">{UsabilityActivationFlow}</div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
    flow: state.flow,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setUserTestKitsDispatch(payload) {
    dispatch(setUserTestKits(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UsabilityKitRegistration);
