import * as Yup from 'yup';

export const signupSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required'),
  addressLine1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  gender: Yup.string().required('Gender is required'),
  race: Yup.string().required('Race is required'),
  ethnicity: Yup.string().required('Ethnicity is required'),
  email: Yup.string()
    .required('Email is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Must be a valid email address'),
  emailConfirmation: Yup.string()
    .required('Email confirmation is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Must be a valid email address')
    .oneOf([Yup.ref('email'), null], 'Emails must match'),
  password: Yup.string()
    .max(70, 'Password must be less than 70 characters')
    .min(8, 'Password must be a minimum of 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character'
    )
    .required('Password is required'),
});

export const familySignupSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required'),
  addressLine1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  gender: Yup.string().required('Gender is required'),
  race: Yup.string().required('Race is required'),
  ethnicity: Yup.string().required('Ethnicity is required'),
});

export const insuranceSchema = Yup.object({
  insuredPolicyNumber: Yup.string().required('Insured policy number is required'),
  insuredGroupNumber: Yup.string().required('Insured group number is required'),
  insurancePlan: Yup.string().ensure().required('Plan name is required'),
  patientRelationship: Yup.string().required('Patient relationship to insured is required'),
  guarantorRelationship: Yup.string().required('Guarantor relationship to insured is required'),
  effectiveDate: Yup.string().required('Effective date to insured is required'),
  expirationDate: Yup.string().required('Expiration date to insured is required'),
  firstName: Yup.string('').required('Insured first name is required'),
  lastName: Yup.string().required('Insured last name is required'),
  addressLine1: Yup.string().required('Insured address is required'),
  city: Yup.string().required('Insured city is required'),
  state: Yup.string().required('Insured state is required'),
  zip: Yup.string().required('Insured zip code is required'),
  phoneNumber: Yup.string().required('Insured phone number is required'),
  dateOfBirth: Yup.string().required('Insured date of birth is required'),
  gender: Yup.string().required('Insured gender is required'),
});

export const nonInsuranceSchema = Yup.object().shape(
  {
    hasInsurance: Yup.bool().when(['ssn', 'driversLicense'], {
      is: (ssn, driversLicense) => !ssn && !driversLicense,
      then: Yup.bool()
        .required('Attest to having no insurance or enter SSN or Drivers License #')
        .nullable(true),
    }),
    driversLicense: Yup.string().when(['ssn', 'hasInsurance'], {
      is: (ssn, hasInsurance) => !ssn && !hasInsurance,
      then: Yup.string().required(
        'Enter a drivers license or SSN or attest to having no insurance'
      ),
    }),
    ssn: Yup.string().when(['driversLicense', 'hasInsurance'], {
      is: (driversLicense, hasInsurance) => !driversLicense && !hasInsurance,
      then: Yup.string()
        .required('Enter a drivers license or SSN or attest to having no insurance')
        .test('ssn-format', 'You must enter a valid SSN to continue', (value) => {
          const re = /^([0-6]\d{2}|7[0-6]\d|77[0-2])([ -]?)(\d{2})\2(\d{4})$/;
          let temp = value;

          if (!re.test(value)) {
            return false;
          }
          if (value.indexOf('-') != -1) {
            temp = value.split('-').join('');
          }
          if (value.indexOf(' ') != -1) {
            temp = value.split(' ').join('');
          }
          if (temp.substring(0, 3) == '000') {
            return false;
          }
          if (temp.substring(3, 5) == '00') {
            return false;
          }
          if (temp.substring(5, 9) == '0000') {
            return false;
          }
          if (temp.substring(0, 3) == '666') {
            return false;
          }
          return true;
        }),
    }),
  },
  [
    ['driversLicense', 'ssn'],
    ['driversLicense', 'hasInsurance'],
    ['ssn', 'hasInsurance'],
  ]
);

export const editAccountSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required'),
  addressLine1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  gender: Yup.string().required('Gender is required'),
  race: Yup.string().required('Race is required'),
  ethnicity: Yup.string().required('Ethnicity is required'),
  email: Yup.string()
    .required('Email is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Must be a valid email address'),
  mothersMaidenName: Yup.string().when('isVaccine', {
    is: true,
    then: Yup.string().required('Mothers maiden name is required'),
    otherwise: Yup.string(),
  }),
});

export const vaccineSchema = Yup.object({
  vaccineType: Yup.string().required('Vaccine type is required'),
  doseDate: Yup.string().required('First dose date is required'),
  lotNumber: Yup.string().required('First dose lot number is required'),

  // secondDoseDate: Yup.string().test('second-dose-date', 'Second dose date is required', function(value) {
  //   return (parseInt(this.parent.vaccineType) === parseInt(this.parent.singleDoseVaccineId) || value !== undefined);
  // }),
  // secondDoseLotNumber: Yup.string().test('second-dose-lot-number', 'Second dose lot number is required', function(value) {
  //   return (parseInt(this.parent.vaccineType) === parseInt(this.parent.singleDoseVaccineId) || value !== undefined);
  // }),
});

export const editInsuranceSchema = Yup.object({
  firstName: Yup.string('').required('Insured first name is required'),
  lastName: Yup.string().required('Insured last name is required'),
  dateOfBirth: Yup.string().required('Insured date of birth is required'),
  addressLine1: Yup.string().required('Insured address is required'),
  city: Yup.string().required('Insured city is required'),
  state: Yup.string().required('Insured state is required'),
  zip: Yup.string().required('Insured zip code is required'),
  phoneNumber: Yup.string().required('Insured phone number is required'),
  insuredPolicyNumber: Yup.string().required('Insured policy number is required'),
  insuredGroupNumber: Yup.string(),
  patientRelationship: Yup.string().required('Patient relationship to insured is required'),
  gender: Yup.string().required('Insured gender is required'),
  insuranceNotListed: Yup.boolean(),
  insuranceName: Yup.string().when('insuranceNotListed', {
    is: false,
    then: Yup.string().required('Plan name is required'),
    otherwise: Yup.string(),
  }),
  insuranceFreeformName: Yup.string().when('insuranceNotListed', {
    is: true,
    then: Yup.string().required('Free Form Plan name is required'),
    otherwise: Yup.string(),
  }),
});
export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  console.log(cleaned, 'cleaned');
  let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})$/);
  console.log(match, 'matched');
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return phoneNumberString;
};
