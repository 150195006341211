import React, { useState } from 'react';
import './index.css';
import Check from '../../../assets/image/check.png';

export default function TestSiteResponse({ onBack, onNext, value }) {
  const [testSite, setTestSite] = useState(value);

  return (
    <>
      <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
        <div className="col-12 col-sm-7 col-md-6 col-lg-5 mb-5 mt-3 px-3 px-sm-0">
          <div className="mb-3 testSiteResponse-header">Where did you take your test?</div>

          <div className="mb-3 testSiteResponse-sub-header">
            Please choose from one of the following
          </div>

          <div className="mb-3 my-4 pt-2">
            <div
              onClick={() => setTestSite('At Home')}
              className={` ${testSite === 'At Home' ? 'selectedResult' : 'notSelectResult'}`}
            >
              {testSite === 'At Home' && <img src={Check} alt="Check" className="mr-2" />}
              At Home
            </div>
          </div>

          <div className="mb-3 my-4 pt-2">
            <div
              onClick={() => setTestSite('At a Testing Site')}
              className={` ${
                testSite === 'At a Testing Site' ? 'selectedResult' : 'notSelectResult'
              }`}
            >
              {testSite === 'At a Testing Site' && <img src={Check} alt="Check" className="mr-2" />}
              At a Testing Site
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5 ml-sm-3 px-1 px-sm-0">
        <div className="col-5 col-sm-3 col-lg-2 p-0 pl-3 pl-sm-0">
          <div className="cancel " onClick={onBack}>
            Back
          </div>
        </div>

        <div className="col-7 col-sm-4 col-md-3 pr-3 pr-sm-0">
          <div
            className="begin"
            style={testSite ? {} : { opacity: '0.4' }}
            onClick={() => {
              if (testSite) onNext(testSite);
            }}
          >
            Next
          </div>
        </div>
      </div>
    </>
  );
}
