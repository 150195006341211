import React, { useState } from 'react';
import './index.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { processAPI } from '../../../api/models/process';
import { setUserProfile, setUser } from '../../../store/action/user';
import { getUserData, getUserDataProfile } from '../../../api/models/user';
import { Alert } from '../../../components';
import { Spinner } from 'react-bootstrap';
import { formatPhoneNumber } from '../../../utils/constraints';

const AdditionalInfoSchema = Yup.object({
  driversLicense: Yup.string().nullable(),
  ssn: Yup.string().nullable(),
});

const AdditionalInfo = (props) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const { setUserDispatch, setUserProfileDispatch, setAdditionalInfoView, setInsuranceDone } =
    props;

  async function nonInsuranceSubmit(values) {
    const { jwt, user_id } = props.userAuthentication;
    setLoading(true);
    try {
      const data = await processAPI({
        key: jwt,
        methodType: 'POST',
        data: {
          driversLicense: values.driversLicense || null,
          ssn: values.ssn || null,
        },
        endpoint: 'no-insurance',
      });

      if (data?.status) {
        const userData = await getUserData(user_id, jwt);
        setUserDispatch(userData.data);
        const userProfile = await getUserDataProfile(user_id, jwt);
        setUserProfileDispatch(userProfile);
        setAdditionalInfoView(false);
        setInsuranceDone(true);
      } else {
        setShowAlert({ show: true, message: 'Failed', type: 'error' });
      }
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }
    setLoading(false);
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  return (
    <>
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5 px-2 IsInsured_ask">Additional Information</div>
        <div className="mt-3 mx-3  d-flex justify-content-center">
          Would you like to upload your Drivers License Number or your Social Security Number?
        </div>
        <div className="mt-3">This is not required to proceed.</div>

        <div className="mt-5 addition_form">
          <Formik
            initialValues={{
              driversLicense: '',
              ssn: '',
            }}
            validationSchema={AdditionalInfoSchema}
            onSubmit={nonInsuranceSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div
                  className={
                    values?.ssn?.length > 0 ? 'insurance-label-disable' : 'insurance-label'
                  }
                >
                  Drivers License Number
                </div>
                <div className="position-relative pb-1">
                  <input
                    className={
                      values?.ssn?.length > 0 ? 'insurance-input-disable' : 'insurance-input'
                    }
                    name="driversLicense"
                    placeholder="Enter Drivers License Number"
                    value={values.driversLicense}
                    onChange={(e) => {
                      setFieldValue('driversLicense', e.target.value);
                      setFieldValue('ssn', '');
                    }}
                  />
                </div>
                {values?.driversLicense &&
                  (values?.driversLicense?.length > 9 || values?.driversLicense?.length < 9) && (
                    <div style={{ color: 'red' }}>Drivers License number should be of 9 digits</div>
                  )}

                <div className="d-flex my-4 justify-content-center align-items-center or_text">
                  or
                </div>

                <div
                  className={
                    values?.driversLicense?.length > 0
                      ? 'insurance-label-disable'
                      : 'insurance-label'
                  }
                >
                  Social Security Number
                </div>
                <div className="position-relative pb-1">
                  <input
                    className={
                      values?.driversLicense?.length > 0
                        ? 'insurance-input-disable'
                        : 'insurance-input'
                    }
                    name="ssn"
                    placeholder="Enter Social Security Number"
                    value={values.ssn}
                    onChange={(e) => {
                      setFieldValue('ssn', formatPhoneNumber(e.target.value));
                      setFieldValue('driversLicense', '');
                    }}
                  />
                </div>
                {values?.ssn && (values?.ssn?.length > 11 || values?.ssn?.length < 11) && (
                  <div style={{ color: 'red' }}>
                    Social Security Number should be of the format '###-##-####'
                  </div>
                )}

                <div className="mt-4 mb-5 pt-3 pb-5">
                  <button disabled={loading} type="submit" className="btn_submit_ ">
                    {loading ? <Spinner animation="border" variant="secondary" /> : 'Next'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userAuthentication: state.authentication,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserProfileDispatch(payload) {
    dispatch(setUserProfile(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(AdditionalInfo);
