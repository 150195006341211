import React, { useState } from 'react';
import './index.css';
import VaccineIcon from '../../../assets/image/aura_vaccine_icon.png';
import Check from '../../../assets/image/check.png';

const IsVaccinated = ({ onSubmit }) => {
  const [isVaccinated, setIsVaccinated] = useState(true);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <div className="text-center">
            <img src={VaccineIcon} alt="VaccineIcon" />
          </div>

          <div className="mt-4 px-2 IsVaccinated_ask">Have you been vaccinated for COVID-19?</div>

          <div className="mt-5 p-3">
            <div
              onClick={() => setIsVaccinated(true)}
              className={` ${
                isVaccinated ? 'IsVaccinated_selectedBtn' : 'IsVaccinated_notSelectBtn'
              }`}
            >
              {isVaccinated && <img src={Check} alt="Check" className="mr-2" />}
              Yes, I have been vaccinated
            </div>

            <div
              onClick={() => setIsVaccinated(false)}
              className={`mt-4 ${
                !isVaccinated ? 'IsVaccinated_selectedBtn' : 'IsVaccinated_notSelectBtn'
              }`}
            >
              {!isVaccinated && <img src={Check} alt="Check" className="mr-2" />}
              No, I have not been vaccinated
            </div>
          </div>

          <div className="my-5 pt-5 px-3">
            <div
              className="IsVaccinated_next"
              onClick={() => {
                onSubmit(isVaccinated);
              }}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IsVaccinated;
