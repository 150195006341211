import { AUTHENTICATION, LOGOUT } from '../types';

const initialState = {
  status: '',
  loading: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION:
      return { ...action.payload };
    case LOGOUT:
      return { status: 'logout', loading: false };
    default:
      return state;
  }
};

export default authReducer;
