import { SEARCH_RESULTS, SEARCH_SELECTED_RESULTS } from '../types';

const initialState = {
  results: [],
  selectedResults: [],
};

const searchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RESULTS:
      return { ...state, results: action.payload };
    case SEARCH_SELECTED_RESULTS:
      return { ...state, selectedResults: action.payload };
    default:
      return state;
  }
};

export default searchResultsReducer;
