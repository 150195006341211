import { createStore, combineReducers } from 'redux';

import authReducer from './reducer/auth';
import userReducer from './reducer/user';
import resultsReducer from './reducer/results';
import alertReducer from './reducer/alert';
import searchReducer from './reducer/search';
import familyReducer from './reducer/family';
import vaxReducer from './reducer/vax';
import flowReducer from './reducer/flow';

const appReducer = combineReducers({
  authentication: authReducer,
  user: userReducer,
  results: resultsReducer,
  alert: alertReducer,
  search: searchReducer,
  family: familyReducer,
  vax: vaxReducer,
  flow: flowReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const configureStore = () =>
  createStore(
    rootReducer,
    window &&
      window.location &&
      window.location.hostname === 'localhost' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export default configureStore;
