import React from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import CloseIcon from '../../assets/svg/close.svg';
import './SearchInput.css';

function SearchInput({ value, onChange, onClearText }) {
  return (
    <InputGroup className="mb-3">
      <FormControl
        aria-describedby="basic-addon1"
        className="input largeText"
        value={value}
        onChange={onChange}
      />
      <InputGroup.Prepend className="close-container" onClick={onClearText}>
        <img alt="close" src={CloseIcon} width={20} />
      </InputGroup.Prepend>
    </InputGroup>
  );
}

export default SearchInput;
