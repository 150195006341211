import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

import {
  Layout,
  Divider,
  MultipleButton,
  Checkbox,
  Button,
  Alert,
  ListForm,
} from '../../components';
import { capitalize, processAddress } from '../../api';
import { search } from '../../api/models/search';
import { processAPI } from '../../api/models/process';
import { getTestLocationAvailability } from '../../api/models/testLocation';
import { getUserFamilyMembers } from '../../api/models/user';
import { setAlert, clearAlert } from '../../store/action/alert';
import { setUserAppointments } from '../../store/action/user';
import css from './Schedule.module.css';
import { useTranslation } from 'react-i18next';

let timeout;

const Schedule = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { alertDispatch, clearAlertDispatch, profile, appointments, setAppointmentDispatch } =
    props;
  const { id, name } = useParams();

  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedData, setSelectedData] = useState({});
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({ ...useParams() });
  const [noLocationFound, setNoLocationFound] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });

  const testDueDate = profile.attributes['next-test-due'];
  const theDate = moment(testDueDate);
  const navFamily = profile.attributes['nav-family-groups'];
  const [familyMembers, setFamilyMembers] = useState([]);
  const [familyMember, setFamilyMember] = useState('');

  useEffect(() => {
    if (location.id) {
      loadData(theDate, 0, location.id);
      loadFamilyData();
    } else {
      loadUserLocation(theDate, 0, profile.attributes);
    }
  }, []);

  async function loadUserLocation(date, index, location) {
    const { longitude, latitude } = location;
    const latLong = `${latitude},${longitude}`;
    const data = await search(latLong, 'lat-lon');

    if (data && data.data && data.data[0]) {
      const address = data.data[0];
      setLocation({ id: address.id, name: address.attributes.name });
      await loadData(date, index, address.id);
      setNoLocationFound(false);
    } else {
      // philadelphia default geolocation
      // const latLong = { longitude: '-75.1979', latitude: '39.957494' };
      // loadUserLocation(dates[0], 0, latLong);
      setNoLocationFound(true);
      setLoading(false);
    }
  }

  function isSelected(time, values, id) {
    const found = values.filter((item) => {
      const appointmentTime = moment
        .utc(item.attributes['appointment-time'])
        .format('YYYY-MM-DD HH:mm');
      const locationId = item.attributes['medtest-location-id'];
      const selectedTime = `${data[selectedButton].date} ${time}`;
      return appointmentTime == selectedTime && locationId == id;
    });
    return found.length > 0;
  }

  async function loadData(date, index, id) {
    setLoading(true);
    const formatDate = moment(date).format('YYYY-MM-DD');
    const result = await getTestLocationAvailability(formatDate, id);

    const { jwt } = props.userAuthentication;

    const { data, included } = await processAPI({
      endpoint: 'medtest-location-appointments?include=medtest-location',
      methodType: 'GET',
      key: jwt,
      dataIncluded: true,
    });
    const dataAppointment = processAddress(data, included);
    // const serializeData = result.map((item) => ({
    //   time: item,
    //   timeFormatted: formatTime(item),
    //   selected: isSelected(item, dataAppointment, id, formatDate),
    // }));
    // const newData = [...data];
    // newData[index] = serializeData;
    setData(result);
    setLoading(false);
    setAppointmentDispatch(dataAppointment);
  }

  async function loadFamilyData() {
    setLoading(true);
    const { jwt, user_id } = props.userAuthentication;

    let familyMemberList = (await getUserFamilyMembers(user_id, jwt)).included || [];
    if (familyMemberList && familyMemberList.length > 0) {
      setFamilyMembers(familyMemberList);
    }
    setLoading(false);
  }

  function formatTime(hr) {
    return moment(`2020-01-01 ${hr}`).format('h:mm a');
  }

  function selectButton(index, date) {
    setSelectedData(null);
  }

  function getDay(date) {
    return moment(date).format('ddd');
  }

  function getDate(date) {
    return moment(date).format('MMM D');
  }

  function updateList(item, selected) {
    setSelectedData(selected ? '' : item);
  }

  async function postData() {
    try {
      setLoading(true);
      const { jwt, user_id: userId } = props.userAuthentication;
      const endpoint = 'medtest-location-appointments';
      const appointmentTime = `${data[selectedButton].date} ${selectedData}`;
      const uid = familyMember === '' ? userId : familyMember;

      const myData = {
        data: {
          type: endpoint,
          attributes: {
            'user-id': uid,
            'medtest-location-id': location.id,
            'appointment-time': appointmentTime,
          },
        },
      };

      let response = await processAPI({
        endpoint,
        methodType: 'POST',
        key: jwt,
        data: myData,
      });

      alertDispatch({
        status: 'appointment',
        text: appointmentTime,
      });
      setLoading(false);
      setShowAlert({ show: true, message: t('successBookedAppointment'), type: 'success' });

      console.log(response);
      history.push({
        pathname: '/confirmation',
        state: { appointmentTime, location: location.name },
      });

      // const newData = [...data];
      // const indexData = newData[selectedButton].findIndex((item) => item.time === selectedData.time);
      // newData[selectedButton][indexData] = selectedData;
      // setData(newData);

      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        clearAlertDispatch();
      }, 5000);
    } catch (error) {
      const { title } = error[0];
      setLoading(false);
      setShowAlert({ show: true, message: title, type: 'error' });
    }

    // history.goBack();
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  function handleButtonPress(index) {
    setSelectedButton(index);
    setSelectedData(null);
  }

  return (
    <Layout headerTitle="Schedule" onClickBack={() => history.goBack()} back>
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.listContainer}>
            <div className={css.container1}>
              <div>
                <div className="d-flex justify-content-between">
                  <p className="medium-sub-font">{t('locationOfTesting')}</p>
                  <p
                    className={classNames('medium-sub-font-blue', css.editLocation)}
                    onClick={() => history.push('/testing')}
                  >
                    Edit location
                  </p>
                </div>
                {noLocationFound && (
                  <p className="normal-font w-75 mt-1">{t('cantDetermineLocation')}</p>
                )}
                <p className="normal-font w-75 mt-1">{location && location.name}</p>
                <Divider className="mt-4" />
              </div>
              {navFamily && familyMembers && familyMembers.length > 0 && (
                <>
                  <ListForm
                    title={t('familyMember')}
                    value={familyMember}
                    onChange={(e) => setFamilyMember(e.target.value)}
                    select
                  >
                    {familyMembers.map(({ attributes, id }) => (
                      <MenuItem className="normal-font" value={id}>
                        {attributes['first-name']} {attributes['last-name']}
                      </MenuItem>
                    ))}
                  </ListForm>
                </>
              )}
              {!noLocationFound && (
                <>
                  <div className="mt-4 mb-4">
                    <p className="card-font text-center">
                      {t('availableTimeslots')}{' '}
                      <span className="normal-font font-weight-bold">COVID-19 Testing</span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-center flex-wrap flex-wrap">
                    {data &&
                      data.length > 0 &&
                      data.map((item, index) => (
                        <MultipleButton
                          className="mb-1"
                          title={getDay(data[index].date)}
                          subTitle={getDate(data[index].date)}
                          selected={selectedButton === index}
                          onClick={() => handleButtonPress(index)}
                        />
                      ))}
                  </div>

                  {loading && (
                    <div className="d-flex justify-content-center mt-4">
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  )}
                  {!loading && data && data.length > 0 && data[selectedButton].length === 0 && (
                    <div className="mt-4 mb-4">
                      <p className="card-font text-center">{t('noAvailableTime')}</p>
                    </div>
                  )}
                  <div className="mt-4">
                    {data &&
                      data.length > 0 &&
                      data[selectedButton].available_times.map((item, index) => (
                        <div>
                          <Divider />
                          <div className="mt-2 mb-2">
                            <Checkbox
                              label={moment(item, 'hh:mm').format('LT')}
                              selected={
                                item === selectedData || isSelected(item, appointments, location.id)
                              }
                              handleChange={(value) => updateList(item, value)}
                              labelColor={!item.selected && '#777777'}
                            />
                          </div>
                        </div>
                      ))}
                    <Divider />
                  </div>
                  <div className={css.sticky}>
                    <Button
                      disabled={!selectedData}
                      className={classNames('mt-4 mb-2')}
                      text={t('bookAppointment')}
                      loading={loading}
                      onClick={postData}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className={css.flex1}></div> */}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userAuthentication: state.authentication,
  profile: state.user.profile,
  alertStatus: state.alert,
  appointments: state.user.appointments,
});

const mapDispatchToProps = (dispatch) => ({
  alertDispatch: (payload) => {
    dispatch(setAlert(payload));
  },
  clearAlertDispatch: () => {
    dispatch(clearAlert());
  },
  setAppointmentDispatch(payload) {
    dispatch(setUserAppointments(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
