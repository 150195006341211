import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import {
  Layout,
  Table,
  ListResults,
  StrokeButton,
  Button,
  Modal,
  DataList,
} from '../../components';
import css from './Contacts.module.css';
import { processAPI } from '../../api/models/process';
import { processColor } from '../../api';
import { setUserContacts, deleteUserContact } from '../../store/action/user';
import AddUserIcon from '../../assets/svg/add_contact.svg';

const Contacts = (props) => {
  const history = useHistory();

  const { userContacts, setContactsDispatch, selectedDataResult, deleteUserContactDispatch } =
    props;
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});

  let iconColor;

  if (selectedDataResult['test-result']) {
    const result = selectedDataResult['test-result'] || 'Unknown';
    const color = selectedDataResult.color.toLowerCase();
    iconColor = processColor(color, result);
  }

  // const { color, sign } = processSignColor(
  //   selectedDataResult['color']?.toLowerCase(),
  // );

  useEffect(() => {
    loadContacts();
  }, []);

  useEffect(() => {
    if (userContacts.length > 0) {
      const groupBy = groupContacts(userContacts);
      setContact(groupBy);
    }
  }, [userContacts]);

  async function loadContacts() {
    // const result = await getStoredData(USER_CONTACT_KEY);
    const { jwt } = props.userAuthentication;

    const result = await processAPI({
      endpoint: 'user-contacts',
      methodType: 'GET',
      key: jwt,
    });
    setLoading(false);
    setContactsDispatch(result);
  }

  function groupContacts(data) {
    return data.reduce((h, obj) => {
      h[obj.attributes['contact-details'].category] = (
        h[obj.attributes['contact-details'].category] || []
      ).concat({
        ...obj,
        name: `${obj.attributes['contact-details'].givenName} ${obj.attributes['contact-details'].familyName}`,
      });
      return h;
    }, {});
  }

  function selectContact(params) {
    setSelectedContact({ ...params.attributes['contact-details'], id: params.id });
    showModal();
  }

  function showModal() {
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }

  async function deleteContact(id) {
    setLoading(true);
    try {
      const { jwt: key } = props.userAuthentication;
      const data = {
        data: {
          id,
          type: 'user-contacts',
        },
      };

      await processAPI({ data, key, methodType: 'DELETE', endpoint: `user-contacts/${id}` });
    } catch (err) {
      console.log('error', err);
    }

    const index = userContacts.findIndex((item) => item.id === id);
    deleteUserContactDispatch(index);
    setLoading(false);
    closeModal();
  }

  return (
    <Layout headerTitle="Contact Tracing" onClickBack={() => history.goBack()} back>
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.listContainer}>
            <div className={css.container1}>
              {selectedDataResult['medtest-type'] && (
                <ListResults
                  full
                  type={selectedDataResult['medtest-type']}
                  date={moment(selectedDataResult['test-date']).format('MM/DD/YYYY')}
                  result={selectedDataResult['test-result'] || 'Unknown'}
                  icon={iconColor}
                />
              )}
              {!contact && !loading && <p className="normal-font text-center">No contact list</p>}
              {loading && (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="warning" />
                </div>
              )}
              {contact.familyMembers && (
                <Table
                  headerTitle="Family Members:"
                  data={contact.familyMembers}
                  handleClick={selectContact}
                />
              )}
              {contact.associates && (
                <Table
                  headerTitle="Associates:"
                  data={contact.associates}
                  handleClick={selectContact}
                />
              )}
              {!loading && (
                <div className="mt-3 mb-4 d-flex">
                  <StrokeButton
                    icon={AddUserIcon}
                    className="mr-2"
                    title="Add New Contact"
                    height={46}
                    handlePress={() => history.push('/add-contact')}
                  />
                  <Button className={css.sendButton} text="Submit" width={100} />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className={css.flex1}></div> */}
      </div>
      <Modal
        show={isVisible}
        handleClose={closeModal}
        submitText="Delete"
        submitDisable={loading}
        handleSubmit={() => deleteContact(selectedContact.id)}
        loading={loading}
        title="Contact Details"
      >
        <DataList
          title="Name"
          description={`${selectedContact.givenName} ${selectedContact.familyName}`}
        />
        <DataList
          title="Email"
          description={
            (selectedContact.emailAddresses &&
              selectedContact.emailAddresses[0] &&
              selectedContact.emailAddresses[0].email) ||
            selectedContact.email
          }
        />
        <DataList
          title="Phone"
          description={
            (selectedContact.phoneNumbers &&
              selectedContact.phoneNumbers[0] &&
              selectedContact.phoneNumbers[0].number) ||
            selectedContact.phoneNumber
          }
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userContacts: state.user.contacts,
  selectedDataResult: state.results.selectedResults,
  userAuthentication: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({
  setContactsDispatch(payload) {
    dispatch(setUserContacts(payload));
  },
  deleteUserContactDispatch(index) {
    dispatch(deleteUserContact(index));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
