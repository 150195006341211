import { SET_VAX_APPOINTMENTS, SET_VAX_HISTORY } from '../types';

const initialState = {
  appointments: [],
  history: [],
};

const vaxReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VAX_APPOINTMENTS:
      return { ...state, appointments: action.payload };
    case SET_VAX_HISTORY:
      return { ...state, history: action.payload };
    default:
      return state;
  }
};

export default vaxReducer;
