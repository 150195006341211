import React, { useState } from 'react';
import './index.css';
import ChooseFile from '../../../assets/image/ChooseFile.png';
import FileIcon from '../../../assets/image/Doc.png';
import GreenTick from '../../../assets/image/Green_Tick.png';
import CutIcon from '../../../assets/image/Trash.png';
import Exclamationmark from '../../../assets/image/Exclamationmark_Y.png';
import Modal from 'react-bootstrap/Modal';

export default function UploadFile({ onBack, onNext, value }) {
  const [files, setFiles] = useState(value || []);
  const [openModal, setOpenModal] = useState(false);

  const uploadReport = (event) => {
    const file = event?.target?.files[0];
    setFiles([file]);
  };

  return (
    <>
      <Modal show={openModal} backdrop="static" keyboard={false} dialogClassName="upload_modal">
        <div className="py-4 px-1 p-sm-5 upload_modal_content">
          <div className="row">
            <div className="col-4 p-0 d-flex justify-content-center">
              <img src={Exclamationmark} alt="Exclamationmark" width="75px" height="75px" />
            </div>
            <div className="col-8">
              <div className="UploadFile-header">Upload a Test Result</div>
              <div className="my-2 UploadFile-drag text-left pt-1">
                Please remember to upload a test result file if you receive one in the future.
              </div>
            </div>
          </div>

          <div className="row my-3">
            <div className="col-5">
              <div className="cancel" onClick={() => setOpenModal(false)}>
                Stay
              </div>
            </div>
            <div className="col-7">
              <div className="cancel" onClick={() => onNext([])}>
                Continue without File
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="row mt-lg-4 ml-sm-3 px-1 px-sm-0">
        <div className="col-12 col-sm-7 col-md-6 mb-5 mt-3 px-3 px-sm-0">
          <div className="mb-3 UploadFile-header">Test Result Documentation</div>

          <div className="mb-3 UploadFile-sub-header">
            Please upload an image or document for your test result.
          </div>

          <div className="position-relative mb-3 my-4 pt-2 d-flex align-items-center justify-content-center UploadFile-box">
            <div className=" position-absolute fileslist z-51">
              {files.map((val, i) => (
                <div key={i} className="row border rounded p-0 ml-2 ml-sm-3 mt-4">
                  <div className="col-8 d-flex align-items-center justify-content-center px-1 py-1 px-sm-2">
                    <img src={FileIcon} alt="fileIcon" className="" />
                    <span className="px-1 px-sm-2 fileText">
                      {val?.name?.length > 10 ? `${val?.name?.slice(0, 10)}...` : val?.name}
                    </span>
                    <img src={GreenTick} alt="GreenTick" width="18px" height="18px" className="" />
                  </div>

                  <div className="col-4 p-0 border-left">
                    <div
                      className="d-flex flex-column align-items-center justify-content-center px-2 py-1 px-sm-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        let filesCopy = [...files];
                        filesCopy.splice(i, 1);
                        setFiles([...filesCopy]);
                      }}
                    >
                      <img src={CutIcon} alt="CutIcon" className="" />
                      <span className="replace-text">Replace</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {files?.length === 0 && (
              <>
                <input
                  type="file"
                  className="position-absolute z-50 fileImage"
                  onChange={uploadReport}
                  accept="image/png, image/jpeg, .pdf"
                  style={{ opacity: '0', cursor: 'pointer' }}
                />

                <img src={ChooseFile} alt="ChooseFile" className="fileImage " />

                <div className="UploadFile-drag position-absolute" style={{ top: '80%' }}>
                  or drag and drop files here
                </div>
              </>
            )}
          </div>

          <div className="no-file mt-3">
            <span onClick={() => setOpenModal(true)}>I do not have any test documentation</span>
          </div>
        </div>
      </div>

      <div className="row mb-5 ml-sm-3 px-1 px-sm-0">
        <div className="col-5 col-sm-3  p-0 pl-3 pl-sm-0">
          <div className="cancel " onClick={onBack}>
            Back
          </div>
        </div>

        <div className="col-7 col-sm-4 col-md-3 pr-3 pr-sm-0">
          <div
            className="begin"
            style={files?.length > 0 ? {} : { opacity: '0.4' }}
            onClick={() => {
              if (files?.length > 0) onNext(files);
            }}
          >
            Next
          </div>
        </div>
      </div>
    </>
  );
}
