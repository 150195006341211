import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PromoBg from '../../assets/image/promo_bg.png';
import AppStoreImg from '../../assets/image/app_store.png';
import GoogleStoreImg from '../../assets/image/google_store.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundImage: `url(${PromoBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontFamily: 'SF Pro Display',
    fontSize: 22,
    textAlign: 'center',
    letterSpacing: 0.8,
    color: 'white',
  },
  description: {
    fontFamily: 'SF Pro Display',
    fontSize: 17,
    textAlign: 'center',
    letterSpacing: -0.408,
    color: 'white',
    marginBottom: theme.spacing(4),
  },
  storesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Typography className={classes.title}>Take Aura on the Go with our Mobile App</Typography>
      <Typography className={classes.description}>
        Need instant access to download your results? Download the Aura app today!
      </Typography>
      <div className={classes.storesContainer}>
        <img src={AppStoreImg} alt="app_store" width={150} />
        <img src={GoogleStoreImg} alt="google_store" width={170} />
      </div>
      <Typography className={classes.description}>
        Proudly being used by over 600K+ users.
      </Typography>
    </div>
  );
};

export default Footer;
