import React from 'react';
import { Spinner } from 'react-bootstrap';
import { LogoImageUrl } from '..';
import './Loading.css';

function Loading() {
  return (
    <div className="loading-container">
      <img alt="logo" className="loading-image-container" src={LogoImageUrl()} width="200" />
      <Spinner animation="grow" variant="warning" />
    </div>
  );
}

export default Loading;
