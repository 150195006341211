import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import horizontalKebab from '../../assets/svg/horizontalkebab.svg';
import QRButton from '../../assets/svg/QR_button.svg';
import AuraFamily from '../../assets/image/aura_family_icon_sm.png';
import WhiteNoneCircle from '../../assets/svg/white_none.svg';
import PendingResultCircle from '../../assets/svg/orange_pending.svg';
import Modal2 from 'react-bootstrap/Modal';
import closeButton from '../../assets/svg/close_button.svg';
import historyicon from '../../assets/svg/result-history.svg';
import symptomcheckericon from '../../assets/svg/symptom-checker.svg';
import insuranceicon from '../../assets/svg/insurance-icon.svg';
import documentsicon from '../../assets/svg/documents-icon.svg';
import vaxicon from '../../assets/svg/vax-icon.svg';
import qricon from '../../assets/svg/qr-icon.svg';
import PositiveResultCircle from '../../assets/svg/red_positive.svg';
import NegativeResultCircle from '../../assets/svg/green_negative.svg';

function FamilyMemberCard({
  id,
  index,
  css,
  unsignedDocs,
  attributes,
  numResults,
  mostRecentResult,
  activeTab,
  loadUser,
  loadFamilyMemberQrCode,
}) {
  const [showFamilyModal, setShowFamilyModal] = useState(false);
  const [navResults, setNavResults] = useState(false);
  const [navHealthReport, setNavHealthReport] = useState(false);
  const [navVaccines, setNavVaccines] = useState(false);
  const [navInsurance, setNavInsurance] = useState(false);
  const [navDocuments, setNavDocuments] = useState(false);
  const [navQRCode, setNavQRCode] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const history = useHistory();

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    const user = await loadUser();
    // familyMemberList.unshift(user);
    setNavHealthReport(user?.attributes['health-report']);
    setNavResults(user?.attributes['nav-results']);
    setNavVaccines(user?.attributes['nav-vaccines']);
    setNavInsurance(user?.attributes['requires-insurance']);
    setNavDocuments(user?.attributes['nav-documents']);
    setNavQRCode(user?.attributes['nav-scan']);
  };

  const hideEllipse = useMemo(() => {
    if (
      !navDocuments &&
      !navHealthReport &&
      !navInsurance &&
      !navQRCode &&
      !navResults &&
      !navVaccines
    )
      return true;
    return false;
  }, [navDocuments, navHealthReport, navInsurance, navQRCode, navResults, navVaccines]);

  const goToResultsHistory = () => {
    console.log('member', selectedMember);
    history.push(`/results-history/${selectedMember.id}`);
  };

  const goToSymptomChecker = () => {
    history.push(`/daily-symptom-checker/${selectedMember.id}`);
  };

  const goToInsurance = () => {
    history.push(`/insurance-information/${selectedMember.id}`);
  };

  const goToVaccination = () => {
    history.push(`/vaccine-information/${selectedMember.id}`);
  };

  const goToDocuments = () => {
    history.push(`/documents/${selectedMember.id}`);
  };

  const goToQrCode = () => {
    loadFamilyMemberQrCode(selectedMember.id);
  };

  let resultTypeCircle = PendingResultCircle;
  if (mostRecentResult) {
    switch (mostRecentResult && mostRecentResult.attributes['test-result']) {
      case 'Positive':
        resultTypeCircle = PositiveResultCircle;
        break;
      case 'Negative':
        resultTypeCircle = NegativeResultCircle;
        break;
      default:
        resultTypeCircle = PendingResultCircle;
        break;
    }
  }
  return (
    <div
      className={
        index !== 0
          ? `${css.familyCard}`
          : `${css.familyCard} ${css.familyCard} ${css.familyCardPrimary}`
      }
      style={unsignedDocs?.length ? { background: '#FF77C0' } : {}}
    >
      <div
        className={`${css.familyCardTop} ${
          unsignedDocs?.length ? `${css.bg_gray_light} ml-2 mr-1 my-2` : ''
        }`}
      >
        <div className={css.familyCardLeft}>
          <img
            src={QRButton}
            alt="QR"
            className={css.QRButton}
            onClick={() => {
              if (unsignedDocs?.length) return;
              console.log('QR', id);
              loadFamilyMemberQrCode(id);
            }}
            style={unsignedDocs?.length ? { opacity: '0.4', cursor: 'default' } : {}}
          />
          <h4 className={css.name}>
            {attributes['first-name']} {attributes['last-name']}
          </h4>
          {index == 0 ? (
            <div>
              <h5 className={css.primaryAccount}>Primary Account Holder</h5>
              <h5 className={css.dob}>{numResults || 0} Total Results</h5>
            </div>
          ) : (
            <div>
              <h5 className={`${css.dob} font-italic`}>
                DOB: {moment(attributes['date-of-birth']).format('M/D/yy').toString()}
              </h5>
              {unsignedDocs?.length ? (
                <div className={`${css.dob}`} style={{ opacity: '0.75' }}>
                  Consent required to view
                </div>
              ) : (
                <h5 className={css.dob}>{numResults || 0} Total Results</h5>
              )}
            </div>
          )}
        </div>
        {!hideEllipse && (
          <div
            style={
              unsignedDocs?.length ? { opacity: '0.4', cursor: 'default' } : { cursor: 'pointer' }
            }
            className={css.familyCardRight}
            onClick={(e) => {
              if (unsignedDocs?.length) return;
              //   setClickLocation({ x: e.clientX, y: e.clientY });
              setShowFamilyModal(!showFamilyModal);
              setSelectedMember(Object.assign(attributes, { id }));
            }}
          >
            <img src={horizontalKebab} alt="horizontalKebab" />
          </div>
        )}
      </div>
      <div className={`${css.familyCardBottom} ${unsignedDocs?.length ? 'd-none' : ''}`}>
        <div className={css.mostRecentContainer}>
          <h5 className={css.mostRecent}>
            Most Recent:{' '}
            <span className={css.mostRecentValue}>
              {mostRecentResult ? mostRecentResult.attributes['medtest-type'] : 'No Results'}
            </span>
          </h5>
        </div>
        <div className={css.resultTag}>
          <div className={css.familyCardBottomRight}>
            {mostRecentResult && mostRecentResult.attributes['test-result'] && (
              <div className={css.resultChip}>
                <h5 className={css.resultChipText}>
                  {mostRecentResult && mostRecentResult.attributes['test-result']}
                </h5>
              </div>
            )}
          </div>

          {mostRecentResult && mostRecentResult.attributes['test-result'] ? (
            <img src={resultTypeCircle} alt="resultTypeCircle" />
          ) : (
            <img src={WhiteNoneCircle} alt="resultTypeCircle" />
          )}
        </div>
      </div>

      <div
        className={`${unsignedDocs?.length ? 'd-flex align-items-end' : 'd-none'}`}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.push(`/sign-doc/${id}`, {
            nextRoute: '/family',
            noProgressBar: true,
            activeTab,
          });
        }}
      >
        <img src={AuraFamily} alt="AuraFamily" />
        <div className="flex-grow-1 text-center pb-3 mr-0 mr-sm-3">
          <div className={css.consent_required}>Consent Required</div>
          <div className={css.consent_sign}>Tap to Sign</div>
        </div>
      </div>

      <Modal2
        show={showFamilyModal}
        onHide={() => setShowFamilyModal(false)}
        backdrop="static"
        keyboard={false}
        style={{
          borderRadius: '30px',
          border: 'none',
        }}
      >
        <div className={css.modalHeader}>
          <img
            src={closeButton}
            alt="close"
            className={css.modalCloseButton}
            onClick={() => setShowFamilyModal(false)}
          />
          <p className={css.modalHeaderTitle}>
            {selectedMember['first-name']} {selectedMember['last-name']}
          </p>
        </div>
        <div className={css.modalBody}>
          {[
            navResults && {
              text: 'Results History',
              icon: <img src={historyicon} alt="result-history" />,
              action: goToResultsHistory,
            },
            navHealthReport && {
              text: 'Symptom Checker',
              icon: <img src={symptomcheckericon} alt="symptoms" />,
              action: goToSymptomChecker,
            },
            navInsurance && {
              text: 'Insurance',
              icon: <img src={insuranceicon} alt="insurance" />,
              action: goToInsurance,
            },
            navDocuments && {
              text: 'Documents',
              icon: <img src={documentsicon} alt="documents" />,
              action: goToDocuments,
            },
            navVaccines && {
              text: 'Vaccination Info',
              icon: <img src={vaxicon} alt="Vaccination-Info" />,
              action: goToVaccination,
            },
            navQRCode && {
              text: 'QR Code',
              icon: <img src={qricon} alt="QR Code" />,
              action: goToQrCode,
            },
          ]
            .filter((val) => val)
            .map(
              (item, index) =>
                item && (
                  <div className={css.modalItem} key={index} onClick={item.action}>
                    {item.icon}
                    <p className={css.modalItemText}>{item.text}</p>
                  </div>
                )
            )}
        </div>
      </Modal2>
    </div>
  );
}

export default FamilyMemberCard;
