import React, { useCallback } from 'react';
import { string, object } from 'prop-types';
import cn from 'classnames';
import css from './Input.css';

const Input = ({
  field,
  label,
  type,
  placeholder,
  disabled,
  onBlurHandler = undefined,
  form: { touched, errors, status, setFieldTouched },
}) => {
  const handleBlur = useCallback(() => {
    setFieldTouched(field.name);
    onBlurHandler !== undefined && onBlurHandler(field);
  }, [field, setFieldTouched, onBlurHandler]);

  return (
    <div className="auraField">
      {label && <label>{label}</label>}
      <div>
        <input
          className={cn({
            withError:
              (touched[field.name] && status && status[field.name]) ||
              (touched[field.name] && errors && errors[field.name]),
          })}
          readOnly={disabled}
          onBlurCapture={handleBlur}
          {...field}
          {...{ placeholder, type }}
        />
        {touched[field.name] && status && status[field.name] && <span>{status[field.name]}</span>}
        {touched[field.name] && errors && errors[field.name] && <span>{errors[field.name]}</span>}
      </div>
    </div>
  );
};

Input.propTypes = {
  field: object.isRequired,
  type: string.isRequired,
};

export default Input;
