import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';
import debounce from 'debounce-promise';
import classNames from 'classnames';
import { Button as MaterialButton, ButtonGroup } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';

import { Layout, Alert } from '../../components';
import EditNonInsuranceInformation from './EditNonInsuranceInformation';
import css from './EditInsuranceInformation.module.css';
import { processAPI } from '../../api/models/process';
import { uploadFile } from '../../api/models/forms';
import { setUserInsurance, setUser } from '../../store/action/user';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input/Input';
import SelectInput from '../../components/SelectInput/SelectInput';
import AsyncCreatableSelectInput from '../../components/AsyncCreatableSelectInput/AsyncCreatableSelectInput';
import NumberFormatInput from '../../components/NumberFormatInput/NumberFormatInput';
import { insuranceSchema } from '../../utils/constraints';
import { genderList } from '../../utils/utils';
import { getUserData } from '../../api/models/user';

const EditInsuranceInformation = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = props.user;
  const userData = props?.user?.attributes || {};
  const userProfile = props?.profile?.attributes || {};
  const data = props.userInsurance?.attributes || {};
  const insuranceId = props.userInsurance?.id || '';
  const { setUserInsuranceDispatch } = props;

  const [freeFormPlanName, setFreeFormPlanName] = useState(data['free-form-name']);
  const [hasInsurance, setHasInsurance] = useState(null);
  const [hasOwnInsurance, setHasOwnInsurance] = useState(
    data['insureds-relationship-to-patient'] === 'SEL'
  );
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ show: false });
  const [planList, setPlanList] = useState(
    (data['insurance-company-name'] && [
      {
        value: data['insurance-plan-id'],
        label: data['insurance-company-name'],
      },
    ]) ||
      []
  );
  const [relationshipList, setRelationshipList] = useState([]);
  const [files, setFiles] = useState([]);

  const noData = insuranceId.length === 0;

  useEffect(() => {
    loadRelationship();
  }, []);

  async function searchPlan(planLabel) {
    if (!planLabel) {
      return [];
    }

    const endpoint = `insurance-plans?filter[plan-name]=${planLabel}`;
    const { jwt: key } = props.userAuthentication;

    const data = await processAPI({ endpoint, key, methodType: 'GET' });
    const plans = data.map((item) => {
      const { attributes } = item;
      const label =
        attributes['company-name'] === attributes['plan-name']
          ? attributes['plan-name']
          : `${attributes['plan-name']}-${attributes['company-name']}`;
      return { value: item.id, label };
    });

    setPlanList(plans);
    return plans;
  }

  async function loadRelationship() {
    const endpoint = 'insurance-plans/hl7-relationship-options';
    const { jwt: key } = props.userAuthentication;

    const data = await processAPI({ endpoint, key, methodType: 'GET' });
    const { options } = data;
    const list = options.map((item) => {
      const [label, value] = item;
      return { label, value };
    });

    setRelationshipList(list);
  }

  async function insuranceSubmit(values) {
    const { jwt } = props.userAuthentication;

    setLoading(true);
    let meta;
    try {
      if (files?.length > 0) {
        meta = await getUrl();
        await uploadFile(meta.url, files[0], meta.fields);
      }

      const myData = {
        data: {
          type: 'insurance-policies',
          attributes: {
            'policy-number': values?.insuredIDNumber,
            'group-number': values?.insuredGroupNumber,
            'first-name-of-insured': values?.insuredFirstName,
            'last-name-of-insured': values?.insuredLastName,
            'insureds-address': `${values?.insuredAddress}^ ${values?.insuredAddress2}^ ${values?.insuredCity}^ ${values?.insuredState}^ ${values?.insuredZip}`,
            'insureds-gender': values?.insuredGender,
            'insureds-phone-number': values?.insuredPhone,
            'insureds-relationship-to-patient': values?.patientRelationship,
            'insureds-date-of-birth': values?.insuredDateOfBirth,
            'insurance-plan-id': (values?.insurancePlan && parseInt(values?.insurancePlan)) || null,
            'free-form-name': freeFormPlanName || null,
          },
          relationships: {
            user: {
              data: { type: 'users', id: parseInt(id) },
            },
          },
        },
      };

      !noData && (myData.data.id = insuranceId);
      const data = await processAPI({
        key: jwt,
        methodType: noData ? 'POST' : 'PATCH',
        data: myData,
        endpoint: noData ? 'insurance-policies' : `insurance-policies/${insuranceId}`,
      });

      if (data.id) {
        setShowAlert({
          show: true,
          message: 'Save successfully',
          type: 'success',
        });
        await saveFileName(meta?.fields?.key, data.id);
        await loadUser();
        history.goBack();
      } else {
        setShowAlert({ show: true, message: 'Failed', type: 'error' });
      }
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed', type: 'error' });
    }

    setLoading(false);
  }

  function asyncSearchOptions(text) {
    if (text) {
      return searchPlan(text);
    }
  }

  async function loadUser() {
    const user = await getUserData(props.userAuthentication.user_id, props.userAuthentication.jwt);
    props.setUserDispatch(user.data);
    setUserInsuranceDispatch(user.included);
  }

  async function getUrl() {
    const { jwt } = props.userAuthentication;

    const preSigned = {
      filename: files[0].name,
      image_type: files[0].type,
    };
    const data = await processAPI({
      key: jwt,
      methodType: 'POST',
      data: preSigned,
      endpoint: 'pre-signed-post',
    });
    return data;
  }

  async function saveFileName(fileName, insurancePlanId) {
    const { jwt } = props.userAuthentication;

    const data = await processAPI({
      key: jwt,
      methodType: 'PATCH',
      data: {
        filename: fileName,
        imageable_id: insurancePlanId,
        imageable_type: 'InsurancePolicy',
      },
      endpoint: 'image',
    });

    return data;
  }

  function hideAlert() {
    setShowAlert({ show: false });
  }

  function createNewInsurancePlan(value) {
    setFreeFormPlanName(value);
    setPlanList([{ value, label: value }]);
  }

  function patientRelationsipChange(value) {
    setHasOwnInsurance(value?.label === 'Self');
  }

  const fullAddress = data['insureds-address'];
  const addressArray = fullAddress && fullAddress.split('^');
  let address = '';
  let address2 = '';
  let city = '';
  let state = '';
  let zip = '';
  if (addressArray && addressArray.length === 5) {
    address = addressArray[0].trim();
    address2 = addressArray[1].trim();
    city = addressArray[2].trim();
    state = addressArray[3].trim();
    zip = addressArray[4].trim();
  }
  return (
    <Layout onClickBack={() => history.goBack()} headerTitle={t('editInsuranceInformation')} back>
      <Alert
        open={showAlert.show}
        handleClose={hideAlert}
        message={showAlert.message}
        type={showAlert.type}
      />
      <div className={css.container}>
        <div className={css.flex1}>
          <div className={css.subContainer}>
            <div className={css.hasInsurance}>
              <h4 className="medium-font mb-4">{t('hasInsurance')}</h4>
              <ButtonGroup
                variant="contained"
                color="#78DA76"
                aria-label="contained primary button group"
              >
                <MaterialButton onClick={() => setHasInsurance(true)}>Yes</MaterialButton>
                <MaterialButton onClick={() => setHasInsurance(false)}>No</MaterialButton>
              </ButtonGroup>
            </div>
            {hasInsurance && (
              <>
                <div>
                  <Formik
                    initialValues={{
                      insurancePlan: data['insurance-plan-id'] || data['free-form-name'],
                      insuredIDNumber: data['policy-number'] || '',
                      insuredGroupNumber: data['group-number'] || '',
                      patientRelationship: data['insureds-relationship-to-patient'] || '',
                      insuredFirstName: data['first-name-of-insured'] || '',
                      insuredLastName: data['last-name-of-insured'] || '',
                      insuredAddress: address || '',
                      insuredAddress2: address2 || '',
                      insuredCity: city || '',
                      insuredState: state || '',
                      insuredZip: zip || '',
                      insuredPhone: data['insureds-phone-number'] || '',
                      insuredDateOfBirth: data['insureds-date-of-birth'] || '',
                      insuredGender: data['insureds-gender'] || '',
                    }}
                    validationSchema={insuranceSchema}
                    onSubmit={insuranceSubmit}
                  >
                    {(formikProps) => (
                      <Form className={css.auraForm}>
                        <div className="insurance-form-container">
                          <div className="insurance-form-sub-containers">
                            <Field
                              name="insurancePlan"
                              label="Plan Name"
                              component={AsyncCreatableSelectInput}
                              classNamePrefix="form-select"
                              handleOnCreate={createNewInsurancePlan}
                              asyncLoadOptions={debounce(asyncSearchOptions, 400)}
                              options={planList}
                              defaultList={planList}
                              freeFormPlanName={freeFormPlanName}
                            />
                            <Field
                              name="insuredIDNumber"
                              label="Insured ID Number"
                              placeholder="Insured ID Number"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredGroupNumber"
                              label="Insured Group Number"
                              placeholder="Insured Group Number"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="patientRelationship"
                              label="Patient Relationship to Insured"
                              component={SelectInput}
                              classNamePrefix="form-select"
                              placeholder="Patient Relationship to Insured"
                              options={relationshipList?.map((s) => ({
                                value: s.value,
                                label: s.label,
                              }))}
                              handleChange={patientRelationsipChange}
                            />
                          </div>
                          <div className="insurance-form-sub-containers">
                            <Field
                              name="insuredFirstName"
                              label="Insured First Name"
                              placeholder="Insured First Name"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredLastName"
                              label="Insured Last Name"
                              placeholder="Insured Last Name"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredAddress"
                              label="Insured Address"
                              placeholder="Insured Address"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredAddress2"
                              label="Insured Address 2"
                              placeholder="Insured Address 2"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredCity"
                              label="Insured City"
                              placeholder="Insured City"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredState"
                              label="Insured State"
                              placeholder="Insured State"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredZip"
                              label="Insured Zip"
                              placeholder="Insured Zip"
                              component={Input}
                              type="text"
                            />
                            <Field
                              name="insuredPhone"
                              label="Insured Phone"
                              component={NumberFormatInput}
                              type="text"
                              placeholder="(###) ###-####"
                              format="(###) ###-####"
                            />
                            <Field
                              name="insuredDateOfBirth"
                              label="Insured Date of Birth"
                              placeholder="Insured Date of Birth"
                              component={Input}
                              type="date"
                            />
                            <Field
                              name="insuredGender"
                              label="Insured Gender"
                              component={SelectInput}
                              classNamePrefix="form-select"
                              placeholder="Insured Gender"
                              options={genderList.map((s) => ({
                                value: s.value,
                                label: s.label,
                              }))}
                            />
                          </div>
                        </div>
                        {files && files.length > 0 && (
                          <p className="thin-normal-font my-2">{files[0].name}</p>
                        )}
                        <label
                          htmlFor="cardImage"
                          className={classNames('my-4', css.customFileUpload)}
                        >
                          {t('uploadImage')}
                        </label>
                        <input
                          id="cardImage"
                          accept="image/*"
                          title="&nbsp;"
                          type="file"
                          multiple
                          name="cardImage"
                          className={css.inputfile}
                          onChange={(e) => {
                            setFiles(e.target.files);
                          }}
                        />
                        <Button type="submit" disabled={loading} className="mb-4 mt-4">
                          {loading ? (
                            <Spinner animation="border" variant="secondary" />
                          ) : (
                            t('submit')
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            )}
            {hasInsurance === false && <EditNonInsuranceInformation />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  profile: state.user.userProfile[0],
  userInsurance: state.user.insurance[0],
  userAuthentication: state.authentication,
});

const mapDispatchTopProps = (dispatch) => ({
  setUserDispatch(payload) {
    dispatch(setUser(payload));
  },
  setUserInsuranceDispatch(payload) {
    dispatch(setUserInsurance(payload));
  },
});

export default connect(mapStateToProps, mapDispatchTopProps)(EditInsuranceInformation);
