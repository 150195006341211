import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Accordion, Button, Card, Spinner, Table } from 'react-bootstrap';
import css from './TechSupport.module.css';
import { Layout, StrokeButton, SmallButton, Modal } from '../../components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TechSupportIcon from '../../assets/svg/aura_tech_support_icon.svg';
import CallIcon from '../../assets/svg/callicon.svg';
import MessageIcon from '../../assets/svg/emailus.svg';
import HelpCenterIcon from '../../assets/svg/helpcenter.svg';
import ManComputer from '../../assets/svg/mancomputer.svg';

const TechSupportContainer = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <Layout
      tab="techSupport"
      onClickBack={() => history.goBack()}
      headerTitle={t('technicalSupport')}
      back
    >
      <div className={css.container}>
        <h1 className={css.headerText}>Available Support Services</h1>
        <div className={css.body}>
          <div className={css.bodyLeft}>
            <img src={TechSupportIcon} alt="Tech Support Icon" className={css.techSupportIcon} />
            <h3 className={css.bodyHeaderText}>{t('techSupportHeaderText')}</h3>
            <h4 className={css.bodySubHeaderText}>
              {t('techSupportText')}
              <br />
              {t('techSupportText2')}
            </h4>
            {[
              {
                text: 'Help Center',
                link: 'https://auratracker.zendesk.com/hc/en-us',
                icon: HelpCenterIcon,
              },
              {
                text: 'Message Us',
                link: 'https://auratracker.zendesk.com/hc/en-us/requests/new',
                icon: MessageIcon,
              },
            ].map((item, index) => (
              <a href={item.link} className={css.supportLink}>
                <img src={item.icon} alt={item.text} className={css.supportIcon} />
                <span>{item.text}</span>
              </a>
            ))}
          </div>
          <div className={css.bodyRight}>
            <img src={ManComputer} alt="Tech Support Icon" className={css.techSupportIcon} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TechSupportContainer);
